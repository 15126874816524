<template>
  <div>
    <div class="success-add-datasource">
      <i class="iconfont iconwancheng"></i>
      <p class="success-add-title">新增数据源成功！</p>
      <p class="success-add-guide">您可以在数据源管理界面
        <pd-button type="primary" ghost @click="handleGoDatasource">查看数据源</pd-button>
        或继续
        <pd-button type="primary" ghost @click="handleGooAddDatasource">新增数据源</pd-button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleGoDatasource() {
      this.$router.push({ path: '/data/dataSource' });
    },
    handleGooAddDatasource() {
      window.location.reload();
    }
  }
};
</script>
<style lang="less" scoped>
.success-add-datasource {
  padding: 80px 0;
  text-align: center;

  .iconfont {
    font-size: 80px;
  }

  .iconwancheng {
    color: #52C41A;
  }

  .success-add-title {
    font-size: 24px;
  }

  .success-add-guide {
    font-size: 16px;
    margin-top: 20px;

    button {
      margin: 0 10px;
    }
  }
}
</style>
