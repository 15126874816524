<template>
  <div>
    <Breadcrumb>
      <BreadcrumbItem to="/data/job/list">任务管理</BreadcrumbItem>
      <BreadcrumbItem to="/data/job/create/process">创建任务</BreadcrumbItem>
    </Breadcrumb>
    <div class="create-task-process">
      <div class="create-task-step">
        <Steps :current="currentStep" v-if="!JSON.parse(taskInfo.ifWholeDb)">
          <Step title="源&目标设置"></Step>
          <Step title="功能配置"></Step>
          <Step title="表&action过滤"></Step>
          <Step title="数据处理"></Step>
          <Step title="创建确认"></Step>
        </Steps>
        <Steps :current="currentStep" v-if="JSON.parse(taskInfo.ifWholeDb)">
          <Step title="任务设置" icon="md-options"></Step>
          <Step title="源&目标设置" icon="md-paper"></Step>
          <Step title="创建确认" icon="md-apps"></Step>
        </Steps>
      </div>
      <div class="create-task-body">
        <OriginalConfig v-if="currentStep===0" ref="Originalconfig" :deployType="deployType"
                        :updateStep="handleNextStep"
                        :updateLoading="updateLoading"
                        :taskInfo="taskInfo"
                        :updateShowAddDataSource="updateShowAddDataSource"></OriginalConfig>
        <FunctionConfig v-if="currentStep===1" ref="functionConfig" :deployType="deployType"
                        :updateStep="handleNextStep"
                        :updateLoading="updateLoading" :taskInfo="taskInfo"
                        :updateShowAddDataSource="updateShowAddDataSource"></FunctionConfig>
        <TableFilter :updateLoading="updateLoading" :taskInfo="taskInfo"
                     v-if="currentStep===2&&!JSON.parse(taskInfo.ifWholeDb)"
                     ref="TableFilter"
                     :updateStep="handleNextStep"></TableFilter>
        <CleanData :updateLoading="updateLoading" :taskInfo="taskInfo" ref="CleanData"
                   :handleStep="handleStep"
                   v-if="currentStep===3&&!JSON.parse(taskInfo.ifWholeDb)"></CleanData>
        <TaskInfo :taskInfo="taskInfo"
                  v-if="DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&(currentStep===4||currentStep===1&&JSON.parse(taskInfo.ifWholeDb))"></TaskInfo>
        <TaskInfoWithNoSourceDb :taskInfo="taskInfo"
                                v-if="DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1&&
                                        (currentStep===4||currentStep===1&&JSON.parse(taskInfo.ifWholeDb))"></TaskInfoWithNoSourceDb>
      </div>
    </div>
    <div class="create-task-tools">
            <span style="position: relative">
                <Button v-if="currentStep===0" @click="handleReturn">返回任务管理</Button>
                <pd-button :loading="loading" @click="handleRefresh"
                           v-if="currentStep === 2 || currentStep === 3"><Icon
                  type="md-refresh"/>刷新数据</pd-button>
                <Button v-if="currentStep!==0" @click="handleStep('pre')">上一步</Button>
                <Button :loading="loading" type="primary" @click="handleStep('next')"
                        v-if="(taskInfo.schemaWhiteListLevel !== 'DB')&&currentStep!==4||(taskInfo.schemaWhiteListLevel === 'DB'&&currentStep<1)">下一步</Button>
                <Button type="primary" @click="handleCreateTask"
                        v-if="currentStep===4||(currentStep===1&&taskInfo.schemaWhiteListLevel === 'DB')">创建任务</Button>
              <!--                             <Button :loading="loading" type="primary" @click="handleStep('next')"-->
              <!--                                     v-if="currentStep!==4">下一步</Button>-->
              <!--                <Button type="primary" @click="handleCreateTask"-->
              <!--                        v-if="currentStep===4">创建任务</Button>-->
                <span style="margin-left: 20px;position: absolute;right: -240px;top: -20px"
                      v-if="currentStep===4||(currentStep===1&&taskInfo.schemaWhiteListLevel === 'DB')">
                <Checkbox v-model="makeSure"></Checkbox>
                <a style="color: #333333" href="https://doc.clougence.com/docs/en/sla"
                   target="_blank">CloudCanal服务等级协议(SLA)</a>
                </span>
            </span>
    </div>
    <Modal
      v-model="showConfirm"
      title="创建确认"
      footer-hide
      @on-ok="handleConfirm">
      <div>
        <p>确认要创建该任务吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <Button type="primary" @click="handleConfirm">确认</Button>
          <Button @click="handleCancel">取消</Button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showCreateStatus"
      :mask-closable="false"
      :footer-hide="true"
      title="任务创建状态">
      <div>
        <Row :gutter="16">
          <Col span="12">
            <Form label-position="left" :label-width="80">
              <FormItem label="任务描述">
                {{ creatingStatus.dataJobName }}
              </FormItem>
              <FormItem label="任务类型">
                {{ creatingStatus.dataJobType }}
              </FormItem>
            </Form>
          </Col>
          <Col span="12">
            <div style="margin-top: 30px" v-if="creatingStatus.state==='PRECHECK'">
              <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
                预检中...</p>
            </div>
            <div style="margin-top: 20px" v-if="creatingStatus.state==='CREATING'">
              <Progress :percent="currentPercentage" :stroke-width="20" status="active"
                        text-inside/>
              <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
                创建中...</p>
            </div>
            <div style="color: #19be6b;text-align: center;"
                 v-if="creatingStatus.state==='INIT'">
              <Icon style="font-size: 60px" type="ios-checkmark-circle"/>
              <p style="padding-left:10px;margin-top: 16px;font-weight:500">创建成功！</p>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
    <Modal
      v-model="showPreCheck"
      :closable="false"
      :footer-hide="true"
      :mask-closable="false"
      width="1000px"
      title="预检中">
      <div>
        <div class="preCheck-title">
          <span class="preCheck-title-desc">{{ preCheckStatusDesc[preCheckStatus].desc }}</span>
          共检查<span class="preCheck-title-count">{{ preCheckItems.length }}</span>
          项，已检查<span class="preCheck-title-count">{{ preCheckItemsCheckedCount }}</span>项，失败
          <span class="preCheck-title-count">{{ preCheckItemsErrorCount }}</span>项。
        </div>
        <div style="max-height: 400px;overflow: auto">
          <Table stripe border size="small" :columns="preCheckColumns" :data="preCheckItems">
            <template slot-scope="{ row }" slot="preCheckResult">
              <div style="font-size: 18px">
                <Icon type="md-checkmark" style="color: #19be6b" v-if="row.success&&row.checked"/>
                <Icon type="md-close" style="color: #ed4014" v-if="!row.success&&row.checked"/>
                <span v-if="!row.checked" style="font-size: 12px;color: #808695;">待检查</span>
              </div>
            </template>
          </Table>
        </div>
        <p style="margin: 20px 0;text-align: center">
          <pd-button style="width: 120px;height: 32px;line-height: 30px;margin-right: 20px" type="default"
                     @click="handleHidePrecheck">关闭
          </pd-button>
          <pd-button style="width: 120px;height: 32px;line-height: 30px" type="default"
                     @click="handleHidePrecheck($event,true)">忽略并继续
          </pd-button>
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import { finalCheck } from '@services/api/job';
import { queryDeployMode } from '@services/api/constant';
import { createJob } from '@services/api/upload';
import OriginalConfig from '@components/function/dataJob/createJob/OriginalConfig';
import CleanData from '@components/function/dataJob/createJob/CleanData';
import TableFilter from '@components/function/dataJob/createJob/TableFilter';
import TaskInfo from '@components/function/dataJob/createJob/TaskInfo';
import FunctionConfig from '@components/function/dataJob/createJob/FunctionConfig';
import CreateJobMixin from '@components/function/mixin/createJobMixin';
import TaskInfoWithNoSourceDb from '@components/function/dataJob/createJob/TaskInfoWithNoSourceDb';
import { INIT_FUNCTIONAL_CONFIG } from '@consts/createTask';
import store from '../../store/index';
import DataSourceGroup from '../dataSourceGroup';
import Mapping from '../util';

export default {
  mixins: [CreateJobMixin],
  components: {
    OriginalConfig,
    CleanData,
    TableFilter,
    TaskInfo,
    FunctionConfig,
    TaskInfoWithNoSourceDb
  },
  created() {
    const type = this.$route.params.type;

    if (type === '1') {
      this.taskType = '数据迁移';
    } else if (type === '2') {
      this.taskType = '数据同步';
    } else {
      this.taskType = '数据订阅';
    }
    queryDeployMode()
      .then((res) => {
        if (res.data.code === '1') {
          this.deployType = res.data.data;
        }
      });
    console.log('jobData', store.state.jobData, this.taskInfo);
    if (store.state.jobData) {
      const jobData = store.state.jobData;
      this.taskInfo.clusterId = jobData.dataTasks[0].clusterId;
      this.taskInfo.sourceDataSourceIdd = jobData.sourceDsVO.id;
      this.taskInfo.sourceInstanceType = jobData.sourceDsVO.deployType;
      this.taskInfo.sourceType = jobData.sourceDsVO.dataSourceType;
      this.taskInfo.sourceInstance = jobData.sourceDsVO.host;
      this.taskInfo.sourceHostType = jobData.sourceDsVO.hostType;
      this.taskInfo.sourceDataSourceId = jobData.sourceDsVO.id;
      this.taskInfo.sourcePrivateHost = jobData.sourceDsVO.privateHost;
      this.taskInfo.sourceInstanceId = jobData.sourceDsVO.instanceId;
      this.taskInfo.sourcePublicHost = jobData.sourceDsVO.publicHost;
      this.taskInfo.sourceInstanceValue = `${this.taskInfo.sourceInstance}|${this.taskInfo.sourceDataSourceId}|${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourcePublicHost}|${
        this.taskInfo.sourceInstanceId}`;
      this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
      this.taskInfo.sinkInstanceType = jobData.targetDsVO.deployType;
      this.taskInfo.sinkType = jobData.targetDsVO.dataSourceType;
      this.taskInfo.sinkInstance = jobData.targetDsVO.host;
      this.taskInfo.sinkHostType = jobData.targetDsVO.hostType;
      this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
      this.taskInfo.sinkPrivateHost = jobData.targetDsVO.privateHost;
      this.taskInfo.sinkInstanceId = jobData.targetDsVO.instanceId;
      this.taskInfo.sinkPublicHost = jobData.targetDsVO.publicHost;
      this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkInstance}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
        this.taskInfo.sinkInstanceId}`;
      this.taskInfo.type = jobData.dataJobType;
      this.taskInfo.desc = jobData.dataJobDesc;
      console.log(1);
      setTimeout(() => {
        console.log('taskinfo', this.taskInfo, this.$refs, 1212, this.taskInfo.sinkInstanceId);
      }, 300);
    }
  },
  beforeDestroy() {
    clearInterval(this.getPercantage);
  },
  computed: {},
  data() {
    return {
      store,
      DataSourceGroup,
      showNotNullTable: false,
      loading: false,
      deployType: 'PUBLIC_CLOUD',
      getPercantage: '',
      clusterList: [],
      showSolution: false,
      currentPage: 0,
      Mapping,
      showAddDataSource: false,
      showAddDataSourceException: false,
      showAddClusterException: false,
      addDataSourceType: '',
      makeSure: true,
      createData: '',
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      preCheckStatus: 'error',
      preCheckItems: [],
      preCheckColumns: [
        {
          title: '类型',
          key: 'preCheckType',
          width: 200
        },
        {
          title: '标题',
          key: 'title'
        },
        {
          title: '措施',
          key: 'passRequirement'
        },
        {
          title: '失败目标',
          render: (h, params) => {
            let value = '';

            if (params.row.contextDbName) {
              if (params.row.contextTableName) {
                if (params.row.contextColumnName) {
                  if (params.row.varActualValue) {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}:${params.row.varActualValue}`;
                  } else {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}`;
                  }
                } else {
                  value = `${params.row.contextDbName}.${params.row.contextTableName}`;
                }
              } else {
                value = `${params.row.contextDbName}`;
              }
            }
            return h('div', {}, params.row.checked && !params.row.success ? value : '');
          }
        },
        {
          title: '检查结果',
          slot: 'preCheckResult',
          width: 100
        }
      ],
      preCheckItemsSuccessCount: 0,
      preCheckItemsErrorCount: 0,
      preCheckItemsCheckedCount: 0,
      showPreCheck: false,
      showConfirm: false,
      showCreateStatus: false,
      taskType: '',
      currentStep: 0,
      preCheckStatusDesc: {
        processing: {
          desc: '预检中',
          color: ''
        },
        success: {
          desc: '预检成功',
          color: '#19be6b'
        },
        error: {
          desc: '预检失败',
          color: '#ed4014'
        }
      },
      currentPercentage: 0,
      sourceInstanceValue: '',
      sinkInstanceValue: '',
      taskInfo: {
        // original
        originalConfigHistory: false,
        dataSourceDeployTypes: [],
        sourceConnection: false,
        sinkConnection: false,
        sourceConnectionLoading: false,
        sinkConnectionLoading: false,
        showSourceConnection: false,
        showSinkConnection: false,
        clusterList: [],
        clusterId: 0,
        sourceDataSourceTypes: [],
        sinkDataSourceTypes: [],
        sourceDbList: [],
        sinkDbList: [],
        sourceInstanceList: [],
        sinkInstanceList: [],
        sourceCharsetList: [],
        targetCharsetList: [],
        showSinkAddWhiteList: false,
        showSourceAddWhiteList: false,
        mappingMode: 'advanced',
        dbMap: [
          {
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'public',
            sourceSchema: 'public'
          }
        ],
        schemaTypeList: [],
        engineTypeList: [],
        dstSchemaLessFormat: 'CLOUDCANAL_JSON_FOR_MQ',
        srcSchemaLessFormat: 'CLOUDCANAL_JSON_FOR_MQ',
        consumerGroupId: '',
        srcRabbitMqVhost: '',
        dstRabbitMqVhost: '',
        targetCaseSensitive: 'true',
        dstRabbitExchange: '',
        sourceType: 'MySQL',
        sinkType: 'MySQL',
        sourceInstance: '',
        sinkInstance: '',
        sourceInstanceId: '',
        sinkInstanceId: '',
        sourceAccount: '',
        sourcePasswd: '',
        sinkAccount: '',
        sinkPasswd: '',
        sourceInstanceType: 'SELF_MAINTENANCE',
        sinkInstanceType: 'SELF_MAINTENANCE',
        sourceHostType: 'PRIVATE',
        targetHostType: 'PRIVATE',
        sourcePrivateHost: '',
        sourcePublicHost: '',
        sinkPrivateHost: '',
        sinkPublicHost: '',
        targetDataSourceId: 0,
        sourceDataSourceId: 0,
        // functional
        // functionalConfigHistory: false,
        // jobTypeList: {},
        // resourceData: {},
        // loopRunTypes: {},
        // type: 'SYNC',
        // ddl: 'false',
        // mode: {
        //   synchronize: false,
        //   init: true,
        //   shortTermNum: 7
        // },
        // specsMap: {},
        // checkMode: 'noCheck',
        // fullPeriod: false,
        // specKind: 'Balance',
        // spec: {},
        ...INIT_FUNCTIONAL_CONFIG,
        // tableFilter
        commonRule: '',
        firstToTableFilter: false,
        compareSelectedDbs: [],
        selectedTables: {},
        sinkTableList: {},
        // cleanData
        firstToCleanData: false,
        selectedColumns: {},
        tableInfo: [],
        setPkAndIdFlags: {},
        sinkColumns: {},
        // 默认值 无修改
        sourceCaseSensitive: 'true',
        enableAutoIncrement: 'true',
        sourceSpecifiedUserPassword: false,
        useQualifiers: 'true',
        ifWholeDb: 'false',
        taskName: 'db1_db1_migrate_001',
        fullJVMHeapMB: 2048,
        checkJVMHeapMB: 2048,
        incrementJVMHeapMB: 2048,
        whiteList: ['db', 'table', 'column'],
        // old
        showLoading: {},
        ddlAction: ['create', 'alter', 'drop'],
        checkPeriodCronExpr: '',
        fullPeriodCronExpr: '',
        srcSchemaIsNotWhiteList: false,
        pkgDescription: '',
        firstTime: true,
        sinkExtraSetting: false,
        sourceExtraSetting: false,
        globalTimeZone: '+08:00',
        sourceAccountRole: false,
        sinkAccountRole: '',
        virtualColumns: [],
        virtualColumnData: {},
        customVirtualColumns: [],
        sourceCharset: 'utf8',
        targetCharset: 'utf8',
        treeData: [],
        dstCkTableEngine: null,
        kuduNumReplicas: 3,
        customPkgFile: null,
        partitionData: {
          partition: [
            {
              key: '',
              func: ''
            }
          ]
        }
      },
      creatingStatus: {
        state: 'CREATING',
        dataJobId: 1,
        dataJobName: 'db1-db2-migrate',
        dataJobType: 'MIGRATION',
        createTime: '2020-01-20 12:12:12'
      },
      coreConfigWrapper: {
        processorConfigList: []
      }
    };
  },
  methods: {
    handleStep(type) {
      if (type === 'next') {
        if (this.currentStep === 0) {
          this.$refs.Originalconfig.handleShowPreCheck(true);
          this.taskInfo.originalConfigHistory = true;
        } else if (this.currentStep === 1) {
          this.taskInfo.functionalConfigHistory = true;
          if (this.taskInfo.fullPeriod
            && (!this.taskInfo.fullPeriodDate.dayType
              || (this.taskInfo.fullPeriodDate.dayType === 'MONTH'
              && (!this.taskInfo.fullPeriodDate.date || !this.taskInfo.fullPeriodDate.time))
              || (this.taskInfo.fullPeriodDate.dayType === 'DAY' && !this.taskInfo.fullPeriodDate.time)
              || (this.taskInfo.fullPeriodDate.dayType === 'HOUR'
              && !this.taskInfo.fullPeriodDate.hour && this.taskInfo.fullPeriodDate.hour !== 0)
              || (this.taskInfo.fullPeriodDate.dayType === 'YEAR'
                && (!this.taskInfo.fullPeriodDate.month || !this.taskInfo.fullPeriodDate.date || !this.taskInfo.fullPeriodDate.time)))) {
            this.$Modal.warning({
              title: '请填写完整且正确的信息',
              content: '请填写完整的定时任务信息'
            });
          } else if (this.taskInfo.checkMode === 'checkPeriod'
            && (!this.taskInfo.checkPeriodDate.dayType
              || (this.taskInfo.checkPeriodDate.dayType === 'MONTH'
                && (!this.taskInfo.checkPeriodDate.date || !this.taskInfo.checkPeriodDate.time))
              || (this.taskInfo.checkPeriodDate.dayType === 'DAY' && !this.taskInfo.checkPeriodDate.time)
              || (this.taskInfo.checkPeriodDate.dayType === 'HOUR'
                && !this.taskInfo.checkPeriodDate.hour && this.taskInfo.checkPeriodDate.hour !== 0)
              || (this.taskInfo.checkPeriodDate.dayType === 'YEAR'
                && (!this.taskInfo.checkPeriodDate.month || !this.taskInfo.checkPeriodDate.date || !this.taskInfo.checkPeriodDate.time)))) {
            this.$Modal.warning({
              title: '请填写完整且正确的信息',
              content: '请填写完整的周期性数据校验任务信息'
            });
          } else {
            this.$refs.functionConfig.handleShowPreCheck(true);
          }
        } else if (this.currentStep === 2) {
          console.log(1111, this.taskInfo.dbMap);
          let noEmptyDb = true;
          let
            noSinkTable = true;

          this.taskInfo.dbMap.map((item) => {
            if (!item.selectedTables) {
              noEmptyDb = false;
              this.$Modal.warning({
                title: '任务创建提示',
                content: `源库${item.sourceDb}为空库，表的数量为0，不支持迁移`
              });
            } else if (item.selectedTables && item.selectedTables.length < 1) {
              noEmptyDb = false;
              this.$Modal.warning({
                title: '任务创建提示',
                content: `每个库请至少选择一张表。当前${item.sourceDb}库没有选择表`
              });
            } else {
              let selectedTableCount = 0;

              item.selectedTables.map((table) => {
                if (table.selected) {
                  selectedTableCount++;
                  if (!table.sinkTable) {
                    noSinkTable = false;
                    this.$Modal.warning({
                      title: '任务创建提示',
                      content: `请务必选择目标表。当前${item.sourceDb}.${table.sourceTable}没有选择目标表`
                    });
                  }
                }
                return null;
              });
              if (selectedTableCount < 1) {
                noEmptyDb = false;
                this.$Modal.warning({
                  title: '任务创建提示',
                  content: `每个库请至少选择一张表。当前${item.sourceDb}库没有选择表`
                });
              }
            }
            return null;
          });
          if (noEmptyDb && noSinkTable) {
            this.$refs.TableFilter.updateNextStep();
          }
        } else if (this.currentStep === 3) {
          const res = this.$refs.CleanData.updateNextStep();

          if (res.noPkList.length > 0 && (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType))) {
            return;
          }

          if (res.noRedisKeyList.length > 0 && DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
            return;
          }
          if (res.allHasColumn.length < 1) {
            if (this.taskInfo.sinkType === 'Hive') {
              let noPartition = false;

              this.taskInfo.dbMap.map((item) => {
                item.selectedTables.map((table) => {
                  if (table.selected) {
                    if (!table.partitionData || !table.partitionData.partition || table.partitionData.partition.length < 1) {
                      console.log('table', table);
                      noPartition = true;
                      this.$Modal.confirm({
                        title: '分区未设置提示',
                        content: `<p>表${table.sourceTable}没有设置分区键，可能导致数据重复导入并覆盖之前的数据，请点击配置分区键进行配置。</p>`
                      });
                    }
                  }
                  return null;
                });
                return null;
              });
              if (!noPartition) {
                this.currentStep = 4;
              }
            } else {
              this.currentStep = 4;
            }
          } else {
            this.$Modal.warning({
              title: '任务创建异常',
              content: `当前${res.allHasColumn[0].sourceTable}表没有选择字段，请重新选择后再点击下一步`
            });
          }
        } else {
          this.currentStep = 5;
        }
      } else {
        switch (this.currentStep) {
          case 1:
            this.taskInfo.functionalConfigHistory = true;
            break;
          default:
            break;
        }
        console.log(this.currentStep);
        this.taskInfo.firstTime = false;
        if (this.currentStep === 2 && !JSON.parse(this.taskInfo.ifWholeDb)) {
          this.$refs.TableFilter.updatePrevStep();
        } else if (this.currentStep === 3) {
          this.$refs.CleanData.updateNextStep();
        }
        this.currentStep--;
      }
    },
    handleNextStep(n) {
      this.currentStep = n;
    },
    handleCreateTask() {
      store.commit('getJobDataForSimilarJob', null);
      if (this.makeSure) {
        this.showConfirm = true;
      } else {
        this.$Modal.warning({
          title: '创建任务提醒',
          content: '请确认并勾选CloudCanal服务等级协议再进行任务创建。'
        });
      }
    },
    handleHidePrecheck(event, ifKeep) {
      this.showPreCheck = false;
      if (ifKeep) {
        this.$Modal.confirm({
          title: '创建任务警告',
          content: '当前任务存在未通过的预检项，直接忽略可能会使任务异常。确认要忽略并继续创建吗？',
          onOk: () => {
            this.updateLoading(true);
            this.handleCreateJob();
          }
        });
      }
    },
    handleConfirm() {
      this.createData = '';
      this.showConfirm = false;
      const taskInfo = this.taskInfo;

      let hasLoop = false;

      if (DataSourceGroup.noDb.indexOf(this.taskInfo.sourceType) === -1) {
        taskInfo.dbMap.map((db) => {
          if (db.selectedTables) {
            db.selectedTables.map((table) => {
              if (table.selected) {
                if (db.selectedColumns && db.selectedColumns[table.sourceTable]) {
                  db.selectedColumns[table.sourceTable].map((column) => {
                    if (column.selected) {
                      if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sourceType) > -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
                        if (taskInfo.sourceInstance === taskInfo.sinkInstance
                          && db.sourceDb === db.sinkDb
                          && db.sourceSchema === db.targetSchema
                          && table.sourceTable === table.sinkTable) {
                          hasLoop = true;
                        }
                      } else if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sourceType) === -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) === -1) {
                        if (taskInfo.sourceInstance === taskInfo.sinkInstance && db.sourceDb === db.sinkDb && table.sourceTable === table.sinkTable) {
                          hasLoop = true;
                        }
                      }
                    }
                    return null;
                  });
                }
              }
              return null;
            });
          }
          return null;
        });
      }
      if (hasLoop) {
        this.$Modal.warning({
          title: '创建任务异常提醒',
          content: '当前任务存在同库表之间的迁移，此操作不被允许，请重新修改'
        });
      } else {
        // eslint-disable-next-line one-var
        const data = this.getDataJobData(),
          that = this;

        this.getPercantage = setInterval(() => {
          if (that.creatingStatus.state === 'CREATING') {
            if (that.currentPercentage < 90) {
              that.currentPercentage += Math.floor(Math.random() * 10);
            } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
              that.currentPercentage += Math.floor(Math.random() * 3);
            } else if (that.currentPercentage >= 97) {
              that.currentPercentage = 99;
            }
          } else if (that.creatingStatus.state === 'INIT') {
            that.currentPercentage = 100;
            clearInterval(that.getPercantage);
          }
        }, 200);

        this.showCreateStatus = true;
        this.creatingStatus.dataJobName = taskInfo.desc;
        this.creatingStatus.dataJobType = taskInfo.type;
        this.creatingStatus.state = 'PRECHECK';

        const stringData = JSON.stringify(data); const
          formData = new FormData();

        if (this.taskInfo.customPkgFile) {
          formData.append('customPkgFile', this.taskInfo.customPkgFile);
        }
        formData.append('dataJobData', new Blob([stringData], { type: 'application/json' }));

        this.createData = formData;// 第二次预检
        finalCheck(data)
          .then((res) => {
            let preCheckStatus = true;

            this.preCheckItemsErrorCount = 0;
            this.preCheckItemsSuccessCount = 0;
            this.preCheckItemsCheckedCount = 0;
            if (res.data.code === '1') {
              if (res.data.data) {
                res.data.data.forEach((item) => {
                  if (item.checked) {
                    this.preCheckItemsCheckedCount++;
                    if (!item.success) {
                      preCheckStatus = false;
                      this.preCheckItemsErrorCount++;
                    } else {
                      this.preCheckItemsSuccessCount++;
                    }
                  }
                });
              }
              this.preCheckItems = res.data.data || [];
            } else {
              preCheckStatus = false;
            }
            if (preCheckStatus) {
              this.handleCreateJob(formData);
            } else {
              this.showPreCheck = true;
              this.showCreateStatus = false;
            }
          });
      }
    },
    handleCreateJob() {
      this.creatingStatus.state = 'CREATING';
      createJob(this.createData)
        .then((response) => {
          if (response.data.code === '1') {
            this.creatingStatus.state = 'INIT';
            const that = this;

            setTimeout(() => {
              that.$router.push({ path: '/data/job/list' });
            }, 1000);
          } else {
            this.$Modal.error({
              title: '创建失败',
              content: response.data.msg
            });
            this.showCreateStatus = false;
          }
        });
    },
    updateShowAddDataSource(type) {
      this.showAddDataSource = true;
      this.addDataSourceType = type;
    },
    updateAddDataSourceException() {
      this.showAddDataSourceException = true;
    },
    closeShowAddDataSource() {
      this.showAddDataSource = false;
      if (this.addDataSourceType === 'source') {
        this.$refs.Originalconfig.getSourceDataSourceList();
      } else if (this.addDataSourceType === 'sink') {
        this.$refs.Originalconfig.getSinkDataSourceList();
      }
    },
    handleGoDataSource() {
      this.$router.push({
        path: '/data/dataSource'
      });
    },
    updateLoading(loading) {
      this.loading = loading;
    },
    handleHideNotNull(notNull) {
      this.showNotNullTable = notNull;
    },
    handleReturn() {
      this.$router.push({ path: '/data/job/list' });
    },
    handleRefresh() {
      if (this.currentStep === 2) {
        this.$refs.TableFilter.handleRefresh();
      } else if (this.currentStep === 3) {
        this.$refs.CleanData.handleRefresh();
      }
    },
    handleCancel() {
      this.showConfirm = false;
    }
  }
};
</script>

<style lang="less">
.create-task-process {
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid #DADADA;
  margin-bottom: 70px;
}

.create-task-step {
  padding: 30px 380px;
  border-bottom: 1px solid #DADADA;
}

.create-task-tools {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #ffffff;
  width: 100%;
  line-height: 60px;
  height: 60px;
  box-shadow: 6px 2px 23px 0 rgba(197, 197, 197, 0.5);

  button {
    margin: 0 10px;
  }
}

.add-datasource-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  background: #fff;
}

.ivu-drawer-body {
  padding: 0;
}

.ivu-drawer-content {
  padding-top: 54px;
}

.ivu-drawer-close {
  top: 62px;
}

.go-to-other-page-btn:hover .forward-btn {
  transform: translate(5px, 0);
}

.demo-carousel {
  text-align: center;
  font-size: 20px;
}

.show-list-point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #A2A9B6;
  margin-right: 8px;
}
</style>
