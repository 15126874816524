<template>
  <div>
    <Form :model="addDataSourceForm" label-position="left" :label-width="100">
      <Divider orientation="left">新增数据源信息确认</Divider>
      <FormItem label="部署类型" prop="instanceType">
        <span>{{ Mapping.cloudOrIdcName[addDataSourceForm.instanceType] }}</span>
      </FormItem>
      <FormItem label="数据库类型" prop="type">
                <span>{{
                    addDataSourceForm.instanceType === 'ALIBABA_CLOUD_HOSTED' ?
                      Mapping.aliyunType[addDataSourceForm.type] : addDataSourceForm.type
                  }}</span>
      </FormItem>
      <FormItem label="地区" prop="region">
        <span>{{ addDataSourceForm.region }}</span>
      </FormItem>
      <FormItem label="host" prop="host"
                v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'||addDataSourceForm.ifAkSK==='false'">
        <span>{{ addDataSourceForm.host }}:{{ addDataSourceForm.port }}</span>
      </FormItem>
      <FormItem label="描述"
                v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'||addDataSourceForm.ifAkSK==='false'">
        <span>{{ addDataSourceForm.instanceDesc }}</span>
      </FormItem>
      <FormItem label="账号" prop="host" v-if="(addDataSourceForm.instanceType==='SELF_MAINTENANCE'||addDataSourceForm.ifAkSK==='false')
            &&addDataSourceForm.account&&addDataSourceForm.type!=='Hive'">
        <span>{{ addDataSourceForm.account }}</span>
      </FormItem>
      <FormItem label="密码" prop="host"
                v-if="(addDataSourceForm.instanceType==='SELF_MAINTENANCE'||addDataSourceForm.ifAkSK==='false')&&addDataSourceForm.password">
        <span>******</span>
      </FormItem>
      <FormItem label="AK" prop="aliyunAk"
                v-if="addDataSourceForm.instanceType!=='SELF_MAINTENANCE'&&addDataSourceForm.ifAkSK==='true'">
        <span>{{ addDataSourceForm.aliyunAk }}</span>
      </FormItem>
      <FormItem label="SK" prop="aliyunSk"
                v-if="addDataSourceForm.instanceType!=='SELF_MAINTENANCE'&&addDataSourceForm.ifAkSK==='true'">
        <span>******</span>
      </FormItem>
      <FormItem label="rds列表" prop="instanceId"
                v-if="addDataSourceForm.instanceType!=='SELF_MAINTENANCE'&&addDataSourceForm.ifAkSK==='true'">
        <Table size="small" border :columns="rdsColumns" :data="addDataSourceForm.rdsList">
          <template slot-scope="{ row, index }" slot="action">
            <div>
              <Checkbox :disabled="addDataSourceForm.type==='Greenplum'" v-model="row.autoCreate"
                        @on-change="handleAutoCreate(row,index,$event)"><span
                style="color: #0BB9F8">自动创建账号密码</span></Checkbox>
              <Tooltip transfer
                       content="选择自动创建账号密码后cloudCanal会自动去对应数据源创建账号，创建数据任务时无需填写相关信息。如没有选择自动创建账号密码，则后续需要您亲自到RDS进行创建，并且后续创建数据传输任务需要手动输入账号密码。"
                       placement="top-end">
                <Icon style="font-size: 14px" type="ios-information-circle-outline"/>
              </Tooltip>
            </div>
          </template>
        </Table>
      </FormItem>
      <div
        v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'&&addDataSourceForm.type==='Hive'">
        <FormItem label="认证方式" prop="hdfsSecurityType">
          <span>{{ addDataSourceForm.securityType }}</span>
        </FormItem>
        <FormItem label="Hive Principal" prop="hdfsSecurityType"
                  v-if="addDataSourceForm.securityType==='KERBEROS'">
          <span>{{ addDataSourceForm.account }}</span>
        </FormItem>
        <FormItem label="HDFS Host" prop="hdfsIp">
          <span>{{ addDataSourceForm.hdfsIp }}:{{ addDataSourceForm.hdfsPort }}</span>
        </FormItem>
        <FormItem label="HDFS Principal" prop="hdfsSecurityType"
                  v-if="addDataSourceForm.securityType==='KERBEROS'">
          <span>{{ addDataSourceForm.hdfsPrincipal }}</span>
        </FormItem>
        <FormItem label="HDFS 数仓路径" prop="hdfsDwPath">
          <span>{{ addDataSourceForm.hdfsDwDir }}</span>
        </FormItem>
        <FormItem label="HadoopUser" prop="hadoopUser"
                  v-if="addDataSourceForm.securityType==='NONE'">
          <span>{{ addDataSourceForm.account }}</span>
        </FormItem>
      </div>
    </Form>
  </div>
</template>
<script>
import Mapping from '../../../views/util';

export default {
  props: {
    addDataSourceForm: Object
  },
  data() {
    return {
      Mapping,
      selectedRds: {},
      rdsColumns: [
        {
          title: '实例ID',
          key: 'instanceId'
        },
        {
          title: 'host',
          key: 'host'
        },
        {
          title: '描述',
          key: 'instanceDesc'
        },
        {
          title: '操作',
          slot: 'action'
        }
      ]
    };
  },
  created() {
    this.addDataSourceForm.rdsList.map((rds) => {
      rds.autoCreate = true;
      return null;
    });
  },
  methods: {
    handleConfirmEditDesc() {
      this.addDataSourceForm.rdsList.map((item) => {
        if (item.instanceId === this.selectedRds.instanceId) {
          item.instanceDesc = this.selectedRds.instanceDesc;
        }
        return null;
      });
    },
    handleAutoCreate(row, index, data) {
      this.addDataSourceForm.rdsList[index].autoCreate = data;
    }
  }
};
</script>
