<template>
    <div>
        <PageHeader :title="fsmInfo.dataJobDesc?`状态机管理-${fsmInfo.dataJobName}（${fsmInfo.dataJobDesc}）`:`状态机管理-${fsmInfo.dataJobName}`"></PageHeader>
        <div class="state-machine-wrapper" style="position: relative">
            <pd-button type="default" style="position: absolute;right: 26px;top: 26px" @click="handleRefresh"
                    :loading="refreshLoading">
                <Icon type="md-refresh"/>
                刷新
            </pd-button>
            <p style="font-size: 14px"><span>当前状态：</span><span v-if="!fsmInfo.exceptionStr&&fsmInfo.dataTaskState!=='COMPLETE'"
                                                               style="font-weight: 500">{{`${TaskStateMapping.taskState[fsmInfo.dataTaskState]} （${fsmInfo.dataTaskState}）`}}</span>
                <span v-if="fsmInfo.dataTaskState==='COMPLETE'" style="font-weight: 500;color:#19be6b">{{`${TaskStateMapping.taskState[fsmInfo.dataTaskState]}（${fsmInfo.dataTaskState}）`}}</span>
                <span
                        v-if="!fsmInfo.active&&fsmInfo.exceptionStr" style="color: #ed4014;font-weight: 500">
                  {{`${TaskStateMapping.taskState[fsmInfo.dataTaskState]}（${fsmInfo.dataTaskState}）`}}</span>
              {{!fsmInfo.active&&fsmInfo.exceptionStr?'（异常）':''}}
            </p>
            <div id="container"></div>
            <div v-if="!fsmInfo.active&&fsmInfo.exceptionStr">
                <Card dis-hover>
                    <p slot="title">异常信息</p>
                    <div style="overflow: auto">
                        <pre>{{fsmInfo.exceptionStr}}</pre>
                    </div>
                </Card>
            </div>
            <div style="margin-top: 20px">
                <Card dis-hover>
                    <p slot="title">trigger信息</p>
                    <div>
                        <Collapse v-model="currentStep">
                            <Panel v-for="(trigger) of fsmInfo.triggers" :name="`${trigger.triggerOrder}`"
                                   :key="trigger.triggerOrder">
                                <span style="margin-right: 5px">sourceState:</span><span>{{`${TaskStateMapping.taskState[trigger.sourceState]}（${trigger.sourceState}）`}}</span><span
                                    style="margin-right: 5px;margin-left: 10px">targetState:</span><span>{{`${TaskStateMapping.taskState[trigger.targetState]}（${trigger.targetState}）`}}</span><span
                                    v-if="fsmInfo.dataTaskState===trigger.sourceState&&fsmInfo.prevTaskState===trigger.targetState">（当前阶段）</span>
                                <div slot="content" class="trigger-info-container">
                                    <p><span>triggerClassName:</span>{{trigger.triggerClassName}}</p>
                                    <p><span>contextClassName:</span>{{trigger.contextClassName}}</p>
                                    <p><span>triggerContext:</span>{{JSON.parse(trigger.triggerContext)}}</p>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Card>
            </div>
        </div>
    </div>
</template>
<script>
import { getFSMDetail } from '@services/api/fsm';
import PageHeader from '@components/function/PageHeader';
// import G6 from '@antv/g6';
import TaskStateMapping from '../util';

export default {
  components: { PageHeader },
  created() {
    this.fsmId = this.$route.params.id;
    this.dataJobId = this.$route.params.jobId;
    this.getFSMDetail();
  },
  mounted() {

  },
  data() {
    return {
      refreshLoading: false,
      jobName: 'db1_db2_migration',
      fsmId: 0,
      dataJobId: 0,
      fsmInfo: {},
      currentStep: '1',
      TaskStateMapping
    };
  },
  methods: {
    handleRefresh() {
      this.getFSMDetail();
    },
    getFSMDetail() {
      this.refreshLoading = true;
      getFSMDetail({
        fsmId: this.fsmId,
        dataJobId: this.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.fsmInfo = res.data.data;

          const fsmData = { nodes: [], edges: [] };
          // 一个点到多个点的需要处理
          const points = new Map();

          let i = 0;

          this.fsmInfo.triggers.map((item) => {
            if (!points.get(item.sourceState)) {
              const items = [];

              items.push(item);
              points.set(item.sourceState, items);
            } else {
              const items = points.get(item.sourceState);

              items.push(item);
              points.set(item.sourceState, items);
            }
            if (!points.get(item.targetState)) {
              const items = [];

              items.push(item);
              points.set(item.targetState, items);
            } else {
              const items = points.get(item.targetState);

              items.push(item);
              points.set(item.targetState, items);
            }
            if (item.sourceState === this.fsmInfo.dataTaskState && item.targetState === this.fsmInfo.prevTaskState) {
              const that = this;

              setTimeout(() => {
                that.currentStep = String(item.triggerOrder);
              });
            }
            return null;
          });

          points.forEach((item, key) => {
            if (key === 'COMPLETE') {
              fsmData.nodes.push({
                id: key,
                x: 200 * i + 100,
                y: 200,
                shape: 'circle-animate',
                size: 40,
                label: `${TaskStateMapping.taskState[key]}`,
                labelCfg: {
                  position: 'bottom',
                  offset: 10
                }
              });
            } else if (this.fsmInfo.dataTaskState === key) {
              fsmData.nodes.push({
                id: key,
                x: 200 * i + 100,
                y: 100,
                shape: 'background-animate',
                size: 40,
                label: `${TaskStateMapping.taskState[key]}`,
                labelCfg: {
                  position: 'bottom',
                  offset: 10
                },
                color: this.fsmInfo.active ? '#19be6b' : '#ed4014',
                style: {
                  fill: this.fsmInfo.active ? '#19be6b' : '#ed4014',
                  opacity: 0.7
                }
              });
            } else {
              fsmData.nodes.push({
                id: key,
                x: 200 * i + 100,
                y: 100,
                shape: 'circle-animate',
                size: 40,
                label: `${TaskStateMapping.taskState[key]}`,
                labelCfg: {
                  position: 'bottom',
                  offset: 10
                }
              });
            }
            i++;
            item.map((eged) => {
              fsmData.edges.push({
                source: eged.sourceState,
                target: eged.targetState,
                shape: this.fsmInfo.dataTaskState === item.targetState && this.fsmInfo.prevTaskState === item.sourceState ? 'circle-running' : ''
              });
              return null;
            });
            return null;
          });

          // G6.registerEdge('circle-running', {
          //     afterDraw(cfg, group) {
          //         // 获得当前边的第一个图形，这里是边本身的 path
          //         const shape = group.get('children')[0],
          //             // 边 path 的起点位置
          //             startPoint = shape.getPoint(0),
          //
          //             // 添加红色 circle 图形
          //             circle = group.addShape('circle', {
          //                 attrs: {
          //                     x: startPoint.x,
          //                     y: startPoint.y,
          //                     fill: '#1890ff',
          //                     r: 3
          //                 }
          //             });
          //
          //         // 对红色圆点添加动画
          //         circle.animate({
          //             // 动画重复
          //             repeat: true,
          //             // 每一帧的操作，入参 ratio：这一帧的比例值（Number）。返回值：这一帧需要变化的参数集（Object）。
          //             onFrame(ratio) {
          //                 // 根据比例值，获得在边 path 上对应比例的位置。
          //                 const tmpPoint = shape.getPoint(ratio);
          //                 // 返回需要变化的参数集，这里返回了位置 x 和 y
          //
          //                 return {
          //                     x: tmpPoint.x,
          //                     y: tmpPoint.y
          //                 };
          //             }
          //         }, 3000); // 一次动画的时间长度
          //     }
          // }, 'line'); // 该自定义边继承内置三阶贝塞尔曲线 cubic

          document.getElementById('container').innerHTML = '';
          // this.renderGraph(fsmData, document.getElementById('container'), 'container', this.fsmInfo.active);
        }
        this.refreshLoading = false;
      }).catch(() => {
        this.refreshLoading = false;
      });
    }
    // renderGraph(data, container, containerName, isActive) {
    // 背景动画
    // G6.registerNode('background-animate', {
    //     drawShape: function drawShape(cfg, group) {
    //         const shapeType = this.shapeType,
    //             style = G6.Util.mix({}, this.getShapeStyle(cfg), {
    //                 x: 0,
    //                 y: 0,
    //                 r: 20
    //             }),
    //             shape = group.addShape(shapeType, {
    //                 attrs: style
    //             }),
    //             // 绘制节点里面的小圆。点击这个小圆会显示tooltip
    //             innerCircle = group.addShape('circle', {
    //                 attrs: {
    //                     x: 0,
    //                     y: 0,
    //                     r: 20,
    //                     fill: isActive ? '#19be6b' : '#ed4014',
    //                     opacity: 0.7,
    //                     cursor: 'pointer'
    //                 }
    //             });
    //             // 设置className属性
    //
    //         innerCircle.set('className', 'node-inner-circle');
    //         return shape;
    //     },
    //     afterDraw(cfg, group) {
    //         const r = cfg.size / 2,
    //             back1 = group.addShape('circle', {
    //                 zIndex: -3,
    //                 attrs: {
    //                     x: 0,
    //                     y: 0,
    //                     r,
    //                     fill: cfg.color,
    //                     opacity: 0.6
    //                 }
    //             }),
    //             back2 = group.addShape('circle', {
    //                 zIndex: -2,
    //                 attrs: {
    //                     x: 0,
    //                     y: 0,
    //                     r,
    //                     fill: cfg.color,
    //                     opacity: 0.6
    //                 }
    //             }),
    //             back3 = group.addShape('circle', {
    //                 zIndex: -1,
    //                 attrs: {
    //                     x: 0,
    //                     y: 0,
    //                     r,
    //                     fill: cfg.color,
    //                     opacity: 0.6
    //                 }
    //             });
    //
    //         group.sort(); // 排序，根据 zIndex 排序
    //         back1.animate({ // 逐渐放大，并消失
    //             r: r + 10,
    //             opacity: 0.1,
    //             repeat: true // 循环
    //         }, 3000, 'easeCubic', null, 0); // 无延迟
    //         back2.animate({ // 逐渐放大，并消失
    //             r: r + 10,
    //             opacity: 0.1,
    //             repeat: true // 循环
    //         }, 3000, 'easeCubic', null, 1000); // 1 秒延迟
    //         back3.animate({ // 逐渐放大，并消失
    //             r: r + 10,
    //             opacity: 0.1,
    //             repeat: true // 循环
    //         }, 3000, 'easeCubic', null, 2000); // 2 秒延迟
    //     }
    // }, 'circle');

    // const width = container.scrollWidth,
    //     height = container.scrollHeight || 300;
    // graph = new G6.Graph({
    //     container: containerName,
    //     width,
    //     height,
    //     defaultNode: {
    //         style: {
    //             fill: '#DEE9FF',
    //             stroke: '#5B8FF9'
    //         }
    //     },
    //     defaultEdge: {
    //         style: {
    //             lineWidth: 1,
    //             stroke: '#b5b5b5',
    //             endArrow: true
    //         }
    //     }
    // });
    //
    // graph.data(data);
    // graph.render();
    // }
  }
};
</script>
<style lang="less">
    .trigger-info-container {
        p {
            margin-bottom: 5px;

            span {
                font-weight: 500;
                font-size: 13px;
                margin-right: 5px;
            }
        }
    }
</style>
