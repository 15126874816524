<template>
  <div>
    <div
      v-if="type==='sink'&&(DataSourceGroup.pg.indexOf(taskInfo.sinkType)>-1
      ||DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1
      ||DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.kudu.indexOf(taskInfo.sinkType)>-1
      ||DataSourceGroup.redis.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.ck.indexOf(taskInfo.sinkType)>-1)">
      <p class="show-manual-account">
        <Checkbox v-model="taskInfo.sinkExtraSetting"></Checkbox>
        <span>高级配置</span>
      </p>
      <div v-if="taskInfo.sinkExtraSetting" class="advance-setting">
        <span style="margin-right: 10px"
              v-if="DataSourceGroup.pg.indexOf(this.taskInfo.sinkType)>-1">大小写敏感</span>
        <RadioGroup v-if="DataSourceGroup.pg.indexOf(this.taskInfo.sinkType)>-1"
                    style="position: relative;top:-2px;margin-right: 56px;"
                    v-model="taskInfo.targetCaseSensitive">
          <Radio label="true">是</Radio>
          <Radio label="false">否</Radio>
        </RadioGroup>
        <span style="margin-right: 6px" v-if="taskInfo.sinkType==='ElasticSearch'">时区选择</span>
        <Tooltip content="选择对应的时区后，时间类型字段会在原时间值上增加对应时区的偏移量。"
                 v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1" placement="right">
          <Select filterable v-model="taskInfo.globalTimeZone" style="width: 100px">
            <Option v-for="(timezone,index) of timeZoneList" :value="timezone" :key="index">
              {{ timezone }}
            </Option>
          </Select>
        </Tooltip>
        <span style="margin-right: 10px"
              v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1">消息格式</span>
        <RadioGroup v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1"
                    style="position: relative;top:-2px;margin-right: 56px;"
                    v-model="taskInfo.dstSchemaLessFormat">
          <Radio v-for="schema in taskInfo.schemaTypeList" :label="schema.format" :key="schema.format">
            {{ schema.i18nName }}
          </Radio>
        </RadioGroup>
        <span style="margin-right: 10px"
              v-if="DataSourceGroup.redis.indexOf(this.taskInfo.sinkType)>-1">缓存格式</span>
        <RadioGroup v-if="DataSourceGroup.redis.indexOf(this.taskInfo.sinkType)>-1"
                    style="position: relative;top:-2px;margin-right: 56px;"
                    v-model="taskInfo.dstSchemaLessFormat">
          <Radio v-for="schema in taskInfo.schemaTypeList" :label="schema.format" :key="schema.format">
            {{ schema.i18nName }}
          </Radio>
        </RadioGroup>
        <span style="margin-right: 10px"
              v-if="DataSourceGroup.ck.indexOf(this.taskInfo.sinkType)>-1">表引擎</span>
        <RadioGroup v-if="DataSourceGroup.ck.indexOf(this.taskInfo.sinkType)>-1"
                    style="position: relative;top:-2px;margin-right: 56px;"
                    v-model="taskInfo.dstCkTableEngine">
          <Radio v-for="schema in taskInfo.engineTypeList" :label="schema.ckTableEngine"
                 :key="schema.ckTableEngine">{{ schema.ckTableEngine }}
          </Radio>
        </RadioGroup>
        <div v-if="DataSourceGroup.kudu.includes(taskInfo.sinkType)">
          <span style="margin-right: 10px">副本数</span>
          <Input style="width: 200px" v-model="taskInfo.kuduNumReplicas"/>
        </div>
      </div>
    </div>
    <div v-if="type==='source'">
      <p class="show-manual-account"
         v-if="((DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1)
         &&(DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.mysql.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sinkType)>-1)
         ||DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1)">
        <Checkbox v-model="taskInfo.sourceExtraSetting"></Checkbox>
        <span>高级配置</span>
      </p>
      <div
        v-if="taskInfo.sourceExtraSetting&&((DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1)
        &&(DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.mysql.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sinkType)>-1)
        ||DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1)"
        class="advance-setting">
        <span style="margin-right: 10px"
              v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1">消息格式</span>
        <RadioGroup v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1"
                    style="position: relative;top:-2px;margin-right: 56px;"
                    v-model="taskInfo.srcSchemaLessFormat">
          <Radio v-for="schema in taskInfo.schemaTypeList" :label="schema.format" :key="schema.format">
            {{ schema.i18nName }}
          </Radio>
        </RadioGroup>
        <span
          v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1"
          style="margin-right: 10px">迁移模式</span>
        <RadioGroup v-model="taskInfo.schemaWhiteListLevel"
                    v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1||DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1
                    ||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1">
          <!--            <Radio label="NONE">整实例迁移</Radio>-->
          <Radio label="">白名单模式（默认）</Radio>
          <Radio label="DB">全库同步</Radio>
        </RadioGroup>
      </div>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import {
  cacheSchemaLessValueFormats,
  ckTableEngine,
  mqSchemaLessValueFormats
} from '../../../../../services/api/constant';

export default {
  props: {
    taskInfo: Object,
    type: String
  },
  data() {
    return {
      DataSourceGroup,
      timeZoneList: ['-12:00', '-11:00', '-10:00', '-09:00', '-08:00', '-07:00', '-06:00',
        '-05:00', '-04:00', '-03:00', '-02:00', '-01:00', '+00:00', '+01:00', '+02:00',
        '+03:00', '+04:00', '+05:00', '+06:00', '+07:00', '+08:00', '+09:00', '+10:00', '+11:00', '+12:00'],
      schemaTypeList: [],
      engineTypeList: []
    };
  },
  mounted() {
    // if (!this.taskInfo.originalConfigHistory) {
    this.getAdvancedSettingList(this.taskInfo.sourceType);
    this.getAdvancedSettingList(this.taskInfo.sinkType);
    // }
  },
  methods: {
    mqSchemaLessValueFormats() {
      mqSchemaLessValueFormats()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.schemaTypeList = res.data.data;
            this.taskInfo.dstSchemaLessFormat = this.taskInfo.schemaTypeList[0].format;
          }
        });
    },
    cacheSchemaLessValueFormats() {
      cacheSchemaLessValueFormats()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.schemaTypeList = res.data.data;
            this.taskInfo.dstSchemaLessFormat = this.taskInfo.schemaTypeList[0].format;
          }
        });
    },
    ckTableEngine() {
      ckTableEngine()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.engineTypeList = res.data.data;
            this.taskInfo.engineTypeList.forEach((item) => {
              if (item.defaultCheck) {
                this.taskInfo.dstCkTableEngine = item.ckTableEngine;
              }
            });
          }
        });
    },
    getAdvancedSettingList(val) {
      if (DataSourceGroup.redis.includes(val)) {
        this.cacheSchemaLessValueFormats();
      } else if (DataSourceGroup.mq.includes(val)) {
        this.mqSchemaLessValueFormats();
      } else if (DataSourceGroup.ck.includes(val)) {
        this.ckTableEngine();
      }
    }

  },
  watch: {
    'taskInfo.sinkType': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAdvancedSettingList(newVal);
        }
      }
    },
    'taskInfo.sourceType': {
      handler(val) {
        this.getAdvancedSettingList(val);
      }
    }
  }
};
</script>
<style scoped>
.advance-setting {
  border: 1px solid #EDEDED;
  background-color: #FAFAFA;
  line-height: 26px;
  padding: 8px 20px;
  vertical-align: middle;
  width: 442px;
}
</style>
