import axios from 'axios';
import { Modal, Spin } from 'view-design';

// eslint-disable-next-line no-restricted-globals
const baseURL = `${location.protocol}//${location.host}/cloudcanal/console/api/v1/inner`;
// const baseURL = '/';
const timeout = 60000;
const instance = axios.create({
  baseURL,
  timeout,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    // 'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true,
  credentials: 'include'
});

export { instance };

// 返回结果拦截器,处理默认的错误
instance.interceptors.response.use((response) =>

// 正常的请求前拦截,在这里处理
// eslint-disable-next-line implicit-arrow-linebreak
  response,

(error) => {
  // 非200请求时的错误处理'
  Spin.hide();
  if (error.response) {
    const res = error.response.data; // 请求data
    const status = error.response.status; // 请求状态吗

    if (status === 499) {
      window.location.href = res.url;
    } else if (status === 401) {
      // router.push({
      //     path: '/myLogin'
      // });
    } else if (res && !res.errors) {
      Modal.error({
        title: 'ERROR',
        content: `${res.message}`
      });
    } else {
      Modal.error({
        title: 'ERROR',
        content: `${res.errors[0].message}`
      });
    }
  } else {
    Modal.error({
      title: 'ERROR',
      content: '已超时！'
    });
  }
  return Promise.reject(error);
});
