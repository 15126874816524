<template>
    <div>
        <PageHeader title="监控详情"></PageHeader>
        <div class="container-wrapper">
            <div class="clearfix" style="margin-bottom:10px;position: relative">
                <div style="float: right">
<!--                    <pd-button @click="handleInterval">{{hasInterval?"取消10s定时刷新":"10s定时刷新"}}</pd-button>-->
                    <Timepicker style="display: inline-block;margin-right: 10px" ref="timepicker" :time="time"></Timepicker>
                    <pd-button @click="handleRefresh">
                        <Icon type="md-refresh"/>
                        刷新
                    </pd-button>
                </div>
                <div style="margin-top: 50px">
                    <div class="panel">
                        <div class="panel-header">
                            <div style="float: right">
                            </div>

                            {{metric.title}}
                        </div>
                        <div class="panel-body">
                            <div style="height: 400px">
                                <Metric ref="metric" :model="metric" :time="time" :filters="filters"
                                        :selected="metric.selected" :filterOthers="filterOthers"></Metric>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { Config } from '@components/function/monitor/config';
import _ from 'lodash';
import PageHeader from '@components/function/PageHeader';
import Timepicker from '@components/function/monitor/metric/timepicker';
import Metric from '@components/function/monitor/metric/metric';
import Time from '@components/function/monitor/utils/time';

export default {
  components: {
    PageHeader,
    Timepicker,
    Metric
  },
  created() {
    const that = this;

    setTimeout(() => {
      that.init();
    }, 200);
  },
  mounted() {
    this.filters.instance = this.$route.query.instance;
    this.$refs.timepicker.$on('refresh', () => {
      this.handleRender();
    });
    this.time.events.on('changed', () => {
      this.$refs.timepicker.$emit('to_refresh');
    });
    const that = this;

    setTimeout(() => {
      that.handleRender();
    }, 200);
  },
  data() {
    return {
      hasInterval: false,
      metrics: [],
      metric: {},
      jobList: [],
      time: new Time(),
      filters: {
        instance: '',
        jobId: ''
      },
      panelType: 'basic',
      configFile: Config.mysql,
      dbs: [],
      db: '全部',
      env: 'prod',
      filterOthers: {
        filterInstances: [{
          ip: '127.0.0.1'
        }],
        dbOnInstance: {},
        database: '全部'
      }
    };
  },
  methods: {
    init() {
      this.panelType = this.$route.params.type;
      const metrics = this.configFile[this.panelType].config;

      this.metric = {};
      _.forEach(metrics, (metric) => {
        metric.favorite = false;
        metric.loading = '';
        if (this.panelType !== 'system') {
          try {
            metric.queries = JSON.parse(metric.options).queries;
          } catch (e) {
            metric.queries = metric.options.queries;
          }
        }
        metric.queries.map((query) => {
          query.metric = query.metric.split('{')[0];
          query.metric += `{worker_ip="${this.filters.instance}"}`;
          return null;
        });
      });

      this.metrics = metrics;
      const query = this.$route.query.metric;

      if (query) {
        this.metrics.map((item) => {
          if (item.id === query) {
            this.metric = item;
          }
          return null;
        });
      } else {
        this.metric = null;
      }
    },
    handleRender() {
      this.init();
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric) {
          that.$refs.metric.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
      }, 100);
    },
    handleRefresh() {
      this.handleRender();
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRender();
        }, 10000);
      }
    }
  }
};
</script>
