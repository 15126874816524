<template>
  <div>
    <Table stripe border size="small" :height="tableHeight" :row-class-name="rowClassName" :loading="loading"
           :columns="getTableColumns"
           :data="getTableData(table)" :span-method="handleSpan"
           @on-select="handleOnSelect(table,index,$event)"
           @on-select-cancel="handleOnSelectCancel(table,index,$event)"
           @on-select-all="handleOnSelectAll(table,index,$event)"
           @on-select-all-cancel="handleOnSelectAllCancel(table,index,$event)">
      <template slot="sourceColumn" slot-scope="{ row }">
          {{row.sourceColumn}}
          <Icon type="ios-key" v-if="row.isPk" style="color: #ff9900;margin-left: 5px;"/>
        <Tooltip transfer v-if="row.isId && DataSourceGroup.es.indexOf(sinkType) > -1" >
          <div class="_id">
            _id
          </div>
          <div slot="content">
            <div>
              ElasticSearch中_id用于唯一识别一个文档。CloudCanal允许用户指定源端的某个列作为_id取值的来源，如果用户不指定，则默认使用源端主键列的取值作为写入对端_id的取值。在使用CloudCanal自定义代码构建宽表进行多表join的场景下，将_id设置为源端多表的关联列对多表join非常有用。
            </div>
            <a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-id-field.html" target="_blank">参考官方资料：_id field说明 </a>
          </div>
        </Tooltip>
      </template>
      <template slot-scope="{ index, row }" slot="sink">
        <Select
          v-if="selectedIndex===index&&table&&selectedColumns[table.sourceDb]&&selectedColumns[table.sourceDb][table.sourceTable]"
          @on-change="handleSinkColumnChange(table,row,$event)"
          v-model="selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn"
          :class="dbToCreateClass(table,row)" filterable
          style="width:100%" :transfer="true">}}
          <Option
            v-if="taskInfo.customPkgFile || havePackage"
            value="clougence_cloudcanal_filter_after_mapping_column" key="-1" label="仅订阅不写入对端">
            <Tooltip transfer content="订阅的源端列的值会在自定义代码中传递给用户，但是该列的值不映射目标端的任何列，也不会写入目标端。" placement="right">
              <p>仅订阅不写入对端</p>
            </Tooltip>
          </Option>
          <Option
            v-for="(item,i) in taskInfo.sinkColumns[table.sinkDb?table.sinkDb:table.targetDb][table.sinkTable?table.sinkTable:table.targetTable]"
            :value="item.columnName" :key="i">{{ item.columnName }}
          </Option>
          <Option
            v-if="(selectedColumns[table.sourceDb][table.sourceTable]&&
              selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn)&&
              !hasTheSameColumn(getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn) &&
              taskInfo.sinkColumns[table.sinkDb?table.sinkDb:table.targetDb][table.sinkTable?table.sinkTable:table.targetTable]) &&
              table.needAutoCreated"
            :value="getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)">
            {{
              getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)
            }}（待创建）
          </Option>
        </Select>
        <div v-else @mouseover="displaySelect(index)">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div>
              <div
                style="color: #FF6E0D"
                v-if="(selectedColumns[table.sourceDb][table.sourceTable]&&
              selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn) &&
              !hasTheSameColumn(getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn),
              taskInfo.sinkColumns[table.sinkDb?table.sinkDb:table.targetDb][table.sinkTable?table.sinkTable:table.targetTable])
              && getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn) === selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn &&
              table.needAutoCreated ">
                {{
                  getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)
                }}（待创建）
              </div>
              <div v-else>
                {{DataSourceGroup.columnMapping[selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn] || selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn}}
              </div>
            </div>
            <Icon type="ios-arrow-down" size="14" color="#808695"/>
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="sinkType">
        {{
          table &&
          selectedColumns[table.sourceDb][table.sourceTable] ?
            selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType : ''
        }}
        <Tooltip v-if="table &&
        selectedColumns[table.sourceDb][table.sourceTable]&&
        !selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType"
                 content="当前目标数据库不支持该类型" placement="bottom" transfer>
          <p style="color: #bbbbbb"><i style="margin-right: 4px;vertical-align: middle"
                                       class="iconfont iconTIP"></i>不支持的类型</p>
        </Tooltip>
      </template>
      <template slot-scope="{ row }" slot="sinkIsNullable">
        {{
          table &&
          selectedColumns[table.sourceDb][table.sourceTable] ?
            selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable : ''
        }}
      </template>
      <template slot="indexType" slot-scope="{row,index}">
        <div>
                        <span style="margin-left: 7px"
                              v-if="(row.sinkType!=='TEXT'&&row.sinkType!=='KEYWORD'&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT')||!row.needAutoCreated">{{
                            row.sinkType
                          }}</span>
          <Select
            v-if="row.needAutoCreated&&row.sinkType&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT'&&(row.sinkType==='TEXT'||row.sinkType==='KEYWORD')"
            style="width:120px;" v-model="row.sinkType" :transfer="true"
            @on-change="handleChangeIndexType(row,index,table,$event)">
            <Option value="TEXT">TEXT</Option>
            <Option value="KEYWORD">KEYWORD</Option>
          </Select>
          <Select
            v-if="row.needAutoCreated&&(row.sourceType.toUpperCase()==='JSON'||row.sourceType.toUpperCase()==='LONGTEXT')"
            style="width:120px;"
            v-model="row.sinkType" :transfer="true"
            @on-change="handleChangeIndexType(row,index,table,$event)">
            <Option value="OBJECT">OBJECT</Option>
            <Option value="TEXT">TEXT</Option>
            <Option value="NESTED">NESTED</Option>
          </Select>
        </div>
      </template>
      <template slot="ifKey" slot-scope="{row, index}">
        <div>
          <Checkbox @on-change="handleChangeIndex(row,index)"
                    :disabled="!row.needAutoCreated||esDisableIndexType.indexOf(row.sinkType)>-1"
                    v-model="row.isIndex"></Checkbox>
        </div>
      </template>
      <template slot="columnValue" slot-scope="{row}">
        <div>
          <Input v-model="row.columnValue"/>
        </div>
      </template>
      <template slot="action" slot-scope="{row,index}">
        <div>
          <i style="color: #FF1815;cursor: pointer" @click="handleDeleteColumn(row,index)"
             v-if="row.isVirtual" class="iconfont iconDEL-R"></i>
        </div>
      </template>
      <template slot="analyzer" slot-scope="{row}">
        <Select v-if="row.sinkType==='TEXT'&&row.needAutoCreated"
                @on-change="handleChangeAnalyzer(row,index,table,$event)" style="width:120px;"
                v-model="row.analyzer" :transfer="true" filterable>
          <Option v-for="esType in esTypeList" :key="esType" :value="esType">{{esType}}</Option>
        </Select>
        <span v-if="row.sinkType==='TEXT'&&!row.needAutoCreated">{{row.analyzer}}</span>
      </template>
      <template slot="mqSink">
        <div>
          <pre :key="mqSchemaKey">{{ mqSchema }}</pre>
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import store from '../../../../../store/index';

export default {
  props: {
    tableHeight: Number,
    rowClassName: Function,
    handleSelected: Function,
    handleSelectCancel: Function,
    handleAllSelected: Function,
    handleCancelSelectAll: Function,
    handleSinkColumnChange: Function,
    hasTheSameColumn: Function,
    handleChangeIndexType: Function,
    handleChangeAnalyzer: Function,
    sourceType: String,
    sinkType: String,
    table: Object,
    selectedColumns: Object,
    mqSchema: String,
    index: Number,
    targetCaseSensitive: String,
    handleDeleteColumn: Function,
    taskInfo: Object,
    mqSchemaKey: Number,
    esTypeList: Array,
    loading: Boolean,
    havePackage: Boolean
  },
  data() {
    return {
      store,
      selectedIndex: -1,
      DataSourceGroup,
      esDisableIndexType: ['binary', 'geo_point', 'object'],
      esJsonValueType: ['VARCHAR', 'TEXT', 'JSON'],
      cleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '源表字段',
          key: 'sourceColumn',
          slot: 'sourceColumn'
          // render: (h, params) => {
          //   if (params.row.isPk) {
          //     return h('div', [
          //       h('span', {}, params.row.sourceColumn),
          //       h('Icon', { props: { type: 'ios-key' }, style: { marginLeft: '5px', color: '#ff9900' } })
          //     ]);
          //   }
          //   return h('div', {}, params.row.sourceColumn);
          // }
        },
        {
          title: '源表字段类型',
          key: 'sourceType',
          width: 120
        },
        {
          title: '能否为NULL',
          key: 'sourceIsNullable',
          width: 110
        },
        {
          title: '目标表字段',
          slot: 'sink'
        },
        {
          title: '目标表字段类型',
          key: 'sinkType',
          width: 130,
          slot: 'sinkType'
        },
        {
          title: '能否为NULL',
          key: 'sinkIsNullable',
          slot: 'sinkIsNullable',
          width: 110
        }
      ],
      mqCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '源表字段',
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', { props: { type: 'ios-key' }, style: { marginLeft: '5px', color: '#ff9900' } })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: '源表字段类型',
          key: 'sourceType',
          width: 150
        },
        {
          title: '能否为NULL',
          key: 'sourceIsNullable',
          width: 120
        }
      ],
      esCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '源表字段',
          key: 'sourceColumn',
          slot: 'sourceColumn'
        },
        {
          title: '源表字段类型',
          key: 'sourceType',
          width: 180
        },
        {
          title: '能否为NULL',
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: '目标索引字段',
          slot: 'sink'
        },
        {
          title: '类型',
          slot: 'indexType',
          width: 150
        },
        {
          title: '是否索引',
          slot: 'ifKey',
          width: 100
        },
        {
          title: '分词器',
          slot: 'analyzer',
          width: 150,
          renderHeader: (h) => h('div', [
            h('span', {}, '分词器'),
            h('Tooltip', {
              props: {
                content: '非内置分析器请确保ES服务端已经安装分析器插件',
                placement: 'right',
                transfer: true
              },
              style: {
                cursor: 'pointer',
                marginLeft: '6px',
                fontSize: '14px'
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-information-circle'
                }
              })
            ])
          ])
        }
      ],
      esExistCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '源表字段',
          key: 'sourceColumn',
          slot: 'sourceColumn'
        },
        {
          title: '源表字段类型',
          key: 'sourceType',
          width: 180
        },
        {
          title: '能否为NULL',
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: '目标索引字段',
          slot: 'sink'
        },
        {
          title: '类型',
          slot: 'indexType',
          width: 150
        },
        {
          title: '分词器',
          slot: 'analyzer',
          width: 150,
          renderHeader: (h) => h('div', [
            h('span', {}, '分词器'),
            h('Tooltip', {
              props: {
                content: '非内置分析器请确保ES服务端已经安装分析器插件',
                placement: 'right',
                transfer: true
              },
              style: {
                cursor: 'pointer',
                marginLeft: '6px',
                fontSize: '14px'
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-information-circle'
                }
              })
            ])
          ])
        }
      ],
      mqToRdbCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '目标表字段',
          key: 'sinkColumn'
        },
        {
          title: '目标表字段类型',
          key: 'sinkType',
          width: 180
        },
        {
          title: '能否为NULL',
          key: 'sinkIsNullable',
          width: 120
        }
      ]
    };
  },
  computed: {
    getTableColumns() {
      if (DataSourceGroup.mq.indexOf(this.sourceType) > -1) {
        return this.mqToRdbCleanDataColumn;
      } if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
        if (this.table.needAutoCreated) {
          return this.esCleanDataColumn;
        }
        return this.esExistCleanDataColumn;
      } if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 || DataSourceGroup.redis.indexOf(this.sinkType) > -1) {
        return this.mqCleanDataColumn;
      }
      return this.cleanDataColumn;
    },
    dbToCreateClass() {
      return (table, row) => {
        if (this.selectedColumns[table.sourceDb][table.sourceTable]
                    && this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn
                    && this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn !== 'clougence_cloudcanal_filter_after_mapping_column'
                    && !this.hasTheSameColumn(this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn,
                      this.taskInfo.sinkColumns[table.sinkDb
                        ? table.sinkDb : table.targetDb] && this.taskInfo.sinkColumns[table.sinkDb
                        ? table.sinkDb : table.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])) {
          return 'db-to-create';
        }
        return '';
      };
    },
    getTableData() {
      return (table) => {
        if (table && table.sourceDb) {
          if (DataSourceGroup.mq.indexOf(this.sourceType) > -1) {
            return table && this.selectedColumns[table.sinkDb] ? this.selectedColumns[table.sinkDb][table.sinkTable] : [];
          }
          return table ? this.selectedColumns[table.sourceDb][table.sourceTable] : [];
        } if (table && table.sinkDb) {
          if (DataSourceGroup.mq.indexOf(this.sourceType) > -1) {
            return table && this.selectedColumns[table.sinkDb] ? this.selectedColumns[table.sinkDb][table.sinkTable] : [];
          }
        }
      };
    },
    getTargetValue() {
      return (target) => {
        if (this.targetCaseSensitive === 'false') {
          return target.toLowerCase();
        } if (this.taskInfo) {
          if (this.taskInfo.commonRule === 'UpperCase') {
            return target.toUpperCase();
          } if (this.taskInfo.commonRule === 'LowerCase') {
            return target.toLowerCase();
          }
        } else if (DataSourceGroup.oracle.indexOf(this.sinkType) > -1) {
          return target.toUpperCase();
        }
        return target;
      };
    }
  },
  methods: {
    handleOnSelect(table, index, $event) {
      this.selectedIndex = -1;
      this.handleSelected(table, index, $event);
    },
    handleOnSelectCancel(table, index, $event) {
      this.selectedIndex = -1;
      this.handleSelectCancel(table, index, $event);
    },
    handleOnSelectAll(table, index, $event) {
      this.selectedIndex = -1;
      this.handleAllSelected(table, index, $event);
    },
    handleOnSelectAllCancel(table, index, $event) {
      this.selectedIndex = -1;
      this.handleCancelSelectAll(table, index, $event);
    },
    displaySelect(index) {
      this.selectedIndex = index;
    },
    handleSpan(columnData) {
      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
        if (this.table.sourceDb) {
          if (columnData.rowIndex === 0 && columnData.columnIndex === 4) {
            return {
              rowspan: this.table.sourceDb ? this.selectedColumns[this.table.sourceDb][this.table.sourceTable].length : 0,
              colspan: 1
            };
          } if (columnData.rowIndex !== 0 && columnData.columnIndex === 4) {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    },
    handleChangeJsonValue(row, index) {
      this.selectedColumns[this.table.sourceDb][this.table.sourceTable][index].jsonValue = row.jsonValue;
    },
    handleChangeIndex(row, index) {
      console.log(row, index);
      this.selectedColumns[this.table.sourceDb][this.table.sourceTable][index].isIndex = row.isIndex;
    }
  }
};
</script>

<style lang="less" scoped>
._id {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #ccc;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  zoom:.6;
  color: #ccc;
  margin-left: 5px;
}
</style>
