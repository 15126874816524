<template>
  <Layout class="login-ow-wrapper">
    <div class="header">
      <div class="header-content" @click="handleHome">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-LOGO3"></use>
        </svg>
        <span class="header-title">{{showLogin?'登录':'注册'}}</span>
      </div>
    </div>
    <div v-if="showLogin">
      <div class="login-container">
        <div class="login-content">
          <div class="home-login">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-box"></use>
            </svg>
            <div class="login-body">
              <div>
                <Tabs :value="login.loginType" style="width:330px" :animated="false"
                      @on-click="handleLoginType">
                  <TabPane label="账号密码登录" name="LOGIN_PASSWORD">
                    <div class="password-login-content">
                      <Alert type="error" show-icon v-if="!!errormsg">{{errormsg}}</Alert>
                      <Alert type="error" show-icon v-if="verifyError">{{verifyError}}</Alert>
                      <div>
                        <Input style="width: 330px;margin-bottom: 20px" type="text" v-model="login.account"
                               placeholder="邮箱/手机号"/>
                        <Input style="width: 330px;margin-bottom: 20px" type="password" password v-model="login.password" @on-keydown="handleKeyDown"
                               placeholder="密码"/>
                        <pd-button type="primary" class="home-login-btn" @click="handleLogin" :loading="loading">登录</pd-button>
                        <p style="margin-top: 20px;position: relative">没有账号，去 <a @click="handleShowSignin">注册</a>
<!--                          <a class="reset-password-btn" @click="handleReset">忘记密码</a>-->
                        </p>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane label="手机验证码登录" name="LOGIN_VERIFY">
                    <div class="phone-login-content">
                      <Alert type="error" show-icon v-if="!!errormsg">{{errormsg}}</Alert>
                      <Alert type="error" show-icon v-if="verifyError">{{verifyError}}</Alert>
                      <div>
                        <Input style="width: 330px;margin-bottom: 20px" type="text" v-model="login.account"
                               placeholder="手机号"/>
                        <Input style="width: 190px;margin-bottom: 20px" type="text" v-model="login.verifyCode"
                               placeholder="请输入短信验证码"/>
                        <pd-button style="margin-left: 6px;margin-bottom: 20px" :disabled="!sendcodeDisabled" type="primary" ghost
                                   @click="handleVerify">
                          {{sendcodeDisabled?"获取短信验证码":"再次获取("+sendCodeAgainTime+"s)"}}
                        </pd-button>
                        <pd-button type="primary" class="home-login-btn" @click="handleLogin" :loading="loading">登录</pd-button>
                        <p style="margin-top: 10px">没有账号，去 <a @click="handleShowSignin">注册</a></p>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style="margin-top: 16px;text-align: center"><span
          style="color: rgb(170, 170, 170);">Copyright © 2020 ClouGence,
                Inc.<a href="http://www.miit.gov.cn/" target="_blank" style="color: rgb(170, 170, 170);margin-left: 10px ">浙ICP备20007605号-1</a></span></p>
    </div>
    <div class="home-signin" v-if="showSignin">
      <div class="home-signin-container">
        <div class="home-signin-content">
          <p class="welcome-title">欢迎注册ClouGence</p>
          <div class="home-signin-hasAccount">已有账号，马上<a style="color: #FF7C00"
                                                       @click="handleLoginDireactly">登录
          </a></div>
          <Alert type="error" show-icon v-if="errormsg">{{errormsg}}</Alert>
          <Form ref="formInline" :model="login" label-position="right" :label-width="100" :rules="ruleValidate">
            <FormItem label="手机号码" prop="phone">
              <Input style="width: 380px" type="text" v-model="login.phone" placeholder="请输入手机号"/>
            </FormItem>
            <FormItem label="短信验证码" prop="verifyCode">
              <Input style="width: 244px" type="text" v-model="login.verifyCode"
                     placeholder="请输入短信验证码"/>
              <pd-button style="margin-left: 4px" :disabled="!sendcodeDisabled" @click="handleVerify" type="primary" ghost>
                {{sendcodeDisabled?"获取短信验证码":"再次获取("+sendCodeAgainTime+"s)"}}
              </pd-button>
              <!--                            <i v-if="verifyError" class="iconfont iconyichang" style="color: #FF6E0D;margin-left: 10px"></i>-->
              <span style="color: #FF6E0D">{{verifyError}}</span>
            </FormItem>
            <FormItem label="邮箱" prop="email">
              <Input style="width: 380px" type="text" v-model="login.email" placeholder="请输入邮箱"/>
            </FormItem>
            <FormItem label="密码" prop="password">
              <Poptip trigger="focus" placement="right-start">
                <Input style="width: 380px" type="password" v-model="login.password" password
                       placeholder="请设置密码" @on-change="handleCheckPassword"/>
                <div slot="content">
                  <ul class="password-check">
                    <li>
                      <Icon :type="pwLength?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                      <span>长度为8-32个字符</span></li>
                    <li>
                      <Icon :type="pwContain?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                      <span>密码不能包含手机号</span></li>
                    <li>
                      <Icon :type="pwFormat?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                      <span>必须包含英文和数字</span></li>
                  </ul>
                </div>
              </Poptip>
            </FormItem>
            <FormItem label="确认密码" prop="password">
              <Poptip trigger="focus" placement="right-start">
                <Input style="width: 380px" type="password" v-model="login.passwordAgain" password
                       placeholder="请再次输入密码" @on-change="handleCheckPasswordAgain"/>
                <div slot="content">
                  <div v-if="!pwConfirm" style="color: #ed4014;font-size: 12px;line-height: 20px">
                    请再次输入相同的密码
                  </div>
                  <div v-if="pwConfirm" style="color: #19be6b;font-size: 12px;">
                    <Icon type="md-checkmark" style="margin-right: 5px"/>
                    请再次输入相同的密码
                  </div>
                </div>
              </Poptip>
            </FormItem>
            <FormItem label="姓名" prop="username">
              <Input style="width: 380px" type="text" v-model="login.username" placeholder="请输入姓名"/>
            </FormItem>
            <FormItem label="公司名称" v-if="deployMode==='CLOUD_PUBLIC'" prop="company">
              <Input style="width: 380px" type="text" v-model="login.company" placeholder="请输入公司名称"/>
            </FormItem>
            <FormItem style="margin-bottom: 32px">
              <pd-button style="width: 380px" type="primary" class="home-signin-btn" :loading="loading"
                         @click="handleSigninConfirm">注册
              </pd-button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div style="position: fixed; bottom: 0; z-index: 1;width: 100%;text-align: center;left: 0;padding: 20px 0 40px 0;background: #fff"><span
          style="color: rgb(170, 170, 170);">Copyright © 2020 ClouGence,
                    Inc.<a href="http://www.miit.gov.cn/" target="_blank" style="color: rgb(170, 170, 170);margin-left: 10px ">浙ICP备20007605号-1</a></span></div>
    </div>
  </Layout>
</template>
<script>
import { signin, login } from '@services/api/auth';
import { sendCode } from '@services/api/noError';
import { queryDeployMode } from '@services/api/constant';

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      minHeight: 0,
      showLogin: true,
      showSignin: false,
      sendcodeDisabled: true,
      errormsg: '',
      verifyError: '',
      routeName: '',
      sendCodeAgainTime: 60,
      verifyCodeType: 'LOGIN',
      login: {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      },
      ruleValidate: {
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '不正确的邮箱格式', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      pwLength: false,
      pwContain: false,
      pwFormat: false,
      pwConfirm: false,
      deployMode: 'CLOUD_PUBLIC'
    };
  },
  mounted() {
    queryDeployMode().then((res) => {
      if (res.data.code === '1') {
        this.deployMode = res.data.data;
      }
    });
    this.minHeight = window.innerHeight;
    this.routeName = this.$route.name;
    // localStorage.removeItem("userInfo");
  },
  methods: {
    handleCheckPassword() {
      this.pwLength = this.login.password.length >= 8 && this.login.password.length <= 32;
      this.pwContain = this.login.password.indexOf(this.login.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.login.password);
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = Boolean(this.login.password && this.login.password === this.login.passwordAgain);
    },
    handleLogin() {
      if (this.login.loginType === 'LOGIN_PASSWORD' && (!this.login.account || !this.login.password)) {
        this.verifyError = '';
        this.errormsg = '请输入完整的账号密码';
      } else {
        this.loading = true;
        login(this.login).then((res) => {
          if (res.data.code === '1') {
            if (decodeURIComponent(window.location.href).split('redirect=')[1]) {
              window.location.href = decodeURIComponent(window.location.href).split('redirect=')[1];
              window.location.reload();
            } else {
              window.location.href = 'https://www.clougence.com/';
            }
          } else {
            const msgList = res.data.msg.split('] ');

            this.errormsg = '';
            this.verifyError = '';

            if (msgList.length > 1) {
              this.errormsg = msgList[1];
            } else {
              this.errormsg = res.data.msg;
            }
          }
          this.loading = false;
        });
      }
    },
    handleLoginDireactly() {
      this.showSignin = false;
      this.showLogin = true;
      this.verifyCodeType = 'LOGIN';
      this.errormsg = '';
      this.verifyError = false;
      this.verifyError = '';
      this.login = {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      };
    },
    handleSigninConfirm() {
      // let p = new RegExp('^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$');
      //
      // if (!p.exec(this.login.account)){
      //     this.$Modal.warning({
      //         title: '注册异常',
      //         content: '请输入正确的手机号'
      //     });
      // } else
      if (!this.pwLength || !this.pwContain || !this.pwFormat) {
        this.$Modal.warning({
          title: '注册异常',
          content: '密码格式有误，请重新填写'
        });
      } else if (!this.pwConfirm) {
        this.$Modal.warning({
          title: '注册异常',
          content: '请确保两次输入的密码是相同的'
        });
      } else {
        this.loading = true;
        signin(this.login).then((res) => {
          this.loading = false;
          if (res.data.code === '1') {
            this.$Modal.success({
              title: '注册成功',
              content: '您已注册成功，2s后将跳转（登录页面）',
              onOk: () => {
                this.handleLoginDireactly();
              }
            });
            this.errormsg = '';
            this.verifyError = '';
            const that = this;

            setTimeout(() => {
              that.$Modal.remove();
              that.handleLoginDireactly();
            }, 2000);
          } else {
            this.errormsg = res.data.msg;
          }
        });
      }
    },
    handleVerify() {
      let phonenumber = '';

      if (this.verifyCodeType === 'LOGIN') {
        phonenumber = this.login.account;
      } else {
        phonenumber = this.login.phone;
      }
      // let p = new RegExp('^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$');
      //
      // if (!p.exec(phonenumber)){
      //     this.errormsg = '';
      //     this.verifyError = '请输入正确的手机号';
      // } else {
      this.errormsg = '';
      this.verifyError = '';
      // eslint-disable-next-line one-var
      const that = this,
        sendCodeAgain = setInterval(() => {
          if (that.sendCodeAgainTime > 0) {
            that.sendCodeAgainTime--;
          } else {
            clearInterval(sendCodeAgain);
            that.sendcodeDisabled = true;
          }
        }, 1000);

      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      sendCode({
        verifyType: 'SMS_VERIFY_CODE',
        email: '',
        phoneNumber: phonenumber,
        verifyCode: '',
        verifyCodeType: this.verifyCodeType
      }).then((res) => {
        if (res.data.code !== '1') {
          const msgList = res.data.msg.split('] ');

          this.errormsg = '';

          if (msgList.length > 1) {
            this.verifyError = msgList[1];
          } else {
            this.verifyError = res.data.msg && JSON.parse(res.data.msg) && JSON.parse(res.data.msg) instanceof Array ? JSON.parse(res.data.msg).join('/n') : res.data.msg;
          }
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(sendCodeAgain);
        }
      }).catch((e) => {
        this.errormsg = '';
        this.verifyError = e;
        this.sendcodeDisabled = true;
        this.sendCodeAgainTime = 60;
        clearInterval(sendCodeAgain);
      });
      // }
    },
    handleLoginType(name) {
      this.login.loginType = name;
      this.errormsg = '';
      this.verifyError = '';
    },
    handleInvitation() {
      this.$router.push({ path: '/home/invitation' });
      window.location.reload();
    },
    handleShowSignin() {
      this.showSignin = true;
      this.showLogin = false;
      this.verifyCodeType = 'REGISTER';
      this.errormsg = '';
      this.verifyError = false;
      this.verifyError = '';
      this.login = {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      };
    },
    handleKeyDown(data) {
      if (data.keyCode === 13) {
        this.handleLogin();
      }
    },
    handleHome() {
      window.location.href = 'https://www.clougence.com/';
    },
    handleReset() {
      this.$router.push({
        path: '/login/reset'
      });
      window.location.reload();
    }
  }
};
</script>
<style lang="less">
.login-ow-wrapper {
  background: #ffffff;
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100vh;

  .header {
    background-color: #ffffff;
    height: 80px;
    box-shadow: 6px 1px 6px 0 rgba(0, 0, 0, 0.08);

    .header-content {
      width: 1200px;
      margin: 0 auto;

      .icon {
        font-size: 160px;
        display: inline-block;
        height: 80px;
        line-height: 80px;
        vertical-align: middle;
      }

      .header-title {
        font-size: 24px;
        color: #555555;
        vertical-align: middle;
        border-left: 1px solid #DADADA;
        padding-left: 20px;
        margin-left: 20px;
        font-family: PingFangSC-Medium,serif;
        font-weight: 500;
      }
    }
  }

  .login-container {
    background: #F6F6F6;

    .login-content {
      width: 1440px;
      height: 545px;
      margin: 0 auto;
      background: url("~@asset/login-back-ow.png");
      background-size: 600px;
      background-repeat: no-repeat;
      background-position: 180px 30px;
      position: relative;

      .home-login {
        position: absolute;
        right: 160px;
        top: 60px;
        background: #ffffff;
        padding: 0 82px 64px 82px;

        .icon {
          position: absolute;
          right: 60px;
          font-size: 32px;
          top: -6px;
        }

        .ivu-input-prefix i, .ivu-input-suffix i {
          height: 44px;
          line-height: 44px;
          font-size: 20px;
        }

        .ivu-tabs-nav .ivu-tabs-tab {
          padding: 23px;
        }

        .login-title {
          font-size: 18px;
          margin-bottom: 16px;
          padding-left: 10px;
        }

        .password-login-content {
          padding-top: 46px;
          position: relative;

          .ivu-alert {
            position: absolute;
            top: 6px;
            width: 330px;
          }

          .reset-password-btn {
            position: absolute;
            right: 0;
            top: 0;
            color: #333333;
            //font-size: 14px;
          }
        }

        .phone-login-content {
          padding-top: 46px;
          position: relative;

          .ivu-alert {
            position: absolute;
            top: 6px;
            width: 330px;
          }
        }

        .home-login-btn {
          width: 330px;
          height: 40px;
          line-height: 38px;
          font-size: 16px;
          font-family: PingFangSC-Semibold,serif;
        }

        .ivu-tabs-bar {
          .ivu-tabs-ink-bar {
            background: #FF7C00;
          }
        }

        .ivu-tabs-nav .ivu-tabs-tab-active {
          color: #333333;
        }

        .ivu-btn-primary {
          background: #FF7C00;
          border-color: #FF7C00;
        }

        .ivu-btn-ghost {
          background: #ffffff;
          color: #FF7C00;

          &:hover {
            background: #FF7C00;
            color: #ffffff;
          }
        }

        a {
          color: #FF7C00;
        }

        .ivu-input:hover, .ivu-input:focus {
          border-color: #FF7C00;
        }
      }
    }

    .ivu-input {
      height: 44px;
    }
  }

  .home-signin {
    padding-top: 60px;

    .home-signin-container {
      background: #ffffff;
      width: 1000px;
      margin: 0 auto;
      padding: 60px 0;
      position: relative;

      .ivu-form-item {
        margin-bottom: 16px;

        .ivu-form-item-content {
          overflow: visible;
          white-space: nowrap;
        }
      }

      .home-signin-hasAccount {
        position: absolute;
        right: -200px;
        top: 6px;
      }

      .home-signin-content {
        margin: 0 auto;
        width: 500px;
        position: relative;

        .welcome-title {
          font-size: 20px;
          text-align: center;
          margin-bottom: 36px;
        }

        .ivu-alert {
          position: absolute;
          top: 28px;
          width: 480px;
        }
      }

      .ivu-btn-primary {
        background: #FF7C00;
        border-color: #FF7C00;
      }

      .ivu-btn-ghost {
        background: #ffffff;
        color: #FF7C00;

        &:hover {
          background: #FF7C00;
          color: #ffffff;
        }
      }

      .ivu-input:hover, .ivu-input:focus {
        border-color: #FF7C00;
      }
    }

    .ivu-form-item-error-tip {
      position: relative;
      display: inline-block;
      color: #FF6E0D;
      margin-left: 8px;
    }
  }
}
</style>
