<template>
  <div class="reset-password-container" :style="`height:${minHeight}px`">
    <div class="reset-password-content">
      <div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-logo-c"></use>
        </svg>
        <span class="reset-title">找回密码</span>
        <span class="reset-return">
          <span>已有账号，<a @click="handleLogin">马上登录</a></span>
          <span class="reset-return-home"><a
            href="https://www.clougence.com">返回ClouGence.com</a></span>
        </span>
      </div>
      <div class="reset-password-process-container">
        <div class="create-task-process">
          <div class="create-task-step">
            <Steps :current="currentStep">
              <Step title="重置登录密码"></Step>
              <!--              <Step title="重置登录密码" icon="md-paper"></Step>-->
              <Step title="完成"></Step>
            </Steps>
          </div>
          <div class="create-task-body">
            <ConfirmAccount v-if="currentStep === 0" :updateStep="updateStep"
                            :confirmData="confirmData"></ConfirmAccount>
            <!--            <ResetPassword v-if="currentStep === 1" :updateStep="updateStep" :confirmData="confirmData"></ResetPassword>-->
            <FinishReset v-if="currentStep === 1"></FinishReset>
          </div>
        </div>
      </div>
      <p class="reset-footer">© 2009-2020 ClouGence.com 版权所有 ICP证：浙B2-20190101</p>
    </div>
  </div>
</template>
<script>
import ConfirmAccount from './ConfirmAccount';
import FinishReset from './FinishReset';

export default {
  components: {
    ConfirmAccount,
    FinishReset
  },
  data() {
    return {
      currentStep: 0,
      minHeight: 0,
      confirmData: {
        phone: '',
        verifyCode: '',
        password: ''
      }
    };
  },
  mounted() {
    this.minHeight = window.innerHeight;
  },
  methods: {
    handleLogin() {
      this.$router.push({ path: '/login' });
      window.location.reload();
    },
    updateStep(n) {
      this.currentStep = n;
    }
  }
};
</script>
<style lang="less" scoped>
.reset-password-container {
  background: #EDEDED;
  padding: 100px 200px 0 200px;
  height: 100%;
}

.reset-password-content {
  width: 100%;
  position: relative;

  .icon {
    width: 160px;
    height: 30px;
    margin: 0 20px;
  }

  .reset-title {
    color: #555555;
    font-size: 20px;
  }

  .reset-return {
    position: absolute;
    right: 0;
    top: 12px;

    .reset-return-home {
      border-left: 1px solid #DADADA;
      padding-left: 10px;
      margin-left: 10px;
    }
  }
}

.reset-password-process-container {
  width: 100%;
}

.reset-footer {
  color: #888888;
  font-size: 14px;
  text-align: center;
  position: relative;
  top: -50px;
}
</style>
