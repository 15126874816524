<template>
  <div class="page-footer-container">
    <div class="page-footer-batch" v-if="batchEdit">
      <pd-button :disabled="batchEditBtns.indexOf('restart')===-1" type="primary"
                 @click="handleBatchEdit('restart')">重启
      </pd-button>
      <pd-button :disabled="batchEditBtns.indexOf('stop')===-1" type="primary"
                 @click="handleBatchEdit('stop')">停止
      </pd-button>
      <pd-button :disabled="batchEditBtns.indexOf('start')===-1" type="primary"
                 @click="handleBatchEdit('start')">启动
      </pd-button>
      <pd-button :disabled="batchEditBtns.indexOf('fsmStart')===-1" type="primary"
                 @click="handleBatchEdit('fsmStart')">初次手动启动
      </pd-button>
    </div>
    <div :class="batchEdit?'page-footer-paging':'page-footer-paging page-footer-shadow'">
      <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
            :page-size="size" @on-page-size-change="handlePageSizeChange"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    handlePageChange: Function,
    total: Number,
    batchEdit: Boolean,
    handlePageSizeChange: Function,
    size: Number,
    handleBatchEdit: Function,
    batchEditBtns: Array
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.page-footer-container {
  width: 100%;
  vertical-align: middle;

  .page-footer-batch {
    background: #FFFFFF;
    height: 60px;
    line-height: 58px;
    padding: 0 16px;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;

    button {
      margin-right: 8px;
    }
  }

  .page-footer-paging {
    height: 60px;
    background: #FAFAFA;
    text-align: center;
    line-height: 60px;
  }

  .page-footer-shadow {
    box-shadow: 6px 2px 23px rgba(197, 197, 197, 0.5);
  }
}
</style>
