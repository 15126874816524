<template>
  <div class="page-header-container">
    <Form ref="formInline" :model="searchData" inline label-position="left"
          style="padding-right: 300px">
      <FormItem>
        <Select v-model="searchType" style="width:120px" @on-change="handleChangeSearchType">
          <Option value="dataJobDesc" :label="$t('dataJob.taskDescription')">
            <span>{{ $t('dataJob.taskDescription') }}</span>
          </Option>
          <Option value="instanceId" :label="$t('dataJob.instanceId')">
            <span>{{ $t('dataJob.instanceId') }}</span>
          </Option>
          <Option value="dataJobType" :label="$t('dataJob.taskType')">
            <span>{{ $t('dataJob.taskType') }}</span>
          </Option>
          <Option value="sourceInstance" :label="$t('dataJob.sourceId')">
            <span>{{ $t('dataJob.sourceId') }}</span>
          </Option>
          <Option value="sinkInstance" :label="$t('dataJob.targetId')">
            <span>{{ $t('dataJob.targetId') }}</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='instanceId'">
        <Input v-model="searchData.dataJobName" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='dataJobType'">
        <Select v-model="searchData.dataJobType" style="width:250px">
          <Option value="MIGRATION" :label="$t('dataJob.dataMigration')">
            <span>{{ $t('dataJob.dataMigration') }}</span>
            <Icon type="md-repeat"></Icon>
          </Option>
          <Option value="SYNC" :label="$t('dataJob.dataSynchronization')">
            <span>{{ $t('dataJob.dataSynchronization') }}</span>
            <Icon type="md-sync"></Icon>
          </Option>
          <Option value="CHECK" :label="$t('dataJob.dataCheck')">
            <span>{{ $t('dataJob.dataCheck') }}</span>
            <Icon type="md-checkmark-circle-outline"/>
          </Option>
          <Option value="STRUCT_MIGRATION" :label="$t('dataJob.buildStruct')">
            <span>{{ $t('dataJob.buildStruct') }}</span>
            <Icon type="md-swap"/>
          </Option>
          <!--                            <Option value="SUBSCRIBE">数据订阅</Option>-->
          <Option value="all" :label="$t('all')">{{ $t('all') }}</Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='dataJobDesc'">
        <Input v-model="searchData.desc" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='sourceInstance'">
        <Select filterable style="width: 250px" v-model="searchData.sourceInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='sinkInstance'">
        <Select filterable style="width: 250px" v-model="searchData.targetInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <pd-button type="primary" @click="_handleSearch" :loading="loading">
          {{ $t('search') }}
        </pd-button>
        <Checkbox style="margin-left: 20px" v-model="ifClassfy" @on-change="handleChooseClassify">
          分类
        </Checkbox>
        <Select v-model="classfyType" style="width:98px" :disabled="!ifClassfy"
                @on-change="handleClassify">
          <Option value="status">任务状态</Option>
          <Option value="state">任务阶段</Option>
          <Option value="type">任务种类</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="page-header-function">
      <!--            <a href="/home/document" target="_blank">查看任务创建流程</a>-->
      <pd-button type="primary" ghost @click="handleCreateJob">
        <i class="iconfont" style="font-size: 12px">&#xe662;</i>
        创建任务
      </pd-button>
      <pd-button v-if="mode==='table'" @click="updateBatchInTable">
        {{ batchEditInTable ? '取消批量处理任务' : '批量处理任务' }}
      </pd-button>
      <Tooltip transfer placement="bottom-end" :content="`点击切换${mode==='table'?'卡片':'列表'}模式`">
                <span class="data-job-mode-switch"
                      @click="handleChangeMode(mode==='table'?'card':'table')">
                <svg class="icon" aria-hidden="true" v-if="mode==='table'">
                    <use xlink:href="#icon-card"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-if="mode==='card'">
                    <use xlink:href="#icon-list"></use>
                </svg>
            </span>
      </Tooltip>
      <Checkbox style="margin-left: 8px" v-model="hasIntervalNow" @on-change="handleInterval">
        20秒定时刷新
      </Checkbox>
      <pd-button style="margin-left: 0" type="default" :loading="loading"
                 @click="_handleSearch">{{ $t('refresh') }}
      </pd-button>
    </div>
  </div>
</template>
<script>
import { listUserLicense } from '../../../../services/api/userLicense';
import { getUserQuota } from '../../../../services/api/resourceQuota';

export default {
  props: ['handleSearch', 'handleChangeMode', 'mode', 'batchEditInTable', 'updateBatchInTable', 'handleClassify', 'loading', 'handleInterval', 'hasInterval', 'instanceList'],
  data() {
    return {
      ifClassfy: false,
      classfyType: 'status',
      hasIntervalNow: this.hasInterval,
      searchType: 'dataJobDesc',
      searchData: {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      }
    };
  },
  created() {
    const params = JSON.parse(sessionStorage.getItem('job_search_params'));
    if (params) {
      this.searchData = params;
      this.searchType = params.searchType;
      this.ifClassfy = params.ifClassfy;
      this.classfyType = params.classfyType;
      this.handleSearch(params);
    } else {
      this.handleSearch(this.searchData);
    }
  },
  methods: {
    _handleSearch() {
      const {
        ifClassfy, classfyType, searchType, searchData
      } = this;
      sessionStorage.setItem('job_search_params', JSON.stringify({
        searchType, ifClassfy, classfyType, ...searchData
      }));
      this.handleSearch(this.searchData);
    },
    handleCreateJob() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.forEach((item) => {
              if (item.quotaType === 'DATA_JOB_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: '额度不足',
                    content: '您当前的任务数额度不足，请去<a href="/system/userCenter">个人中心</a>申请额度。'
                  });
                } else {
                  listUserLicense()
                    .then((res1) => {
                      if (res1.data.code === '1') {
                        let hasResource = false;

                        res1.data.data.forEach((item1) => {
                          if (item1.amount > 0 || item1.amount === -10000) {
                            hasResource = true;
                          }
                        });
                        if (hasResource) {
                          this.$router.push({ path: '/data/job/create/process' });
                        } else {
                          this.$Modal.warning({
                            title: '资源不足',
                            content: '您当前的资源不足，请去<a href="/system/userCenter">个人中心</a>充值后再创建任务。'
                          });
                        }
                      }
                    });
                }
              }
            });
          }
        });
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      };
    },
    handleChooseClassify(data) {
      this.handleChangeMode('card');
      if (!data) {
        this.classfyType = 'status';
        this.handleClassify('');
      } else {
        this.classfyType = 'status';
        this.handleClassify('status');
      }
    }
  }
};
</script>
<style lang="less" scoped>
.page-header-container {
  background: #ffffff;
  border: 1px solid #EDEDED;
  height: 60px;
  line-height: 54px;
  padding: 0 20px;
  position: relative;

  .ivu-form-inline .ivu-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .ivu-form-item {
    margin-bottom: 0;
  }

  .page-header-function {
    position: absolute;
    right: 20px;
    top: 2px;

    a {
      color: #333;
      margin-right: 10px;
    }

    button {
      margin-left: 8px;
    }

    .ivu-tooltip {
      margin-left: 8px;
    }
  }
}

.data-job-mode-switch {
  width: 32px;
  height: 32px;
  border: 1px solid #BABDC5;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
</style>
