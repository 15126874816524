<template>
    <div>
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem>状态机管理</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchKey" inline label-position="left"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:100px" @on-change="handleChangeSearchType">
                        <Option value="status" label="状态">
                            <span>状态</span>
                        </Option>
                        <Option value="instanceId" label="任务ID">
                            <span>任务ID</span>
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='instanceId'">
                    <Input v-model="searchKey.dataJobName" style="width: 280px"/>
                </FormItem>
                <FormItem v-if="searchType==='status'">
                    <Select v-model="searchKey.dataTaskState" style="width: 280px">
                        <Option value="all">全部</Option>
                        <Option v-for="(state) of dataStates" :value="state" :key="state">{{`${TaskStateMapping.taskState[state]}（${state}）`}}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <pd-button type="primary" @click="getFsmList">查询</pd-button>
                </FormItem>
            </Form>
            <pd-button type="default" style="position: absolute;right: 20px;top: 12px" @click="handleRefresh"
                    :loading="refreshLoading">
                刷新
            </pd-button>
        </div>
        <Table style="margin-top: 20px" size="small" border :columns="stateColumn" :data="showData">
            <template slot-scope="{ row }" slot="link">
                <a @click="handleDetail(row.id)" style="color: #0BB9F8;width:100%;display: block">{{row.id}}</a>
            </template>
            <template slot-scope="{ row }" slot="action">
                <a style="margin-right: 5px" @click="handleDetail(row)">详情
                </a>
              <a v-if="row.active" style="margin-right: 5px" @click="handleShowInactiveFsmModal(row)">失活
              </a>
                <a v-else style="margin-right: 5px" @click="handleActive(row)">激活
                </a>
            </template>
        </Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <Page :total="stateData.length" show-total
                      @on-change="handlePageChange" @on-page-size-change="handlePageSizeChange"/>
            </div>
        </div>
      <Modal
        v-model="showInactiveFsmModal"
        title="失活状态机确认"
        footer-hide
      >
        <div>
          <p>确认要失活名为{{ selectedRow.dataJobDesc }}({{ selectedRow.dataJobName }})的状态机？</p>
          <div slot="footer" class="modal-footer" style="margin-top: 20px">
            <pd-button type="primary" @click="handleInActive">确定</pd-button>
            <pd-button @click="hideInactiveFsmModal">取消</pd-button>
          </div>
        </div>
      </Modal>
    </div>
</template>
<script>
import { listFSM, reactiveFSM, inactiveFSM } from '@services/api/fsm';
import { listDataTaskStates } from '@services/api/constant';
import fecha from 'fecha';
import _ from 'lodash';
import TaskStateMapping from '../util';

export default {
  mounted() {
    this.getFsmList();
    this.listDataTaskStates();
  },
  data() {
    return {
      showInactiveFsmModal: false,
      selectedRow: {},
      searchType: 'status',
      TaskStateMapping,
      refreshLoading: false,
      searchKey: {
        dataTaskState: 'all',
        dataJobName: ''
      },
      page: 1,
      size: 10,
      dataStates: [],
      stateColumn: [
        {
          title: '状态机ID',
          width: 100,
          key: 'id'
        },
        {
          title: '任务ID',
          key: 'dataJobName',
          width: 200,
          render: (h, params) => h('a', {
            on: {
              click: () => {
                this.$router.push({ path: `/data/job/${params.row.dataJobId}` });
              }
            }
          }, params.row.dataJobName)
        },
        {
          title: '描述',
          key: 'dataJobDesc'
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          width: 190,
          sortable: true,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '当前状态',
          key: 'dataTaskState',
          render: (h, params) => h('div', {}, `${TaskStateMapping.taskState[params.row.dataTaskState]}（${params.row.dataTaskState}）`)
        },
        {
          title: '先前状态',
          key: 'prevTaskState',
          render: (h, params) => h('div', {}, `${TaskStateMapping.taskState[params.row.prevTaskState]}（${params.row.prevTaskState}）`)
        },
        {
          title: '活跃状态',
          key: 'active',
          width: 120,
          render: (h, params) => {
            if (!params.row.active && params.row.dataTaskState === 'COMPLETE') {
              return h('div', {
                style: {
                  marginLeft: '40px'
                }
              }, '-');
            }
            return h('div', {
              style: {
                background: params.row.active ? 'rgb(25, 190, 107)' : 'rgb(237, 64, 20)',
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginLeft: '40px'
              }
            });
          }
        },
        {
          title: '是否有异常',
          key: 'exceptionStr',
          width: 150,
          render: (h, params) => h('div', {
            style: {
              color: params.row.exceptionStr ? 'rgb(237, 64, 20)' : ''
            }
          }, params.row.exceptionStr ? '有' : '无')
        },
        {
          title: '操作',
          key: '',
          slot: 'action',
          width: 150
        }
      ],
      stateData: [],
      showData: []
    };
  },
  methods: {
    hideInactiveFsmModal() {
      this.showInactiveFsmModal = false;
    },
    handleShowInactiveFsmModal(row) {
      this.showInactiveFsmModal = true;
      this.selectedRow = row;
    },
    handleRefresh() {
      this.getFsmList();
    },
    handleDetail(row) {
      this.$router.push({ path: `/system/fsm/${row.id}/${row.dataJobId}` });
    },
    handleInActive() {
      inactiveFSM({
        fsmId: this.selectedRow.id
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('失活成功！');
          this.showInactiveFsmModal = false;
          this.getFsmList();
        }
      });
    },
    handleActive(row) {
      reactiveFSM({
        fsmId: row.id,
        dataJobId: row.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('激活成功！');
          this.getFsmList();
        }
      });
    },
    getFsmList() {
      this.refreshLoading = true;
      const searchData = _.cloneDeep(this.searchKey);

      if (searchData.dataTaskState === 'all') {
        searchData.dataTaskState = '';
      }
      listFSM(searchData).then((res) => {
        if (res.data.code === '1') {
          this.stateData = res.data.data;
          this.showData = this.stateData.slice((this.page - 1) * this.size, this.page * this.size);
        }
        this.refreshLoading = false;
      }).catch(() => {
        this.refreshLoading = false;
      });
    },
    listDataTaskStates() {
      listDataTaskStates().then((res) => {
        if (res.data.code === '1') {
          this.dataStates = res.data.data;
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.stateData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.showData = this.stateData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handleChangeSearchType() {
      this.searchKey = {
        dataTaskState: 'all',
        dataJobName: ''
      };
    }
  }
};
</script>
<style lang="less">
    .state-machine-wrapper {
        background: #ffffff;
        margin-top: 16px;
        padding: 24px;
    }
</style>
