import {instance} from '../instance';

export const listConsoleJobLabels = (param) => instance.post('constant/consolejoblabel', param);

export const listCloudOrIdcNames = (param) => instance.post('constant/cloudoridcname', param);

export const listWorkerTypes = (param) => instance.post('constant/workertype', param);

export const listRegions = (param) => instance.post('constant/region', param);

export const listDataJobTypes = (param) => instance.post('constant/datajobtype', param);

export const listDataTaskStates = (param) => instance.post('constant/datataskstate', param);

export const listConsoleTaskStates = (param) => instance.post('constant/consoletaskstate', param);

export const listDatasourceDeployType = (param) => instance.post('constant/datasourcedeploytype', param);

export const supportedsourcedstype = (param) => instance.post('constant/supportedsourcedstype', param);

export const supportedtargetdstype = (param) => instance.post('constant/supportedtargetdstype', param);

export const listSupportedRegions = (param) => instance.post('constant/supportedregion', param);

export const queryDeployMode = (param) => instance.post('constant/deploymode', param);

export const listHdfsFileFormat = (param) => instance.post('constant/listhdfsfileformatandcompress', param);

export const getGlobalSystemConfig = (param) => {
    return instance.post('/constant/global/config', param);
}

export const listHivePartitionFunc = (param) => {
    return instance.post('constant/listhivepartitionfunc', param);
};

export const listDataSourceTypesByDeployType = (param) => instance.post('constant/dstypebydeploytype', param);

export const defaultLicenseTypeUrl = (param) => instance.post('constant/defaultlicensetypeurl', param);

export const defaultDisplayLicenseSet = (param) => instance.post('constant/defaultdisplaylicenseset', param);

export const transformJobType = (param) => instance.post('constant/transformjobtype', param);

export const listDsSupportedRegions = (param) => instance.post('constant/dssupportedregion', param);

export const drdsPartitionAlgs = (param) => instance.post('constant/dspartition/polardbxpartitionalgs', param);

export const dsSecurityOption = (param) => instance.post('constant/security/dssecurityoption', param);

export const tableMappingRule = (param) => {
    return instance.post('constant/tablemappingrule', param);
};

export const colMappingRule = (param) => instance.post('constant/colmappingrule', param);

export const mqSchemaFormat = (param) => instance.post('constant/mqschemaformat', param);

export const dsCharsetOptions = (param) => instance.post('constant/dscharset/dscharsetoptions', param);

export const mqSchemaLessValueFormats = (param) => {
    return instance.post('constant/mqschemalessvalueformats', param);
};

export const cacheSchemaLessValueFormats = (param) => instance.post('constant/cacheschemalessvalueformats', param);

export const schemaLessValueFormatTemplate = (param) => instance.post('constant/schemalessvalueformattemplate', param);

export const listFilterDsTypes = (param) => {
    return instance.post('constant/listfilterdstypes', param);
};

export const ckTableEngine = (param) => {
    return instance.post('constant/cktableengine', param);
};

export const getEsAnalyzerTypes = (param) => {
    return instance.post('constant/getesanalyzertypes', param);
};

export const dsPosTypes = (param) => {
  return instance.post('constant/dspostypes', param);
};

export const validExprFilter = (param) => instance.post('datarule/validexprfilter', param);

export const listLoopRunTypes = (param) => instance.post('constant/listloopruntypes', param);

export const editJobInitialSyncSwitch = (param) => instance.post('constant/editjobinitialsyncswitch', param);

export const noPkUkSyncUpdateDelete = (param) => instance.post('constant/nopkuksyncupdatedelete', param);

export const needTestBeforeAddDsTypes = (param) => instance.post('constant/needtestbeforeadddstypes', param);

