<template>
  <div class="app-wrapper">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>
<style lang="less">
.content-wrapper {
  padding: 64px 16px 0 16px;
}
</style>
