import { instance } from '../instance';

export const listLicense = (param) => instance.post('license/listlicense', param);

export const listLicenseSet = (param) => instance.post('license_set/listlicenseset', param);

export const listAllLicenseMeta = (param) => instance.post('license_meta/listall', param);
export const genNewLicenseSetAndOccupy = (param) => instance.post('license_set/gennewlicensesetandoccupy', param);
export const generateSet = (param) => instance.post('license_set_meta/generateset', param);
export const listAll = (param) => instance.post('license_set_meta/listall', param);
export const listAllSimple = (param) => instance.post('license_set_meta/listallsimple', param);
export const logicDeleteSet = (param) => instance.post('license_set_meta/logicdeleteset', param);
