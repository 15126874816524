import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    setPkAndIdFlags: {},
    globalConfig: {},
    sourceConnection: false,
    sinkConnection: false,
    dataSourceDeployTypes: [],
    sourceDataSourceTypes: [],
    sinkDataSourceTypes: [],
    sourceInstance: [],
    sinkInstance: [],
    sourceDbList: [],
    sinkDbList: [],
    showTest1: false,
    showTest2: false,
    tableDatas: [],
    selectedTables: {},
    compareSelectedTables: [],
    compareSelectedDbs: [],
    firstToTableFilter: false,
    firstToCleanData: false,
    sinkTableList: {},
    selectedColumns: {},
    tableInfo: [],
    cleanDataData: [],
    sinkColumns: {},
    cleanDataSinkTables: [],
    urlLabels: [],
    userRole: '',
    specsMap: {},
    rdsData: [],
    addedRdsList: [],
    firstAddDataSource: true,
    selectedCluster: {},
    clusterList: [],
    jobData: null
  },
  mutations: {
    updateSetPkAndIdFlags(state, flags) {
      state.setPkAndIdFlags = flags;
    },
    getGlobalConfig(state, config) {
      state.globalConfig = config;
    },
    getUserRole(state, role) {
      state.userRole = role;
    },
    getUrlLabels(state, list) {
      state.urlLabels = list;
    },
    changeConnection(state, changeState) {
      if (changeState.type === 'source') {
        state.sourceConnection = changeState.ifConnection;
      } else {
        state.sinkConnection = changeState.ifConnection;
      }
    },
    getDataSourceDeployTypes(state, list) {
      state.dataSourceDeployTypes = list;
    },
    getSourceDataSourceTypes(state, list) {
      state.sourceDataSourceTypes = list;
    },
    getSinkDataSourceTypes(state, list) {
      state.sinkDataSourceTypes = list;
    },
    getSourceInstance(state, list) {
      state.sourceInstanceList = list;
    },
    getSinkInstance(state, list) {
      state.sinkInstanceList = list;
    },
    getSourceDbList(state, list) {
      state.sourceDbList = list;
    },
    getSinkDbList(state, list) {
      state.sinkDbList = list;
    },
    changeTest1(state, ifTest) {
      state.showTest1 = ifTest;
    },
    changeTest2(state, ifTest) {
      state.showTest2 = ifTest;
    },
    getTableDatas(state, data) {
      state.tableDatas[data.db][data.index][data.type] = data.data;
    },
    updateCleanDataData(state, data) {
      state.cleanDataData[`${data.table}|${data.db}`][data.index][data.type] = data.data;
    },
    getSelectedTables(state, data) {
      state.selectedTables = data;
    },
    getSelectedColumns(state, data) {
      state.selectedColumns = data;
    },
    updateSelectedTables(state, data) {
      state.selectedTables[data.db][data.index][data.type] = data.data;
    },
    updateTableFilter(state) {
      state.firstToTableFilter = true;
    },
    updateCleanData(state, firstToCleanData = true) {
      state.firstToCleanData = firstToCleanData;
    },
    getSinkTableList(state, data) {
      state.sinkTableList[data.db] = data.data;
    },
    getTableInfo(state, list) {
      state.tableInfo = list;
    },
    getSinkColumns(state, data) {
      state.sinkColumns[data.key] = data.data;
    },
    getCleanDataData(state, data) {
      state.cleanDataData[`${data.table}|${data.db}`] = data.data;
    },
    clearCleanData(state) {
      state.cleanDataData = {};
    },
    getCleanDataSinkTables(state, list) {
      state.cleanDataSinkTables = list;
    },
    updateCompareSelectedTables(state, obj) {
      state.compareSelectedTables = obj;
    },
    updateCompareSelectedDbs(state, obj) {
      state.compareSelectedDbs = obj;
    },
    getJobDataForSimilarJob(state, data) {
      state.jobData = data;
    }
  },
  actions: {},
  modules: {}
});
