import {instance} from '../file';

export const addDataSource = (param) => instance.post('datasource/add', param);

export const createJob = (param) => instance.post('datajob/create', param);

export const updateAccountAndPassword = (param) => instance.post('datasource/updateaccountandpassword', param);

export const uploadPackage = (param) => instance.post('jarpackage/upload', param);

