<template>
  <div>
    <Table :row-class-name="rowClassName" no-data-text="您还没有相关任务" class="job-list-table" border
           :columns="batchEditInTable?jobColumnBatch:jobColumn" :data="showData" row-key="dataJobId"
           @on-sort-change="handleTableSort" :stripe="true" @on-select-all="handleSelectAllBatch"
           @on-select-all-cancel="handleCancelAllBatch"
           @on-select="handleSelectBatch" @on-select-cancel="handleCancelBatch"
           ref="job-list-table">
      <template slot-scope="{ row }" slot="instanceId">
        <div style="padding: 30px 10px 30px 12px;position:relative">
          <router-link :to="`/data/job/${row.dataJobId}`">{{ row.dataJobName }}</router-link>
          <Tooltip placement="right" class="dataSource-icon" transfer
                   :content="`${row.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.sourceDsVO.dataSourceType]:
                           row.sourceDsVO.dataSourceType}->${row.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.targetDsVO.dataSourceType]:row.targetDsVO.dataSourceType}`">
            <DataSourceIcon class="job-list-datasource job-list-source"
                            :type="row.sourceDsVO.dataSourceType"
                            :instanceType="row.sourceDsVO.deployType"></DataSourceIcon>

            <DataSourceIcon class="job-list-datasource job-list-sink"
                            :type="row.targetDsVO.dataSourceType"
                            :instanceType="row.targetDsVO.deployType"></DataSourceIcon>
          </Tooltip>
          <Tooltip placement="bottom" class="alarm-icon" transfer content="账户余额不足，任务被冻结"
                   v-if="row.lifeCycleState==='LOCKED'&&row.consoleTaskState!=='FAILED'">
            <span style="display: block" @click="handleGoUserCenter"><i
              class="iconfont iconfreeze"></i></span>
          </Tooltip>
          <Tooltip placement="right" class="alarm-icon" transfer content="存在异常的后台任务，请点击处理"
                   v-if="row.consoleTaskState==='FAILED'">
            <span style="display: inline-block" @click="handleGoConsoleJob(row)"><i
              class="iconfont iconyibuforce"></i></span>
          </Tooltip>
          <Tooltip placement="bottom" class="alarm-icon" transfer content="该任务存在异常，点击查看详情"
                   v-if="row.haveException">
            <span style="display: block" @click="handleGoExceptionLog(row)"><i
              class="iconfont iconyibuforce"></i></span>
          </Tooltip>
          <div class="data-job-desc" style="position:relative;padding-right: 20px;margin-top: 4px">
            <Tooltip :content="row.dataJobDesc?row.dataJobDesc:'暂无描述'" placement="bottom" transfer>
                <span style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;
                    -o-text-overflow:ellipsis;max-width: 150px;display: inline-block;vertical-align: middle">
                      {{ row.dataJobDesc ? row.dataJobDesc : '暂无描述' }}</span>
            </Tooltip>
            <i class="iconfont iconBIANJI job-list-desc-edit icon"
               @click="handleEditJobDesc(row)"></i>
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="dataJobType">
        <div>
          {{Mapping.jobType[row.dataJobType]}}
          <Tooltip transfer v-if="row.havePackage" content="存在自定义代码处理的任务" placement="right">
            <svg style="font-size: 14px;margin-left: 6px" class="icon job-info-status job-info-status-running" aria-hidden="true">
              <use xlink:href="#icon-zidingyishujuchuli"></use>
            </svg>
          </Tooltip>
        </div>
      </template>
      <template slot-scope="{ row }" slot="progress">
        <div style="padding: 7px">
          <div style="display: flex;">
            <div v-if="getDataTask(row.dataTasks)['BUILD_STRUCT']" class="job-state-item-container"
                 :style="`width:${450/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus,getDataTask(row.dataTasks)['BUILD_STRUCT'].healthLevel)"
                  :percent="parseInt(Number(JSON.parse(getDataTask(row.dataTasks)['BUILD_STRUCT'].taskPosition).builtPercent)*100, 10)"
                  :stroke-width="20" text-inside/>
              </div>
              <div class="job-state-item-content">
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  结构迁移</p>
                <p class="job-info-progress-content">
                  {{ JSON.parse(getDataTask(row.dataTasks)['BUILD_STRUCT'].taskPosition).built }}
                  /
                  {{
                    JSON.parse(getDataTask(row.dataTasks)['BUILD_STRUCT'].taskPosition).totalToBuild
                  }}</p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['FULL']" class="job-state-item-container"
                 :style="`width:${450/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['FULL'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['FULL'].dataTaskStatus,getDataTask(row.dataTasks)['FULL'].healthLevel)"
                  :percent="Number(JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).transPercent)"
                  :stroke-width="20" text-inside/>
              </div>
              <div class="job-state-item-content">
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['FULL'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ row.dataJobType === 'SYNC' ? '数据初始化' : '全量迁移' }}</p>
                <p class="job-info-progress-content">
                  {{
                    formatCount(JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).trans)
                  }}
                  行<a
                  @click="handleShowHistory(row,'FULL')"
                  v-if="JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).resultHistory">查看历史</a>
                <p v-if="getDataTask(row.dataTasks).FULL.nextFireTime">
                  <span
                    class="job-state-schedule-stop">{{
                      Mapping.taskStatus[getDataTask(row.dataTasks)['FULL'].dataTaskStatus]
                    }}</span>
                  （下次：{{ getDataTask(row.dataTasks).FULL.nextFireTime }}）</p>
                </p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['INCREMENT']" class="job-state-item-container"
                 :style="`width:${450/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus,getDataTask(row.dataTasks)['INCREMENT'].healthLevel)"
                  :percent="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'?0:99.99"
                  :stroke-width="20" hide-info/>
              </div>
              <div class="job-state-item-content">
                <p class="job-info-incre-status">
                  {{ Mapping.taskStatus[getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus] }}
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ row.dataJobType === 'SYNC' ? '数据同步' : '增量迁移' }}</p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus!=='INIT'">延迟
                  <span :style="getDelayColor(JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis<=60000?
                                    getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs:JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis)"
                        v-text="                      formatSeconds(JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis <= 60000 ?
                        getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs === -1 ?
                          0.001 : getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs / 1000 : JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis / 1000)"
                  />
                </p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'">&nbsp;&nbsp;</p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['CHECK']" class="job-state-check-container">
              <div class="job-state-item-container" style="width:450px">
                <div class="job-state-item">
                  <Progress
                    :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                    :status="getProgressStatus(getDataTask(row.dataTasks)['CHECK'].dataTaskStatus,getDataTask(row.dataTasks)['CHECK'].healthLevel)"
                    :percent="Number(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).checkedPercent)"
                    :stroke-width="20" text-inside/>
                </div>
                <div class="job-state-item-content">
                  <p
                    :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                    数据校验</p>
                </div>
              </div>
              <div class="job-state-item-container" style="width: 450px;text-align: center">
                <p style="text-align: center">
                  已校验 <span
                  style="color:rgb(25, 190, 107)">{{
                    formatCount(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).checked)
                  }}</span>
                  行，丢失 <span
                  style="color: #ed4014">{{
                    formatCount(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).loss)
                  }}</span>
                  行，不一致 <span
                  style="color: #ed4014">{{
                    formatCount(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).diff)
                  }}</span>
                  行
                </p>
                <p>
                  <a @click="handleShowHistory(row,'CHECK')"
                     v-if="JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).resultHistory">查看历史</a>
                </p>
                <p v-if="getDataTask(row.dataTasks).CHECK.nextFireTime">
                  <span
                    class="job-state-schedule-stop">{{
                      Mapping.taskStatus[getDataTask(row.dataTasks)['CHECK'].dataTaskStatus]
                    }}</span>
                  （下次：{{ getDataTask(row.dataTasks).CHECK.nextFireTime }}）</p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['REVISE']" class="job-state-check-container">
              <div class="job-state-item-container" style="width:450px">
                <div class="job-state-item">
                  <Progress
                    :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['REVISE'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                    :status="getProgressStatus(getDataTask(row.dataTasks)['REVISE'].dataTaskStatus,getDataTask(row.dataTasks)['REVISE'].healthLevel)"
                    :percent="Number(JSON.parse(getDataTask(row.dataTasks)['REVISE'].taskPosition).transPercent)"
                    :stroke-width="20" text-inside/>
                </div>
                <div class="job-state-item-content">
                  <p
                    :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['REVISE'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                    数据订正</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot-scope="{ row,index }" slot="action">
        <div class="action-container">
          <a type="primary" @click="handleDetail(row)">{{ $t('detail') }}</a>
          <a type="primary" @click="handleStartFsm(row,index)"
             v-if="getDataTask(row.dataTasks)['BUILD_STRUCT']?
                        (!row.fsmActive&&row.dataTaskState==='INIT'&&(getDataTask(row.dataTasks)['BUILD_STRUCT']&&getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE')):
                        (!row.fsmActive&&row.dataTaskState==='INIT')"
          >
            {{ $t('start') }}
          </a>
          <a type="primary" @click="handleStart(row,index)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||
                        store.state.urlLabels.indexOf('/datajob/start')>-1)&&
                        (getDataTask(row.dataTasks)['FULL'] &&
                         getDataTask(row.dataTasks)['FULL'].dataTaskStatus === 'STOP'||
                          getDataTask(row.dataTasks)['INCREMENT'] &&
                           getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus === 'STOP' ||
                           getDataTask(row.dataTasks)['CHECK'] && getDataTask(row.dataTasks)['CHECK'].dataTaskStatus === 'STOP'||
                            (loadingData[row.dataJobId] && loadingData[row.dataJobId].starting))"
             :disabled="loadingData[row.dataJobId] ? loadingData[row.dataJobId].starting : false">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].starting ? '启动中' : $t('start')
            }}
          </a>
          <a type="primary" @click="handleResumSchedule(row)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/resumetimeschedule')>-1)&&row.dataTaskState === 'TIMING_SCHEDULE_PAUSE'">恢复调度
          </a>
          <a type="primary" @click="handleRestart(row,index)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||
                        store.state.urlLabels.indexOf('/datajob/restart')>-1)&&
                        (row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                          loadingData[row.dataJobId] &&
                           loadingData[row.dataJobId].stopping||
                           (row.dataTaskState==='CHECK'&&
                           (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                           getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))"
             :disabled:="loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping || loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting ? '重启中' : $t('restart')
            }}
          </a>
          <a type="warning" @click="handleStop(row,index)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||
                        store.state.urlLabels.indexOf('/datajob/stop')>-1)&&
                        (row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                           loadingData[row.dataJobId] &&
                            loadingData[row.dataJobId].stopping||
                            (row.dataTaskState==='CHECK'&&
                            (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                            getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))"
             :disabled="loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping || loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping ||
              loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting ? '停止中' : $t('stop')
            }}
          </a>
          <a type="primary" @click="handleReplay(row)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/replay')>-1)&&
                      ((row.dataJobType === 'CHECK' || row.dataJobType === 'REVISE') && (row.currentStatus === 'STOP' || row.dataTaskState === 'COMPLETE') ||
                        (getDataTask(row.dataTasks).FULL
                        &&(row.dataTaskState==='COMPLETE'
                        ||(row.dataTaskState === 'CATCH_UP'||row.dataTaskState === 'INCRE')&&row.currentStatus === 'STOP')))">重跑
          </a>
          <a type="primary" @click="handleStartTimeScheduleJob(row)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/starttimeschedulejob')>-1)&&row.dataTaskState === 'TIMING_SCHEDULE'">立即执行
          </a>
          <a type="warning" @click="handleStopSchedule(row)"
             v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/pausetimeschedule')>-1)&&row.dataTaskState === 'TIMING_SCHEDULE'">暂停调度
          </a>
          <a type="error" @click="handleDelete(row)"
             v-if="store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1"
             :disabled="row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                        row.dataTaskState === 'TIMING_SCHEDULE'||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                           loadingData[row.dataJobId] &&
                            loadingData[row.dataJobId].stopping||
                            (row.dataTaskState==='CHECK'&&
                            (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                            getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING'))">
            {{ $t('delete') }}
          </a>
        </div>
      </template>
    </Table>
    <Modal
      v-model="showEditDesc"
      title="修改任务描述"
      @on-ok="handleConfirmEdit(selectedRow,dataJobDesc)"
    >
      <div>
        <Form label-position="left" :label-width="60">
          <FormItem label="实例 ID">
            {{ selectedRow.dataJobName }}
          </FormItem>
          <FormItem label="描述">
            <Input type="textarea" v-model="dataJobDesc"/>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import Mapping from '../../../../views/util';
import DataSourceIcon from '../../DataSourceIcon';
import store from '../../../../store';
import { formatCount, formatSeconds } from '../../../util';

export default {
  components: {
    DataSourceIcon
  },
  props: {
    showData: Array,
    getJobList: Function,
    handleRestart: Function,
    handleStartFsm: Function,
    handleStop: Function,
    handleDelete: Function,
    handleStart: Function,
    handleResumSchedule: Function,
    handleStopSchedule: Function,
    handleStartTimeScheduleJob: Function,
    handleConfirmEdit: Function,
    handleBatchEdit: Function,
    getProgressStatus: Function,
    handleShowGroup: Function,
    getFilters: Function,
    batchEditInTable: Boolean,
    handleTableSort: Function,
    handleShowHistory: Function,
    updateAllBatchList: Function,
    handleCancelAllBatch: Function,
    handleSelectBatch: Function,
    handleCancelBatch: Function,
    handleReplay: Function
  },
  created() {
    this.showData.forEach((item) => {
      this.showEdit[item.instanceId] = false;
    });
  },
  // mounted () {
  //
  //     if (localStorage.getItem('userInfo')) {
  //
  //         const userId = JSON.parse(localStorage.getItem('userInfo')).id;
  //
  //         if (localStorage.getItem(`job_list_sort_${userId}`)) {
  //
  //             const sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
  //
  //             if (sort) {
  //
  //                 const { sortTime } = sort;
  //
  //                 if (sortTime) {
  //
  //                     this.$refs['job-list-table'].handleSort(2, sortTime);
  //
  //                 }
  //
  //             }
  //
  //         }
  //
  //     }
  //
  // },
  data() {
    return {
      selectedRow: '',
      showEditDesc: false,
      formatCount,
      formatSeconds,
      store,
      showEdit: {},
      dataJobDesc: '',
      Mapping,
      sortDelay: 'normal',
      sortTime: 'normal',
      statusTime: 10000,
      filterValue: '',
      jobId: 0,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      loadingData: [],
      jobColumnBatch: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('dataJob.instanceId'),
          key: 'dataJobName',
          width: 220,
          slot: 'instanceId',
          tree: true
        },
        {
          title: '类型',
          width: 120,
          slot: 'dataJobType'
        },
        {
          title: this.$t('dataJob.createTime'),
          key: 'gmtCreated',
          width: 200,
          sortable: 'custom',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreated), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('dataJob.createUser'),
          key: 'username',
          width: 140
        },
        {
          title: this.$t('dataJob.status'),
          width: 120,
          render: (h, params) => {
            const type = Mapping.taskSateToTaskType[params.row.dataTaskState];

            if (type) {
              if (!this.getDataTask(params.row.dataTasks)[type]) {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, '暂停')
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].dataTaskStatus === 'STOP') {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, '暂停')
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].healthLevel === 'Health') {
                return h('div', {
                  style: {
                    color: '#52C41A'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#52C41A'
                    }
                  }),
                  h('Tooltip', {
                    props: {
                      transfer: true,
                      placement: 'right',
                      content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                    },
                    style: {
                      marginLeft: '8px'
                    }
                  }, '正常')
                ]);
              }
              return h('div', {
                style: {
                  color: '#FF1815'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#FF1815'
                  }
                }),
                h('Tooltip', {
                  props: {
                    transfer: true,
                    placement: 'right',
                    content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                  },
                  style: {
                    marginLeft: '8px'
                  }
                }, '异常')
              ]);
            }
            if (params.row.dataTaskState === 'COMPLETE') {
              return h('div', {
                style: {
                  color: '#CCCCCC'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#CCCCCC'
                  }
                }),
                h('span', {
                  style: {
                    marginLeft: '8px'
                  }
                }, '完成')
              ]);
            }
            return h('div', {
              style: {
                color: '#52C41A'
              }
            }, [
              h('span', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  background: '#52C41A'
                }
              }),
              h('Tooltip', {
                props: {
                  transfer: true,
                  placement: 'right',
                  content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                },
                style: {
                  marginLeft: '8px'
                }
              }, '正常')
            ]);
          },
          filters: [
            {
              label: '正常',
              value: 'normal'
            },
            {
              label: '异常',
              value: 'error'
            },
            {
              label: '已暂停',
              value: 'stop'
            },
            {
              label: '已完成',
              value: 'finish'
            }
          ],
          filterMultiple: false,
          filterRemote(value) {
            this.getFilters(value);
          }
        },
        {
          title: this.$t('dataJob.progress'),
          slot: 'progress',
          key: 'dataTasks',
          sortable: 'custom',
          width: 560
        },
        {
          title: this.$t('dataJob.option'),
          key: '',
          minWidth: 250,
          fixed: 'right',
          slot: 'action'
        }
      ],
      jobColumn: [
        {
          title: '任务ID/描述',
          key: 'dataJobName',
          width: 260,
          slot: 'instanceId',
          tree: true,
          className: 'job-info-instance'
        },
        {
          title: '类型',
          slot: 'dataJobType',
          width: 120
        },
        {
          title: this.$t('dataJob.createTime'),
          key: 'gmtCreated',
          width: 200,
          sortable: 'custom',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreated), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('dataJob.createUser'),
          key: 'username',
          width: 140
        },
        {
          title: this.$t('dataJob.status'),
          width: 120,
          render: (h, params) => {
            const type = Mapping.taskSateToTaskType[params.row.dataTaskState];

            if (type) {
              if (!this.getDataTask(params.row.dataTasks)[type]) {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, '暂停')
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].dataTaskStatus === 'STOP') {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, '暂停')
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].healthLevel === 'Health') {
                return h('div', {
                  style: {
                    color: '#52C41A'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#52C41A'
                    }
                  }),
                  h('Tooltip', {
                    props: {
                      transfer: true,
                      placement: 'right',
                      content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                    },
                    style: {
                      marginLeft: '8px'
                    }
                  }, '正常')
                ]);
              }
              return h('div', {
                style: {
                  color: '#FF1815'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#FF1815'
                  }
                }),
                h('Tooltip', {
                  props: {
                    transfer: true,
                    placement: 'right',
                    content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                  },
                  style: {
                    marginLeft: '8px'
                  }
                }, '异常')
              ]);
            }
            if (params.row.dataTaskState === 'COMPLETE') {
              return h('div', {
                style: {
                  color: '#CCCCCC'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#CCCCCC'
                  }
                }),
                h('span', {
                  style: {
                    marginLeft: '8px'
                  }
                }, '完成')
              ]);
            }
            return h('div', {
              style: {
                color: '#52C41A'
              }
            }, [
              h('span', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  background: '#52C41A'
                }
              }),
              h('Tooltip', {
                props: {
                  transfer: true,
                  placement: 'right',
                  content: '进程存活，并且持续进行数据同步即视为正常。需注意：当进行位点回溯或者遇到增量流量较大时进程仍然是存活并且在同步数据的，视为状态正常。'
                },
                style: {
                  marginLeft: '8px'
                }
              }, '正常')
            ]);
          },
          filters: [
            {
              label: '正常',
              value: 'normal'
            },
            {
              label: '异常',
              value: 'error'
            },
            {
              label: '已暂停',
              value: 'stop'
            },
            {
              label: '已完成',
              value: 'finish'
            }
          ],
          filterMultiple: false,
          filterRemote(value) {
            this.getFilters(value);
          }
        },
        {
          title: this.$t('dataJob.progress'),
          slot: 'progress',
          key: 'dataTasks',
          sortable: 'custom',
          width: 560
        },
        {
          title: this.$t('dataJob.option'),
          key: '',
          minWidth: 250,
          fixed: 'right',
          slot: 'action'
        }
      ]
    };
  },
  computed: {
    getDelayColor() {
      return (delay) => {
        // 位点延迟小于1分钟，正常，为绿色，展示数据延迟。1分钟<位点延迟<5分钟，黄色，展示位点延迟。位点延迟>5分钟，异常，为红色，展示位点延迟。
        if (delay <= 60000) {
          return 'color: #19be6b';
        }
        if (delay < 300000) {
          return 'color: #ff9900';
        }
        return 'color: #ed4014';
      };
    },
    getStatusName() {
      return (row) => {
        const type = Mapping.taskSateToTaskType[row.dataTaskState];

        if (type) {
          if (!this.getDataTask(row.dataTasks)[type]) {
            return '初始化';
          }
          if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
            return '已停止';
          }
          if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
            return '正常';
          }
          return '异常';
        }
        if (row.dataTaskState === 'COMPLETE') {
          return '已完成';
        }
        return '正常';
      };
    }
  },
  methods: {
    handleTSort(data) {
      // let userId = '',
      //     sort = {};
      //
      // if (localStorage.getItem('userInfo')) {
      //
      //     userId = JSON.parse(localStorage.getItem('userInfo')).id;
      //
      // }
      //
      // if (data.key === 'gmtCreated') {
      //     this.$refs['job-list-table'].handleSort(2, data.order);
      //
      // } else if (data.key === 'dataTasks') {
      //     this.$refs['job-list-table'].handleSort(5, data.order);
      //
      // }
      this.handleTableSort(data);
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.forEach((item) => {
        dataTasks[item.dataTaskType] = item;
      });
      return dataTasks;
    },
    handleDetail(row) {
      this.$router.push({ path: `/data/job/${row.dataJobId}` });
    },
    handleEditJobDesc(row) {
      this.dataJobDesc = row.dataJobDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handleCancelEdit(row) {
      row.showEditDesc = false;
    },
    handleEditConfirm(row) {
      row.showEditDesc = false;
      this.handleConfirmEdit(row, this.dataJobDesc);
    },
    handleSelectAllBatch(selection) {
      this.updateAllBatchList(selection);
    },
    rowClassName(row) {
      if (!row.isParent) {
        return 'sub-job-info';
      }
      return '';
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/system/state/task/${row.consoleJobId}` });
    },
    handleGoUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleGoExceptionLog(row) {
      localStorage.setItem('exception_ids', JSON.stringify(row));

      this.$router.push({ path: '/monitor/exception' });
    }
  }
};
</script>
<style lang="less" scoped>
.task-list-body {
  padding: 24px;
  background: #ffffff;
  margin-top: 16px;

  .demo-table-info-row {
    .ivu-table-cell-slot {
      display: inline-block;
      vertical-align: middle;
    }

    .ivu-table-cell-tree {
      display: none;
    }

    .ivu-table-cell-tree-level {
      display: none;
    }
  }

  .ivu-progress-inner-text {
    color: #17233d;
  }
}

.job-edit-desc-a {
  opacity: 0;
}

.ivu-table-row :hover .job-edit-desc-a {
  opacity: 1;
}

.task-step-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.job-info-container {
  margin-top: 16px;
}

.job-list-table {
  margin-top: 16px;
  margin-bottom: 76px;
}

.job-list-datasource {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #C9C9C9;
  font-size: 14px;
  border-radius: 50%;
  top: -12px;
  background: #ffffff;
  text-align: center;
  padding-bottom: 2px;

  .datasource-icon .icon {
    margin-right: 4px;
  }
}

.job-list-source {
  left: 12px;
}

.job-list-sink {
  left: 28px;
}

.job-state-title {
  width: 76px;
  height: 28px;
  border-radius: 13px;
  background: #EEEEEE;
  color: #333;
  text-align: center;
  line-height: 28px;
  margin: 0 auto;
}

.job-state-content {
  font-size: 12px;
  text-align: center;
  width: 150px;
  margin-top: 2px;
}

.job-state-item-container {
  display: inline-block;
  position: relative;
  margin-right: 10px;

  .job-state-schedule-stop {
    color: #FFA30E;
  }

  .job-info-incre-status {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.job-state-check-container {
  //display: flex;
  //display: -webkit-flex;
  align-items: center;
}

.job-state-item {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.job-state-item-content {
  margin-top: 10px;
  width: 100%;
  text-align: center;

  .job-state-error {
    color: #FC3F00;
  }
}

.action-container {
  a {
    /*display: block;*/
    /*margin-bottom: 12px;*/
    /*margin-top: 12px;*/
    margin-right: 16px;
  }
}

.job-list-desc-edit {
  font-size: 14px;
  height: 20px;
  cursor: pointer;
  margin-left: 8px;
}

.button-group {
  margin-top: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}

.sub-job-info {
  background-color: #F4F4F4;
}

.job-info-title {
  color: #333;
  vertical-align: middle;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ivu-table-cell-slot {
  display: inline-block !important;
}

.ivu-table-cell-tree-empty {
  display: inline-block !important;
}

.ivu-table-cell-tree-level {
  display: inline-block !important;
}

.job-info-progress-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-left: 50px;
  position: relative;
  top: 4px;

  .iconyibuforce {
    font-size: 14px;
  }

  .iconfreeze {
    font-size: 19px;
  }
}

.dataSource-icon {
  //position: absolute;
  //left: 0;
  //top: 40px;
  cursor: pointer;
}

.data-job-desc {
  .iconfont {
    visibility: hidden;
  }

  &:hover .iconfont {
    visibility: visible;
  }
}
</style>
