<template>
  <div>
    <FormItem label="Hive Principal"
              v-if="getSecurity(addDataSourceForm.securityType).userNamePrinciple">
      <Input v-model="addDataSourceForm.account" style="width: 280px"
             placeholder="例如：hive/FQDN@domain"/>
    </FormItem>
    <p class="datasource-setting-title">额外信息设置</p>

    <!--        <Divider orientation="left" v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'&&addDataSourceForm.type==='Hive'">额外信息设置</Divider>-->
    <FormItem label="HDFS Host" prop="hdfsIp"
              v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'&&addDataSourceForm.type==='Hive'">
      <Input v-model="addDataSourceForm.hdfsIp" style="width: 280px"/>
      <span>:</span>
      <Input v-model="addDataSourceForm.hdfsPort" style="width: 100px"/>
      <span style="margin-left: 10px;color: rgb(128, 134, 149)">用于生成fs.defaultFS链接</span>
    </FormItem>
    <FormItem label="HDFS Principal" prop="hdfsSecurityType" v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'
        &&addDataSourceForm.type==='Hive'&&addDataSourceForm.securityType==='KERBEROS'">
      <Input v-model="addDataSourceForm.hdfsPrincipal" style="width: 280px"
             placeholder="例如：hdfs/FQDN@domain"/>
    </FormItem>
    <FormItem label="HadoopUser" prop="account"
              v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'&&addDataSourceForm.type==='Hive'&&addDataSourceForm.securityType==='NONE'">
      <Input v-model="addDataSourceForm.account" style="width: 280px"/>
      <span style="margin-left: 10px;color: rgb(128, 134, 149)">以该身份操作HDFS</span>
    </FormItem>
    <FormItem label="HDFS 数仓路径" prop="hdfsDwDir"
              v-if="addDataSourceForm.instanceType==='SELF_MAINTENANCE'&&addDataSourceForm.type==='Hive'">
      <Input v-model="addDataSourceForm.hdfsDwDir" style="width: 280px"
             placeholder="例如：/user/hive/warehouse"/>
    </FormItem>
  </div>
</template>
<script>
export default {
  props: {
    addDataSourceForm: Object,
    handleFileChange: Function,
    handleKeyTabFileChange: Function,
    getSecurity: Function
  }
};
</script>
<style lang="less" scoped>
.datasource-setting-title {
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
