<template>
  <div class="user-center">
    <p class="user-center-title">阿里云访问权限</p>
    <div>
      <div class="container-wrapper">
        <Alert type="warning" show-icon>
          <p>阿里云AK/SK需要赋予必要的权限。<a href="https://doc.clougence.com/docs/en/aliyun_ram_auth"
                                  target="_blank">如何赋权？</a></p>
          <p style="margin-top: 8px">阿里云AK/SK仅保存2小时，过期后自动删除。</p>
        </Alert>
        <Form style="margin-top: 12px" label-position="right" :label-width="130" :model="form"
              :rules="validate" ref="aksk-form">
          <FormItem label="AccessKey ID" prop="aliyunAk">
            <Input v-model="form.aliyunAk" style="width: 280px"/>
          </FormItem>
          <FormItem label="AccessKey Secret" prop="aliyunSk">
            <Input v-model="form.aliyunSk" type="password" password style="width: 280px"/>
            <a style="margin-left: 10px;color: #535C70"
               href="https://doc.clougence.com/docs/en/aliyun_ram_sts_auth" target="_blank">如何获取AK/SK？</a>
          </FormItem>
        </Form>
      </div>
      <div class="modal-footer" style="margin-top: 20px">
        <pd-button type="primary" @click="handleApplyStToken">授权访问</pd-button>
        <pd-button type="default" @click="handleCleanStToken">解除访问权限</pd-button>
      </div>
    </div>
  </div>
</template>
<script>
import { cleanAliyunAkSk, updateAliyunAkSk } from '@services/api/user';

export default {
  components: {},
  data() {
    return {
      form: {
        aliyunAk: '',
        aliyunSk: ''
      },
      validate: {
        aliyunAk: [
          {
            required: true,
            message: '阿里云AK不能为空'
          }
        ],
        aliyunSk: [
          {
            required: true,
            message: '阿里云SK不能为空'
          }
        ]
      }
    };
  },
  methods: {
    handleApplyStToken() {
      this.$refs['aksk-form'].validate((valid) => {
        if (valid) {
          updateAliyunAkSk({
            ...this.form
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.$Message.success('授权成功！');
                this.aliyunAk = '';
                this.aliyunSk = '';
              }
            });
        }
      });
    },
    handleCleanStToken() {
      cleanAliyunAkSk()
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('解除成功！');
            this.aliyunAk = '';
            this.aliyunSk = '';
          }
        });
    }
  }
};
</script>
<style lang="less">
.user-center-title {
  font-size: 16px;
  line-height: 16px;
  padding-left: 8px;
  color: #333333;
  font-family: PingFangSC-Semibold, serif;
  font-weight: 500;
  border-left: 3px solid #535C70;
  margin-bottom: 6px;
}

.user-center-wrapper {
  margin-top: 16px;
  padding: 20px 40px;
  background-color: #ffffff;
  border: 1px solid #DADADA;

  & > p {
    line-height: 36px;
    font-size: 16px;
    padding: 12px 0;
    border-bottom: 1px solid #DADADA;

    .user-center-label {
      font-family: PingFangSC-Semibold, serif;
      font-weight: 500;
      display: inline-block;
      width: 104px;
    }
  }

  .ivu-table td, .ivu-table th {
    height: 40px;
  }
}

.user-center-wrapper-sp {
  background-color: #ECECEC;
  margin-bottom: 20px;

  .ivu-input {
    height: 40px;
    line-height: 40px;
  }

  .user-center-wrapper-sp-btn {
    background-color: #FFA30E;
    color: #ffffff;
    margin-top: 16px;
    background-image: none;
    border: none;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, serif;
    font-weight: 500;

    &:hover {
      background-color: #FFA30E !important;
      background-image: none;
    }
  }
}

/*.ivu-form-item:last-child{*/
/*    margin-bottom: 0;*/
/*}*/
.system-setting-title {
  font-family: PingFangSC-Semibold, serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.user-center {
  position: relative;

  .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #0BB9F8;
    font-family: PingFangSC-Semibold, serif;
  }

  .ivu-table th {
    background-color: #F5F5F5;
  }
}
</style>
