import { instance } from '../instance';

export const getDataSourceList = (param) => instance.get('datasource/list', { params: param });

export const listbycondition = (param) => instance.post('datasource/listbycondition', param);

export const deleteDataSource = (param) => instance.post('datasource/delete', param);

export const getDbList = (param) => instance.post('datasource/db/list', param);

export const getTableList = (param) => instance.post('datasource/table/list', param);

export const testConnection = (param) => instance.post('datasource/connection', param);

export const batchListColumns = (param) => instance.post('datasource/column/batchlist', param);

export const queryById = (param) => instance.post('datasource/queryds', param);

export const updateDataSourceDesc = (param) => instance.post('datasource/updatedatasourcedesc', param);

export const updatePublicHost = (param) => instance.post('datasource/updatepublichost', param);

export const updateDataSourceHost = (param) => instance.post('datasource/updateprivatehost', param);

export const deleteAccount = (param) => instance.post('datasource/deleteaccount', param);

export const queryIndex = (param) => instance.post('datasource/es/index/query', param);

export const queryIndexExistence = (param) => instance.post('datasource/dsobject/existence', param);

export const updateAliyunRdsAkSk = (param) => instance.post('datasource/updatealiyunrdsaksk', param);

export const getDsTypeMapping = (param) => instance.post('datasource/gettypemapping', param);

export const getDsTypeMeta = (param) => instance.post('datasource/gettypemeta', param);

export const listDbTablesUniqs = (param) => instance.post('datasource/listdbtablesuniqs', param);

export const testConnectionBeforeAdd = (param) => instance.post('datasource/testconnectionbeforeadd', param);

export const schemasTables = (param) => instance.post('datasource/schemastables', param);

export const schemasTableCount = (param) => instance.post('datasource/schemastablecount', param);

export const queryDsAttrs = (param) => instance.post('datasource/querydsattrs', param);

