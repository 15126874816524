import { instance } from '../instance';

export const listEcs = (param) => instance.post('aliyun/ecs/listecs', param);

export const install = (param) => instance.post('aliyun/ecs/install', param);

export const uninstall = (param) => instance.post('aliyun/ecs/uninstall', param);

export const stopInstallAndClean = (param) => instance.post('aliyun/ecs/stopinstallandclean', param);

export const queryProcess = (param) => instance.post('aliyun/ecs/queryprocess', param);

export const addAndInstall = (param) => instance.post('aliyun/ecs/addandinstall', param);

export const upgradeAll = (param) => instance.post('aliyun/ecs/upgradeall', param);
