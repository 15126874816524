<template>
  <Modal
    v-model="showAliyunAkSk"
    width="720px"
    footer-hide
    title="阿里云AKSK">
    <div>
      <Alert type="warning" show-icon>
        <p>阿里云AK/SK需要赋予必要的权限。<a href="https://doc.clougence.com/docs/en/aliyun_ram_auth"
                                target="_blank">如何赋权？</a></p>
        <p style="margin-top: 8px">阿里云AK/SK仅保存2小时，过期后自动删除。</p>
      </Alert>
      <Form style="margin-top: 12px" label-position="right" :label-width="130" ref="aksk-form"
            :rules="validate" :model="form">
        <FormItem label="AccessKey ID" prop="aliyunAk">
          <Input v-model="form.aliyunAk" style="width: 280px" autocomplete="new-password"/>
        </FormItem>
        <FormItem label="AccessKey Secret" prop="aliyunSk">
          <Input v-model="form.aliyunSk" type="password" password style="width: 280px" autocomplete="new-password"/>
          <a style="margin-left: 10px;color: #535C70"
             href="https://doc.clougence.com/docs/en/aliyun_ram_sts_auth"
             target="_blank">如何获取AK/SK？</a>
        </FormItem>
      </Form>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button type="primary" @click="handleApplyStToken">确定</pd-button>
        <pd-button @click="handleCancelEdit">取消</pd-button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { updateAliyunAkSk } from '@services/api/user';

export default {
  props: {
    nextStep: Function
  },
  data() {
    return {
      form: {
        aliyunAk: '',
        aliyunSk: ''
      },
      validate: {
        aliyunAk: [
          {
            required: true,
            message: '阿里云AK不能为空'
          }
        ],
        aliyunSk: [
          {
            required: true,
            message: '阿里云SK不能为空'
          }
        ]
      },
      showAliyunAkSk: false
    };
  },
  methods: {
    handleApplyStToken() {
      this.$refs['aksk-form'].validate((valid) => {
        if (valid) {
          updateAliyunAkSk({
            ...this.form
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.showAliyunAkSk = false;
                if (this.nextStep) {
                  this.nextStep();
                }
              }
            });
        }
      });
    },
    handleShowAkSk() {
      this.showAliyunAkSk = true;
    },
    handleCancelEdit() {
      this.showAliyunAkSk = false;
    }
  }
};
</script>
