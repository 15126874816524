<template>
  <div>
    <Poptip transfer placement="bottom" width="700">
      <div @click="handleShowClusterList" style="width: 250px;height: 20px"
           class="show-cluster-btn">
        <span class="show-cluster-name"
              style="position: absolute;left: 0;">已选择{{ showSelectCluster(row) }}台机器</span>
        <Icon type="ios-arrow-down"></Icon>
      </div>
      <div slot="content">
        <p class="white-list-net">
                    <span
                      style="margin-right: 10px;display: inline-block;line-height: 36px;vertical-align: middle">
                        <span style="color: #FF1815">*</span>白名单类型
                    </span>
          <RadioGroup v-model="row.whiteListAddType" @on-change="handleChangeWhiteType($event,row)">
            <Radio label="ADD_ALL" :disabled="!row.publicHost">全部</Radio>
            <Radio label="PRIVATE_IP_ONLY">内网IP</Radio>
            <Radio label="PUBLIC_IP_ONLY" :disabled="!row.publicHost">公网出口IP</Radio>
          </RadioGroup>
        </p>
        <div style="overflow: auto;margin-top: 6px">
          <Table stripe max-height="300" border ref="selection" :columns="clusterColumns"
                 :data="store.state.clusterList" @on-selection-change="handleSelect">
            <template slot-scope="{row}" slot="clusterName">
              <div>
                <p>{{ row.clusterName }}</p>
                <p v-if="row.workerCount===0" style="color: #FF6E0D;margin-top: 2px">请在机器管理中添加机器</p>
              </div>
            </template>
          </Table>
        </div>
        <p v-if="store.state.clusterList.length===0">暂无集群</p>
      </div>
    </Poptip>
  </div>
</template>
<script>
import { listWithWorkerNets } from '@services/api/cluster';
import store from '../../../store/index';

export default {
  props: {
    row: Object,
    handleChangeType: Function,
    handleChangeCluster: Function
  },
  data() {
    return {
      store,
      showClusterList: false,
      clusterList: [],
      whiteListAddType: '',
      clusterColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            if (params.row.workersNet.length > 0) {
              return h('div',
                params.row.workersNet.map((item) => {
                  if (item.privateIp) {
                    return h('p', {
                      style: {
                        lineHeight: '30px'
                      }
                    }, [
                      h('i', {
                        class: 'iconfont iconmachine',
                        style: {
                          marginRight: '6px'
                        }
                      }),
                      h('span', {}, this.whiteListAddType === 'PRIVATE_IP_ONLY'
                        ? item.privateIp : this.whiteListAddType === 'PUBLIC_IP_ONLY'
                          ? item.publicIp : `${item.publicIp},${item.privateIp}`)
                    ]);
                  }
                  return null;
                }));
            }
          }
        },
        {
          title: '集群名称',
          key: 'clusterName',
          width: 220,
          slot: 'clusterName'
        },
        {
          title: '备注',
          key: 'clusterDesc'
        },
        {
          title: '机器数量',
          key: 'workerCount',
          width: 100,
          render: (h, params) => h('div', {}, params.row.workersNet.length)
        }
      ]
    };
  },
  created() {
    if (store.state.firstAddDataSource) {
      this.getAllClusterAndWorkers();
    } else {
      Object.keys(store.state.selectedCluster)
        .map((item) => {
          store.state.selectedCluster[item].map((selectedCluster) => {
            store.state.clusterList.map((cluster) => {
              if (selectedCluster.id === cluster.id) {
                cluster._checked = true;
                cluster.selected = true;
              }
              return null;
            });
            return null;
          });
          return null;
        });
    }
  },
  mounted() {
    this.whiteListAddType = this.row.whiteListAddType;
  },
  methods: {
    getAllClusterAndWorkers() {
      listWithWorkerNets()
        .then((res) => {
          if (res.data.code === '1') {
            store.state.clusterList = res.data.data;
            store.state.clusterList.map((item) => {
              item.selected = false;
              item.workerList = [{
                title: item.clusterName,
                expand: false,
                children: item.workersNet
              }];
              if (item.workersNet.length === 0) {
                item._disabled = true;
              }
              return null;
            });
          }
        });
    },
    handleShowClusterList() {
      this.showClusterList = !this.showClusterList;
      store.state.clusterList.push('');
      store.state.clusterList.pop();
    },
    handleSelectCluster(cluster, row, data) {
      let alreadyHas = false;
      let
        currentIndex = -1;

      if (!store.state.selectedCluster[row.instanceId]) {
        store.state.selectedCluster[row.instanceId] = [];
      }
      store.state.selectedCluster[row.instanceId].map((item, index) => {
        if (item.clusterName === cluster.clusterName) {
          alreadyHas = true;
          currentIndex = index;
        }
        return null;
      });
      if (data) {
        if (!alreadyHas) {
          store.state.selectedCluster[row.instanceId].push(cluster);
        }
      } else if (alreadyHas) {
        store.state.selectedCluster[row.instanceId].splice(currentIndex, 1);
      }
      store.state.selectedCluster = { ...store.state.selectedCluster };
      if (this.handleChangeCluster) {
        this.handleChangeCluster(row, store.state.selectedCluster);
      }
    },
    showSelectCluster(row) {
      let count = 0;

      if (store.state.selectedCluster[row.instanceId]) {
        store.state.selectedCluster[row.instanceId].map((item) => {
          count += item.workersNet.length;
          return null;
        });
      }
      return count;
    },
    handleChangeWhiteType(data, row) {
      this.whiteListAddType = data;
      this.handleChangeType(data, row);
    },
    handleSelect(selection) {
      if (!store.state.selectedCluster[this.row.instanceId]) {
        store.state.selectedCluster[this.row.instanceId] = [];
      }
      store.state.selectedCluster[this.row.instanceId] = selection;
      store.state.selectedCluster = { ...store.state.selectedCluster };
      if (this.handleChangeCluster) {
        this.handleChangeCluster(this.row, store.state.selectedCluster);
      }
    },
    updateSelectedCluster(list) {
      store.state.selectedCluster = list;
    }
  },
  watch: {
    'row.whiteListAddType': {
      handler(val) {
        this.whiteListAddType = val;
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.show-cluster-btn {
  display: inline-block;
  width: 100%;
  text-align: right;
  cursor: pointer;
  /*position: relative;*/
  /*.ivu-icon{*/
  /*    position: absolute;*/
  /*    right: 0;*/
  /*    top: -10px;*/
  /*}*/

  .show-cluster-name {
    width: 90%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    vertical-align: middle;
    text-align: left;
  }

  .ivu-select-dropdown {
    padding-top: 0 !important;
  }
}

.white-list-cluster-item {
  padding: 0 16px;

  .ivu-tree {
    display: inline-block;
    vertical-align: top;
  }

  .ivu-checkbox-wrapper {
    vertical-align: top;
    margin-top: 10px;
  }
}

.white-list-net {
  border-bottom: 1px solid #DADADA;
  padding: 0 16px;
  height: 40px;

  .ivu-radio-group {
    line-height: 36px;
  }
}
</style>
