<template>
  <div class="classify-jobs-container" style="margin-top: 10px">
    <div v-if="classifyType==='status'" class="classify-container">
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>异常
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.abnormalList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData"
                     style="width: 336px" :key="job.id" :handleReplay="handleReplay"
                     v-for="job in computedAbnormalList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop" :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>正常
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.normalList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedNormalList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>暂停
          <span style="margin-left: 10px;font-weight: 500">{{ classifyData.stopList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedStopList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>已完成
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.finishList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedFinishList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
    </div>
    <div v-if="classifyType==='state'" class="classify-container">
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>全量迁移
          <span style="margin-left: 10px;font-weight: 500">{{ classifyData.fullList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedFullList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>增量同步
          <span
            style="margin-left: 10px;font-weight: 500">{{
              classifyData.incrementList.length
            }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedIncrementList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>结构迁移
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.buildStruct.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedBuildStruct" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>数据校验
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.checkList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedCheckList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>已完成
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.finishList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedFinishList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
    </div>
    <div v-if="classifyType==='type'" class="classify-container">
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>数据迁移
          <span
            style="margin-left: 10px;font-weight: 500">{{
              classifyData.migrationList.length
            }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedMigrationList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>数据同步
          <span style="margin-left: 10px;font-weight: 500">{{ classifyData.syncList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedSyncList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>数据校验
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.checkList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedCheckList" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
      <div class="classify-content">
        <p class="classify-jobs-title"><span class="warn-point"
                                             style="background-color: #333"></span>结构迁移
          <span
            style="margin-left: 10px;font-weight: 500">{{ classifyData.structList.length }}</span>
        </p>
        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :key="job.id"
                     style="width: 336px" :handleReplay="handleReplay"
                     v-for="job in computedBuildStruct" :handleShowGroup="handleShowGroup"
                     :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                     :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                     :handleDelete="handleDelete" :handleRestart="handleRestart"
                     :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                     :dataJobInfo="job"
                     :handleBatchEdit="handleBatchEdit"></JobInfoCard>
      </div>
    </div>
  </div>
</template>
<script>
import JobInfoCard from './JobInfoCard';

export default {
  components: {
    JobInfoCard
  },
  props: {
    loadingData: Object,
    classifyData: Object,
    classifyType: String,
    handleRestart: Function,
    handleStartFsm: Function,
    handleStop: Function,
    handleDelete: Function,
    handleStart: Function,
    handleResumSchedule: Function,
    handleStopSchedule: Function,
    handleStartTimeScheduleJob: Function,
    handleConfirmEdit: Function,
    handleBatchEdit: Function,
    getProgressStatus: Function,
    handleShowGroup: Function,
    handleShowHistory: Function,
    handleReplay: Function
  },
  computed: {
    computedAbnormalList() {
      return this.classifyData.abnormalList.filter((job) => job.isParent);
    },
    computedNormalList() {
      return this.classifyData.normalList.filter((job) => job.isParent);
    },
    computedFinishList() {
      return this.classifyData.finishList.filter((job) => job.isParent);
    },
    computedFullList() {
      return this.classifyData.fullList.filter((job) => job.isParent);
    },
    computedIncrementList() {
      return this.classifyData.incrementList.filter((job) => job.isParent);
    },
    computedBuildStruct() {
      return this.classifyData.buildStruct.filter((job) => job.isParent);
    },
    computedCheckList() {
      return this.classifyData.checkList.filter((job) => job.isParent);
    },
    computedMigrationList() {
      return this.classifyData.migrationList.filter((job) => job.isParent);
    },
    computedSyncList() {
      return this.classifyData.syncList.filter((job) => job.isParent);
    },
    computedStopList() {
      return this.classifyData.stopList.filter((job) => job.isParent);
    }
  }
};
</script>
<style lang="less" scoped>
.classify-jobs-title {
  margin-bottom: 12px;
  background-color: #E4E4E4;
  padding: 4px 16px;
  border-radius: 13px;
  max-width: 130px;
}

.classify-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.classify-content {
  width: 350px;
  min-width: 350px;
}

.classify-jobs-container {
  overflow: auto;
  margin-bottom: 64px;
}
</style>
