<template>
  <div>
    <div class="table-filter-nav-item-db" v-if="type==='target'">
      <p><i class="iconfont iconshujuku"></i>{{ db.sourceDb }}<span v-if="db.sourceSchema && hasSchema.includes(taskInfo.sourceType)">.{{db.sourceSchema}}</span></p>
      <p class="chosed-count">已选中
        <span
          :class="getSelectedInfo(db.sourceDb).selectedCount<(selectedTables[db.sourceDb]?selectedTables[db.sourceDb].length:0)?'warn-count':''"
          style="text-align: center;display: inline-block;">{{ getSelectedInfo(db.sourceDb).selectedCount }}</span>
        /{{ selectedTables[db.sourceDb] ? selectedTables[db.sourceDb].length : 0 }}张表</p>
    </div>
    <div class="table-filter-nav-item-db" v-if="type==='source'">
      <p><i class="iconfont iconshujuku"></i>{{ db.sinkDb }}</p>
      <p class="chosed-count">已选中
        <span
          :class="getSelectedInfo(db.sinkDb).selectedCount<(selectedTables[db.sinkDb]?selectedTables[db.sinkDb].length:0)?'warn-count':''"
          style="text-align: center;display: inline-block;">{{ getSelectedInfo(db.sinkDb).selectedCount }}</span>/{{ selectedTables[db.sinkDb] ? selectedTables[db.sinkDb].length : 0 }}张表
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    taskInfo: Object,
    db: Object,
    getSelectedInfo: Function,
    selectedTables: Object,
    type: {
      type: String,
      default: 'target'
    }
  },
  data() {
    return {
      hasSchema: [
        'Oracle',
        'PostgreSQL',
        'Greenplum'
      ]
    };
  }
};
</script>
