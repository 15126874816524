<template>
  <div>
    <div class="table-filter-nav" :style="`height:${tableHeight+120}px`">
      <div @click="handleCheckTableList(db,index)"
           :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
           v-for="(db,index) in taskInfo.dbMap" :key="index">
        <DbMappingInTableFilter
          v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&
            DataSourceGroup.es.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.redis.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.kudu.indexOf(taskInfo.sinkType)===-1"
          :sourceType="taskInfo.sourceType"
          :sinkType="taskInfo.sinkType"
          :selectedTables="selectedTables" :db="db"
          :getSelectedInfo="getSelectedInfo"></DbMappingInTableFilter>
        <DbItem :type="DataSourceGroup.mq.includes(taskInfo.sourceType)?'source':'target'"
                v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1||
            DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.redis.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.kudu.indexOf(taskInfo.sinkType)>-1"
                :selectedTables="selectedTables" :db="db"
                :getSelectedInfo="getSelectedInfo" :task-info="taskInfo"></DbItem>
      </div>
    </div>
    <div class="table-filter-container">
      <div>
        <div
          v-if="!DataSourceGroup.mq.includes(taskInfo.sourceType)&&filterTable[db.sourceDb]||DataSourceGroup.mq.includes(taskInfo.sourceType)&&filterTable[db.sinkDb]">
          <FilterHeader :filterTable="filterTable" :db="db"
                        :handleChangeFilterType="handleChangeFilterType"
                        :handleTableFilter="handleTableFilter" :taskInfo="taskInfo"
                        :handleSetPartitions="handleSetPartitions"
                        :handleActionFilter="handleActionFilter" :handleSetShards="handleSetShards"
                        :filterList="filterList" :handleDeleteFilter="handleDeleteFilter"
                        :handleClearFilter="handleClearFilter" :migrationType="migrationType"
                        :handleChangeMigrationType="handleChangeMigrationType" :loading="loading"
                        :handleDefaultTopic="handleDefaultTopic"
                        :handleShowMappingRule="handleShowMappingRule"
                        :handleSetTopic="handleSetTopic"
                        :theMappingRule="theMappingRule"
          ref="filterHeader"></FilterHeader>
        </div>
        <FilterBody :tableHeight="tableHeight" :taskInfo="taskInfo" :db="db" :loading="loading"
                    :showTableList="showTableList" :handleTableSelected="handleTableSelected"
                    :selectedIndex="selectedIndex" :handleSelectCancel="handleSelectCancel"
                    :handleAllTableSelected="handleAllTableSelected"
                    :handleCancelSelect="handleCancelSelect"
                    :handleActionChange="handleActionChange" :selectedTables="selectedTables"
                    :handleSelectChange="handleSelectChange" :topicName="topicName"
                    :handleChangeShards="handleChangeShards" :pageData="pageData"
                    :handlePageChange="handlePageChange"
                    :handleChangeReplication="handleChangeReplication"
                    :containsTable="containsTable" :handleChooseIndex="handleChooseIndex"
                    :migrationType="migrationType" :handleFocus="handleFocus"
                    :remoteMethod="remoteMethod"
                    :handleBlur="handleBlur" :currentKey="currentKey"
                    :theMappingIndex="theMappingIndex"
                    :updateShowTableList="updateShowTableList"
                    :theMappingRule="theMappingRule"></FilterBody>
      </div>
    </div>
    <Modal
      v-model="showSetTopic"
      :title="setDefaultValue?'设置默认topic':DataSourceGroup.mq.includes(taskInfo.sinkType)?'批量设置Topic名称':'批量设置目标表'"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80">
          <FormItem :label="DataSourceGroup.mq.includes(taskInfo.sinkType)?'Topic名称':'目标表'">
            <Select v-model="currentTopicName"
                    :placeholder="DataSourceGroup.mq.includes(taskInfo.sinkType)?'请选择Topic名称':'请选择目标表'"
                    filterable>
              <Option
                v-for="(topic,index) of taskInfo.sinkTableList[taskInfo.dbMap[selectedIndex].sourceDb]"
                :value="topic.tableName" :key="index">{{ topic.tableName }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetTopic">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMappingRule"
      title="目标映射规则"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80">
          <FormItem label="映射规则">
            <Select v-model="theMappingIndex" placeholder="请选择映射规则" filterable>
              <Option v-for="(rule,index) of theMappingRule"
                      :value="index" :key="index">{{ rule.i18nRuleName }}
              </Option>
            </Select>
          </FormItem>
          <!--          <FormItem :label="DataSourceGroup.mq.includes(taskInfo.sinkType)?'Topic名称':'目标表'">-->
          <!--            <Select v-model="currentTopicName" :placeholder="DataSourceGroup.mq.includes(taskInfo.sinkType)?'请选择Topic名称':'请选择目标表'" filterable>-->
          <!--              <Option v-for="(topic,index) of store.state.sinkTableList[taskInfo.dbMap[selectedIndex].sourceDb]"-->
          <!--                      :value="topic.tableName" :key="index">{{topic.tableName}}</Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetMappingRule">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetShards"
      title="批量设置分片数"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80" inline>
          <FormItem label="分片数">
            <Input style="width: 100px;margin-right: 60px" v-model="shardsCount"></Input>
          </FormItem>
          <FormItem label="分片副本数">
            <Input style="width: 100px" v-model="shardsReplication"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetShards">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  batchListColumns,
  getTableList,
  queryIndex
} from '@services/api/datasource';
import _ from 'lodash';
import { tableMappingRule, noPkUkSyncUpdateDelete } from '@services/api/constant';
import store from '../../../../store';
import Mapping from '../../../../views/util';
import DbMappingInTableFilter from './DbMappingInTableFilter';
import DbItem from './DbItem';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import FilterHeader from './tableFilter/FilterHeader';
import FilterBody from './tableFilter/FilterBody';
import { getTargetName } from './util';

export default {
  name: 'TableFilter',
  props: {
    taskInfo: Object,
    updateStep: Function,
    updateLoading: Function
  },
  components: {
    DbMappingInTableFilter,
    DbItem,
    FilterHeader,
    FilterBody
  },
  created() {
    this.store = store;
    const dbList = [];

    if (this.taskInfo.sinkType === 'Oracle') {
      this.taskInfo.commonRule = 'UpperCase';
    }
    this.taskInfo.dbMap.forEach((item) => {
      dbList.push({
        sourceDb: item.sourceDb,
        sinkDb: item.sinkDb
      });
    });
    this.db = this.taskInfo.dbMap[0];
    this.selectedIndex = 0;
    if (!this.taskInfo.firstToTableFilter) {
      this.getMappingRule(this.init);

      this.taskInfo.firstToTableFilter = true;
      this.taskInfo.compareSelectedDbs = dbList;
    } else if (_.isEqual(this.taskInfo.compareSelectedDbs, dbList)) {
      this.getMappingRule();

      this.selectedTables = _.cloneDeep(this.taskInfo.selectedTables);
      this.taskInfo.dbMap.forEach((item) => {
        let db = item.sourceDb;

        if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          db = item.sinkDb;
        }

        this.filterTable[db] = {};
        this.filterTable[db].tableFilterName = '';
        this.filterTable[db].tableFilterCreate = ['autoCreated', 'noCreate'];
        this.filterTable[db].tableFilterAction = ['INSERT', 'UPDATE', 'DELETE'];
        this.filterTable[db].tableFilterType = '';
        this.filterTable[db].tableFilterPk = ['hasPk', 'noPk'];
        this.filterTable[db].tableFilterColumn = '';
        this.showList[db] = _.cloneDeep(this.selectedTables[db]);
        this.pageData[db] = {
          page: 1,
          size: 20,
          total: this.showList[db] ? this.showList[db].length : 0
        };
        this.showTableList[db] = this.showList[db]
          ? this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size,
            this.pageData[db].page * this.pageData[db].size) : [];
      });
      const that = this;

      setTimeout(() => {
        that.currentNum = '0';
      });
      this.forceUpdateView();
      this.taskInfo.compareSelectedDbs = dbList;
      this.updateLoading(false);
    } else {
      this.getMappingRule(this.init);
      this.taskInfo.compareSelectedDbs = dbList;
    }
    noPkUkSyncUpdateDelete({
      srcDsType: this.taskInfo.sourceType,
      dstDsType: this.taskInfo.sinkType
    }).then((res) => {
      if (res.data.code === '1') {
        this.hasUpdateAndDelete = res.data.data;
      }
    });
  },
  mounted() {
    this.calcHeight();
  },
  data() {
    return {
      theMappingRule: [],
      theMappingIndex: 0,
      DataSourceGroup,
      currentKey: 0,
      remoteSearch: false,
      shardsCount: 5,
      shardsReplication: 1,
      searchLoading: false,
      migrationType: 'table',
      showSetTopic: false,
      showSetShards: false,
      currentTopicName: '',
      topicName: '',
      Mapping,
      existenceMap: {},
      db: {},
      selectedIndex: 0,
      filterList: {},
      loading: false,
      filterTable: {},
      showList: {},
      showTableList: {},
      noPkAndNotRds: [],
      pageData: {},
      showAll: true,
      index: 0,
      store: {},
      currentNum: '',
      sourceDb: '',
      sinkDb: '',
      currentDb: '',
      tableHeight: 600,
      tableData: [],
      sinkTableList: {
        db1: []
      },
      selectedTables: {},
      setDefaultValue: false,
      showMappingRule: false,
      promiseList: [],
      hasUpdateAndDelete: false
    };
  },
  computed: {
    getSelectedInfo() {
      return (db) => {
        let n = 0;
        const tableMap = [];

        if (this.selectedTables[db] instanceof Array) {
          this.selectedTables[db].forEach((item) => {
            if (item.selected) {
              n++;
              tableMap.push({
                sourceTable: item.sourceTable,
                sinkTable: item.sinkTable
              });
            }
          });
        }
        return {
          selectedCount: n,
          tableMap
        };
      };
    },
    getSinkTable() {
      return (sinkTable) => {
        let finalTable;

        if (this.taskInfo.targetCaseSensitive === 'false') {
          finalTable = sinkTable.toLowerCase();
        } else if (this.taskInfo.commonRule === 'UpperCase') {
          finalTable = sinkTable.toUpperCase();
        } else if (this.taskInfo.targetCaseSensitive === 'false') {
          finalTable = sinkTable.toLowerCase();
        } else {
          finalTable = sinkTable;
        }
        return finalTable;
      };
    }
  },
  methods: {
    calcHeight() {
      let totalHeight = window.innerHeight;

      if (totalHeight < 720) {
        totalHeight = 720;
      }
      // eslint-disable-next-line no-undef
      $('.table-filter-container')
        .css('height', `${totalHeight - 300}px`);
      this.tableHeight = totalHeight - 425;
      if (this.taskInfo.sinkType === 'Kafka' || this.taskInfo.sinkType === 'RocketMQ') {
        this.tableHeight = totalHeight - 465;
      }
    },
    init() {
      this.updateLoading(true);
      this.remoteSearch = false;
      this.taskInfo.dbMap.forEach((item, index) => {
        this.updateLoading(true);

        if (!item.needAutoCreated && !item.schemaAutoCreate) {
          getTableList({
            host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
            privateHost: this.taskInfo.sinkPrivateHost,
            publicHost: this.taskInfo.sinkPublicHost,
            hostType: this.taskInfo.targetHostType,
            type: this.taskInfo.sinkType,
            userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sinkType) > -1 && this.taskInfo.sinkAccountRole ? `${this.taskInfo.sinkAccount} as SYSDBA` : this.taskInfo.sinkAccount,
            dbName: item.sinkDb,
            dataSourceId: this.taskInfo.targetDataSourceId,
            tableSchema: item.targetSchema,
            clusterId: this.taskInfo.clusterId,
            rabbitMqVhost: this.taskInfo.dstRabbitMqVhost,
            rabbitExchange: this.taskInfo.dstRabbitExchange
          })
            .then((response) => {
              this.taskInfo.sinkTableList[item.sourceDb] = [];
              if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                this.taskInfo.sinkTableList[item.sinkDb] = response.data.data;
              } else {
                this.taskInfo.sinkTableList[item.sourceDb] = response.data.data;
              }
              getTableList({
                host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
                privateHost: this.taskInfo.sourcePrivateHost,
                publicHost: this.taskInfo.sourcePublicHost,
                hostType: this.taskInfo.sourceHostType,
                type: this.taskInfo.sourceType,
                dbName: item.sourceDb,
                dataSourceId: this.taskInfo.sourceDataSourceId,
                clusterId: this.taskInfo.clusterId,
                tableSchema: item.sourceSchema,
                specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword,
                rabbitMqVhost: this.taskInfo.srcRabbitMqVhost,
                rabbitExchange: this.taskInfo.srcRabbitExchange
              })
                .then((res) => {
                  try {
                    if (res.data.data.length < 1) {
                      this.$Modal.warning({
                        title: '任务创建提示',
                        content: `当前源库${item.sourceDb}为空库，表的数量为0，不支持迁移`
                      });
                    } else {
                      const arr = [];
                      const
                        that = this;

                      let theDb = item.sourceDb;

                      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                        theDb = item.sinkDb;
                      }

                      res.data.data.forEach((table, i) => {
                        let checked = true;
                        let selected = true;
                        let disabled = false;
                        let theSinkTable = '';

                        let action = [];

                        if (!table.hasPk) {
                          if (this.hasUpdateAndDelete) {
                            action = ['INSERT', 'UPDATE', 'DELETE'];
                          } else {
                            action = ['INSERT'];
                          }
                        } else {
                          action = ['INSERT', 'UPDATE', 'DELETE'];
                        }

                        // if ((this.taskInfo.sourceInstanceType !== 'ALIBABA_CLOUD_HOSTED'
                        //   || this.taskInfo.sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED'
                        //   || DataSourceGroup.pg.indexOf(this.taskInfo.sinkType) > -1) && !DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                        //   if (!table.hasPk && !DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && !DataSourceGroup.pg.includes(this.taskInfo.sourceType)
                        //     && !DataSourceGroup.polar.includes(this.taskInfo.sinkType)) {
                        //     action = ['INSERT'];
                        //     onlyInsert = true;
                        //   } else {
                        //     action = ['INSERT', 'UPDATE', 'DELETE'];
                        //   }
                        // } else {
                        //   action = ['INSERT', 'UPDATE', 'DELETE'];
                        // }

                        if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                          theSinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sinkDb, table, this.taskInfo);
                        } else {
                          theSinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sourceDb, table, this.taskInfo);
                        }

                        if (this.taskInfo.sinkType === 'Hive') {
                          if (table.tableName.substr(0, 1) === '_') {
                            checked = false;
                            theSinkTable = '';
                            selected = false;
                            action = [];
                            disabled = true;
                          }
                        } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)
                          && this.taskInfo.sinkTableList[item.sinkDb]
                          && !this.containsTable(this.taskInfo.sinkTableList[item.sinkDb], theSinkTable)) {
                          checked = false;
                          theSinkTable = '';
                          selected = false;
                          action = [];
                        } else if (DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !table.hasPk) {
                          checked = false;
                          theSinkTable = '';
                          selected = false;
                          action = [];
                          disabled = true;
                        } else if (((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') || this.taskInfo.type === 'REVISE')
                          && !this.containsTable(this.taskInfo.sinkTableList[item.sourceDb], theSinkTable)) {
                          checked = false;
                          theSinkTable = '';
                          selected = false;
                          action = [];
                        } else if (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.taskInfo.sinkTableList[item.sourceDb], theSinkTable)) {
                          checked = false;
                          theSinkTable = '';
                          selected = false;
                          action = [];
                          disabled = true;
                        }
                        let partitions = 4;

                        if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                          let hasSame = false;
                          let
                            theTopic = null;

                          response.data.data.forEach((topic) => {
                            if (topic.tableName === theSinkTable) {
                              hasSame = true;
                              theTopic = topic;
                            }
                          });

                          if (hasSame) {
                            partitions = theTopic.mqTopicPartitions;
                          }
                        }
                        arr.push({
                          sourceTable: table.tableName,
                          action,
                          sinkTable: theSinkTable,
                          commonRule: this.taskInfo.commonRule,
                          customSinkTable: '',
                          hasPk: table.hasPk,
                          isRds: this.taskInfo.sourceInstanceType === 'ALIBABA_CLOUD_HOSTED'
                            && this.taskInfo.sinkInstanceType === 'ALIBABA_CLOUD_HOSTED'
                            && DataSourceGroup.mysql.indexOf(this.taskInfo.sinkType) > -1,
                          index: i,
                          selected,
                          db: item.sourceDb,
                          sourceSchema: item.sourceSchema,
                          targetSchema: item.targetSchema,
                          sinkDb: item.sinkDb,
                          _checked: checked,
                          _disabled: disabled,
                          partitions,
                          shards: 5,
                          replication: 1,
                          needAutoCreated: Boolean(this.taskInfo.sinkTableList[theDb] && !this.containsTable(this.taskInfo.sinkTableList[theDb], theSinkTable))
                        });
                      });
                      this.selectedTables[theDb] = arr;
                      this.filterTable[theDb] = {};
                      this.filterTable[theDb].tableFilterName = '';
                      this.filterTable[theDb].tableFilterCreate = ['autoCreated', 'noCreate'];
                      this.filterTable[theDb].tableFilterAction = ['INSERT', 'UPDATE', 'DELETE'];
                      this.filterTable[theDb].tableFilterType = '';
                      this.filterTable[theDb].tableFilterPk = ['hasPk', 'noPk'];
                      this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
                      this.pageData[theDb] = {
                        page: 1,
                        size: 20,
                        total: this.showList[theDb].length
                      };
                      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                        this.pageData[theDb].page * this.pageData[theDb].size);

                      setTimeout(() => {
                        item.selectedTables = that.selectedTables[theDb];
                        that.$set(that.selectedTables, index, index);
                        that.index++;
                        that.currentNum = '0';
                        that.$set(that.selectedTables, index, index);
                        that.index++;
                      });
                    }
                    this.loading = false;
                    this.updateLoading(false);
                    this.showTableList = { ...this.showTableList };
                  } catch (e) {
                    console.log('e', e);
                  }
                  if (store.state.jobData) {
                    console.log(1);
                    this.parseSchemaData(store.state.jobData.schemaData);
                  }
                })
                .catch(() => {
                  this.loading = false;
                  this.updateLoading(false);
                });
            })
            .catch(() => {
              this.loading = false;
              this.updateLoading(false);
            });
        } else {
          let theDb = item.sourceDb;

          if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
            theDb = item.sinkDb;
          }

          this.taskInfo.sinkTableList[theDb] = [];
          getTableList({
            host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
            privateHost: this.taskInfo.sourcePrivateHost,
            publicHost: this.taskInfo.sourcePublicHost,
            hostType: this.taskInfo.sourceHostType,
            type: this.taskInfo.sourceType,
            dbName: item.sourceDb,
            dataSourceId: this.taskInfo.sourceDataSourceId,
            clusterId: this.taskInfo.clusterId,
            tableSchema: item.sourceSchema,
            specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword,
            rabbitMqVhost: this.taskInfo.srcRabbitMqVhost,
            rabbitExchange: this.taskInfo.srcRabbitExchange
          })
            .then((res) => {
              if (res.data.data.length < 1) {
                this.$Modal.warning({
                  title: '任务创建提示',
                  content: `当前源库${item.sourceDb}为空库，表的数量为0，不支持迁移`
                });
              } else {
                const arr = [];
                const that = this;
                res.data.data.forEach((table, i) => {
                  let action;
                  if (!table.hasPk) {
                    if (this.hasUpdateAndDelete) {
                      action = ['INSERT', 'UPDATE', 'DELETE'];
                    } else {
                      action = ['INSERT'];
                    }
                  } else {
                    action = ['INSERT', 'UPDATE', 'DELETE'];
                  }

                  // if ((this.taskInfo.sourceInstanceType !== 'ALIBABA_CLOUD_HOSTED'
                  //   || this.taskInfo.sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED'
                  //   || this.taskInfo.sinkType === 'PostgreSQL') && !DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                  //   if (!table.hasPk) {
                  //     action = ['INSERT'];
                  //     onlyInsert = true;
                  //   } else {
                  //     action = ['INSERT', 'UPDATE', 'DELETE'];
                  //   }
                  // } else {
                  //   action = ['INSERT', 'UPDATE', 'DELETE'];
                  // }
                  // eslint-disable-next-line one-var
                  let checked = true,
                    selected = true,
                    sinkTable = table.tableName,
                    disabled = false;

                  if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                    sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sinkDb, table, this.taskInfo);
                  } else {
                    sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sourceDb, table, this.taskInfo);
                  }
                  if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                    sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sinkDb, table, this.taskInfo);
                  } else {
                    sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], item.sourceDb, table, this.taskInfo);
                  }

                  if (this.taskInfo.sinkType === 'Hive') {
                    if (table.tableName.substr(0, 1) === '_') {
                      checked = false;
                      selected = false;
                      sinkTable = '';
                      action = [];
                      disabled = true;
                    }
                  } else if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !this.containsTable(this.taskInfo.sinkTableList[item.sourceDb], sinkTable)) {
                    checked = false;
                    sinkTable = '';
                    selected = false;
                    action = [];
                    disabled = true;
                  } else if (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.taskInfo.sinkTableList[item.sourceDb], sinkTable)) {
                    checked = false;
                    sinkTable = '';
                    selected = false;
                    action = [];
                    disabled = true;
                  }
                  arr.push({
                    sourceTable: table.tableName,
                    action,
                    sinkTable,
                    commonRule: this.taskInfo.commonRule,
                    customSinkTable: '',
                    hasPk: table.hasPk,
                    isRds: this.taskInfo.sourceInstanceType === 'ALIBABA_CLOUD_HOSTED'
                      && this.taskInfo.sinkInstanceType === 'ALIBABA_CLOUD_HOSTED'
                      && this.taskInfo.sinkType === 'MySQL',
                    index: i,
                    selected,
                    db: item.sourceDb,
                    sinkDb: item.sinkDb,
                    sourceSchema: item.sourceSchema,
                    targetSchema: item.targetSchema,
                    _checked: checked,
                    _disabled: disabled,
                    partitions: 4,
                    shards: 5,
                    replication: 1,
                    needAutoCreated: Boolean(this.taskInfo.sinkTableList[item.sourceDb] && !this.containsTable(this.taskInfo.sinkTableList[item.sourceDb], table.tableName))
                  });
                });

                this.selectedTables[theDb] = arr;
                this.filterTable[theDb] = {};
                this.filterTable[theDb].tableFilterName = '';
                this.filterTable[theDb].tableFilterCreate = ['autoCreated', 'noCreate'];
                this.filterTable[theDb].tableFilterAction = ['INSERT', 'UPDATE', 'DELETE'];
                this.filterTable[theDb].tableFilterType = '';
                this.filterTable[theDb].tableFilterPk = ['hasPk', 'noPk'];
                this.filterTable[theDb].tableFilterColumn = '';
                this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
                this.pageData[theDb] = {
                  page: 1,
                  size: 20,
                  total: this.showList[theDb].length
                };
                this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                  this.pageData[theDb].page * this.pageData[theDb].size);

                setTimeout(() => {
                  item.selectedTables = that.selectedTables[theDb];
                  that.$set(that.selectedTables, index, index);
                  that.index++;
                  that.currentNum = '0';
                  that.$set(that.selectedTables, index, index);
                  that.index++;
                });
              }
              this.loading = false;
              this.updateLoading(false);
              this.showTableList = { ...this.showTableList };
            })
            .catch(() => {
              this.loading = false;
              this.updateLoading(false);
            });
        }
      });
    },
    handleAllTableSelected(index, db) {
      this.showList[db].forEach((item) => {
        let action;

        if (!item.hasPk) {
          if (this.hasUpdateAndDelete) {
            action = ['INSERT', 'UPDATE', 'DELETE'];
          } else {
            action = ['INSERT'];
          }
        } else {
          action = ['INSERT', 'UPDATE', 'DELETE'];
        }
        if (this.taskInfo.sinkType === 'Hive' && item.sourceTable.substr(0, 1) === '_') {
          this.selectedTables[db][item.index].action = [];
          this.selectedTables[db][item.index].sinkTable = '';
        } else {
          if (DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !this.selectedTables[db][item.index].hasPk) {
            this.selectedTables[db][item.index].action = [];
          } else {
            this.selectedTables[db][item.index].action = action;
          }
          const sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], db, item, this.taskInfo);
          if ((DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !this.selectedTables[db][item.index].hasPk)
            || (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.taskInfo.sinkTableList[db], sinkTable))
            || ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !this.containsTable(this.taskInfo.sinkTableList[db], sinkTable))) {
            this.selectedTables[db][item.index].selected = false;

            this.selectedTables[db][item.index].sinkTable = '';
          } else {
            this.selectedTables[db][item.index].sinkTable = sinkTable;

            this.selectedTables[db][item.index].selected = true;
          }
          item._checked = true;
          this.selectedTables[db].forEach((table) => {
            if (table.sourceTable === item.sourceTable) {
              if ((DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !table.hasPk)
                || (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.taskInfo.sinkTableList[db], sinkTable))
                || ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !this.containsTable(this.taskInfo.sinkTableList[db], sinkTable))) {
                table._checked = false;
              } else {
                table._checked = true;
              }
            }
          });
        }
        this.handleSelectChange(index, db, item, getTargetName(this.theMappingRule[this.theMappingIndex], db, item, this.taskInfo, 'create'));
      });

      // this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];

      // setTimeout(() => {
      //   that.forceUpdateView();
      // });
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup(['INSERT', 'UPDATE', 'DELETE']);
    },
    handleTableSelected(index, db, selection) {
      selection.forEach((item) => {
        let action;

        if (!item.hasPk) {
          if (this.hasUpdateAndDelete) {
            action = ['INSERT', 'UPDATE', 'DELETE'];
          } else {
            action = ['INSERT'];
          }
        } else {
          action = ['INSERT', 'UPDATE', 'DELETE'];
        }
        if (!this.selectedTables[db][item.index].action || this.selectedTables[db][item.index].action.length < 1) {
          this.selectedTables[db][item.index].action = action;
        }
        // if (!this.selectedTables[db][item.index].sinkTable) {
        //   let sinkTable;
        //
        //   if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
        //     sinkTable = (`cc_${this.taskInfo.sourceInstanceId}_${db}_${item.sourceTable}`).toLowerCase();
        //     existCheckIndices.push(sinkTable);
        //   } else {
        //     sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], db, item, this.taskInfo);
        //   }
        //
        //   this.selectedTables[db][item.index].sinkTable = sinkTable;
        // }
        this.selectedTables[db][item.index].selected = true;
        item._checked = true;
        this.showList[db].forEach((show) => {
          if (item.sourceTable === show.sourceTable) {
            show._checked = true;
          }
        });
        this.handleSelectChange(index, db, item, getTargetName(this.theMappingRule[this.theMappingIndex], db, item, this.taskInfo, 'create'));
      });

      this.selectedTables[db].forEach((table) => {
        selection.forEach((row) => {
          if (row.sourceTable === table.sourceTable) {
            table._checked = true;
          }
        });
      });
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup(['INSERT', 'UPDATE', 'DELETE']);
    },
    handleSelectCancel(index, db, selection) {
      this.showList[db].forEach((show, i) => {
        if (i >= (this.pageData[db].page - 1) * this.pageData[db].size && i < this.pageData[db].page * this.pageData[db].size) {
          let alreadyHave = false;

          selection.forEach((row) => {
            if (row.sourceTable === show.sourceTable) {
              alreadyHave = true;
            }
          });
          if (!alreadyHave) {
            show._checked = false;
            this.selectedTables[db].forEach((item) => {
              if (item.sourceTable === show.sourceTable) {
                item.action = [];
                // item.sinkTable = '';
                item.selected = false;
                item._checked = false;
                item.shards = 5;
                item.replication = 1;
              }
            });
          }
        }
      });
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup([]);
    },
    handleCancelSelect(index, db) {
      this.currentNum = '';
      this.showList[db].forEach((show) => {
        show._checked = false;
        this.selectedTables[db].forEach((item) => {
          if (show.sourceTable === item.sourceTable) {
            // item.sinkTable = '';
            item.action = [];
            item.selected = false;
            item._checked = false;
            item.shards = 5;
            item.replication = 1;
          }
        });
      });
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      const that = this;

      setTimeout(() => {
        that.currentNum = String(index);
        that.forceUpdateView();
      });
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup([]);
    },
    handleSelectChange(index, db, row, data) {
      let hasSame = false;
      const sinkTableList = this.taskInfo.sinkTableList[db];

      if (sinkTableList) {
        sinkTableList.forEach((table) => {
          if (this.taskInfo.targetCaseSensitive === 'false') {
            if (data) {
              if (table.tableName.toLowerCase() === data.toLowerCase()) {
                hasSame = true;
              }
            }
          } else if (this.taskInfo.commonRule === 'LowerCase') {
            if (data) {
              if (table.tableName.toLowerCase() === data.toLowerCase()) {
                hasSame = true;
              }
            }
          } else if (this.taskInfo.commonRule === 'UpperCase') {
            if (data) {
              if (table.tableName.toUpperCase() === data.toUpperCase()) {
                hasSame = true;
              }
            }
          } else if (data === table.tableName
            || table.tableName === `cc_${this.taskInfo.sourceInstanceId}_${db}_${data}`) {
            if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
              hasSame = this.containsTable(sinkTableList, data);
            }

            this.selectedTables[db][row.index].replication = table.replication;
            this.selectedTables[db][row.index].shards = table.shards;
            this.selectedTables[db][row.index].fieldMetaList = table.fieldMetaList;
          }
        });
      }
      hasSame = this.containsTable(this.taskInfo.sinkTableList[db], row.sinkTable || data);

      this.selectedTables[db][row.index].needAutoCreated = !hasSame && data;
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
        hasSame = false;
        let theTopic = null;

        this.taskInfo.sinkTableList[this.db.sourceDb].forEach((topic) => {
          if (topic.tableName === data) {
            hasSame = true;
            theTopic = topic;
          }
        });

        if (hasSame) {
          this.selectedTables[db][row.index].partitions = theTopic.mqTopicPartitions;
        }
      } else if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
        hasSame = false;
        let theIndex = null;

        this.taskInfo.sinkTableList[this.db.sourceDb].forEach((indexItem) => {
          if (indexItem.tableName === data) {
            hasSame = true;
            theIndex = indexItem;
          }
        });
        if (hasSame) {
          this.selectedTables[db][row.index].shards = theIndex.indexMeta.numberOfShards;
          this.selectedTables[db][row.index].replication = theIndex.indexMeta.numberOfReplicas;
        }
      }
      // this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
    },
    handleActionChange(index, db) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[theDb];
    },
    forceUpdateView() {
      // this.$forceUpdate();
      this.selectedTables = { ...this.selectedTables };
      // this.$set(this.selectedTables, this.index, this.index);
      // this.index++;
      // this.$set(this.selectedTables, this.index, this.index);
    },
    updateNextStep() {
      Object.keys(this.selectedTables)
        .forEach((item) => {
          if (this.selectedTables[item] instanceof Array) {
            this.selectedTables[item].forEach((table) => {
              if (table.selected) {
                if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                  table.needAutoCreated = !this.containsTable(this.taskInfo.sinkTableList[table.sinkDb], table.sinkTable);
                } else {
                  if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
                    table.needAutoCreated = false;
                  } else {
                    table.needAutoCreated = !this.containsTable(this.taskInfo.sinkTableList[table.db], table.sinkTable);
                  }
                }
              }
            });
          }
        });
      this.taskInfo.selectedTables = this.selectedTables;
      this.updateStep(3);
    },
    updatePrevStep() {
      this.taskInfo.selectedTables = this.selectedTables;
    },
    containsTable(list, table) {
      let contains = false;
      const targetTableName = table.toUpperCase();

      if (list) {
        list.forEach((item) => {
          const sourceTableName = item.tableName.toUpperCase();
          if (this.taskInfo.targetCaseSensitive === 'false') {
            if (sourceTableName === targetTableName) {
              contains = true;
            }
          } else if (this.taskInfo.commonRule === 'UpperCase') {
            if (sourceTableName === targetTableName) {
              contains = true;
            }
          } else if (this.taskInfo.commonRule === 'LowerCase') {
            if (sourceTableName === targetTableName) {
              contains = true;
            }
          } else if (item.tableName === table) {
            contains = true;
          }
        });
      }
      return contains;
    },
    handleTableFilter(db, schema, database, type) {
      this.calcHeight(db);
      const value = this.filterTable[db].tableFilterName;

      this.showList[db] = [];
      let finalList = [];

      finalList = this.selectedTables[db].filter((item) => {
        if (!this.filterList[db]) {
          this.filterList[db] = [];
        }
        return Boolean((this.filterList[db].indexOf('autoCreated') > -1 ? item.needAutoCreated : true)
          && (this.filterList[db].indexOf('noCreate') > -1 ? !item.needAutoCreated : true)
          && (this.filterList[db].indexOf('hasPk') > -1 ? item.hasPk : true)
          && (this.filterList[db].indexOf('noPk') > -1 ? !item.hasPk : true)
          && (this.filterList[db].indexOf('hasSelected') > -1 ? item.selected : true)
          && (this.filterList[db].indexOf('notSelected') > -1 ? !item.selected : true)
          && (value ? item.sourceTable.toLowerCase().indexOf(value.toLowerCase()) > -1 : true));
      });
      this.showList[db] = finalList;
      if (this.filterList[db].indexOf('differPk') > -1) {
        this.filterByDifferPk(database, finalList);
      }

      if (this.filterList[db].indexOf('column') > -1) {
        this.filterByColumn(db, schema, finalList);
      }

      this.showList = { ...this.showList };
      if (type !== 'select') {
        this.pageData[db].page = 1;
      }
      this.pageData[db].total = this.showList[db].length;
      this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      this.showTableList = { ...this.showTableList };
    },
    handleActionFilter(db, action) {
      this.filterTable[db].tableFilterAction = action;
      this.showList[db].forEach((item) => {
        if (this.selectedTables[db][item.index].selected) {
          if (this.hasUpdateAndDelete) {
            this.selectedTables[db][item.index].action = action;
          } else {
            if (action.indexOf('INSERT') > -1) {
              this.selectedTables[db][item.index].action = ['INSERT'];
            } else {
              this.selectedTables[db][item.index].action = [];
            }
          }
        }
      });
      this.selectedTables = { ...this.selectedTables };
    },
    handlePageChange(db, page) {
      this.pageData[db].page = page;
      this.showTableList[db] = _.cloneDeep(this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size));
      this.currentKey++;
    },
    handleChangeFilterType(db, type) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (!this.filterList[theDb]) {
        this.filterList[theDb] = [];
      }
      if (this.filterTable[theDb].tableFilterType) {
        if (this.filterList[theDb].indexOf(type) === -1) {
          this.filterList[theDb].push(type);
        }
      }
      this.filterTable[theDb].tableFilterType = type;
      this.filterTable = { ...this.filterTable };

      const value = this.filterTable[theDb].tableFilterName;

      if (value || type) {
        this.handleTableFilter(theDb, db.sourceSchema, db);
      } else {
        this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
        this.pageData[theDb] = {
          page: 1,
          size: 20,
          total: this.showList[theDb].length
        };
        this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
          this.pageData[theDb].page * this.pageData[theDb].size);
      }
      // }
    },
    filterByDifferPk(db, finalList) {
      const list = [];
      const dbTablesMap = {};
      const sinkDbTablesMap = {};
      const sourceTableMetaList = [];
      const
        sinkTableMetaList = [];

      this.loading = true;
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }
      dbTablesMap[theDb] = [];
      sinkDbTablesMap[db.sinkDb] = [];
      this.selectedTables[theDb].forEach((table) => {
        if (table.selected) {
          dbTablesMap[theDb].push(table.sourceTable);
          sinkDbTablesMap[db.sinkDb].push(table.sinkTable);
          sourceTableMetaList.push({
            dbName: theDb,
            tableName: table.sourceTable,
            schemaName: db.sourceSchema
          });
          sinkTableMetaList.push({
            dbName: db.sinkDb,
            tableName: table.sinkTable,
            schemaName: db.targetSchema
          });
        }
      });
      batchListColumns({
        host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
        privateHost: this.taskInfo.sinkPrivateHost,
        publicHost: this.taskInfo.sinkPublicHost,
        hostType: this.taskInfo.targetHostType,
        type: this.taskInfo.sinkType,
        userName: this.taskInfo.sinkAccount,
        dbName: db.sinkDb,
        dbTablesMap: sinkDbTablesMap,
        dataSourceId: this.taskInfo.targetDataSourceId,
        tableSchema: '',
        tableMetas: sinkTableMetaList,
        clusterId: this.taskInfo.clusterId
      })
        .then((res) => {
          batchListColumns({
            host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
            privateHost: this.taskInfo.sourcePrivateHost,
            publicHost: this.taskInfo.sourcePublicHost,
            hostType: this.taskInfo.sourceHostType,
            type: this.taskInfo.sourceType,
            dbName: db.sourceDb,
            dbTablesMap,
            dataSourceId: this.taskInfo.sourceDataSourceId,
            tableSchema: '',
            tableMetas: sourceTableMetaList,
            clusterId: this.taskInfo.clusterId
          })
            .then((response) => {
              if (response.data.code === '1') {
                const sourceData = response.data.data;
                const
                  sinkData = res.data.data;

                if (Object.keys(sourceData.tableMetaDataMap[theDb]).length > 0) {
                  Object.keys(sourceData.tableMetaDataMap[theDb])
                    .forEach((key) => {
                      let sinkKey = '';
                      let sinkTable = '';
                      let
                        sourceKey = '';

                      sourceData.tableMetaDataMap[theDb][key].forEach((item) => {
                        // 两边都存在主键，但主键不一致
                        if (item.pk) {
                          sourceKey += item.columnName;
                        }
                      });
                      this.selectedTables[theDb].forEach((table) => {
                        if (table.sourceTable === key) {
                          sinkTable = table.sinkTable;
                        }
                      });
                      if (sinkData.tableMetaDataMap[db.sinkDb][sinkTable]) {
                        sinkData.tableMetaDataMap[db.sinkDb][sinkTable].forEach((column) => {
                          if (column.pk) {
                            sinkKey += column.columnName;
                          }
                        });
                      } else {
                        sinkKey = sourceKey;
                      }
                      if (sourceKey && sinkKey) {
                        if (sourceKey !== sinkKey) {
                          this.selectedTables[theDb].forEach((table) => {
                            if (table.sourceTable === key) {
                              list.push(table);
                            }
                          });
                        }
                      } else if (!sourceKey || !sinkKey) {
                        this.selectedTables[theDb].forEach((table) => {
                          if (table.sourceTable === key) {
                            list.push(table);
                          }
                        });
                      }
                      this.showList[theDb] = finalList.filter((item) => list.indexOf(item) > -1);
                      this.pageData[theDb].page = 1;
                      this.pageData[theDb].total = this.showList[theDb].length;
                      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                        this.pageData[theDb].page * this.pageData[theDb].size);
                      this.showTableList = { ...this.showTableList };
                    });
                } else {
                  this.showList[theDb] = [];
                  this.pageData[theDb].page = 1;
                  this.pageData[theDb].total = this.showList[theDb].length;
                  this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                    this.pageData[theDb].page * this.pageData[theDb].size);
                  this.showTableList = { ...this.showTableList };
                }
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
    },
    filterByColumn(db, schema, finalList) {
      let list = [];

      if (this.filterTable[db].tableFilterColumn) {
        this.loading = true;
        const dbTablesMap = {};
        const
          sourceTableMetaList = [];

        dbTablesMap[db] = [];
        this.selectedTables[db].forEach((table) => {
          dbTablesMap[db].push(table.sourceTable);
          sourceTableMetaList.push({
            dbName: db,
            tableName: table.sourceTable,
            schemaName: schema
          });
        });
        batchListColumns({
          host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
          && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
          dbName: db,
          dbTablesMap,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          tableSchema: schema,
          tableMetas: sourceTableMetaList,
          clusterId: this.taskInfo.clusterId
        })
          .then((res) => {
            if (res.data.code === '1') {
              Object.keys(res.data.data.tableMetaDataMap[db])
                .forEach((key) => {
                  res.data.data.tableMetaDataMap[db][key].forEach((item) => {
                    if (item.columnName === this.filterTable[db].tableFilterColumn) {
                      this.selectedTables[db].forEach((table) => {
                        if (table.sourceTable === key) {
                          list.push(table);
                        }
                      });
                    }
                  });
                });
            }
            this.loading = false;
            this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
            this.showList = { ...this.showList };
            this.pageData[db].page = 1;
            this.pageData[db].total = this.showList[db].length;
            this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
            this.showTableList = { ...this.showTableList };
          })
          .catch(() => {
            this.loading = false;
            this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
            this.showList = { ...this.showList };
            this.pageData[db].page = 1;
            this.pageData[db].total = this.showList[db].length;
            this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
            this.showTableList = { ...this.showTableList };
          });
      } else {
        list = this.selectedTables[db];
        this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
        this.showList = { ...this.showList };
        this.pageData[db].page = 1;
        this.pageData[db].total = this.showList[db].length;
        this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
        this.showTableList = { ...this.showTableList };
      }
    },
    handleRefresh() {
      this.loading = true;
      this.getMappingRule(this.init);
    },
    handleCheckTableList(db, index) {
      this.db = db;
      this.selectedIndex = index;
    },
    handleDeleteFilter(i) {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.filterList[theDb].splice(i, 1);
      this.filterTable[theDb].tableFilterType = '';
      this.handleTableFilter(theDb, this.db.sourceSchema, this.db);
    },
    handleClearFilter() {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.filterList[theDb] = [];
      this.filterTable[theDb].tableFilterType = '';
      this.handleTableFilter(theDb, this.db.sourceSchema, this.db);
    },
    handleChangeMigrationType(data) {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.migrationType = data;
      if (data === 'db') {
        this.selectedTables[theDb].forEach((item) => {
          item.sinkTable = `${this.taskInfo.sourceInstanceId}.${item.db}`;
        });
      } else {
        this.selectedTables[theDb].forEach((item) => {
          item.sinkTable = `${this.taskInfo.sourceInstanceId}.${item.db}.${item.sourceTable}`;
        });
      }
      this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
    },
    handleDefaultTopic() {
      this.topicName = '';
      this.currentTopicName = '';
      this.handleChangeMigrationType('db');
    },
    handleSetTopic(type) {
      this.currentTopicName = '';
      this.showSetTopic = true;
      this.setDefaultValue = type === 'default';
    },
    handleConfirmSetTopic() {
      this.topicName = this.currentTopicName;
      if (this.setDefaultValue) {
        this.taskInfo.defaltValue = this.topicName;
        this.taskInfo.sinkTableList[this.db.sourceDb].forEach((item) => {
          if (item.tableName === this.topicName) {
            this.taskInfo.defaltPartitions = item.mqTopicPartitions;
          }
        });
        this.showSetTopic = false;
        this.taskInfo = { ...this.taskInfo };
        return;
      }
      let partitions = 4;
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
        let hasSame = false;
        let
          theTopic = null;

        this.taskInfo.sinkTableList[theDb].forEach((topic) => {
          if (topic.tableName === this.topicName) {
            hasSame = true;
            theTopic = topic;
          }
        });

        if (hasSame) {
          partitions = theTopic.mqTopicPartitions;
        }
      }
      this.selectedTables[theDb].forEach((item) => {
        if (item.selected) {
          item.sinkTable = this.topicName;
          item.needAutoCreated = !this.containsTable(this.taskInfo.sinkTableList[theDb], item.sinkTable);
          item.partitions = partitions;
        }
      });
      this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
      this.showSetTopic = false;
    },
    handleCancel() {
      this.showSetTopic = false;
      this.showSetShards = false;
      this.showMappingRule = false;
    },
    handleSetShards() {
      this.showSetShards = true;
    },
    handleSetPartitions() {

    },
    handleConfirmSetShards() {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.selectedTables[theDb].forEach((item) => {
        item.shards = this.shardsCount;
        item.replication = this.shardsReplication;
      });
      this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
      this.showSetShards = false;
    },
    remoteMethod(db, row, event) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (event.keyCode === 13) {
        queryIndex({
          host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
          privateHost: this.taskInfo.sinkPrivateHost,
          publicHost: this.taskInfo.sinkPublicHost,
          hostType: this.taskInfo.targetHostType,
          type: this.taskInfo.sinkType,
          userName: this.taskInfo.sinkAccount,
          dbName: theDb,
          dataSourceId: this.taskInfo.targetDataSourceId,
          tableSchema: '',
          clusterId: this.taskInfo.clusterId,
          regexIndexName: this.selectedTables[theDb][row.index].sinkTable
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.searchLoading = false;
              const indexList = [];

              res.data.data.forEach((item) => {
                indexList.push({
                  tableName: item.indexMeta.indexName,
                  shards: item.indexMeta.numberOfShards,
                  replication: item.indexMeta.numberOfReplicas,
                  fieldMetaList: item.indexMeta.fieldMetaList
                });
              });
              this.taskInfo.sinkTableList[theDb] = indexList;
              this.selectedTables[theDb][row.index].visible = true;
              this.forceUpdateView();
              this.taskInfo.dbMap[row.index].selectedTables = this.selectedTables[theDb];
              this.handleTableFilter(theDb, '', this.db, 'select');
            }
          });
      }
    },
    handleChangeShards(index, db, row, data) {
      if (data) {
        this.selectedTables[db][row.index].shards = data;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
    },
    handleChangeReplication(index, db, row, data) {
      if (data) {
        this.selectedTables[db][row.index].replication = data;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
    },
    handleChooseIndex(db, row, name) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (name) {
        this.selectedTables[theDb][row.index].sinkTable = name;
        this.selectedTables[theDb][row.index].visible = false;
        this.selectedTables[theDb][row.index].needAutoCreated = false;
        this.taskInfo.sinkTableList[theDb].forEach((item) => {
          if (item.tableName === name) {
            this.selectedTables[theDb][row.index].shards = item.shards;
            this.selectedTables[theDb][row.index].replication = item.replication;
          }
        });
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    handleBlur(db, row) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      this.selectedTables[theDb][row.index].visible = false;
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    handleFocus(db, row) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      this.selectedTables[theDb][row.index].visible = true;
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    updateShowTableList() {
      this.showTableList = { ...this.showTableList };
    },
    getMappingRule(func) {
      this.updateLoading(true);

      tableMappingRule({
        sourceDsId: this.taskInfo.sourceDataSourceId,
        targetDsId: this.taskInfo.targetDataSourceId,
        targetSensitive: this.taskInfo.targetCaseSensitive,
        clusterId: this.taskInfo.clusterId,
        sourceHostType: this.taskInfo.sourceHostType,
        targetHostType: this.taskInfo.targetHostType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.theMappingRule = res.data.data;
            res.data.data.forEach((rule, index) => {
              if (rule.defaultCheck) {
                this.theMappingIndex = index;
              }
            });

            if (func) {
              func();
            }
          }
        })
        .catch(() => {
          this.updateLoading(true);
        });
    },
    handleShowMappingRule() {
      this.showMappingRule = true;
    },
    handleConfirmSetMappingRule() {
      this.showMappingRule = false;
      const theDb = this.taskInfo.dbMap[this.selectedIndex].sourceDb;

      if (this.selectedTables[theDb]) {
        this.selectedTables[theDb].forEach((table) => {
          table.sinkTable = getTargetName(this.theMappingRule[this.theMappingIndex], this.taskInfo.dbMap[this.selectedIndex].sinkDb, table, this.taskInfo);
          table.needAutoCreated = Boolean(this.taskInfo.sinkTableList[theDb] && !this.containsTable(this.taskInfo.sinkTableList[theDb], table.sinkTable));
        });

        this.forceUpdateView();
        this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
        this.handleTableFilter(this.db.sourceDb, '', this.db, 'select');
      }
    },
    parseSchemaData(data) {
      const sourceSchemaJson = JSON.parse(data.sourceSchema); const
        mappingJson = JSON.parse(data.mappingConfig);
      const targetSchemaJson = data.targetSchema ? JSON.parse(data.targetSchema) : {};
      let tableMapping = {};
      let method = 'TABLE_TABLE';
      if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
        method = 'TABLE_INDEX';
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
        method = 'TABLE_TOPIC';
      }
      mappingJson.forEach((mapping) => {
        try {
          if (mapping.method === method) {
            tableMapping = mapping.serializeMapping;
          }
        } catch (e) {
          console.log('e', e);
        }
      });

      console.log('target', targetSchemaJson, sourceSchemaJson);

      let schema = [];

      if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
        schema = targetSchemaJson;
      } else {
        schema = sourceSchemaJson;
      }

      console.log('schema', schema);
      this.taskInfo.dbMap.forEach((theDb) => {
        this.selectedTables[theDb.sourceDb].forEach((theTable) => {
          schema.forEach((db) => {
            if (db.db === theDb.sourceDb) {
              let sameItem = null;
              let tables = [];
              if (db.schemas) {
                tables = db.schemas[0].tables;
              } else if (db.tableSpaces) {
                tables = db.tableSpaces[0].tables;
              } else {
                tables = db.tables;
              }
              tables.forEach((table) => {
                console.log('table.table', table.table, theTable.sourceTable, theTable.sinkTable);
                if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                  if (table.table === theTable.sinkTable) {
                    sameItem = table;
                    if (sameItem) {
                      theTable.select = true;
                      theTable._checked = true;
                      theTable.action = sameItem.actions;
                    }
                  }
                } else {
                  if (table.table === theTable.sourceTable) {
                    sameItem = table;
                    if (sameItem) {
                      theTable.select = true;
                      theTable._checked = true;
                      theTable.sinkTable = this.getTargetTable(theTable, tableMapping, db);
                      theTable.action = sameItem.actions;
                    }
                  }
                }
              });
              if (!sameItem) {
                theTable.selected = false;
                theTable._checked = false;
                theTable.action = [];
              }
            }
          });
        });
        this.showList[theDb.sourceDb] = _.cloneDeep(this.selectedTables[theDb.sourceDb]);
        this.showTableList[theDb.sourceDb] = this.showList[theDb.sourceDb]
          ? this.showList[theDb.sourceDb].slice((this.pageData[theDb.sourceDb].page - 1) * this.pageData[theDb.sourceDb].size,
            this.pageData[theDb.sourceDb].page * this.pageData[theDb.sourceDb].size) : [];
      });
    },
    getTargetTable(table, mapping, db) {
      let targetTable = table.sourceTable;
      Object.keys(mapping).forEach((theMapping) => {
        let theKey = {
          parent: {
            value: table.db
          },
          value: table.sourceTable
        };
        if (db.schemas || db.tableSpaces) {
          if (db.tableSpaces) {
            theKey = {
              value: table.sourceTable,
              parent: {
                value: table.sourceSchema,
                parent: {
                  value: table.db
                }
              }
            };
          } else {
            theKey = {
              parent: {
                value: table.sourceSchema,
                parent: {
                  value: table.db
                }
              },
              value: table.sourceTable
            };
          }
        }
        if (theMapping === JSON.stringify(theKey)) {
          const theValue = JSON.parse(mapping[theMapping]);
          targetTable = theValue.value;
        }
      });
      return targetTable;
    }
  }
};
</script>
<style lang="less" scoped>
.table-filter-container {
  margin-left: 280px;
  border-left: 1px solid #DADADA;
  overflow: auto;
  position: relative;

  .table-filter-container-search {
    padding: 14px 20px;
    background-color: #FAFAFA;
  }

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .table-filter-footer {
    margin-top: 50px;
    border-top: 1px solid #DADADA;
    background-color: #FAFAFA;
  }
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}

</style>
