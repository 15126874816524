<template>
  <div class="clean-data-body">
    <FilterHeader :handleTableFilter="handleTableFilter" :filterTable="filterTable"
                  :handleBatchSetRedisKey="handleBatchSetRedisKey"
                  :handleShowVirtualColumn="handleShowVirtualColumn"
                  :handleSetTargetKey="handleSetTargetKey" :taskInfo="taskInfo"
                  :handleShowFilterAll="handleShowFilterAll" :sourceType="taskInfo.sourceType"
                  :sinkType="taskInfo.sinkType" :filterList="filterList"
                  :handleShowOperateColumns="handleShowOperateColumns"
                  :handleDeleteFilter="handleDeleteFilter" :handleClearFilter="handleClearFilter"
                  :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
                  :getSelectedInfo="getSelectedInfo" :getWhereCondition="getWhereCondition"
                  :handleFilter="handleFilter" :handleSetPartition="handleSetPartition"
                  theType="create"
                  :handlePageChange="handlePageChange"
                  :handleShowPartitionAll="handleShowPartitionAll"
                  :showTableList="showTableList" :selectedColumns="selectedColumns"></FilterHeader>
    <div class="table-filter-nav-container" style="position: relative;width:400px;float: left;">
      <div class="table-filter-nav table-filter-nav-column">
        <TableMapping
          v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&!DataSourceGroup.redis.includes(taskInfo.sinkType) && !loading"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          theType="create"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :sourceType="taskInfo.sourceType"
          :sinkType="taskInfo.sinkType" :handleShowVirtualColumn="handleShowVirtualColumn"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :handleChangePk="handleChangePk"
          :handle-set-id="handleSetId"
          :handleSetDrdsPartition="handleSetDrdsPartition"
          :handleConfirmChangePk="handleConfirmChangePk"
          :selectedColumns="selectedColumns" :uniqList="uniqList" :set-pk-and-id-flags="setPkAndIdFlags"
        ></TableMapping>
        <TableCacheMapping
          v-if="DataSourceGroup.redis.includes(taskInfo.sinkType)"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          theType="create"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :sourceType="taskInfo.sourceType"
          :sinkType="taskInfo.sinkType" :handleShowVirtualColumn="handleShowVirtualColumn"
          :handleSetRedisKey="handleSetRedisKey"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :handleChangePk="handleChangePk"
          :handleSetDrdsPartition="handleSetDrdsPartition"
          :handleConfirmChangePk="handleConfirmChangePk"
          :selectedColumns="selectedColumns" :uniqList="uniqList"></TableCacheMapping>
        <MqTableMapping
          v-if="DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1"
          :handleSetPartition="handleSetPartition" :handleChangePk="handleChangePk" theType="create"
          :type="DataSourceGroup.mq.includes(taskInfo.sourceType)?'source':'sink'"
          :handleShowVirtualColumn="handleShowVirtualColumn" :sourceType="taskInfo.sourceType"
          :sinkType="taskInfo.sinkType"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :selectedColumns="selectedColumns"></MqTableMapping>
      </div>
      <Page size="small" :page-size="pageSize" class="clean-data-page" :total="total" show-total
            @on-change="handlePageChange"/>
    </div>
    <div class="column-filter-container">
      <div class="mq-schema-left-container" v-if="DataSourceGroup.mq.includes(taskInfo.sourceType)">
        <div class="mq-schema-content">
          <p class="mq-header">源消息结构 <span class="tip">该 Topic 需符合以下结构</span>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyMqSchema(mqSchema)"/>
          </p>
          <div class="mq-schema-format" :style="`height:${tableHeight-40}px`">
            <pre>{{ mqSchema }}</pre>
          </div>
        </div>
      </div>
      <TableContainer
        :style="`marginRight:${(DataSourceGroup.mq.includes(taskInfo.sinkType)||DataSourceGroup.redis.includes(taskInfo.sinkType))?'480px':''}`"
        :tableHeight="tableHeight" :rowClassName="rowClassName" :handleSelected="handleSelected"
        :taskInfo="taskInfo" :mqSchemaKey="mqSchemaKey"
        :handleSelectCancel="handleSelectCancel" :handleAllSelected="handleAllSelected"
        :handleCancelSelectAll="handleCancelSelectAll"
        :handleSinkColumnChange="handleSinkColumnChange" :hasTheSameColumn="hasTheSameColumn"
        :handleChangeIndexType="handleChangeIndexType" :handleDeleteColumn="handleDeleteColumn"
        :handleChangeAnalyzer="handleChangeAnalyzer" :sourceType="taskInfo.sourceType"
        :sinkType="taskInfo.sinkType" :table="table" :esTypeList="esTypeList"
        :selectedColumns="selectedColumns" :mqSchema="mqSchema" :index="index"
        :targetCaseSensitive="taskInfo.targetCaseSensitive" :loading="loading"></TableContainer>
      <div class="mq-schema-container"
           v-if="DataSourceGroup.mq.includes(taskInfo.sinkType)||DataSourceGroup.redis.includes(taskInfo.sinkType)">
        <div class="mq-schema-content">
          <p class="mq-header">
            {{ DataSourceGroup.mq.includes(taskInfo.sinkType) ? '目标消息结构' : '目标Cache结构' }}
            <span><Icon class="copy-icon" type="ios-photos-outline"
                        @click="handleCopyMqSchema(mqSchema)"/></span>
          </p>
          <div class="mq-schema-format" :style="`height:${tableHeight-40}px`">
            <pre>{{ mqSchema }}</pre>
          </div>
        </div>
      </div>
    </div>
    <CleanDataModal :showFilter="showFilter" :whereConditionFilter="whereConditionFilter"
                    :selectedTable="selectedTable" :clearWhereCondition="clearWhereCondition"
                    :handleAddWhereCondition="handleAddWhereCondition" :handleCancel="handleCancel"
                    :showFilterAll="showFilterAll" :handleColumnChange="handleColumnChange"
                    :handleFindTable="handleFindTable" :handleVirtualColumn="handleVirtualColumn"
                    :checkAll="checkAll" :handleCheckAll="handleCheckAll"
                    :checkAllGroup="checkAllGroup" :updateWhereCondition="updateWhereCondition"
                    :checkAllGroupChange="checkAllGroupChange"
                    :showHasColumnTableList="showHasColumnTableList"
                    :hasColumnTableList="hasColumnTableList" :hasTheSameColumn="hasTheSameColumn"
                    :showHasColumnTableListSize="showHasColumnTableListSize"
                    :showVirtualColumn="showVirtualColumn" :taskInfo="taskInfo"
                    :handleShowHasColumnPageChange="handleShowHasColumnPageChange"
                    :typeMeta="typeMeta" :whereCondition="whereCondition"
                    :handleAddAllWhereCondition="handleAddAllWhereCondition"
                    :showPartition="showPartition" :partitionData="partitionData"
                    :HivePartitionFuncs="HivePartitionFuncs"
                    :HdfsFileFormats="HdfsFileFormats" :showOperateColumns="showOperateColumns"
                    :virtualColumnData="taskInfo.virtualColumnData&&table&&taskInfo.virtualColumnData[`/${table.sourceDb}/${table.sourceTable}`]||[]"
                    :showPartitionAll="showPartitionAll"
                    :partitionCheckAll="partitionCheckAll"
                    :partitionCheckAllGroup="partitionCheckAllGroup"
                    :partitionCheckAllGroupChange="partitionCheckAllGroupChange"
                    :handleOperateColumns="handleOperateColumns"
                    :handleAddPartitionAll="handleAddPartitionAll"
                    :showMqPartition="showMqPartition"
                    :mqPartition="mqPartition" :handleAddMqPartition="handleAddMqPartition"
                    :showSetAllPartitions="showSetAllPartitions" :selectedColumns="selectedColumns"
                    :tableList="tableList" :showDrdsPartition="showDrdsPartition"
                    :drdsPartition="drdsPartition"
                    :handleAddDrdsPartitions="handleAddDrdsPartitions"
                    :drdsPartitionSetting="drdsPartitionSetting"
                    :handleConfirmSetRedisKey="handleConfirmSetRedisKey"
                    :showRedisKey="showRedisKey"
                    :showBatchSetRedisKey="showBatchSetRedisKey"
                    :handleConfirmBatchSetRedisKey="handleConfirmBatchSetRedisKey"
    ></CleanDataModal>
    <VirtualColumn :selectedTable="selectedTable" :handleDeleteVirtual="handleDeleteVirtual"
                   :virtualColumnData="taskInfo.virtualColumnData&&table&&taskInfo.virtualColumnData[`/${table.sourceDb}/${table.sourceTable}`]||[]"></VirtualColumn>
    <Modal
      v-model="showChangePk"
      width="500px"
      footer-hide
      :closable="false"
      title="修改目标主键">
      <div>
        <Select filterable placeholder="请选择索引列，否则会影响性能" v-model="selectedTargetKeys" multiple
                :key="uqIndex">
          <Option v-for="uniq in getUniqData" :key="uniq.constraintName"
                  :value="uniq.cols.join(',')">
            <span>索引名称：{{ uniq.constraintName }}</span>
            <span>列名：{{ uniq.cols.join(',') }}</span>
          </Option>
          <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn"
                  :value="column.columnName||column.sourceColumn">
            {{ column.columnName || column.sourceColumn }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetId"
      width="500px"
      footer-hide
      :closable="false"
      title="设置_id">
      <template #header>
        设置_id
        <Tooltip transfer>
          <Icon type="ios-help-circle" size="14"/>
          <div slot="content">
            <div>
              ElasticSearch中_id用于唯一识别一个文档。CloudCanal允许用户指定源端的某个列作为_id取值的来源，如果用户不指定，则默认使用源端主键列的取值作为写入对端_id的取值。在使用CloudCanal自定义代码构建宽表进行多表join的场景下，将_id设置为源端多表的关联列对多表join非常有用。
            </div>
            <a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-id-field.html" target="_blank">参考官方资料：_id field说明 </a>
          </div>
        </Tooltip>
      </template>
      <div>
        <Select filterable placeholder="设置_id" v-model="selectedIds" multiple
                :key="uqIndex">
          <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn"
                  :value="column.columnName||column.sourceColumn">
            {{ column.columnName || column.sourceColumn }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmIds">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showChangePkBatch"
      width="500px"
      footer-hide
      :closable="false"
      title="批量修改目标主键">
      <div>
        <Select style="width: 340px" filterable placeholder="请选择索引列，否则会影响性能"
                @on-change="handleSelectUniq" multiple :key="uqIndex" v-model="currentCols">
          <Option v-for="uniq in getAllUniqData" :key="uniq.column" :value="uniq.column">
            <span>{{ uniq.column }}</span>
            <span v-if="uniq.isUk" style="margin-left: 8px"><Icon type="ios-key"/></span>
          </Option>
        </Select>
        <pd-button style="margin-left: 6px" type="primary" @click="handleFindTableByUq">查找有该列的表
        </pd-button>
        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">应用到以下表</p>
        <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
          <Checkbox style="margin-bottom: 10px"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAllBatch">全选
          </Checkbox>
          <CheckboxGroup style="max-height: 200px;overflow: auto;" v-model="checkAllGroup"
                         @on-change="checkAllGroupChange">
            <Checkbox style="margin-bottom: 10px" v-for="(table,i) of showHasColumnTableList"
                      :label="`${table.dbName}.${table.tableName}`" :key="i"></Checkbox>
          </CheckboxGroup>
          <Page size="small" style="margin-top: 20px" :total="hasColumnTableList.length" show-total
                :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk('batch')">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoPkList"
      width="500px"
      footer-hide
      :closable="false"
      title="创建任务提示">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">
          以下表选择了同步UPDATE或DELETE，但没有选择主键列，可能导致同步异常或性能低下。是否需要自动为这些表设置第一个唯一键为主键？</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getaNoPkTableList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleSetPk">自动设置</pd-button>
          <pd-button type="default" @click="handleNext">忽略并继续</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoUkList"
      width="500px"
      footer-hide
      :closable="false"
      title="创建任务提示">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">
          以下表选择了同步UPDATE或DELETE，但没有选择主键列，也没有唯一键，可能导致同步异常或导致性能低下。请返回上一步取消以下表的UPDATE和DELETE，或为这些表设置主键或唯一键。</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getNoUkTableList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancel">知道了</pd-button>
          <pd-button type="default" @click="handleNext">忽略并继续</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoRedisKey"
      width="500px"
      footer-hide
      :closable="false"
      title="创建任务提示">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">以下表没有设置Redis KEY，请设置后再点击下一步</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getNoRedisKeyList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancel">知道了</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  colMappingRule,
  drdsPartitionAlgs,
  getEsAnalyzerTypes,
  listHdfsFileFormat,
  listHivePartitionFunc,
  schemaLessValueFormatTemplate
} from '@services/api/constant';
import { getDsTypeMapping, listDbTablesUniqs } from '@services/api/datasource';
import { validExprFilter } from '@services/api/datarule';
import _ from 'lodash';
import { getExampleData, handleMqSchemaTool } from '@views/viewUtil';
import $ from 'jquery';
import store from '../../../../store';
import Mapping from '../../../../views/util';
import TableMapping from './cleanData/TableMapping';
import MqTableMapping from './cleanData/MqTableMapping';
import TableCacheMapping from './cleanData/TableCacheMapping';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import FilterHeader from './cleanData/FilterHeader';
import CleanDataModal from './cleanData/CleanDataModal';
import TableContainer from './cleanData/TableContainer';
import CleanDataMixin from '../../mixin/cleanDataMixin';
import { handleCopy } from './util';
import VirtualColumn from './cleanData/VirtualColumn';

export default {
  mixins: [CleanDataMixin],
  props: {
    taskInfo: Object,
    updateLoading: Function,
    handleStep: Function
  },
  components: {
    FilterHeader,
    TableMapping,
    MqTableMapping,
    CleanDataModal,
    TableContainer,
    VirtualColumn,
    TableCacheMapping
  },
  created() {
    if (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType)) {
      this.getMappingRule(this.getUniqList(this.handleInit));
    } else {
      this.getMappingRule(this.handleInit());
    }

    if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
      this.getEsAnalyzerTypes();
    }
  },
  mounted() {
    let totalHeight = window.innerHeight;
    this.setPkAndIdFlags = this.taskInfo.setPkAndIdFlags;

    if (totalHeight < 720) {
      totalHeight = 720;
    }
    $('.clean-data-body')
      .css('height', `${totalHeight - 300}px`);
    $('.table-filter-nav-container')
      .css('height', `${totalHeight - 360}px`);
    $('.table-filter-nav')
      .css('height', `${totalHeight - 425}px`);
    this.tableHeight = totalHeight - 370;
  },
  data() {
    return {
      setPkAndIdFlags: {},
      showNoPkList: false,
      showNoUkList: false,
      ignoreNoPk: false,
      noPkList: [],
      noUkList: [],
      noRedisKey: [],
      mqSchemaKey: 0,
      showChangePk: false,
      showSetId: false,
      currentColumnList: [],
      showChangePkBatch: false,
      showOperateColumns: false,
      showRedisKey: false,
      showNoRedisKey: false,
      drdsPartitionSetting: {},
      type: 'create',
      sourceTableMetaList: {},
      sinkTableMetaList: {},
      DataSourceGroup,
      esDisableIndexType: ['binary', 'geo_point', 'object'],
      tableHeight: 500,
      Mapping,
      table: {},
      selectedIndex: 0,
      filterList: [],
      loading: false,
      showSetAllPartitions: false,
      index: 0,
      HivePartitionFuncs: [],
      HdfsFileFormats: {},
      store: {},
      total: 0,
      page: 1,
      pageSize: 20,
      showFilter: false,
      showVirtualColumn: false,
      showFilterAll: false,
      showPartitionAll: false,
      checkAll: false,
      showDrdsPartition: false,
      showBatchSetRedisKey: false,
      uniqList: {},
      drdsPartition: {
        dbpartition: '',
        tbpartition: '',
        tbpartitions: 1,
        dbFuncs: 'HASH',
        tbFuncs: 'HASH'
      },
      finishStatus: {},
      checkAllGroup: [],
      partitionCheckAll: true,
      partitionCheckAllGroup: [],
      showPartition: false,
      showMqPartition: false,
      sinkTables: [],
      mqSchema: '',
      mqSchemaTemplate: '',
      whereCondition: '',
      whereConditionFilter: {},
      filterTable: {
        filterTableName: '',
        tableFilterType: ''
      },
      mqPartition: {
        key: [],
        count: 4
      },
      cleanDataData: {},
      tableInfo: [],
      selectedColumns: {},
      sinkColumns: {},
      sinkColumnNames: [],
      tableList: [],
      showTableList: [],
      filteredTableList: [],
      selectedTable: {},
      hasColumnTableList: [],
      showHasColumnTableList: [],
      showHasColumnTableListPage: 1,
      showHasColumnTableListSize: 10,
      virtualColumnData: {},
      typeMapping: {},
      currentUqs: [],
      currentCols: '',
      uqInfo: {
        constraintName: '',
        cols: []
      },
      uqIndex: 0,
      esTypeList: [],
      selectedTargetKeys: [],
      selectedIds: [],
      promiseList: [],
      partitionData: {
        partition: [
          {
            key: '',
            func: ''
          }
        ]
      },
      supportPkgDs: []
    };
  },
  computed: {
    getUniqData() {
      const key = {
        dbName: this.selectedTable.sourceDb,
        tableSchema: null,
        tableName: this.selectedTable.sourceTable
      };

      if (this.uniqList[JSON.stringify(key)]) {
        this.updateCurrentUqs(this.uniqList[JSON.stringify(key)]);

        return this.uniqList[JSON.stringify(key)];
      }

      this.updateCurrentUqs([]);
      return [];
    },
    getAllUniqData() {
      const list = [];

      Object.values(this.uniqList)
        .forEach((item) => {
          item.forEach((uq) => {
            let hasSame = false;

            list.forEach((column) => {
              if (column.column === uq.cols.join(',')) {
                hasSame = true;
              }
            });
            if (!hasSame) {
              list.push({
                column: uq.cols.join(','),
                isUk: true
              });
            }
          });
        });

      Object.values(this.selectedColumns)
        .forEach((db) => {
          Object.values(db)
            .forEach((table) => {
              table.forEach((column) => {
                let hasSame = false;

                list.forEach((column1) => {
                  if (column1.column === column.sourceColumn) {
                    hasSame = true;
                  }
                });
                if (!hasSame) {
                  list.push({
                    column: column.sourceColumn,
                    isUk: false
                  });
                }
              });
            });
        });

      return list;
    },
    getAllColumns() {
      const mySet = new Set();

      Object.values(this.selectedColumns)
        .map((db) => {
          Object.values(db)
            .map((table) => {
              table.forEach((column) => {
                mySet.add(column.sourceColumn);
              });
              return null;
            });
          return null;
        });

      return Array.from(mySet);
    },
    getaNoPkTableList() {
      const tableList = [];

      this.noPkList.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
        return null;
      });

      return tableList.join(',');
    },
    getNoUkTableList() {
      const tableList = [];

      this.noUkList.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
        return null;
      });

      return tableList.join(',');
    },
    getNoRedisKeyList() {
      const tableList = [];

      this.noRedisKey.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
      });

      return tableList.join(',');
    }
  },
  methods: {
    getSelectedInfo(db, index) {
      let n = 0;
      const columnMap = [];

      if (this.selectedColumns[db] && this.selectedColumns[db][index] instanceof Array) {
        this.selectedColumns[db][index].forEach((item) => {
          if (item.selected) {
            n++;
            columnMap.push({
              sourceColumn: item.sourceColumn,
              sinkColumn: item.sinkColumn
            });
          }
          return null;
        });
      }
      return {
        selectedCount: n,
        columnMap
      };
    },
    updateCurrentUqs(data) {
      this.currentUqs = data;
    },
    getMappingRule(func) {
      this.updateLoading(true);

      colMappingRule({
        sourceDsId: this.taskInfo.sourceDataSourceId,
        targetDsId: this.taskInfo.targetDataSourceId,
        targetSensitive: this.taskInfo.targetCaseSensitive,
        clusterId: this.taskInfo.clusterId,
        sourceHostType: this.taskInfo.sourceHostType,
        targetHostType: this.taskInfo.targetHostType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.theMappingRule = res.data.data;

            if (func) {
              func();
            }
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },
    handleInit() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) || DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        schemaLessValueFormatTemplate({ schemaLessValueFormat: this.taskInfo.dstSchemaLessFormat })
          .then((res) => {
            this.mqSchemaTemplate = res.data.data;
          });
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        schemaLessValueFormatTemplate({ schemaLessValueFormat: this.taskInfo.srcSchemaLessFormat })
          .then((res) => {
            this.mqSchemaTemplate = res.data.data;
          });
      }
      getDsTypeMapping({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType,
        dstDsId: this.taskInfo.targetDataSourceId
      })
        .then((res1) => {
          if (res1.data.code === '1') {
            this.typeMapping = res1.data.data;

            this.store = store;
            if (this.taskInfo.firstToCleanData) {
              if (_.isEqual(this.taskInfo.selectedTables, this.taskInfo.compareSelectedTables)) {
                this.selectedColumns = _.cloneDeep(this.taskInfo.selectedColumns);
                this.tableList = this.taskInfo.tableInfo;
                this.filteredTableList = _.cloneDeep(this.tableList);
                this.total = this.filteredTableList.length;
                this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
                this.table = this.showTableList[0];
                let
                  totalHeight = window.innerHeight;

                if (totalHeight < 720) {
                  totalHeight = 720;
                }
                this.tableHeight = totalHeight - 570;
                this.selectedIndex = 0;
                this.forceUpdateView();
                const that = this;

                setTimeout(() => {
                  that.taskInfo.dbMap.forEach((db) => {
                    that.handleGetSelectedColumns(db.sourceDb);
                    return null;
                  });
                  this.updateLoading(false);
                });
              } else {
                this.init();
                this.taskInfo.compareSelectedTables = this.taskInfo.selectedTables;
              }
            } else {
              this.init();
              this.taskInfo.firstToCleanData = true;
              this.taskInfo.compareSelectedTables = this.taskInfo.selectedTables;
            }

            if (DataSourceGroup.hive.indexOf(this.taskInfo.sinkType) > -1) {
              listHivePartitionFunc({
                loopRunType: this.taskInfo.fullPeriodDate.dayType
              })
                .then((res) => {
                  if (res.data.code === '1') {
                    this.HivePartitionFuncs = res.data.data;
                    this.partitionData.partition[0].key = this.HivePartitionFuncs[0].partitionKey;
                    this.partitionData.partition[0].func = this.HivePartitionFuncs[0].function;
                  }
                });

              listHdfsFileFormat()
                .then((res) => {
                  if (res.data.code === '1') {
                    this.HdfsFileFormats = res.data.data;
                  }
                });
            }
            if (DataSourceGroup.drds.indexOf(this.taskInfo.sinkType) > -1) {
              drdsPartitionAlgs()
                .then((res) => {
                  if (res.data.code === '1') {
                    this.drdsPartitionSetting = res.data.data;
                  }
                });
            }

            if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
              this.getDsTypeMeta();
            }
          } else {
            this.updateLoading(false);
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
      this.updateLoading(false);
    },
    getUniqList(func) {
      this.updateLoading(true);

      const dbNames = [];

      this.taskInfo.dbMap.forEach((db) => {
        dbNames.push(db.sourceDb);
        return null;
      });
      listDbTablesUniqs({
        host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
        privateHost: this.taskInfo.sourcePrivateHost,
        publicHost: this.taskInfo.sourcePublicHost,
        hostType: this.taskInfo.sourceHostType,
        type: this.taskInfo.sourceType,
        userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1 && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
        dbNames,
        dataSourceId: this.taskInfo.sourceDataSourceId,
        clusterId: this.taskInfo.clusterId
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.uniqList = res.data.data;
          }
          if (func) {
            func();
          } else {
            this.updateLoading(false);
          }
        });
    },
    init() {
      if (DataSourceGroup.hive.includes(this.taskInfo.sinkType) && this.HivePartitionFuncs && this.HivePartitionFuncs.length > 0) {
        this.taskInfo.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
      this.finishStatus = {};
      this.taskInfo.dbMap.forEach((item) => {
        this.finishStatus[item.sourceDb] = false;
      });
      this.updateLoading(true);
      this.taskInfo.tableInfo = [];
      this.filteredTableList = [];
      this.tableList = [];
      this.promiseList = [];
      this.taskInfo.dbMap.forEach((item, index) => {
        this.updateLoading(true);

        this.selectedItem = item;
        if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
          this.selectedColumns[item.sinkDb] = {};
        } else {
          this.selectedColumns[item.sourceDb] = {};
        }
        this.dbTablesMap = {};
        this.dbTablesMap[item.sourceDb] = [];
        this.sinkDbTableMap[item.sinkDb] = [];
        this.sourceTableMetaList[item.sourceDb] = [];
        this.sinkTableMetaList[item.sinkDb] = [];
        if (item.selectedTables) {
          item.selectedTables.forEach((table) => {
            if (table.selected) {
              this.dbTablesMap[item.sourceDb].push(table.sourceTable);
              if (!table.needAutoCreated) {
                // console.log('sinkDbTableMap push');
                this.sinkDbTableMap[item.sinkDb].push(table.sinkTable);
              }
              this.tableList.push({
                sourceTable: table.sourceTable,
                sinkTable: table.sinkTable,
                customSinkTable: table.customSinkTable,
                sourceDb: item.sourceDb,
                sinkDb: item.sinkDb,
                action: table.action,
                index,
                sourceSchema: table.sourceSchema,
                targetSchema: table.targetSchema,
                needAutoCreated: table.needAutoCreated,
                partitions: table.partitions,
                shards: table.shards,
                replication: table.replication,
                fieldMetaList: table.fieldMetaList,
                partitionKeys: []
              });
              this.taskInfo.tableInfo = this.tableList;

              this.sourceTableMetaList[item.sourceDb].push({
                dbName: item.sourceDb,
                tableName: table.sourceTable,
                schemaName: item.sourceSchema
              });
              this.sinkTableMetaList[item.sinkDb].push({
                dbName: item.sinkDb,
                tableName: table.sinkTable,
                schemaName: item.targetSchema
              });
            }
            return null;
          });
        }
        if (this.sinkDbTableMap[item.sinkDb].length > 0 && DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
          this.getMqColumnData(item, index === this.taskInfo.dbMap.length - 1);
        } else if (this.sinkDbTableMap[item.sinkDb].length > 0) {
          this.getSourceAndTargetColumnData(item, index === this.taskInfo.dbMap.length - 1);
        } else {
          this.getSourceColumnData(item, index === this.taskInfo.dbMap.length - 1);
        }
        return null;
      });
      Promise.allSettled(this.promiseList)
        .then((results) => {
          // const len = results.length;
          results.forEach((res) => {
            res = res.value;
            const {
              item,
              selectedItem,
              sinkTables = {}
            } = res;
            try {
              if (this.sinkDbTableMap[item.sinkDb].length > 0 && DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                if (res.data.code === '1') {
                  let sinkTables2 = {};

                  sinkTables2 = res.data.data.tableMetaDataMap[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb];
                  Object.keys(sinkTables2)
                    .forEach((key, i) => {
                      const arr = [];

                      sinkTables2[key].forEach((sinkColumn) => {
                        // console.log(1);
                        arr.push({
                          _checked: true,
                          _disabled: sinkColumn.pk,
                          sinkColumn: sinkColumn.columnName,
                          sinkType: sinkColumn.dataType,
                          sinkIsNullable: sinkColumn.isNullable,
                          selected: true,
                          isPk: sinkColumn.pk,
                          isId: false,
                          index: i,
                          needAutoCreated: false,
                          sinkTable: key,
                          sinkDb: selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb
                        });
                      });
                      this.selectedColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][key] = arr;
                    });
                }
              } else if (this.sinkDbTableMap[item.sinkDb].length > 0) {
                let tables = [];

                tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb];
                const noPkTableNameList = res.data.data.noPkTableNameList;

                this.tableList.forEach((table) => {
                  try {
                    const arr = [];
                    const suffixFields = [];

                    if (table.sourceDb === selectedItem.sourceDb) {
                      if (tables && tables[table.sourceTable]) {
                        tables[table.sourceTable].forEach((column, i) => {
                          if (column.pk) {
                            suffixFields.push(column.columnName);
                          }

                          let hasTheSameColumn = false;

                          if (sinkTables) {
                            if (sinkTables[table.sinkTable ? table.sinkTable : table.targetTable]) {
                              sinkTables[table.sinkTable ? table.sinkTable : table.targetTable].forEach((sinkColumn) => {
                                if (sinkColumn.columnName === column.columnName) {
                                  hasTheSameColumn = true;
                                  arr.push({
                                    _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                      && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                        || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                          ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))),
                                    _disabled: DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && this.supportPkgDs.includes(this.taskInfo.sinkType)
                                      ? false : (table.cols && table.cols[column.columnName]
                                      || Boolean(!this.typeMapping[column.dataType.toUpperCase()])) || table.needAutoCreated,
                                    sourceColumn: column.columnName,
                                    sourceType: column.dataType.toUpperCase(),
                                    sinkColumn: (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                      || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                        ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                        ? table.sinkTable : table.targetTable]))) ? this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '' : '',
                                    commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                                    sourceIsNullable: column.isNullable,
                                    sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                                    sinkIsNullable: sinkColumn.isNullable,
                                    selected: Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                      && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                        || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                          ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))),
                                    isPk: table.cols && table.cols[column.columnName] || column.pk,
                                    isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                                    index: i,
                                    sourceDb: table.sourceDb,
                                    sourceTable: table.sourceTable,
                                    sourceSchema: table.sourceSchema,
                                    targetSchema: table.targetSchema,
                                    analyzer: sinkColumn.esAnalyzerType,
                                    jsonValue: false,
                                    isIndex: true,
                                    timeFormat: sinkColumn.timeFormat,
                                    needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                      ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])
                                  });
                                }
                              });
                            }
                          }

                          if (!hasTheSameColumn) {
                            console.log('3', table.sinkTable);
                            const arrColumn = {
                              _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                  || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))),
                              _disabled: DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && this.supportPkgDs.includes(this.taskInfo.sinkType)
                                ? false : (table.cols && table.cols[column.columnName] || table.needAutoCreated
                                  || Boolean(!this.typeMapping[column.dataType.toUpperCase()])),
                              sourceColumn: column.columnName,
                              sourceType: column.dataType.toUpperCase(),
                              sinkColumn: (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                  ? table.sinkTable : table.targetTable]))) ? this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '' : '',
                              commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                              sourceIsNullable: column.isNullable,
                              sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                              sinkIsNullable: column.isNullable,
                              selected: Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                  || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))),
                              isPk: table.cols && table.cols[column.columnName] || column.pk,
                              isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                              index: i,
                              sourceDb: table.sourceDb,
                              sourceTable: table.sourceTable,
                              sourceSchema: table.sourceSchema,
                              targetSchema: table.targetSchema,
                              jsonValue: false,
                              isIndex: true,
                              needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb] && this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                ? table.sinkTable : table.targetTable])
                            };

                            if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                              arrColumn.analyzer = column.analyzer
                                ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null;
                            }

                            arr.push(arrColumn);
                          }
                          if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                            if (!table.partitionKeys) {
                              table.partitionKeys = [];
                            }
                            if (!table.partitions) {
                              table.partitions = 4;
                            }
                            table.partitionKeys.push(column.columnName);
                          }
                        });
                      }
                      this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                        this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.partitionKeys = table.partitionKeys;
                          }
                        });
                      }
                      if (DataSourceGroup.redis.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                        this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.suffixFields = suffixFields;
                            table.suffixFields = suffixFields;
                          }
                        });
                      }
                      if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                        table.isNoPk = true;
                        tables[table.sourceTable].isNoPk = true;
                        const key = {
                          dbName: table.sourceDb,
                          tableSchema: null,
                          tableName: table.sourceTable
                        };

                        if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                          const uqInfo = this.uniqList[JSON.stringify(key)][0];

                          table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                          this.taskInfo.selectedTables[table.sourceDb].forEach((item1) => {
                            if (item1.sourceTable === table.sourceTable) {
                              item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                              item1.cols = _.cloneDeep(uqInfo.cols);
                            }
                          });

                          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                              if (uqInfo.cols.includes(column.sourceColumn)) {
                                column.isPk = true;
                                if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                                  column.isId = true;
                                }
                              } else {
                                column.isPk = false;
                                column.isId = false;
                              }
                            });
                          }
                        } else {
                          table.cols = [];
                        }
                      }
                      // setTimeout(() => {
                      //   that.handleGetSelectedColumns(table.sourceDb);
                      //   that.selectedColumns = { ...that.selectedColumns };
                      // });
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                });
                if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                  this.selectedColumns[this.table.sourceDb][this.table.sourceTable].foreach((column) => {
                    if (column.selected) {
                      this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                      this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                    }
                  });
                }
              } else {
                let tables = [];

                tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb];
                const noPkTableNameList = res.data.data.noPkTableNameList;

                this.tableList.forEach((table) => {
                  try {
                    const arr = [];

                    if (table.fieldMetaList) {
                      const columns = [];

                      table.fieldMetaList.forEach((field) => {
                        columns.push({
                          columnName: field.fieldName,
                          dataTypeWithLength: field.fieldTypeName,
                          needIndex: field.needIndex,
                          analyzer: field.esAnalyzerType ? field.esAnalyzerType : field.fieldTypeName === 'TEXT' ? 'STANDARD' : null,
                          timePrecision: field.timePrecision,
                          jsonValue: field.jsonValue
                        });
                      });
                      if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                      }
                      this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = columns;
                    } else {
                      if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                      }
                      this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = [];
                    }
                    if (table.sourceDb === selectedItem.sourceDb) {
                      if (tables[table.sourceTable]) {
                        tables[table.sourceTable].forEach((column, i) => {
                          const analyzer = DataSourceGroup.es.indexOf(this.sinkType) > -1 ? column.analyzer
                            ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null : '';
                          console.log(4, table.sinkTable, analyzer);
                          arr.push({
                            _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()])
                              && (table.needAutoCreated || DataSourceGroup.mq.includes(this.taskInfo.sinkType)
                                || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))),
                            sourceColumn: column.columnName,
                            sourceType: column.dataType.toUpperCase(),
                            sinkColumn: this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '',
                            commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                            sourceIsNullable: column.isNullable,
                            sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                            sinkIsNullable: column.isNullable,
                            selected: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                            isPk: table.cols && table.cols[column.columnName] || column.pk,
                            isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                            index: i,
                            sourceDb: table.sourceDb,
                            sourceTable: table.sourceTable,
                            sourceSchema: table.sourceSchema,
                            targetSchema: table.targetSchema,
                            needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                              ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]),
                            isIndex: true,
                            analyzer: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? column.analyzer
                              ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null : '',
                            timePrecision: column.timePrecision,
                            jsonValue: column.jsonValue
                          });

                          if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                            if (!table.partitionKeys) {
                              table.partitionKeys = [];
                              table.partitions = 4;
                            }

                            table.partitionKeys.push(column.columnName);
                          }
                        });
                      }
                      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                        this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.partitionKeys = table.partitionKeys;
                          }
                        });
                      }
                      this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                      if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                        table.isNoPk = true;
                        tables[table.sourceTable].isNoPk = true;
                        const key = {
                          dbName: table.sourceDb,
                          tableSchema: null,
                          tableName: table.sourceTable
                        };

                        if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                          const uqInfo = this.uniqList[JSON.stringify(key)][0];

                          table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                          let list = this.taskInfo.selectedTables[table.sourceDb];

                          if (!list) {
                            list = this.tableList;
                          }
                          if (list) {
                            list.forEach((item1) => {
                              if (item1.sourceTable === table.sourceTable) {
                                item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                                item1.cols = _.cloneDeep(uqInfo.cols);
                              }
                            });
                          }

                          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                              if (uqInfo.cols.includes(column.sourceColumn)) {
                                column.isPk = true;
                                if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                                  column.isId = true;
                                }
                              } else {
                                column.isPk = false;
                                column.isId = false;
                              }
                            });
                          }
                        } else {
                          table.cols = [];
                        }
                      }
                      // setTimeout(() => {
                      //   that.handleGetSelectedColumns(table.sourceDb);
                      //   that.selectedColumns = { ...that.selectedColumns };
                      // });
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                });
                if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                  this.taskInfo.tableInfo = this.tableList;
                  this.tableList = this.taskInfo.tableInfo;
                  this.filteredTableList = _.cloneDeep(this.tableList);
                  this.total = this.filteredTableList.length;
                  this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
                  this.table = this.showTableList[0];
                  if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                    const pkColumns = [];

                    // eslint-disable-next-line no-unused-expressions
                    this.taskInfo.sinkColumns && this.taskInfo.sinkColumns[selectedItem.sinkDb.db
                      ? selectedItem.sinkDb.db : selectedItem.sinkDb][this.table.sinkTable
                      ? this.table.sinkTable : this.table.targetTable].forEach((item1) => {
                      if (item1.isPk) {
                        pkColumns.push(item1.columnName);
                      }
                    });
                    this.mqSchema = {
                      data: [
                        {}
                      ],
                      database: selectedItem.sourceDb,
                      entryType: null,
                      es: 1608782968300,
                      id: 0,
                      isDdl: false,
                      mysqlType: {},
                      old: [],
                      pkNames: pkColumns,
                      schema: null,
                      sql: null,
                      sqlType: {},
                      table: this.table.sourceTable,
                      ts: 1608782968300,
                      type: 'INSERT'
                    };
                    this.selectedColumns[this.table.sourceDb][this.table.sourceTable].forEach((column) => {
                      if (column.selected) {
                        this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                        this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                      }
                    });
                  }
                }
              }
            } catch (e) {
              console.log(e);
            }
            if (store.state.jobData) {
              this.parseSchemaData(store.state.jobData.schemaData);
            }
          });
        })
        .finally(() => {
          this.updateLoading(false);
          this.loading = false;
          setTimeout(() => {
            this.handleGetColumnByTable(showTableList[0], 0);
            this.taskInfo.dbMap.forEach((db) => {
              if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                this.handleGetSelectedColumns(db.sinkDb);
              } else {
                this.handleGetSelectedColumns(db.sourceDb);
              }
            });
            this.selectedColumns = { ...this.selectedColumns };
            this.$forceUpdate();
          });
        });
      this.tableList = this.taskInfo.tableInfo;
      this.filteredTableList = _.cloneDeep(this.tableList);
      this.total = this.filteredTableList.length;
      const showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.showTableList = showTableList;
      // this.table = this.showTableList[0];
      // this.selectedIndex = 0;

      // const that = this;
      //
      // setTimeout(() => {
      //   that.handleGetSelectedColumns(0);
      //   that.selectedColumns = { ...that.selectedColumns };
      // });
    },
    handleMqSchema() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) || DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        let type = 'tableMq';

        if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
          type = 'cache';
        }
        this.mqSchema = this.mqSchemaTemplate;
        this.mqSchema = handleMqSchemaTool(this.mqSchema, this.selectedColumns[this.table.sourceDb][this.table.sourceTable], this.table.sourceDb, this.table.sourceTable, type);
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.mqSchema = this.mqSchemaTemplate;
        this.mqSchema = handleMqSchemaTool(this.mqSchema, this.selectedColumns[this.table.sinkDb][this.table.sinkTable], this.table.sinkDb, this.table.sinkTable, 'mqTable');
      }
    },
    hasTheSameItem(column, columns) {
      let isSame = false;

      if (columns) {
        columns.forEach((item) => {
          if (item.columnName === column) {
            isSame = true;
          }
          return null;
        });
      }
      return isSame;
    },
    hasTheSameColumn(columnName, columns) {
      let isSame = false;

      if (columns) {
        columns.forEach((item) => {
          if (item.columnName === columnName) {
            isSame = true;
          }
          return null;
        });
      }
      return isSame;
    },
    handleFilter(table) {
      this.showFilter = true;
      this.selectedTable = table;
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = _.cloneDeep(item.whereCondition);
        }
        return null;
      });
    },
    updateWhereCondition(data) {
      this.whereCondition = data;
    },
    handleShowVirtualColumn(table) {
      this.showVirtualColumn = true;
      this.selectedTable = table;
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};
      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .forEach((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].forEach((column, i) => {
                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                  if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb]) {
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb][this.selectedColumns[dbName][item][i].sinkTable] = this.selectedColumns[dbName][item];
                  } else {
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb] = {};
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb][this.selectedColumns[dbName][item][i].sinkTable] = this.selectedColumns[dbName][item];
                  }
                } else if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
                return null;
              });
            }
            return null;
          });
      }
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
        this.taskInfo.dbMap.forEach((item) => {
          if (commonSelectEdColumns[item.sinkDb]) {
            item.selectedColumns = commonSelectEdColumns[item.sinkDb];
          }
          return null;
        });
      } else {
        this.taskInfo.dbMap.forEach((item) => {
          if (commonSelectEdColumns[item.sourceDb]) {
            item.selectedColumns = commonSelectEdColumns[item.sourceDb];
          }
          return null;
        });
      }
      this.handleGetColumnByTable(this.table, this.selectedIndex);
    },
    handleSelected(table, index, selection) {
      selection.forEach((item, i) => {
        if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          if (!this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn) {
            let columnName = item.sourceColumn;

            if (this.taskInfo.targetCaseSensitive === 'false') {
              columnName = item.sourceColumn.toLowerCase();
            }
            if (!(table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
              || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(item.sourceColumn), this.taskInfo.sinkColumns[table.sinkDb
                ? table.sinkDb : table.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])))) {
              columnName = '';
            }
            this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn = columnName;
            if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
              this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = this.typeMapping[item.sourceType.split('(')[0].toUpperCase()];
            } else {
              this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = item.sourceType;
            }
            this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = item.sourceIsNullable;
            if (this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable]) {
              this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
                if (sinkColumn.columnName === item.sourceColumn) {
                  this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = sinkColumn.dataType;
                  this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = sinkColumn.isNullable;
                }
                return null;
              });
            }
          }
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index].selected = true;
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index]._checked = true;
        } else {
          this.selectedColumns[table.sinkDb][table.sinkTable][i].selected = true;
          this.selectedColumns[table.sinkDb][table.sinkTable][i]._checked = true;
        }
        return null;
      });
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleSelectCancel(table, index, selection) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          let alreadyHave = false;

          selection.forEach((row) => {
            if (row.sinkColumn === item.sinkColumn) {
              alreadyHave = true;
            }
            return null;
          });
          if (!alreadyHave) {
            item.selected = false;
            this.forceUpdateView();
          }

          this.selectedColumns[table.sinkDb][table.sinkTable].forEach((column) => {
            let alreadyHaveColumn = false;

            selection.forEach((row) => {
              if (row.sinkColumn === column.sinkColumn) {
                alreadyHaveColumn = true;
              }
              return null;
            });
            if (!alreadyHaveColumn) {
              column._checked = false;
            }
            return null;
          });
          return null;
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          let alreadyHave = false;

          selection.forEach((row) => {
            if (row.sourceColumn === item.sourceColumn) {
              alreadyHave = true;
            }
            return null;
          });
          if (!alreadyHave) {
            item.sinkColumn = '';
            item.selected = false;
            this.forceUpdateView();
          }

          this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
            let alreadyHaveColumn = false;

            selection.forEach((row) => {
              if (row.sourceColumn === column.sourceColumn) {
                alreadyHaveColumn = true;
              }
              return null;
            });
            if (!alreadyHaveColumn) {
              column._checked = false;
            }
            return null;
          });
          return null;
        });
      }
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleAllSelected(table) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          item.selected = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
          item._checked = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
          return null;
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          let sinkColumnName = item.sourceColumn;

          if (this.taskInfo.targetCaseSensitive === 'false') {
            sinkColumnName = item.sourceColumn.toLowerCase();
          }
          if (this.typeMapping[item.sourceType.toUpperCase()]
            && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
              || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(item.sourceColumn), this.taskInfo.sinkColumns[table.sinkDb
                ? table.sinkDb : table.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])))) {
            item.sinkColumn = sinkColumnName;
          } else {
            item.sinkColumn = '';
          }
          item.selected = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
          item._checked = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
        });
      }
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleCancelSelectAll(table) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          item.selected = false;
          item._checked = false;
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          item.sinkColumn = '';
          item.selected = false;
          item._checked = false;
        });
      }
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleSinkColumnChange(table, row, column) {
      if (column) {
        if (row.sourceColumn !== column) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
          if (column === 'clougence_cloudcanal_filter_after_mapping_column') {
            row.isIndex = false;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = false;
          } else {
            row.sinkColumn = column;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn = column;
          }
        } else {
          let hasSame = false;

          this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
            if (sinkColumn.columnName === column) {
              hasSame = true;
            }
            return null;
          });
          if (!hasSame) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = true;
            if (column === 'clougence_cloudcanal_filter_after_mapping_column') {
              this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
            }
          }
        }
        this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
          if (sinkColumn.columnName === column) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
            if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
              row.sinkType = sinkColumn.dataType.toUpperCase();
              row.analyzer = sinkColumn.analyzer;
              row.isIndex = sinkColumn.needIndex;
              // eslint-disable-next-line no-param-reassign
              row = { ...row };
            }
          }
          return null;
        });
        this.handleGetSelectedColumns(table.sourceDb);
        this.forceUpdateView();
      }
    },
    rowClassName(row) {
      let className = '';

      if (row._isChecked) {
        className += 'demo-table-info-row';
      }
      if (row.isVirtual) {
        className += ' virtual-column-cell';
      }
      return className;
    },
    forceUpdateView() {
      this.$forceUpdate();
      // this.$set(this.selectedColumns, this.index, this.index);
      // this.index++;
      // this.$set(this.selectedColumns, this.index, this.index);
    },
    updateNextStep() {
      const noColumnList = [];

      this.noPkList = [];
      this.noRedisKey = [];

      if (!this.ignoreNoPk) {
        this.tableList.forEach((table) => {
          let count = 0;
          let
            hasPk = false;

          if (this.selectedColumns[table.sourceDb] && this.selectedColumns[table.sourceDb][table.sourceTable]) {
            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((columns, index) => {
              if (columns.selected) {
                count++;
                if (columns.isPk) {
                  hasPk = true;
                }
              }
              if (index === this.selectedColumns[table.sourceDb][table.sourceTable].length - 1 && count === 0) {
                noColumnList.push(table);
              }
            });
          }
          if ((table.action.includes('UPDATE') || table.action.includes('DELETE')) && !hasPk) {
            this.noPkList.push(table);
          }

          if (!table.suffixFields || table.suffixFields.length === 0) {
            this.noRedisKey.push(table);
          }
        });
        this.taskInfo.selectedColumns = this.selectedColumns;
        if (this.noPkList.length > 0 && (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType))) {
          this.showNoPkList = true;
        }
        if (this.noRedisKey.length > 0 && DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
          this.showNoRedisKey = true;
        }
      }
      return {
        allHasColumn: noColumnList,
        noPkList: this.noPkList,
        noRedisKeyList: this.noRedisKey
      };
    },
    handlePageChange(page) {
      this.page = page;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.table = this.showTableList[0];
      this.selectedIndex = 0;
    },
    handleShowHasColumnPageChange(page) {
      this.showHasColumnTableListPage = page;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    checkColumns(columns, columnList) {
      const noSameColumns = [];
      columns.forEach((item) => {
        let hasSame = false;
        columnList.forEach((column) => {
          if (item === column.sourceColumn) {
            hasSame = true;
          }
        });
        if (!hasSame) {
          noSameColumns.push(item);
        }
      });
      if (noSameColumns.length > 0) {
        this.$Modal.error({
          title: '添加where条件异常提示',
          content: `当前表没有选择${noSameColumns.join(',')}列，请填写准确的where条件`
        });
        return false;
      }
      return true;
    },
    handleAddWhereCondition(table) {
      if (this.whereCondition) {
        validExprFilter({
          dataFilterType: 'SQL_WHERE',
          expression: this.whereCondition
        }).then((res) => {
          if (res.data.code === '1') {
            const columns = res.data.data;
            const ifCorrect = this.checkColumns(columns, this.selectedColumns[table.sourceDb][table.sourceTable]);
            if (ifCorrect) {
              this.showFilter = false;
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
                  item.whereCondition = _.cloneDeep(this.whereCondition);
                }
              });
              this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
                if (item.sourceTable === table.sourceTable) {
                  item.whereCondition = _.cloneDeep(this.whereCondition);
                }
              });
              this.forceUpdateView();
            }
          }
        });
      } else {
        this.showFilter = false;
        this.tableList.forEach((item) => {
          if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
            item.whereCondition = _.cloneDeep(this.whereCondition);
          }
        });
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.whereCondition = _.cloneDeep(this.whereCondition);
          }
        });
        this.forceUpdateView();
      }
    },
    handleAddAllWhereCondition(data) {
      this.whereCondition = data;
      this.showFilterAll = false;
      if (data) {
        this.checkAllGroup.forEach((item) => {
          this.taskInfo.selectedTables[item.split('.')[0]].forEach((table) => {
            if (item.split('.')[1] === table.sourceTable) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
            }
          });
          this.tableList.forEach((table) => {
            if (item.split('.')[1] === table.sourceTable && item.split('.')[0] === table.sourceDb) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
            }
          });
        });
      } else {
        this.checkAllGroup = [];
      }
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
      this.forceUpdateView();
    },
    handleOperateColumns(type, data) {
      if (type === 'cancel') {
        if (data) {
          this.checkAllGroup.forEach((item) => {
            const tableNameList = item.split('.');
            const db = tableNameList[0];
            const table = tableNameList[tableNameList.length - 1];

            this.selectedColumns[db][table].forEach((column) => {
              if (column.sourceColumn === data) {
                column.selected = false;
                column._checked = false;
                column.sinkColumn = '';
              }
              return null;
            });
            this.handleGetSelectedColumns(db);
            const columnsData = _.cloneDeep(this.selectedColumns);

            // this.selectedIndex = -1;
            this.forceUpdateView();

            setTimeout(() => {
              this.selectedColumns = columnsData;
            });
            return null;
          });
        }
      } else if (type === 'select') {
        if (data) {
          this.checkAllGroup.forEach((item) => {
            const tableNameList = item.split('.');
            const db = tableNameList[0];
            const table = tableNameList[tableNameList.length - 1];

            let targetDb = db;

            this.selectedColumns[db][table].forEach((column) => {
              if (column.sourceColumn === data && !column.selected) {
                column.selected = true;
                column._checked = true;
                column.sinkColumn = this.getTargetValue(column.sourceColumn);
                this.taskInfo.dbMap.forEach((item1) => {
                  if (item1.sourceDb === db) {
                    targetDb = item1.sinkDb;
                  }
                  return null;
                });
                column.needAutoCreated = !this.hasTheSameItem(this.getTargetValue(column.sourceColumn), this.taskInfo.sinkColumns[targetDb][table]);
              }
              return null;
            });
            this.handleGetSelectedColumns(db);
            const columnsData = _.cloneDeep(this.selectedColumns);

            // this.selectedIndex = -1;
            this.forceUpdateView();

            setTimeout(() => {
              this.selectedColumns = columnsData;
            });
            return null;
          });
        }
      }

      this.showOperateColumns = false;
    },
    handleAddMqPartition(table) {
      this.showMqPartition = false;
      if (this.mqPartition.count > 32 || this.mqPartition.count < 1) {
        this.$Modal.warning({
          title: '分区数设置有误',
          content: '分区数支持范围为1~32，请设置正确的分区数。'
        });
      } else {
        this.tableList.forEach((item) => {
          if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.filteredTableList = _.cloneDeep(this.tableList);
        this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      }
    },
    handleAddPartitionAll() {
      this.showPartitionAll = false;
      this.taskInfo.partitionData = _.cloneDeep(this.partitionData);
      this.partitionData = {
        partition: [
          {
            key: this.HivePartitionFuncs[0].partitionKey,
            func: this.HivePartitionFuncs[0].function
          }
        ]
      };
      this.partitionCheckAllGroup.forEach((item) => {
        this.taskInfo.selectedTables[item.split('.')[0]].forEach((table) => {
          if (item.split('.')[1] === table.sourceTable) {
            table.partitionData = _.cloneDeep(this.taskInfo.partitionData);
          }
        });
      });
      this.taskInfo.dbMap.forEach((item) => {
        if (this.taskInfo.selectedTables[item.sourceDb]) {
          item.selectedTables = _.cloneDeep(this.taskInfo.selectedTables[item.sourceDb]);
        }
      });
    },
    handleCheckAll() {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = [];
        this.hasColumnTableList.forEach((table) => {
          this.checkAllGroup.push(`${table.db}.${table.sourceTable}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    handleCheckAllBatch() {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = [];
        this.hasColumnTableList.forEach((table) => {
          this.checkAllGroup.push(`${table.dbName}.${table.tableName}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.hasColumnTableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    partitionCheckAllGroupChange(data) {
      if (data.length === this.tableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    handleShowFilterAll() {
      this.showFilterAll = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
    },
    handleShowOperateColumns() {
      this.showOperateColumns = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
    },
    handleShowPartitionAll() {
      this.showPartitionAll = true;
      this.tableList.forEach((table) => {
        this.partitionCheckAllGroup.push(`${table.sourceDb}.${table.sourceTable}`);
        return null;
      });
      let func = '';
      if (!this.taskInfo.partitionData.partition.func) {
        func = this.partitionData.partition[0].func;
      }
      this.partitionData = _.cloneDeep(this.taskInfo.partitionData);
      if (func) {
        this.partitionData.partition[0].func = func;
        this.HivePartitionFuncs.forEach((item) => {
          if (item.function === func) {
            this.partitionData.partition[0].key = item.partitionKey;
          }
        });
      }
    },
    handleFindTable(data) {
      if (data) {
        validExprFilter({
          dataFilterType: 'SQL_WHERE',
          expression: data
        }).then((res) => {
          if (res.data.code === '1') {
            const columnList = res.data.data;
            this.hasColumnTableList = [];
            Object.keys(this.selectedColumns)
              .forEach((db) => {
                Object.keys(this.selectedColumns[db])
                  .forEach((table) => {
                    let hasSame = true;

                    columnList.forEach((c) => {
                      let sameColumn = false;

                      this.selectedColumns[db][table].forEach((column) => {
                        if (column.sourceColumn === c) {
                          sameColumn = true;
                        }
                      });
                      if (!sameColumn) {
                        hasSame = false;
                      }
                    });
                    if (hasSame) {
                      this.hasColumnTableList.push({
                        db,
                        sourceTable: table
                      });
                    }
                  });
              });
            this.showHasColumnTableListPage = 1;
            this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
              this.showHasColumnTableListPage * this.showHasColumnTableListSize);
          }
        });
      }
    },
    handleColumnChange() {
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
    },
    getWhereCondition(tb) {
      let hasWhereCondition = false;

      if (this.taskInfo.selectedTables[tb.sourceDb]) {
        this.taskInfo.selectedTables[tb.sourceDb].forEach((table) => {
          if (tb.sourceTable === table.sourceTable) {
            if (table.whereConditionFilter && table.whereConditionFilter.column) {
              hasWhereCondition = true;
            }
          }
          return null;
        });
      }
      return hasWhereCondition;
    },
    clearWhereCondition(table) {
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = '';
          item.whereCondition = _.cloneDeep(this.whereCondition);
        }
        return null;
      });
    },
    handleTableFilter(filterType, type) {
      if (this.filterTable.tableFilterType && filterType === 'option') {
        if (this.filterList.indexOf(type) === -1) {
          this.filterList.push(type);
        }
      }
      this.filteredTableList = [];
      this.filteredTableList = this.tableList.filter((item) => (this.filterList.indexOf('hasWhere') > -1
        ? Boolean(item.whereConditionFilter && item.whereConditionFilter.column) : true)
        && (this.filterList.indexOf('noWhere') > -1 ? !item.whereConditionFilter || !item.whereConditionFilter.column : true)
        && (this.filterTable.filterTableName ? item.sourceTable.toLowerCase().indexOf(this.filterTable.filterTableName.toLowerCase()) > -1 : true));
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.table = this.showTableList[0];
      this.selectedIndex = 0;
    },
    handleRefresh() {
      // this.loading = true;

      if (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType)) {
        this.getUniqList(this.init);
      } else {
        this.init();
      }
    },
    handleGetColumnByTable(table, index) {
      this.table = table;
      this.selectedIndex = index;
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sinkType) > -1 || DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1
        || DataSourceGroup.redis.indexOf(this.taskInfo.sinkType) > -1) {
        this.handleMqSchema();
      }

      if (this.taskInfo.virtualColumnData[`${table.sourceDb}/${table.sourceTable}`]) {
        let
          totalHeight = window.innerHeight;

        if (totalHeight < 720) {
          totalHeight = 720;
        }
        this.tableHeight = totalHeight - 570;
      }
    },
    handleDeleteFilter(i) {
      this.filterList.splice(i, 1);
      this.filterTable.tableFilterType = '';
      this.handleTableFilter('filter');
    },
    handleClearFilter() {
      this.filterList = [];
      this.filterTable.tableFilterType = '';
      this.handleTableFilter('filter');
    },
    handleCancel() {
      this.showFilter = false;
      this.showFilterAll = false;
      this.showPartition = false;
      this.showPartitionAll = false;
      this.showMqPartition = false;
      this.showDrdsPartition = false;
      this.showVirtualColumn = false;
      this.showSetId = false;
      this.showChangePk = false;
      this.showChangePkBatch = false;
      this.selectedTargetKeys = [];
      this.uqIndex++;
      this.showNoPkList = false;
      this.showNoUkList = false;
      this.showOperateColumns = false;
      this.showRedisKey = false;
      this.showBatchSetRedisKey = false;
      this.showNoRedisKey = false;
      if (DataSourceGroup.hive.includes(this.taskInfo.sinkType)) {
        this.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
    },
    handleNext() {
      this.ignoreNoPk = true;
      this.handleStep('next');
    },
    handleSetPartition(row) {
      this.selectedTable = row;
      this.mqPartition.count = row.partitions;
      this.mqPartition.key = row.partitionKeys;
      this.showMqPartition = true;
    },
    handleChangeIndexType(row, index, table, data) {
      if (data === 'KEYWORD' || data === 'NESTED') {
        row.analyzer = null;
        this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = null;
      } else {
        row.analyzer = 'STANDARD';
        this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = 'STANDARD';
      }
      this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = data;
      this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
        if (sinkColumn.columnName === row) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
          if (this.taskInfo.sinkType === 'ElasticSearch') {
            row.sinkType = sinkColumn.dataType;
            row.analyzer = sinkColumn.analyzer;
            row.isIndex = sinkColumn.needIndex;
            // eslint-disable-next-line no-param-reassign
            row = { ...row };
          }
        }
        return null;
      });
    },
    handleChangeAnalyzer(row, index, table, data) {
      this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = data;
      this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
        if (sinkColumn.columnName === row) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
          if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
            row.sinkType = sinkColumn.dataType.toUpperCase();
            row.analyzer = sinkColumn.analyzer;
            row.isIndex = sinkColumn.needIndex;
            // eslint-disable-next-line no-param-reassign
            row = { ...row };
          }
        }
        return null;
      });
    },
    handleSetDrdsPartition(row) {
      this.selectedTable = row;
      this.showDrdsPartition = true;
      this.drdsPartition.dbpartition = row.dbpartition;
      this.drdsPartition.dbFuncs = row.dbFuncs ? row.dbFuncs : 'HASH';
      this.drdsPartition.tbpartition = row.tbpartition;
      this.drdsPartition.tbFuncs = row.tbFuncs;
      this.drdsPartition.tbpartitions = row.tbpartitions;
    },
    handleAddDrdsPartitions(table) {
      this.showDrdsPartition = false;
      this.tableList.forEach((item) => {
        if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
          item.dbpartition = this.drdsPartition.dbpartition instanceof Array
            ? this.drdsPartition.dbpartition : this.drdsPartition.dbpartition
              ? [].concat(this.drdsPartition.dbpartition) : [];
          item.dbFuncs = this.drdsPartition.dbFuncs;
          item.tbpartition = this.drdsPartition.tbpartition instanceof Array
            ? this.drdsPartition.tbpartition : this.drdsPartition.tbpartition
              ? [].concat(this.drdsPartition.tbpartition) : [];
          item.tbFuncs = this.drdsPartition.tbFuncs;
          item.tbpartitions = this.drdsPartition.tbpartitions;
        }
        return null;
      });
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.dbpartition = this.drdsPartition.dbpartition instanceof Array
            ? this.drdsPartition.dbpartition : this.drdsPartition.dbpartition
              ? [].concat(this.drdsPartition.dbpartition) : [];
          item.dbFuncs = this.drdsPartition.dbFuncs;
          item.tbpartition = this.drdsPartition.tbpartition instanceof Array
            ? this.drdsPartition.tbpartition : this.drdsPartition.tbpartition
              ? [].concat(this.drdsPartition.tbpartition) : [];
          item.tbFuncs = this.drdsPartition.tbFuncs;
          item.tbpartitions = this.drdsPartition.tbpartitions;
        }
        return null;
      });
      this.filteredTableList = _.cloneDeep(this.tableList);
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    },
    handleVirtualColumn(table, columns) {
      this.showVirtualColumn = false;
      const fieldPathName = `/${table.sourceDb}/${table.sourceTable}`;

      let hasSame = false;
      let currentItem = {};
      let hasCustom = false;
      let hasSameConsole = false;
      let currentConsoleItem = {};
      const fieldList = [];
      const customFieldList = [];
      let
        totalHeight = window.innerHeight;

      if (totalHeight < 720) {
        totalHeight = 720;
      }
      this.tableHeight = totalHeight - 570;

      this.taskInfo.virtualColumns.forEach((item) => {
        if (item.fieldPathName === fieldPathName) {
          hasSame = true;
          currentItem = item;
        }
        return null;
      });
      this.taskInfo.customVirtualColumns.forEach((item) => {
        if (item.fieldPathName === fieldPathName) {
          hasSameConsole = true;
          currentConsoleItem = item;
        }
        return null;
      });

      columns.forEach((item) => {
        if (item.isCustom) {
          hasCustom = true;
          customFieldList.push({
            fieldName: item.customName.trim(),
            typeName: item.targetVirtualType,
            fieldValue: item.columnValue,
            sqlType: item.sqlType,
            length: item.columnLength,
            needAutoCreate: hasCustom && !hasSameConsole
          });
        }
        fieldList.push({
          fieldName: item.isCustom ? item.customName.trim() : item.targetVirtualName.trim(),
          typeName: item.targetVirtualType,
          fieldValue: item.columnValue,
          sqlType: item.sqlType,
          length: item.columnLength,
          needAutoCreate: hasCustom && !hasSameConsole
        });
        return null;
      });
      if (!hasSame) {
        this.taskInfo.virtualColumns.push({
          fieldPathName,
          customFieldList: fieldList
        });
      } else {
        currentItem.customFieldList = fieldList;
      }
      if (hasCustom) {
        if (!hasSameConsole) {
          this.taskInfo.customVirtualColumns.push({
            fieldPathName,
            customFieldList,
            needAutoCreated: true
          });
        } else {
          currentConsoleItem.customFieldList = customFieldList;
        }
      }
      this.taskInfo.virtualColumnData[fieldPathName] = columns;
    },
    handleDeleteColumn(row, index) {
      this.selectedColumns[row.sourceDb][row.sourceTable].splice(index, 1);
    },
    handleDeleteVirtual(index, table) {
      const fieldPath = `/${table.sourceDb}/${table.sourceTable}`;

      this.taskInfo.virtualColumnData[fieldPath].splice(index, 1);
      if (this.taskInfo.virtualColumnData[fieldPath].length < 1) {
        let totalHeight = window.innerHeight;

        if (totalHeight < 720) {
          totalHeight = 720;
        }
        this.tableHeight = totalHeight - 370;
      }
      this.taskInfo.virtualColumns.forEach((item) => {
        if (item.fieldPathName === fieldPath) {
          item.customFieldList.splice(index, 1);
        }
        return null;
      });
    },
    handleCopyMqSchema(data) {
      handleCopy(data);
      this.$Message.success('复制成功！');
    },
    handleChangePk(table) {
      this.currentColumnList = this.selectedColumns[table.sourceDb][table.sourceTable];
      const pks = [];
      this.currentColumnList.forEach((column) => {
        if (column.isPk) {
          const columnName = column.columnName || column.sourceColumn;
          pks.push(columnName);
        }
      });
      this.selectedTargetKeys = pks;
      this.showChangePk = true;
      this.selectedTable = table;
    },
    handleSetId(table) {
      this.currentColumnList = this.selectedColumns[table.sourceDb][table.sourceTable];
      const selectedIds = [];
      this.currentColumnList.forEach((column) => {
        if (column.isId) {
          const columnName = column.columnName || column.sourceColumn;
          selectedIds.push(columnName);
        }
      });
      this.selectedIds = selectedIds;
      this.showSetId = true;
      this.selectedTable = table;
    },
    handleConfirmSetIds(table, ids) {
      this.showFilter = false;
      const cols = ids.join(',')
        .split(',');

      if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
          if (cols.includes(column.sourceColumn)) {
            column.isId = true;
          } else {
            column.isId = false;
          }
          return null;
        });
      }
      const columnsData = _.cloneDeep(this.selectedColumns);
      const index = this.selectedIndex;

      this.selectedIndex = -1;

      this.updatePkAndIdFlag(table, 'id');
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);

      this.uqIndex++;
    },
    updatePkAndIdFlag(table, type) {
      const key = `${table.sourceDb}.${table.sourceTable}`;
      const pkAndIdFlag = this.setPkAndIdFlags[key] || {};

      pkAndIdFlag[type] = true;
      this.setPkAndIdFlags[key] = pkAndIdFlag;

      // this.$set(this.setPkAndIdFlags, key, pkAndIdFlag);
      this.taskInfo.setPkAndIdFlags = this.setPkAndIdFlags;
    },
    handleConfirmChangePk(table, selectedTargetKeys) {
      this.showFilter = false;
      const cols = selectedTargetKeys.join(',')
        .split(',');
      this.tableList.forEach((item) => {
        if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
          item.cols = _.cloneDeep(cols);
        }
        return null;
      });
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.cols = _.cloneDeep(cols);
        }
        return null;
      });

      if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
          if (cols.includes(column.sourceColumn)) {
            column.isPk = true;
          } else {
            column.isPk = false;
          }
          return null;
        });
      }
      const columnsData = _.cloneDeep(this.selectedColumns);
      const
        index = this.selectedIndex;

      this.selectedIndex = -1;
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);

      this.uqIndex++;
    },
    handleConfirmChangePkBatch() {
      if (this.currentCols) {
        const cols = this.currentCols.join(',')
          .split(',');

        this.checkAllGroup.forEach((check) => {
          const db = check.split('.')[0];
          const
            table = check.split('.')[1];

          this.tableList.forEach((item) => {
            if (item.sourceTable === table && item.sourceDb === db) {
              item.cols = cols;
            }
            return null;
          });
          this.taskInfo.selectedTables[db].forEach((item) => {
            if (item.sourceTable === table) {
              item.cols = cols;
            }
            return null;
          });

          if (this.selectedColumns[db][table]) {
            this.selectedColumns[db][table].forEach((column) => {
              if (cols
                .includes(column.sourceColumn)) {
                column.isPk = true;
              } else {
                column.isPk = false;
              }
              return null;
            });
          }
          return null;
        });
        const columnsData = _.cloneDeep(this.selectedColumns);
        const
          index = this.selectedIndex;

        this.selectedIndex = -1;
        this.forceUpdateView();

        setTimeout(() => {
          this.selectedColumns = columnsData;
          this.selectedIndex = index;
        }, 200);
      }
    },
    handleSelectUniq(data) {
      this.selectedTargetKeys = data;
    },
    handleSetIds(data) {
      this.selectedIds = data;
    },
    handleConfirmPk(type) {
      this.showChangePk = false;
      this.showChangePkBatch = false;
      if (type === 'batch') {
        this.handleConfirmChangePkBatch(this.selectedTable);
      } else {
        this.handleConfirmChangePk(this.selectedTable, this.selectedTargetKeys);
      }
      this.selectedTargetKeys = [];
    },
    handleConfirmIds() {
      this.showSetId = false;
      this.handleConfirmSetIds(this.selectedTable, this.selectedIds);
      this.selectedIds = [];
    },
    handleSetTargetKey() {
      this.hasColumnTableList = [];

      this.showChangePkBatch = true;
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
    },
    handleFindTableByUq() {
      this.hasColumnTableList = [];

      if (this.currentCols) {
        const columnList = this.currentCols.join(',')
          .split(',');

        Object.keys(this.selectedColumns)
          .forEach((db) => {
            Object.keys(this.selectedColumns[db])
              .forEach((table) => {
                let hasSame = true;

                columnList.forEach((c) => {
                  let sameColumn = false;

                  this.selectedColumns[db][table].forEach((column) => {
                    if (column.sourceColumn === c) {
                      sameColumn = true;
                    }
                  });
                  if (!sameColumn) {
                    hasSame = false;
                  }
                });
                if (hasSame) {
                  this.hasColumnTableList.push({
                    dbName: db,
                    tableName: table
                  });
                }
              });
          });
      }
      this.showHasColumnTableListPage = 1;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleSetPk() {
      this.noUkList = [];

      this.noPkList.forEach((table) => {
        const key = {};

        key.dbName = table.sourceDb;
        key.tableSchema = null;
        key.tableName = table.sourceTable;
        const uqInfos = this.uniqList[JSON.stringify(key)];

        if (uqInfos && uqInfos.length > 0) {
          const uqInfo = uqInfos[0];

          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
            if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
              this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                if (uqInfo.cols.includes(column.sourceColumn)) {
                  column.isPk = true;
                } else {
                  column.isPk = false;
                }
                return null;
              });
            }
          }
        } else {
          this.noUkList.push(table);
        }
        return null;
      });
      const columnsData = _.cloneDeep(this.selectedColumns);
      const
        index = this.selectedIndex;

      this.selectedIndex = -1;
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);
      if (this.noUkList.length > 0) {
        this.showNoPkList = false;
        this.showNoUkList = true;
      } else {
        this.handleStep('next');
      }
    },
    handleSetRedisKey(table) {
      this.selectedTable = table;
      this.showRedisKey = true;
    },
    handleConfirmSetRedisKey(table, suffixFields) {
      this.showFilter = false;

      this.tableList.forEach((item) => {
        if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
          item.suffixFields = _.cloneDeep(suffixFields);
        }
      });
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.suffixFields = _.cloneDeep(suffixFields);
        }
      });

      const index = this.selectedIndex;

      this.handleTableFilter('filter');

      this.table = this.showTableList[index];
      this.selectedIndex = index;

      this.uqIndex++;

      this.showRedisKey = false;
    },
    handleConfirmBatchSetRedisKey(suffixFields) {
      const list = [];

      list.push(suffixFields);

      this.checkAllGroup.forEach((check) => {
        const db = check.split('.')[0];
        const table = check.split('.')[1];

        this.tableList.forEach((item) => {
          if (item.sourceTable === table && item.sourceDb === db) {
            item.suffixFields = list;
          }
        });
        this.taskInfo.selectedTables[db].forEach((item) => {
          if (item.sourceTable === table) {
            item.suffixFields = list;
          }
        });
      });

      this.filteredTableList = _.cloneDeep(this.tableList);
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);

      this.forceUpdateView();

      this.uqIndex++;

      this.showBatchSetRedisKey = false;
    },
    handleBatchSetRedisKey() {
      this.showBatchSetRedisKey = true;
    },
    getEsAnalyzerTypes() {
      getEsAnalyzerTypes()
        .then((res) => {
          if (res.data.code === '1') {
            this.esTypeList = res.data.data;
          }
        });
    },
    parseSchemaData(data) {
      if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
        console.log(1234);
      } else {
        const sourceSchemaJson = JSON.parse(data.sourceSchema); const
          mappingJson = JSON.parse(data.mappingConfig);
        const targetSchemaJson = data.targetSchema ? JSON.parse(data.targetSchema) : {};
        console.log('targetschema', targetSchemaJson);
        let columnMapping = {};
        mappingJson.forEach((mapping) => {
          if (mapping.method === 'COLUMN_COLUMN') {
            columnMapping = mapping.serializeMapping;
          }
        });
        if (DataSourceGroup.hive.includes(this.taskInfo.sinkType) && targetSchemaJson.length > 0) {
          console.log('target', targetSchemaJson[0], targetSchemaJson[0].tables[0]);
          const tableInfo = targetSchemaJson[0].tables[0];
          this.taskInfo.partitionData = {
            partition: [
              {
                key: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].keyName : '',
                func: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].partitionFunction : ''
              }
            ],
            fileFormat: tableInfo.fileFormat,
            compressType: tableInfo.compressType
          };
          this.partitionData = {
            partition: [
              {
                key: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].keyName : '',
                func: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].partitionFunction : ''
              }
            ],
            fileFormat: tableInfo.fileFormat,
            compressType: tableInfo.compressType
          };
        }
        sourceSchemaJson.forEach((db) => {
          let tables = [];
          if (db.schemas) {
            tables = db.schemas[0].tables;
          } else if (db.tableSpaces) {
            tables = db.tableSpaces[0].tables;
          } else {
            tables = db.tables;
          }
          tables.forEach((table) => {
            if (table.dataFilter) {
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.whereCondition = table.dataFilter.expression;
                }
              });
              this.taskInfo.selectedTables[db.db].forEach((item) => {
                if (item.sourceTable === table.table) {
                  item.whereCondition = table.dataFilter.expression;
                }
              });
            }
            console.log('table', table, table.partitionKeys);
            if (DataSourceGroup.hive.includes(this.taskInfo.sinkType)) {
              console.log(121212);
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.partitionData = _.cloneDeep(this.taskInfo.partitionData);
                }
              });
              this.taskInfo.selectedTables[db.db].forEach((item) => {
                if (item.sourceTable === table.table) {
                  item.partitionData = _.cloneDeep(this.taskInfo.partitionData);
                }
              });
              console.log('this.taskInfo.selectedTables', this.taskInfo.selectedTables);
            }
            if (table.specifiedPks) {
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.cols = _.cloneDeep(table.specifiedPks);
                }
                return null;
              });
              this.taskInfo.selectedTables[db.db].forEach((item) => {
                if (item.sourceTable === table.table) {
                  item.cols = _.cloneDeep(table.specifiedPks);
                }
                return null;
              });
            }
            if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
              targetSchemaJson.forEach((target) => {
                if (target.prefix === table.table) {
                  this.tableList.forEach((item) => {
                    if (item.sourceTable === table.table && item.sourceDb === db.db) {
                      item.suffixFields = target.suffixFields;
                    }
                  });
                  this.taskInfo.selectedTables[db.db].forEach((item) => {
                    if (item.sourceTable === table.table) {
                      item.suffixFields = target.suffixFields;
                    }
                  });
                }
              });
              this.filteredTableList = _.cloneDeep(this.tableList);
              this.total = this.filteredTableList.length;
              this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
            }
            if (this.selectedColumns[db.db][table.table]) {
              this.selectedColumns[db.db][table.table].forEach(((column) => {
                table.columns.forEach((theColumn) => {
                  if (theColumn.column === column.sourceColumn) {
                    if (theColumn.inBlackList) {
                      column.selected = false;
                      column._checked = false;
                      column.sinkColumn = '';
                      if (table.specifiedPks.length > 0) {
                        if (table.specifiedPks.includes(theColumn.column)) {
                          column.isPk = true;
                        } else {
                          column.isPk = false;
                        }
                      }
                    } else {
                      column.sinkColumn = this.getTargetColumn(column, columnMapping, db);
                      if (table.specifiedPks.length > 0) {
                        if (table.specifiedPks.includes(theColumn.column)) {
                          column.isPk = true;
                        } else {
                          column.isPk = false;
                        }
                      }
                    }
                  }
                });
              }));
            }
          });
        });
        this.taskInfo.dbMap.forEach((item) => {
          if (this.taskInfo.selectedTables[item.sourceDb]) {
            item.selectedTables = _.cloneDeep(this.taskInfo.selectedTables[item.sourceDb]);
          }
        });
        console.log('taskinfo', this.taskInfo.dbMap, this.taskInfo.selectedTables);
      }
    },
    getTargetColumn(column, mapping, db) {
      let targetColumn = column.sourceColumn;
      Object.keys(mapping).forEach((theMapping) => {
        try {
          let theKey = {
            parent: {
              value: column.sourceTable,
              parent: {
                value: column.sourceDb
              }
            },
            value: column.sourceColumn
          };
          if (db.schemas || db.tableSpaces) {
            theKey = {
              parent: {
                value: column.sourceTable,
                parent: {
                  value: column.sourceSchema,
                  parent: {
                    value: column.sourceDb
                  }
                }
              },
              value: column.sourceColumn
            };
          }
          if (theMapping === JSON.stringify(theKey)) {
            const theValue = JSON.parse(mapping[theMapping]);
            targetColumn = theValue.value;
          }
        } catch (e) {
          console.log('e', e);
        }
      });
      return targetColumn;
    }
  },
  watch: {
    HivePartitionFuncs(val) {
      if (val && val.length > 0) {
        this.taskInfo.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
    }
  }
};
</script>
<style lang="less" scoped>
.clean-data-body {
  overflow: auto;
}

.clean-data-table-info {
  font-size: 16px;
  color: #0BB9F8;
  margin-right: 10px;
}

.clean-data-table-arrow {
  margin: 0 3px;
}

.ivu-modal-confirm-body > div {
  word-break: break-word;
}

.has-where-table {
  color: #FF6E0D;
}

.clean-data-page {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 58px;
  vertical-align: middle;
  background-color: #FAFAFA;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;;
  text-align: center;
}

.table-filter-nav-column {
  width: 400px;
  border-right: 1px solid #DADADA;
  border-top: 1px solid #DADADA;
}

.column-filter-container {
  margin-left: 400px;
  position: relative;
  border-top: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
  background: #FAFAFA;

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .mq-schema-container {
    width: 470px;
    position: absolute;
    right: 0;
    top: 0;
    //float: right;
    border-left: 1px solid #DADADA;
    margin-left: 10px;
    background: #ffffff;
  }
}

.mq-schema-left-container {
  width: 470px;
  float: left;
  border-right: 1px solid #DADADA;
  margin-right: 10px;
  background: #ffffff;
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}
</style>
