<template>
  <div>
    <div>
      <Row :gutter="16">
        <Col span="24">
          <div style="margin-top: 10px">
            <Row>
              <Col span="6"><span
                style="font-weight: 500;margin-right: 5px">CPU逻辑核数:</span>{{ row.logicalCoreNum }}
              </Col>
              <Col span="6"><span
                style="font-weight: 500;margin-right: 5px">物理磁盘容量:</span>{{ row.physicDiskGb }} GB
              </Col>
              <Col span="6"><span
                style="font-weight: 500;margin-right: 5px">物理内存容量:</span>{{ row.physicMemMb }} MB
              </Col>
            </Row>
            <p style="margin-top: 10px">
              <Col span="6"><span
                style="font-weight: 500;margin-right: 5px">空闲内存:</span>{{ row.freeMemMb }} MB
              </Col>
              <Col span="6"><span
                style="font-weight: 500;margin-right: 5px">空闲磁盘:</span>{{ row.freeDiskGb }} GB
              </Col>
            </p>
          </div>
        </Col>
      </Row>
      <div v-if="row.taskScheduleVOs">
        <Divider/>
        <div style="margin-top: 20px">
          <p style="margin-bottom: 10px;font-size: 13px;font-weight: 500">运行在该节点上的task列表：</p>
          <Table :row-class-name="rowClassName" size="small" border :columns="taskColumn"
                 :data="taskData"></Table>
        </div>
      </div>
    </div>
    <Modal
      v-model="showDispatch"
      title="重新调度"
      @on-ok="handleDispatch">
      <p style="margin-bottom: 10px">
        需要把{{ selectedRow.taskName }}从{{ selectedRow.workerIp }}调度到：</p>
      <Select v-model="workerToDispatch" style="width:200px">
        <Option :disabled="item.workerIp===selectedRow.workerIp" v-for="item in getWorkerList(selectedRow)" :value="item.id" :key="item.workerIp">
          {{ item.workerIp }}
        </Option>
      </Select>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import { manualSchedule } from '@services/api/job';

export default {
  props: {
    row: Object,
    index: Number,
    workerList: Array,
    getWorkList: Function,
    taskId: Number
  },
  mounted() {
    this.taskData = this.row.taskScheduleVOs;
  },
  data() {
    return {
      showDispatch: false,
      selectedRow: {},
      workerToDispatch: '',
      taskColumn: [
        {
          title: 'task名称',
          key: 'taskName'
        },
        {
          title: '任务名称',
          key: 'jobName',
          render: (h, params) => h('a', {
            on: {
              click: () => {
                this.$router.push({ path: `/data/job/${params.row.jobId}` });
              }
            }
          }, params.row.jobName)
        },
        {
          title: '任务描述',
          key: 'jobDesc'
        },
        {
          title: '规格',
          key: 'jvmHeapMb',
          render: (h, params) => h('div', {}, `${params.row.jvmHeapMb / 1024}G`)
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '操作',
          width: 120,
          render: (h, params) => h('div', [
            h('pd-button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              on: {
                click: () => {
                  this.showDispatch = true;
                  this.selectedRow = params.row;
                }
              }
            }, '重新调度')
          ])
        }
      ],
      taskData: []
    };
  },
  computed: {
    getWorkerList() {
      return (selectedRow) => {
        const list = [];

        this.workerList.map((item) => {
          if (item.workerIp !== selectedRow.workerIp && item.healthLevel === 'Health') {
            list.push(item);
          }
          return null;
        });
        return list;
      };
    }
  },
  methods: {
    handleDispatch() {
      const data = {
        oldWorkerId: this.selectedRow.workerId,
        targetWorkerId: this.workerToDispatch,
        dataTaskId: this.selectedRow.taskId
      };

      manualSchedule(data)
        .then((res) => {
          if (res.data.code === '1') {
            this.getWorkList();
            // console.log(this.taskData,this.row,this.index);
            // let that = this;
            // setTimeout(()=>{
            //     console.log(that.taskData,this.row,this.index);
            // },1000);
          }
        });
    },
    rowClassName(row) {
      if (this.taskId) {
        if (row.taskId.toString() === this.taskId.toString()) {
          return 'current-task-row';
        }
        return '';
      }
      return '';
    }
  }
};
</script>
<style>
.ivu-table .current-task-row td {
  background-color: rgba(45, 183, 245, 0.1);

}
</style>
