import { instance } from '../instance';

export const queryMonitorData = (param) => instance.post('monitor/querymetric', param);

export const getWorkerCount = (param) => instance.post('monitor/worker/count', param);

export const getTaskCount = (param) => instance.post('monitor/task/count', param);

export const getDataJobMonitor = (param) => instance.post('monitor/task/monitor', param);

export const queryWorkerMonitorConfig = (param) => instance.post('monitor_config/queryworkermonitorconfig', param);

export const queryTaskMonitorConfig = (param) => instance.post('monitor_config/querytaskmonitorconfig', param);

export const queryTaskDetailMonitorConfig = (param) => instance.post('monitor_config/querytaskdetailmonitorconfig', param);
