<template>
  <div>
    <p class="task-info-title">任务信息</p>
    <div>
      <p class="task-info-item-container">
                    <span class="task-info-item">
                        <span class="task-info-item-title">
                            迁移类型
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.type === 'MIGRATION' ? '数据迁移' : (taskInfo.type === 'SYNC' ? '数据同步' : taskInfo.type === 'CHECK' ? '数据校验' : '结构迁移') }}
                        </span>
                    </span>
        <span class="task-info-item"
              v-if="!(taskInfo.fullPeriod&&
                      (taskInfo.fullPeriodDate.time||taskInfo.fullPeriodDate.hour||taskInfo.fullPeriodDate.day)
                      &&taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize)">
                        <span class="task-info-item-title">
                            {{ taskInfo.type === 'MIGRATION' ? '开启增量迁移' : '全量数据初始化' }}
                        </span>
                        <span class="task-info-item-value">
                            {{ (taskInfo.type === 'MIGRATION' ? taskInfo.mode.synchronize : taskInfo.mode.init) ? '是' : '否' }} <span
                          style="margin-left: 5px"
                          v-if="taskInfo.type==='MIGRATION'&&taskInfo.mode.synchronize">（开启<span
                          style="margin: 0 5px">{{ taskInfo.mode.shortTermNum }}</span>天）
                        </span></span>
                    </span>
        <span class="task-info-item"
              v-if="taskInfo.fullPeriod&&
                      (taskInfo.fullPeriodDate.time||taskInfo.fullPeriodDate.hour||taskInfo.fullPeriodDate.day)&&
                      taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize">
                        <span class="task-info-item-title">
                            数据校验
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.checkMode === 'checkPeriod' ? '周期性数据校验' : taskInfo.checkMode === 'checkOnce' ? '一次性数据校验' : '不校验' }}
                            <span
                              v-if="taskInfo.checkMode==='checkPeriod'&&taskInfo.checkPeriodDate"
                              style="margin-left: 10px">{{ getExpression('checkPeriodDate') }}</span>
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            数据校验
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.checkMode === 'checkPeriod' ? '周期性数据校验' : taskInfo.checkMode === 'checkOnce' ? '一次性数据校验' : '不校验' }}
                            <span
                              v-if="taskInfo.checkMode==='checkPeriod'&&taskInfo.checkPeriodDate"
                              style="margin-left: 10px">{{ getExpression('checkPeriodDate') }}</span>
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            任务规格
                        </span>
                        <span class="task-info-item-value">
                          全量{{ taskInfo.spec.fullMemoryMb / 1024 }}G，增量{{ taskInfo.spec.increMemoryMb / 1024 }}G，校验{{ taskInfo.spec.checkMemoryMb / 1024 }}G
                          <!--                            {{taskInfo.fullJVMHeapMB/1024}}G-->
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            DDL同步策略
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.ddl === 'false' ? '全部同步' : '过滤' }}
                        </span>
                    </span>
      </p>
      <p class="task-info-item-container">
                    <span class="task-info-item task-info-item-auto">
                        <span class="task-info-item-title">
                            迁移汇总
                        </span>
                        <span class="task-info-item-value">
                            总共选择了{{ taskInfo.dbMap.length }}个库，{{ getTableCount() }}张表。<span
                          style="font-weight: bolder">新增表不自动同步。</span>
                            <span v-if="structMigration">其中存在结构迁移。</span>
                            <span v-if="structMigration" style="margin-left: 5px;color: #808695">注：表结构迁移排序规则采用字符集默认关联的collation</span>
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            是否自动启动
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.autoStart?'是':'否' }}
                        </span>
                    </span>
        <span class="task-info-item task-info-item-all" style="border-top: 1px solid #DADADA" v-if="taskInfo.customPkgFile">
                        <span class="task-info-item-title">
                            代码包名称
                        </span>
                        <span class="task-info-item-value">
                            {{taskInfo.customPkgFile.name}}
                        </span>
                    </span>
      </p>
      <p class="task-info-item-container" v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            时区
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.globalTimeZone }}
                        </span>
                    </span>
      </p>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    taskInfo: Object,
    structMigration: Boolean
  },
  data() {
    return {
      DataSourceGroup
    };
  },
  computed: {
    getTableCount() {
      return () => {
        let count = 0;

        this.taskInfo.dbMap.forEach((item) => {
          if (item.selectedTables) {
            item.selectedTables.forEach((table) => {
              if (table.selected) {
                count++;
              }
            });
          }
        });
        return count;
      };
    },
    getExpression() {
      return (type) => {
        const weekMap = {
          1: '日',
          2: '一',
          3: '二',
          4: '三',
          5: '四',
          6: '五',
          7: '六'
        };

        let expression = '每';

        if (this.taskInfo[type].dayType === 'day') {
          expression += '天';
          expression += this.taskInfo[type].time;
        } else if (this.taskInfo[type].dayType === 'hour') {
          expression += '小时';
          expression += `第${this.taskInfo[type].hour}分`;
        } else {
          expression += `周${weekMap[this.taskInfo[type].day]}`;
          expression += this.taskInfo[type].time;
        }
        return expression;
      };
    }
  }
};
</script>
<style lang="less" scoped>
.task-info-title {
  background-color: #ECECEC;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Medium, serif;
  font-weight: 500;
  border-bottom: 1px solid #DADADA;
  padding-left: 20px;
}

.task-info-item-container {
  font-size: 0;
  border-bottom: 1px solid #DADADA;
}

.task-info-item {
  width: 20%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  overflow: hidden;

  .task-info-item-title {
    background-color: #F4F4F4;
    text-align: right;
    display: inline-block;
    font-weight: 400;
    width: 120px;
    border-right: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    padding-right: 10px;
  }

  .task-info-item-value {
    font-weight: 500;
    padding-left: 6px;
  }
}

.task-info-item-double {
  width: 40%;
}

.task-info-item-auto {
  width: 60%;
}
.task-info-item-all {
  width: 100%;
}
</style>
