<template>
  <div class="layout cloudCanal-header">
    <Layout>
      <Header>
        <Menu mode="horizontal" theme="dark" :active-name="activeName" ref="menu">
          <div class="layout-logo" @click="handleHome">
            <i class="iconfont iconlogo"></i>
          </div>
          <div class="layout-nav">
            <MenuItem name="/data/job/list" to="/data/job/list">
              任务管理
            </MenuItem>
            <MenuItem name="/data/dataSource" to="/data/dataSource">
              数据源管理
            </MenuItem>
            <MenuItem name="/system/resource" to="/system/resource"
                      v-if="menuList.indexOf('/system/resource')>-1">
              机器管理
            </MenuItem>
            <Submenu name="monitor">
              <template slot="title">
                监控管理
              </template>
              <MenuItem name="/monitor/job/dashboard" to="/monitor/job/dashboard"
                        v-if="menuList.indexOf('/monitor/job/dashboard')>-1">监控管理
              </MenuItem>
              <MenuItem name="/monitor/exception" to="/monitor/exception"
                        v-if="menuList.indexOf('/monitor/exception')>-1">异常监控
              </MenuItem>
              <MenuItem name="/alarm/log" to="/alarm/log">
                告警日志
              </MenuItem>
            </Submenu>
            <Submenu name="system"
                     v-if="menuList.indexOf('/system/user')>-1||menuList.indexOf('/system/resource')>-1||menuList.indexOf('/system/fsm')>-1||menuList.indexOf('/system/state/task')>-1">
              <template slot="title">
                系统管理
              </template>
              <MenuItem name="/system/config" to="/system/config"
                        v-if="menuList.indexOf('/system/config')>-1">系统配置
              </MenuItem>
              <MenuItem name="/system/user" to="/system/user"
                        v-if="menuList.indexOf('/system/user')>-1">用户管理
              </MenuItem>
              <MenuItem name="/system/fsm" to="/system/fsm"
                        v-if="menuList.indexOf('/system/fsm')>-1">状态机管理
              </MenuItem>
              <MenuItem name="/system/state/task" to="/system/state/task"
                        v-if="menuList.indexOf('/system/state/task')>-1">异步任务管理
              </MenuItem>
              <MenuItem name="/system/operationLog" to="/system/operationLog"
                        v-if="menuList.indexOf('/system/operationLog')>-1">操作审计
              </MenuItem>
              <MenuItem name="/system/license" to="/system/license"
                        v-if="menuList.indexOf('/system/license')>-1">资源中心
              </MenuItem>
            </Submenu>
          </div>
          <div class="navbar-collapse clearfix">
            <div class="navbar-nav-nb" style="margin-left: 30px">
              <Dropdown>
                <a class="dropdown-arrow-a">
                  {{ username }}
                  <svg style="font-size: 28px;vertical-align: middle;margin-left: 10px" class="icon"
                       aria-hidden="true">
                    <use xlink:href="#icon-headimg"></use>
                  </svg>
                </a>
                <DropdownMenu slot="list">
                  <DropdownItem>
                    <div
                      style="height: 60px;line-height: 60px;border-bottom: 1px solid #dadada;padding: 0 20px;vertical-align: middle">
                      <svg style="font-size: 36px;vertical-align: middle" class="icon"
                           aria-hidden="true">
                        <use xlink:href="#icon-headimg"></use>
                      </svg>
                      <span
                        style="font-size: 16px;font-family: PingFangSC-Semibold,serif;font-weight: 500;margin: 0 10px">{{
                          username
                        }}</span>
                      <span>{{ phone }}</span>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div class="header-menu-item" @click="handleGoUrl('/system/userCenter')"><i
                      class="iconfont iconzhanghu"
                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>账户信息
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div class="header-menu-item" @click="handleGoUrl('/system/alertSetting')"><i
                      class="iconfont icongaojing"
                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>告警设置
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div class="header-menu-item" @click="handleGoUrl('/system/stTokenSetting')"><i
                      class="iconfont iconfangwe"
                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>阿里云访问权限
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div
                      style="display: block;width: 240px;height: 40px;line-height: 40px;text-align: center;background-color: #ECECEC;color: #FF6E0D;vertical-align: middle"
                      @click="handleLogout">
                      <i class="iconfont icontuichu"
                         style="margin-right: 4px;vertical-align: middle"></i>
                      退出登录
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div class="navbar-nav-nb">
              <Poptip placement="bottom-end">
                <a style="margin-left: 10px;font-size: 14px">寻求帮助</a>
                <div slot="content">
                  <div style="padding: 10px 30px 30px 30px">
                    <p
                      style="margin-bottom: 20px;text-align: center;font-size: 16px;color: #333333">
                      添加微信寻求帮助</p>
                    <div style="padding: 40px;background-color: #DEEFFF">
                      <img style="width: 204px;height: 204px" src="@asset/Wechat1.jpg" alt="官微">
                    </div>
                  </div>
                </div>
              </Poptip>
              <Tooltip content="帮助文档" placement="bottom"
                       style="padding-right: 20px;margin-left:20px;" transfer>
                <a class="dropdown-arrow-a" href="https://doc.clougence.com/" target="_blank">
                  <icon class="iconfont iconnav_help"></icon>
                </a>
              </Tooltip>
              <!--              <a style="vertical-align: middle;"><i class="iconfont iconmessage"></i></a>-->
            </div>
          </div>
        </Menu>
      </Header>
    </Layout>
    <StToken ref="stToken"></StToken>
  </div>
</template>
<script>
import { listcatalog } from '@services/api/authority';
import { logout } from '@services/api/auth';
import { invalidStsToken } from '@services/api/stToken';
import StToken from './ApplyStToken';

export default {
  components: { StToken },
  data() {
    return {
      username: '',
      phone: '',
      activeName: '/',
      openName: ['data'],
      menuList: []
    };
  },
  mounted() {
    this.getUserInfo();
    listcatalog()
      .then((res) => {
        if (res.data.code === '1') {
          this.menuList = res.data.data;
          const { path } = this.$route;
          this.activeName = this.$route.path;
          if (path) {
            if (path.includes('/data/job/list')) {
              this.activeName = '/data/job/list';
            }
            if (path.includes('/data/dataSource')) {
              this.activeName = '/data/dataSource';
            }
            if (path.includes('/system/resource')) {
              this.activeName = '/system/resource';
            }
          }
          if (this.$route.path === '/') {
            this.activeName = '/';
            // this.openName = 'data';
          }
          const that = this;

          this.$nextTick(() => {
            that.$refs.menu.updateActiveName();
            that.$refs.menu.updateOpened();
          });
        }
      });
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userInfo')) {
        this.username = JSON.parse(localStorage.getItem('userInfo')).username;
        this.phone = JSON.parse(localStorage.getItem('userInfo')).phone;
      }
    },
    handleUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleGoUrl(url) {
      this.$router.push({ path: url });
    },
    handleLogout() {
      logout()
        .then((res) => {
          if (res.data.code !== '1') {
            this.$Modal.error({
              title: 'error',
              content: '登出错误'
            });
          } else {
            this.$router.push({ path: '/login' });
          }
        });
    },
    handleRefreshStToken() {
      this.$refs.stToken.handleShowAkSk();
    },
    handleCleanStToken() {
      invalidStsToken()
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('操作成功！');
          }
        });
    },
    handleHome() {
      window.location.href = '/data/job/list';
    }
  },
  watch: {
    menuList() {
      this.getUserInfo();
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  /*overflow: hidden;*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border: none;
}

.layout-logo {
  width: 180px;
  height: 48px;
  background-image: url('~@asset/logo-BG.png');
  float: left;
  position: relative;
  color: #ffffff;
  padding-left: 20px;
  cursor: pointer;

  .iconfont {
    font-size: 22px;
  }
}

.layout-nav {
  width: 820px;
  /*margin: 0 auto;*/
  /*margin-right: 20px;*/
}

.layout-footer-center {
  text-align: center;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  min-height: 48px;

  .iconfont {
    margin-right: 5px;
  }

  .icon-database {
    font-size: 12px;
  }

  .icon-aliyun {
    font-size: 14px;
  }
}

.navbar-brand {
  float: none;
  height: auto;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  display: flex;
}

.navbar-brand-mini {
  display: none;
  float: none;
  height: auto;
  padding: 0 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #7793a7;
}

.navbar-header {
  position: fixed;
  width: 200px;
  float: left;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  /*background-color: #10121A;*/
  text-align: center;
  height: 48px;
}

.navbar-collapse {
  /*background-color: #10121A;*/
  padding-right: 15px;
  position: relative;
  height: auto;
  overflow: visible;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  float: right;
  /*top: 15px;*/
  /*right: 20px;*/
  /*background-image: url("../../assets/logo-BG.png");*/
}

.navbar-switch {
  float: left;

  .navbar-btn {
    display: block;
    line-height: 48px;
    color: #58666e;
    font-size: 12px;
  }
}

.navbar-form {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  float: left;

  input {
    height: 30px;
    padding: 5px 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 12px;
    line-height: 1.5;
    border-color: transparent;
    border-width: 0;
    color: #58666e;
    background-color: #edf1f2;
    display: inline-block;
    vertical-align: middle;
  }
}

.menu-icon {
  transition: all .3s;
}

.rotate-icon {
  transform: rotate(-90deg) translate(12px, -80px);
}

.dropdown-arrow {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
}

.dropdown-arrow-a:hover .dropdown-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
}

.ivu-select-dropdown {
  padding: 0 !important;
}

.ivu-dropdown-item {
  padding: 0 !important;
}

.header-menu-item {
  padding: 12px 20px;
}

.navbar-nav-nb {
  a {
    font-size: 14px;
  }

  .iconfont {
    font-size: 20px;
  }
}

.navbar-nav-nb {
  a {
    color: #ffffff;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      color: #ffffff;
    }
  }

  float: right;
  position: relative;

  display: block;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    /*background-color: #f8f8f9;*/
    cursor: pointer;
  }

  .menu-dropdown {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    position: absolute;
    width: 200px;
    background: #fff;
    right: 0;

    .info {
      border-bottom: 1px solid #dee5e7;
      color: #58666e;
      background-color: #edf1f2;
      padding: 15px;
      margin-bottom: 10px;
    }

    .divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    a {
      height: 30px;
      line-height: 30px;
      display: block;
      padding: 0 10px;
      color: #58666e;

      &:hover {
        background-color: #edf1f2;
      }
    }
  }
}
</style>
