]
<template>
  <div class="add-datasource-step2">
    <p class="add-datasouce-title">数据源信息</p>
    <p class="add-datasource-basic">
      <span class="add-datasource-basic-label">部署类型</span><span
      class="add-datasource-basic-value">{{
        Mapping.cloudOrIdcName[addDataSourceForm.instanceType]
      }}</span><span
      class="add-datasource-basic-label">源库类型</span><span
      class="add-datasource-basic-value">{{
        addDataSourceForm.instanceType === 'ALIBABA_CLOUD_HOSTED' ? Mapping.aliyunType[addDataSourceForm.type] : addDataSourceForm.type
      }}</span>
    </p>
    <p class="add-datasource-basic">
      <span class="add-datasource-basic-label">地区</span><span
      class="add-datasource-basic-value">{{ addDataSourceForm.region }}</span>
    </p>
    <p class="add-datasouce-title">创建账号密码</p>
    <Table border
           :columns="getSecurity(addDataSourceForm.rdsList[0].securityType).needExtraAliyunAkSk?dataSourceColumnWithAkSk:dataSourceColumn"
           :data="addDataSourceForm.rdsList">
      <template slot-scope="{row,index}" slot="securityType">
        <Select v-model="addDataSourceForm.rdsList[index].securityType" style="width:280px"
                @on-change="handleUpdateAddForm" transfer>
          <Option v-for="security in securitySetting" :value="security.securityType"
                  :key="security.securityType">
            {{ Mapping.securityType[security.securityType] }}
          </Option>
        </Select>
      </template>
      <template slot-scope="{row,index}" slot="aliyunAKSK">
        <span>AK</span>
        <Input size="small" v-model="addDataSourceForm.rdsList[index].accessKey"
               style="width: 200px" placeholder="请输入阿里云AccessKey ID"/>
        <span style="margin-left: 16px">SK</span>
        <Input size="small" v-model="addDataSourceForm.rdsList[index].secretKey"
               style="width: 200px" password type="password" placeholder="请输入阿里云AccessKey Secret"/>
      </template>
      <template slot-scope="{row,index}" slot="account">
        <div>
                <span
                  v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).needUserName&&!getSecurity(addDataSourceForm.rdsList[index].securityType).accountAliyunAkSk">
                    <span>账号</span>
                    <Input :disabled="row.autoCreate" size="small"
                           v-model="addDataSourceForm.rdsList[index].account"
                           style="width: 200px" placeholder="请输入数据库账号"/>
                </span>
          <span v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).needPassword&&!getSecurity(addDataSourceForm.rdsList[index].securityType).accountAliyunAkSk">
                    <span style="margin-left: 16px">密码</span>
                    <Input :disabled="row.autoCreate" size="small"
                           v-model="addDataSourceForm.rdsList[index].password"
                           style="width: 200px" password type="password" placeholder="请输入数据库密码"/>
                </span>
          <span v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).accountAliyunAkSk">
                    <span>AK</span>
                    <Input :disabled="row.autoCreate" size="small"
                           v-model="addDataSourceForm.rdsList[index].account"
                           style="width: 200px" placeholder="请输入阿里云AccessKey ID"/>
                    <span style="margin-left: 16px">SK</span>
                    <Input :disabled="row.autoCreate" size="small"
                           v-model="addDataSourceForm.rdsList[index].password"
                           style="width: 200px" password type="password"
                           placeholder="请输入阿里云AccessKey Secret"/>
                </span>
          <span
            v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).needClientTrustStorePassword">
                    <span>客户端TrustStore密码</span>
                    <Input :disabled="row.autoCreate" size="small"
                           v-model="addDataSourceForm.rdsList[index].clientTrustStorePassword"
                           style="width: 200px" placeholder="请输入客户端TrustStore密码"/>
                </span>
          <input v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).needTlsFile"
                 @change="handleFileChange(index,$event)" type="file" name="uploadfile"
                 id="uploadfile1"/>
          <Checkbox
            v-if="getSecurity(addDataSourceForm.rdsList[index].securityType).hasAutoCreateAccountOption"
            style="margin-left: 4px" v-model="row.autoCreate"
            @on-change="handleChangeAuto(index,$event)">自动创建
          </Checkbox>
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import Mapping from '../../../views/util';
import DataSourceGroup from '../../../views/dataSourceGroup';
import store from '../../../store/index';

export default {
  props: {
    addDataSourceForm: Object,
    securitySetting: Array
  },
  created() {
    if (store.state.firstAddDataSource) {
      if (DataSourceGroup.mysql.indexOf(this.addDataSourceForm.type) > -1 || DataSourceGroup.drds.indexOf(this.addDataSourceForm.type) > -1) {
        this.addDataSourceForm.rdsList.map((rds) => {
          rds.autoCreate = false;
          return null;
        });
      } else if (DataSourceGroup.kafka.indexOf(this.addDataSourceForm.type) > -1) {
        this.addDataSourceForm.rdsList.map((rds) => {
          rds.noAccount = false;
          rds.securityType = this.addDataSourceForm.securityType;
          rds.clientTrustStorePassword = 'KafkaOnsClient';
          return null;
        });
      } else {
        this.addDataSourceForm.rdsList.map((rds) => {
          rds.noAccount = false;
          rds.securityType = this.addDataSourceForm.securityType;
          return null;
        });
      }
    }
    store.state.firstAddDataSource = false;
  },
  data() {
    return {
      store,
      DataSourceGroup,
      Mapping,
      dataSourceColumn: [
        {
          title: '实例ID',
          key: 'instanceId',
          minWidth: 360,
          render: (h, params) => h('div', {}, `${params.row.instanceDesc}/${params.row.instanceId}`)
        },
        {
          title: '数据库认证方式',
          slot: 'securityType',
          minWidth: 300
        },
        {
          title: '数据库账号密码',
          key: '',
          slot: 'account',
          minWidth: 630,
          renderHeader: (h) => h('div', [
            h('span', {
              style: {
                fontFamily: 'PingFangSC-Medium',
                fontWeight: '500'
              }
            }, '数据库账号密码'),
            h('span', {
              style: {
                color: '#888888',
                marginLeft: '8px',
                fontWeight: 400
              }
            }, '用于平台进行数据同步')
          ])
        }
      ],
      dataSourceColumnWithAkSk: [
        {
          title: '实例ID',
          key: 'instanceId',
          minWidth: 360,
          render: (h, params) => h('div', {}, `${params.row.instanceDesc}/${params.row.instanceId}`)
        },
        {
          title: '数据库认证方式',
          slot: 'securityType',
          minWidth: 300
        },
        {
          title: '数据库账号密码',
          key: '',
          slot: 'account',
          minWidth: 630,
          renderHeader: (h) => h('div', [
            h('span', {
              style: {
                fontFamily: 'PingFangSC-Medium',
                fontWeight: '500'
              }
            }, '数据库账号密码'),
            h('span', {
              style: {
                color: '#888888',
                marginLeft: '8px',
                fontWeight: 400
              }
            }, '用于平台进行数据同步')
          ])
        },
        {
          title: 'AK/SK(必填，用于数据同步拉取备份的日志文件)',
          slot: 'aliyunAKSK',
          minWidth: 500,
          renderHeader: (h) => h('div', [
            h('span', {
              style: {
                fontFamily: 'PingFangSC-Medium',
                fontWeight: '500'
              }
            }, '阿里云AccessKey ID/AccessKey Secret'),
            h('span', {
              style: {
                color: '#888888',
                marginLeft: '8px',
                fontWeight: 400
              }
            }, '必填，用于数据同步拉取备份的日志文件')
          ])
        }
      ],
      dataSourceData: []
    };
  },
  methods: {
    handleChangeAuto(index, data) {
      this.addDataSourceForm.rdsList[index].autoCreate = data;
    },
    handleChangeNoAccount(index, data) {
      this.addDataSourceForm.rdsList[index].noAccount = data;
    },
    handleFileChange(index, e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.addDataSourceForm.rdsList[index].securityFile = file;
      }
    },
    handleKeyTabFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.addDataSourceForm.secretFile = file;
      }
    },
    handleUpdateAddForm() {
      this.addDataSourceForm = { ...this.addDataSourceForm };
    },
    getSecurity(type) {
      let security = {};

      this.securitySetting.map((item) => {
        if (item.securityType === type) {
          security = item;
        }
        return null;
      });
      return security;
    }
  }
};
</script>
<style lang="less" scoped>
.add-datasource-step2 {
  .add-datasouce-title {
    height: 40px;
    line-height: 40px;
    background-color: #ECECEC;
    border-bottom: 1px solid #DADADA;
    padding: 0 20px;
    font-weight: 500;
  }

  .add-datasource-basic {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #DADADA;

    .add-datasource-basic-label {
      width: 10%;
      height: 39px;
      background-color: #F4F4F4;
      padding: 0 10px;
      text-align: right;
      display: inline-block;
      border-right: 1px solid #DADADA;
    }

    .add-datasource-basic-value {
      width: 39.8%;
      padding: 0 10px;
      display: inline-block;
      border-right: 1px solid #DADADA;
    }

    .add-datasource-basic-value:last-child {
      border-right: none;
    }
  }
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}
</style>
