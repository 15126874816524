import {
  activeFsm,
  activeFsmBatch,
  pauseJobTimeSchedule,
  replayJob,
  restartJob,
  restartJobBatch,
  resumeJobTimeSchedule,
  startJob,
  startJobBatch,
  startTimeScheduleJob,
  stopJob,
  stopJobBatch,
  updateJob
} from '@services/api/job';
import store from '../../../store/index';

export default {
  data() {
    return {
      jobId: 0,
      loadingData: {},
      loading: false,
      selectedJobRow: {},
      showConfirm: false,
      sendCodeAgain: '',
      verifyCode: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      batchEditList: [],
      batchEdit: false,
      batchEditBtns: [],
      showScheduleHistory: false,
      showBatchConfirm: false,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      batchType: '',
      checkAllGroup: [],
      store
    };
  },
  computed: {
    getFitSpan() {
      let count = 0;

      const width = window.innerWidth;
      const countMapping = {
        3: 3,
        4: 4,
        5: 4,
        6: 6,
        7: 6,
        8: 8,
        9: 8
      };

      count = parseInt(width / 360, 10);
      return countMapping[parseInt(24 / count, 10)];
    }
  },
  methods: {
    handleCancelDelete() {
      this.verifyCode = '';
      this.showConfirm = false;
      this.showBatchConfirm = false;
    },
    handleBeginBatchEdit() {
      this.showBatchConfirm = false;
      if (this.batchType === 'stop') {
        this.handleStopBatch();
      } else if (this.batchType === 'restart') {
        this.handleRestartBatch();
      } else if (this.batchType === 'start') {
        this.handleStartBatch();
      } else if (this.batchType === 'fsmStart') {
        this.handleActiveFsmBatch();
      }
    },
    handleStartBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      startJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('启动成功！');
          }
        });
    },
    handleStopBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      stopJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success('停止成功！');
          }
        });
    },
    handleRestartBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      restartJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('重启成功！');
          }
        });
    },
    handleActiveFsmBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      activeFsmBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('启动成功！');
          }
        });
    },
    handleRestart(row) {
      this.$Modal.confirm({
        title: '重启任务确认',
        content: '请确认是否要重启该任务，重启中将出现任务中断',
        onOk: () => {
          restartJob({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (!this.loadingData[row.dataJobId]) {
                  this.loadingData[row.dataJobId] = {};
                }
                this.loadingData[row.dataJobId].restarting = true;
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success('重启成功！');
              }
            });
        }
      });
    },
    handleStop(row) {
      this.$Modal.confirm({
        title: '停止任务确认',
        content: '请确认是否要停止该任务，停止将使任务中断',
        onOk: () => {
          stopJob({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (!this.loadingData[row.dataJobId]) {
                  this.loadingData[row.dataJobId] = {};
                }
                this.loadingData[row.dataJobId].stopping = true;
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success('停止成功！');
              }
            });
        }
      });
    },
    handleStartFsm(row) {
      activeFsm({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('启动成功！');
          }
        });
    },
    handleReplay(row) {
      replayJob({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('重跑成功！');

            const that = this;

            setTimeout(() => {
              if (this.type === 'group') {
                that.getJobData();
              } else {
                that.getJobList();
              }
            }, 500);
          }
        });
    },
    handleResumSchedule(row) {
      resumeJobTimeSchedule({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('恢复调度成功！');
          }
        });
    },
    handleStopSchedule(row) {
      this.$Modal.confirm({
        title: '停止调度确认',
        content: '请确认是否要停止该任务调度，停止后定时任务将中止',
        onOk: () => {
          pauseJobTimeSchedule({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success('停止调度成功！');
              }
            });
        }
      });
    },
    handleStart(row) {
      startJob({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (!this.loadingData[row.dataJobId]) {
              this.loadingData[row.dataJobId] = {};
            }
            this.loadingData[row.dataJobId].starting = true;
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success('启动成功！');
          }
        });
    },
    getBtns() {
      let allBtns = ['restart', 'stop', 'start', 'fsmStart'];

      this.batchEditList.forEach((item) => {
        const rowBtns = [];

        if (this.getDataTask(item.dataTasks).BUILD_STRUCT
          ? !item.fsmActive && item.dataTaskState === 'INIT'
          && (this.getDataTask(item.dataTasks).BUILD_STRUCT
            && this.getDataTask(item.dataTasks).BUILD_STRUCT.dataTaskStatus === 'COMPLETE')
          : !item.fsmActive && item.dataTaskState === 'INIT') {
          rowBtns.push('fsmStart');
        }
        if ((store.state.urlLabels.indexOf('/') > -1
            || store.state.urlLabels.indexOf('/datajob/start') > -1)
          && (this.getDataTask(item.dataTasks).FULL
            && this.getDataTask(item.dataTasks).FULL.dataTaskStatus === 'STOP'
            || this.getDataTask(item.dataTasks).INCREMENT
            && this.getDataTask(item.dataTasks).INCREMENT.dataTaskStatus === 'STOP'
            || this.getDataTask(item.dataTasks).CHECK
            && this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'STOP')) {
          rowBtns.push('start');
        }
        if ((store.state.urlLabels.indexOf('/') > -1
            || store.state.urlLabels.indexOf('/datajob/restart') > -1)
          && (item.currentStatus === 'ABNORMAL'
            || item.currentStatus === 'RUNNING'
            || item.currentStatus === 'WAIT_START'
            || item.dataTaskState === 'CHECK'
            && (this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'WAIT_START'
              || this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'RUNNING'))) {
          rowBtns.push('restart');
        }
        if ((store.state.urlLabels.indexOf('/') > -1
            || store.state.urlLabels.indexOf('/datajob/stop') > -1)
          && (item.currentStatus === 'ABNORMAL'
            || item.currentStatus === 'RUNNING'
            || item.currentStatus === 'WAIT_START'
            || item.dataTaskState === 'CHECK'
            && (this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'WAIT_START'
              || this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'RUNNING'))) {
          rowBtns.push('stop');
        }
        allBtns = allBtns.filter((v) => rowBtns.includes(v));
      });
      if (this.batchEditList.length === 0) {
        this.batchEditBtns = [];
      } else {
        this.batchEditBtns = allBtns;
      }
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.forEach((item) => {
        dataTasks[item.dataTaskType] = item;
      });
      return dataTasks;
    },
    handleShowGroup(bl, row) {
      if (bl) {
        // this.showGroup = true;
        this.groupData = row;
        this.$router.push({ path: `/data/job/group/${row.dataJobId}` });
      } else {
        this.showGroup = false;
      }
    },
    handleConfirmEdit(row, dataJobDesc) {
      updateJob({
        jobId: row.dataJobId,
        dataJobDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success('修改成功！');
          }
        });
    },
    handleBatchEdit(row, selected) {
      if (selected) {
        this.batchEditList.push(row);
      } else {
        this.batchEditList.forEach((item, index) => {
          if (item.dataJobId === row.dataJobId) {
            this.batchEditList.splice(index, 1);
          }
        });
      }
      this.batchEdit = this.batchEditList.length > 0;
      this.getBtns();
    },
    handleStartTimeScheduleJob(row) {
      startTimeScheduleJob({ jobId: row.dataJobId })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success('立即执行操作成功，任务将在两分钟后自动执行');
          }
        });
    },
    handleDelete(row) {
      this.selectedJobRow = row;
      this.jobId = row.dataJobId;
      let hasRunningTask = false;

      if (row.childJobs) {
        row.childJobs.forEach((child) => {
          child.dataTasks.forEach((task) => {
            if (child.dataTaskState === 'INCRE' || child.dataTaskState === 'CATCH_UP') {
              if (task.dataTaskType === 'INCREMENT') {
                if (task.dataTaskStatus !== 'STOP' && task.dataTaskStatus !== 'COMPLETE') {
                  hasRunningTask = true;
                }
              }
            } else if (child.dataTaskState === task.dataTaskType) {
              if (task.dataTaskStatus !== 'STOP' && task.dataTaskStatus !== 'COMPLETE') {
                hasRunningTask = true;
              }
            }
          });
        });
      }

      if (!hasRunningTask) {
        this.showConfirm = true;
      } else {
        this.$Modal.warning({
          title: '删除任务提示',
          content: '当前任务存在运行中的关联任务，请先停止或删除关联任务。'
        });
      }
    },
    handleShowHistory(row, type) {
      if (type === 'CHECK') {
        this.scheduleHistoryColumn = [
          {
            title: '执行时间',
            key: 'gmtModified'
          },
          {
            title: '进度（%）',
            key: 'checkedPercent'
          },
          {
            title: '已校验（行）',
            key: 'checked',
            render: (h, params) => h('div', {}, `${params.row.checked}（预估值）`)
          },
          {
            title: '丢失（行）',
            key: 'loss'
          },
          {
            title: '不一致（行）',
            key: 'diff'
          }
        ];
      } else {
        this.scheduleHistoryColumn = [
          {
            title: '执行时间',
            key: 'gmtModified'
          },
          {
            title: '进度（%）',
            key: 'transPercent'
          },
          {
            title: '已迁移（行）',
            key: 'trans',
            render: (h, params) => h('div', {}, `${params.row.trans}（预估值）`)
          }
        ];
      }
      const history = JSON.parse(this.getDataTask(row.dataTasks)[type].taskPosition).resultHistory;

      if (history) {
        this.scheduleHistoryData = JSON.parse(history)
          .reverse();
      }
      this.showScheduleHistory = true;
    },
    updateAllBatchList() {
      this.pagingData.forEach((theData) => {
        theData._checked = true;
        if (theData.children) {
          theData.children.forEach((child) => {
            child._checked = true;
          });
        }
      });

      this.batchEditList = this.pagingData;
      this.getBtns();
    },
    handleCancelAllBatch() {
      this.pagingData.forEach((theData) => {
        theData._checked = false;
        if (theData.children) {
          theData.children.forEach((child) => {
            child._checked = false;
          });
        }
      });

      this.batchEditList = [];
      this.getBtns();
    },
    handleSelectBatch(selection, row) {
      this.pagingData.forEach((data) => {
        if (row.dataJobId === data.dataJobId) {
          data._checked = true;
          this.batchEditList.push(data);
          if (data.children) {
            data.children.forEach((child) => {
              child._checked = false;
            });
          }
        }
      });
      this.getBtns();
    },
    handleCancelBatch(selection, row) {
      this.pagingData.forEach((data) => {
        if (row.dataJobId === data.dataJobId) {
          data._checked = false;
          if (data.children) {
            data.children.forEach((child) => {
              child._checked = false;
            });
          }
        }
      });
      this.batchEditList.forEach((edit, i) => {
        if (edit.dataJobId === row.dataJobId) {
          this.batchEditList.splice(i, 1);
          if (edit.children) {
            edit.children.forEach((child, index) => {
              if (child.dataJobId === edit.dataJobId) {
                this.batchEditList.splice(index, 1);
              }
            });
          }
        }
      });
      this.getBtns();
    },
    updateBatchInTable() {
      this.batchEdit = !this.batchEdit;
      this.batchEditList = [];
      this.batchEditBtns = [];
    },
    handleBatchEditConfirm(type) {
      this.batchType = type;
      this.showBatchConfirm = true;
      this.batchEditList.forEach((item) => {
        this.checkAllGroup.push(item.dataJobName);
      });
    }
  }
};
