<template>
  <div>
    <div class="table-filter-nav-item-arrow"></div>
    <div class="table-filter-nav-item-db">
      <p><i class="iconfont iconshujuku"></i>{{ db.sourceDb }}
        <span v-if="sourceType==='Oracle'||
              sourceType==='PostgreSQL'||
              sourceType==='Greenplum'||
              sourceType==='RdsForPostgreSQL'||
              sourceType==='AdbForPostgreSQL'">.{{ db.sourceSchema }}</span></p>
      <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
      <p :class="db.needAutoCreated||db.schemaAutoCreate?'db-to-create':''">
        <i
          :class="`iconfont ${db.needAutoCreated||db.schemaAutoCreate?'icondaijianKU':'iconshujuku'}`"></i>
        <span>{{ db.sinkDb }}</span>
        <span v-if="sinkType==='Oracle'||
              sinkType==='PostgreSQL'||
              sinkType==='Greenplum'||
              sinkType==='RdsForPostgreSQL'||
              sinkType==='AdbForPostgreSQL'">.{{ db.targetSchema }}</span></p>
      <p class="chosed-count">已选中
        <span :class="getSelectedInfo(db.sourceDb).selectedCount<(selectedTables[db.sourceDb]?
              selectedTables[db.sourceDb].length:0)?'warn-count':''"
              style="text-align: center;display: inline-block;">
                {{ getSelectedInfo(db.sourceDb).selectedCount }}</span>
        /{{ selectedTables[db.sourceDb] ? selectedTables[db.sourceDb].length : 0 }}张表</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    db: Object,
    getSelectedInfo: Function,
    selectedTables: Object,
    sourceType: String,
    sinkType: String
  }
};
</script>
