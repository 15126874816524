import { instance } from '../instance';

export const updateDesc = (param) => instance.post('cluster/updatedesc', param);

export const deleteCluster = (param) => instance.post('cluster/delete', param);

export const createCluster = (param) => instance.post('cluster/create', param);

export const queryById = (param) => instance.post('cluster/querybyid', param);

export const listClusterByCondition = (param) => instance.post('cluster/listbycondition', param);

export const listWithWorkerNets = (param) => instance.post('cluster/listwithworkernets', param);
