import axios from 'axios';
import { Modal, Spin } from 'view-design';

const baseURL = `${window.location.protocol}//${window.location.host}/cloudcanal/console/api/v1/inner`;
// const baseURL = '/' + '/api/v1/';
const timeout = 60000;
const instance = axios.create({
  baseURL,
  timeout,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
    // 'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true,
  credentials: 'include'
});

export { instance };

// 返回结果拦截器,处理默认的错误
// 返回结果拦截器,处理默认的错误
instance.interceptors.response.use((response) => {
  if (response.data.code !== '1') {
    if (response.data.code !== '6028' && response.data.code !== '6047' && response.data.code !== '0014') {
      if (response.data.msg) {
        Modal.info({
          render: (h) => h('div', [
            h('div', {
              class: 'operation-error-title'
            }, [
              h('Icon', {
                props: {
                  type: 'md-close-circle'
                },
                style: {
                  fontSize: '24px',
                  float: 'left'
                }
              }),
              h('p', {
                class: 'preCheck-title-desc',
                style: {
                  marginLeft: '30px',
                  wordBreak: 'break-all'
                }
              }, '操作失败'),
              h('p', {
                style: {
                  marginLeft: '30px'
                }
              }, response.data.msg)
            ])
          ]),
          width: 600,
          okText: '知道了',
          closable: true
        });
      }
    }
  }
  // 正常的请求前拦截,在这里处理
  return response;
}, (error) => {
  // 非200请求时的错误处理'
  Spin.hide();
  if (error.response) {
    const res = error.response.data; // 请求data
    const status = error.response.status; // 请求状态吗

    if (status === 499) {
      window.location.href = res.url;
    } else if (status === 401) {
      if (window.location.href.indexOf('redirect') < 0) {
        window.location.href = `${window.location.protocol}//${window.location.host}/#/login`;
      }
      window.location.reload();
    } else if (status === 406) {
      if (window.location.href.split('#')[1] !== '/permission/denied' && window.location.href.split('#')[1] !== '/permission/exception') {
        localStorage.setItem('console_last_url', window.location.href.split('#')[1]);
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/#/permission/denied`;
    } else if (status === 500) {
      if (window.location.href.split('#')[1] !== '/permission/denied' && window.location.href.split('#')[1] !== '/permission/exception') {
        localStorage.setItem('console_last_url', window.location.href.split('#')[1]);
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/#/permission/exception`;
    } else if (res && !res.errors) {
      Modal.error({
        title: 'ERROR',
        content: `${res.message}`
      });
    } else {
      Modal.error({
        title: 'ERROR',
        content: `${res.errors[0].message}`
      });
    }
  } else {
    Modal.error({
      title: 'ERROR',
      content: '已超时！'
    });
  }
  return Promise.reject(error);
});
