<template>
  <div class="job-detail-state-container">
    <div>
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>增量迁移
          <a @click="handleShowLog(task.dataTaskId)">查看日志</a>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('INCREMENT')" :percent="0"
                    :size="200">
            <div class="incre-inner"
                 :style="`font-size:32px;background-color:${getStepColor};box-shadow: 0 0 10px 0 ${getStepColor};`">
              {{ MappingUtil.taskStatus[taskList['INCREMENT'].dataTaskStatus] }}
            </div>
          </i-circle>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>当前位点</p>
        <div class="job-detail-position"
             v-if="DataSourceGroup.mq.indexOf(jobData.sourceDsVO.dataSourceType) === -1&&DataSourceGroup.pg.indexOf(jobData.sourceDsVO.dataSourceType) === -1">
          <p><span>binlog文件：</span><span
            class="basic-info-value">{{ taskPosition.journalFile }}</span>
          </p>
          <p><span>binlog位点：</span><span
            class="basic-info-value">{{ taskPosition.filePosition }}</span>
          </p>
          <p><span>binlog时间戳：</span><span class="basic-info-value">
                      {{
              fecha.format(new Date(taskPosition.positionTimestamp), 'YYYY-MM-DD HH:mm:ss')
            }}（{{ taskPosition.positionTimestamp }}）</span>
          </p>
          <p v-if="taskList['INCREMENT'].dataTaskStatus!=='INIT'">距离上次更新：<span
            class="basic-info-value" :style="getDelayColor(taskPosition.delayMillis)">
                      {{ formatSeconds(taskPosition.delayMillis / 1000) }}</span></p>
          <div class="job-detail-position-groups" v-if="posTypeList.length>0">
            <pd-button ghost type="primary" size="small"
                       @click="handleShowConfirm">回溯位点
            </pd-button>
            <pd-button style="margin-left: 6px" type="error" size="small" ghost
                       @click="handleClearBinlog">清除位点
            </pd-button>
          </div>
        </div>
        <div class="job-detail-position"
             v-if="DataSourceGroup.pg.indexOf(jobData.sourceDsVO.dataSourceType)>-1">
          <p><span>LSN：</span><span class="basic-info-value">{{ taskPosition.lsn }}</span>
          </p>
          <p><span>位点时间戳：</span><span
            class="basic-info-value">{{
              fecha.format(new Date(taskPosition.positionTimestamp), 'YYYY-MM-DD HH:mm:ss')
            }}（{{ taskPosition.positionTimestamp }}）</span>
          </p>
          <p v-if="taskList['INCREMENT'].dataTaskStatus!=='INIT'">距离上次更新：<span
            class="basic-info-value" :style="getDelayColor(taskPosition.delayMillis)">
                  {{ formatSeconds(taskPosition.delayMillis / 1000) }}</span></p>
          <div class="job-detail-position-groups">
            <pd-button ghost type="primary" size="small"
                       @click="handleShowConfirm">回溯位点
            </pd-button>
            <pd-button style="margin-left: 6px" type="error" size="small" ghost
                       @click="handleClearBinlog">清除位点
            </pd-button>
          </div>
        </div>
        <div class="job-detail-position"
             v-if="DataSourceGroup.mq.indexOf(jobData.sourceDsVO.dataSourceType)>-1">
          <p><span>位点时间戳：</span><span
            class="basic-info-value">{{
              fecha.format(new Date(taskPosition.positionTimestamp), 'YYYY-MM-DD HH:mm:ss')
            }}
                  （{{ taskPosition.positionTimestamp }}）</span>
          </p>
          <p v-if="taskList['INCREMENT'].dataTaskStatus!=='INIT'">距离上次更新：<span
            class="basic-info-value" :style="getDelayColor(taskPosition.delayMillis)">
                  {{ formatSeconds(taskPosition.delayMillis / 1000) }}</span></p>

          <div class="job-detail-position-groups" v-if="posTypeList.length>0"
          >
            <pd-button ghost type="primary" size="small"
                       @click="handleShowConfirm">回溯位点
            </pd-button>
            <pd-button style="margin-left: 6px" type="error" size="small" ghost
                       @click="handleClearBinlog">清除位点
            </pd-button>
          </div>
        </div>
      </div>
    </div>
    <div class="job-detail-metric">
      <p class="job-detail-state-progress-title"><span class="status-point"></span>迁移速率
        <a @click="handleMoreMonitor(taskList['INCREMENT'].dataTaskId,'INCREMENT')">更多</a></p>
      <div style="margin-top:20px">
        <div class="panel">
          <div class="panel-header">
            <div style="float: right">
            </div>

            {{ incrementMetric.title }}
          </div>
          <div class="panel-body">
            <div style="height: 200px">
              <Metric ref="metric2" :model="incrementMetric" :time="time"
                      :filters="filters" :resourceType="resourceType" :dataJobId="jobId.toString()"
                      :selected="incrementMetric.selected"
                      :dataTaskId="taskList['INCREMENT'].dataTaskId.toString()"
                      :filterOthers="filterOthers"></Metric>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import Metric from '../../monitor/metric/metric';
import MappingUtil from '../../../../views/util';
import { formatSeconds } from '../../../util';
import DataSourceGroup from '../../../../views/dataSourceGroup';

export default {
  components: { Metric },
  props: {
    getCurrentStateColor: Function,
    getStepColor: String,
    taskPosition: Object,
    taskList: Object,
    getDelayColor: Function,
    handleShowConfirm: Function,
    handleClearBinlog: Function,
    handleMoreMonitor: Function,
    incrementMetric: Object,
    filters: Object,
    resourceType: String,
    filterOthers: Object,
    time: Object,
    jobId: Number,
    jobData: Object,
    handleShowLog: Function,
    task: Object,
    posTypeList: Array
  },
  data() {
    return {
      MappingUtil,
      fecha,
      formatSeconds,
      DataSourceGroup
    };
  },
  methods: {
    handleRender() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
      }, 100);
    }
  }
};
</script>
