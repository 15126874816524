<template>
  <div>
    <Modal
      v-model="showMapping"
      :title="`${jobData.dataJobName}任务的库表映射`"
      width="1200px"
      footer-hide
      :closable="false"
    >
      <div>
        <div style="height: 560px;overflow: auto;" v-if="showMapping" class="show-mapping-modal">
          <ConfigMapping :sourceSchema="sourceSchema" :getTargetData="getTargetData"
                         :getAddedData="getAddedData"
                         :selectedIndex="selectedIndex" :mappingDef="mappingDef"
                         :getMappingCount="getMappingCount"
                         :showTableMapping="showTableMapping" :sourceTable="sourceTable"
                         :handlePageChange="handlePageChange"
                         :handleChangeTable="handleChangeTable" :jobData="jobData"
                         :mqSchema="mqSchema" :handleShowWhereCondition="handleShowWhereCondition"
                         :targetSchema="targetSchema"></ConfigMapping>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">关闭
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showResetPosition"
      title="回溯位点"
      footer-hide
      :closable="false"
    >
      <div>
        <Alert type="warning" style="margin-bottom: 20px">
          <div style="color: #333;line-height: 22px">
            <ul>
              <li style="list-style:none;"><span class="warn-point"></span>该操作<span
                class="warn-font">不可逆</span>，请谨慎操作。
              </li>
              <li style="list-style:none;"><span class="warn-point"></span>回溯位点后将自动重启任务。</li>
              <li style="list-style:none;"><span class="warn-point"></span>回溯位点的时间如果大于当前的位点时间，可能有<strong>数据丢失风险</strong>。
              </li>
              <li style="list-style:none;"><span class="warn-point"></span>如需清空位点，从当前时间开始消费，请使用清除位点。
              </li>
            </ul>
          </div>
        </Alert>
        <p style="margin-bottom: 16px" v-if="posTypeList.length>1">
          <span>位点类型：</span>
          <Select style="width: 200px;display: inline-block" v-model="posType">
            <Option v-for="type in posTypeList" :key="type" :value="type">{{MappingUtil.posType[type]||type}}</Option>
          </Select>
        </p>
        <div v-if="posType === 'MYSQL_TIMESTAMP_POS' || posType === 'MONGODB_TIMESTAMP_POS'
         || posType === 'KAFKA_TIMESTAMP_POS' || posType === 'ROCKETMQ_TIMESTAMP_POS'
          || posType === 'POLAR_MY_TIMESTAMP_POS' || posType === 'POLAR_X_TIMESTAMP_POS'">
          <span style="margin-right: 10px">选择时间戳：</span>
          <DatePicker :editable="false" :options="startTimeOptions"
                      v-model="theBinlogTime"
                      @on-change="updatePositionInfo(theBinlogTime, posType)"
                      type="datetime" style="width: 200px"></DatePicker>
        </div>
        <div v-if="posType === 'MYSQL_GTID_POS'||posType === 'POLAR_MY_GTID_POS'">
          <span style="margin-right: 10px">填写 GTID：</span>
          <Input v-model="gtid" style="width: 200px" @on-change="updatePositionInfo(gtid, posType)" />
        </div>
        <div v-if="posType === 'MYSQL_LOG_FILE_POS' || posType === 'POLAR_MY_LOG_FILE_POS' || posType === 'POLAR_X_LOG_FILE_POS'">
          <p style="margin-bottom: 16px">
            <span style="margin-right: 10px">填写文件名称：</span>
            <Input v-model="theLogFile" style="width: 200px" @on-change="updatePositionInfo(theLogFile, posType, 'file')" />
          </p>
          <p>
            <span style="margin-right: 10px">填写文件位点：</span>
            <Input v-model="logFilePosition" style="width: 200px" @on-change="updatePositionInfo(logFilePosition, posType, 'position')" />
          </p>
        </div>
        <div v-if="posType === 'PG_LSN_POS' || posType === 'SQLSERVER_LSN_POS'">
          <span style="margin-right: 10px">填写 LSN</span>
          <Input v-model="lsn" style="width: 200px" @on-change="updatePositionInfo(lsn, posType)" />
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" :loading="positionLoading"
                     @click="handleResetPosition('', false, posType)">确定
          </pd-button>
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">取消
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showPartition" title="分区设置">
      <div style="word-break: break-all">ƒ
        <Form label-position="left" :label-width="80" v-if="partition.partition[0]">
          <FormItem label="分区键">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem label="分区方法">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem label="文件格式">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem label="压缩方式">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal footer-hide v-model="showWhereCondition" draggable title="where条件">
      <div>
        <div style="word-break: break-all">{{ currentWhereCondition }}</div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancelDelete">确认</pd-button>
          <pd-button @click="handleCancelDelete">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditJobDesc"
      title="修改任务描述"
      footer-hide
      :closable="false"
    >
      <div>
        <Form label-position="left" :label-width="60">
          <FormItem label="实例 ID">
            {{ selectedJob.dataJobName }}
          </FormItem>
          <FormItem label="描述">
            <Input type="textarea" v-model="selectedJob.dataJobDesc"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEdit">确认</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showLog"
           title="任务日志"
           footer-hide
           width="1200px"
           class-name="show-log-container"
           :closable="false"
    >
      <div>
        <Tabs type="card" v-model="logFileName" @on-click="handleSelectLog" :animated="false">
          <TabPane :label="log.fileName" :name="log.fileName" v-for="(log,index) in logData"
                   :key="index">
            <div class="log-content">
              <pd-button class="refresh-btn" :loading="logLoading" @click="handleShowLog">刷新
              </pd-button>
              <p class="log-content-desc"><span>描述：</span><span
                class="point-content">{{ selectedLog.desc }}</span></p>
              <p class="log-content-desc"><span>路径：</span><span
                class="point-content">{{ selectedLog.path }}</span></p>
              <pre>{{ selectedLog.content }}</pre>
            </div>
          </TabPane>
        </Tabs>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleCancelDelete">关闭
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditJob"
      title="修改订阅"
      footer-hide
      width="1200px"
      :closable="false"
    >
      <div>
        <EditJob :updateSourceSchema="updateSourceSchema" :handleCancelEdit="handleCancelEdit"
                 :jobData="jobData" :showEditJob="showEditJob" :queryJobSchema="queryJobSchema"
                 :sourceSchema="sourceSchema" :mappingDef="mappingDef"
                 :mqSchemaTemplate="mqSchemaTemplate" :targetSchema="targetSchema"></EditJob>
      </div>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import EditJob from '../../createJob/EditJob';
import ConfigMapping from '../ConfigMapping';
import MappingUtil from '../../../../../views/util';
import DataJobDetailMixin from '../../../mixin/dataJobDetailMixin';

export default {
  mixins: [DataJobDetailMixin],
  components: {
    EditJob,
    ConfigMapping
  },
  props: {
    showMapping: Boolean,
    jobData: Object,
    getAddedData: Function,
    selectedIndex: Number,
    mappingDef: Array,
    sourceSchema: Array,
    getMappingCount: Function,
    showTableMapping: Object,
    sourceTable: Object,
    showResetPosition: Boolean,
    binlogTime: Date,
    handleResetPosition: Function,
    handleChangeBinlogCancel: Function,
    showPartition: Boolean,
    partition: Object,
    showWhereCondition: Boolean,
    showEditJobDesc: Boolean,
    selectedJob: Object,
    handleConfirmEdit: Function,
    showConfirm: Boolean,
    handleCancelDelete: Function,
    showLog: Boolean,
    handleSelectLog: Function,
    logLoading: Boolean,
    handleShowLog: Function,
    logData: Array,
    showEditJob: Boolean,
    handleCancelEdit: Function,
    showClearPosition: Boolean,
    currentWhereCondition: String,
    taskPosition: Object,
    handleChangeTable: Function,
    updatePositionInfo: Function,
    selectedLog: Object,
    handlePageChange: Function,
    updateLogFileName: Function,
    updateSourceSchema: Function,
    mqSchema: Object,
    targetSchema: Array,
    handleShowWhereCondition: Function,
    positionLoading: Boolean,
    mqSchemaTemplate: String,
    queryJobSchema: Object,
    posTypeList: Array
  },
  data() {
    return {
      fecha,
      MappingUtil,
      startTimeOptions: {
        disabledDate: (date) => date && date.valueOf() > new Date(this.taskPosition.positionTimestamp)
          || date.valueOf() < new Date(new Date(this.taskPosition.positionTimestamp).getTime() - 7 * 24 * 3600 * 1000)
      },
      verifyCode: '',
      logFileName: '',
      theBinlogTime: '',
      posType: '',
      gtid: '',
      theLogFile: '',
      logFilePosition: '',
      lsn: ''
    };
  },
  computed: {},
  methods: {},
  watch: {
    taskPosition(val) {
      this.theBinlogTime = new Date(val.positionTimestamp);
    },
    posTypeList(val) {
      if (val) {
        this.posType = val[0];
      }
    }
  }
};
</script>
