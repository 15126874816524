<template>
  <div class="page-header-container">
    <Form ref="formInline" :model="searchKey" inline label-position="left"
          style="padding-right: 300px">
      <FormItem>
        <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
          <Option value="type" label="类型">
            <span>类型</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='type'">
        <Select v-model="searchKey.dbType" style="width:250px">
          <Option value="all">全部</Option>
          <Option v-for="(type) of dataSourceTypes" :value="type" :key="type">{{ type }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <pd-button type="primary" @click="_handleSearch">{{ $t('search') }}</pd-button>
      </FormItem>
    </Form>
    <div class="page-header-function">
      <pd-button type="primary" ghost @click="handleShowAddDataSource">
        <Icon type="md-add"/>
        新增数据源
      </pd-button>
      <pd-button type="default" style="margin-left: 6px" @click="_handleSearch"
                 :loading="refreshLoading">
        刷新
      </pd-button>
    </div>
  </div>
</template>
<script>
import { listFilterDsTypes } from '@services/api/constant';

export default {
  props: {
    handleSearch: Function,
    handleShowAddDataSource: Function,
    refreshLoading: Boolean
  },
  data() {
    return {
      searchType: 'type',
      searchKey: {
        host: '',
        region: '',
        dbType: ''
      },
      dataSourceTypes: []
    };
  },
  mounted() {
    const params = JSON.parse(sessionStorage.getItem('datasource_search_params'));
    if (params) {
      this.searchKey = params;
      this.searchType = params.searchType;
      this.handleSearch(params);
    } else {
      this.handleSearch(this.searchKey);
    }
    listFilterDsTypes()
      .then((res) => {
        if (res.data.code === '1') {
          this.dataSourceTypes = res.data.data;
        }
      });
  },
  methods: {
    _handleSearch() {
      sessionStorage.setItem('datasource_search_params', JSON.stringify({ searchType: this.searchType, ...this.searchKey }));
      this.handleSearch(this.searchKey);
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        key: '',
        tag: []
      };
    }
  }
};
</script>
<style lang="less" scoped>
.page-header-container {
  background: #ffffff;
  border: 1px solid #EDEDED;
  height: 60px;
  line-height: 54px;
  padding: 0 20px;
  position: relative;

  .ivu-form-inline .ivu-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .ivu-form-item {
    margin-bottom: 0;
  }

  .page-header-function {
    position: absolute;
    right: 20px;
    top: 2px;

    a {
      color: #333;
      margin-right: 10px;
    }

    button {
      margin-left: 8px;
    }

    .ivu-tooltip {
      margin-left: 8px;
    }
  }
}

.data-job-mode-switch {
  width: 32px;
  height: 32px;
  border: 1px solid #BABDC5;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  vertical-align: middle;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
</style>
