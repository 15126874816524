<template>
  <div>
    <EditJobOne ref="editJob1" :updateSourceSchema="updateSourceSchema" v-if="currentStep===0"
                :jobData="jobData" :targetSchema="targetSchema" :queryJobSchema="queryJobSchema"
                :showEditJob="showEditJob" :sourceSchema="sourceSchema" :mappingDef="mappingDef"
                :updateLoading="updateLoading"></EditJobOne>
    <EditJobTwo :mqSchemaTemplate="mqSchemaTemplate" ref="editJob2" v-if="currentStep===1"
                :newData="newData" :jobData="jobData" :updateLoading="updateLoading"></EditJobTwo>
    <EditJobThree :selectedColumnList="selectedColumns" :sourceTableList="sourceTableList"
                  :newData="newData" :reduceData="reduceData" v-if="currentStep===2"></EditJobThree>
    <div class="table-filter-footer">
      <Button style="margin-right: 20px" :loading="loading" v-if="currentStep===0" type="primary" @click="handleGoStep(1)">
        下一步：修改列
      </Button>
      <Button style="margin-right: 20px" :loading="loading" v-if="currentStep===1" type="primary" @click="handleGoStep(2)">
        确认信息
      </Button>
      <Checkbox style="position: absolute;left: 340px;top: 26px" v-if="showChooseInitialSync&&currentStep===2" v-model="initialSync">全量数据初始化
        <Tooltip placement="top" transfer content="如不勾选全量数据初始化，将从增量数据开始迁移。如需完整的数据，请务必勾选数据初始化">
          <Icon style="font-size: 14px" type="ios-information-circle" />
        </Tooltip>
      </Checkbox>
      <Button style="margin-right: 20px" v-if="currentStep===2" type="primary" @click="handleConfirmEdit">确认修改订阅</Button>
      <Button @click="handleCancel">取消</Button>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { updateTransferObject } from '@services/api/job';
import { editJobInitialSyncSwitch } from '@services/api/constant';
import EditJobOne from './EditJobOne';
import EditJobTwo from './EditJobTwo';
import EditJobThree from './EditJobThree';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import CreateJobMixin from '../../mixin/createJobMixin';

export default {
  mixins: [CreateJobMixin],
  components: {
    EditJobThree,
    EditJobTwo,
    EditJobOne
  },
  props: {
    jobData: Object,
    handleCancelEdit: Function,
    showEditJob: Boolean,
    sourceSchema: Array,
    mappingDef: Array,
    updateSourceSchema: Function,
    mqSchemaTemplate: String,
    targetSchema: Array,
    queryJobSchema: Object
  },
  data() {
    return {
      loading: false,
      currentStep: 0,
      newData: {},
      selectedColumns: {},
      sourceTableList: [],
      reduceData: {},
      DataSourceGroup,
      sourceType: this.jobData.sourceDsVO.dataSourceType,
      sinkType: this.jobData.targetDsVO.dataSourceType,
      showChooseInitialSync: false,
      initialSync: true
    };
  },
  methods: {
    handleGoStep(n) {
      if (n === 1) {
        this.newData = this.$refs.editJob1.newData;
        this.sourceTableList = this.$refs.editJob1.sourceTableList;
        this.reduceData = this.$refs.editJob1.reduceData;
        const newDataLength = Object.keys(this.newData).length;
        const reduceDataLength = Object.keys(this.reduceData).length;

        let noData = false;

        Object.keys(this.newData)
          .map((item) => {
            if (!this.newData[item].tableList || this.newData[item].tableList.length < 1) {
              noData = true;
            }
            return null;
          });

        if (newDataLength === 0 && reduceDataLength === 0) {
          this.$Modal.warning({
            title: '修改订阅提示',
            content: '当前没有修改订阅库表，请正确选择需要编辑的内容'
          });
        } else if (noData) {
          this.$Modal.warning({
            title: '修改订阅提示',
            content: '当前有库没有选择任务表，请重新选择'
          });
        } else if (newDataLength === 0 && reduceDataLength !== 0) {
          this.currentStep = 2;
        } else {
          this.currentStep = 1;
        }
      } else if (n === 2) {
        this.selectedColumns = this.$refs.editJob2.selectedColumns;
        this.currentStep = 2;
      }
    },
    editJobInitialSyncSwitch() {
      editJobInitialSyncSwitch({
        dstDsType: this.jobData.targetDsVO.dataSourceType
      }).then((response) => {
        if (response.data.code === '1') {
          this.showChooseInitialSync = response.data.data;
        }
      });
    },
    handleConfirmEdit() {
      this.handleUpdateEdit();
    },
    handleUpdateEdit() {
      const newDataList = Object.values(this.newData);

      newDataList.map((item) => {
        item.sinkDb = item.targetDb;
        item.sourceDb = item.sourceDb.db || item.sourceDb;
        return null;
      });

      const reduceConfig = [];
      const dbMapping = {};
      const tableTopicMapping = {};
      const reduceMappingConfig = [];
      const tableMapping = {};
      const columnMapping = {};
      const targetReduceConfigList = [];
      const configData = this.getConfigData(newDataList, this.jobData.sourceDsVO.dataSourceType, this.jobData.targetDsVO.dataSourceType, 'edit', this.newData, this.selectedColumns);

      if (DataSourceGroup.hasSchema.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        dbMapping.method = 'DB_SCHEMA';
      } else if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
        dbMapping.method = 'SCHEMA_DB';
      } else {
        dbMapping.method = 'DB_DB';
      }
      dbMapping.serializeMapping = {};
      dbMapping.serializeAutoGenRules = {};
      dbMapping.commonGenRule = 'MIRROR';

      tableTopicMapping.serializeMapping = {};
      tableTopicMapping.method = 'TABLE_TOPIC';
      tableTopicMapping.serializeAutoGenRules = {};
      tableTopicMapping.commonGenRule = 'MIRROR';

      tableMapping.serializeMapping = {};
      tableMapping.method = 'TABLE_TABLE';
      tableMapping.serializeAutoGenRules = {};
      tableMapping.commonGenRule = 'MIRROR';

      columnMapping.method = 'COLUMN_COLUMN';
      columnMapping.serializeMapping = {};
      columnMapping.serializeAutoGenRules = {};
      columnMapping.commonGenRule = 'MIRROR';

      Object.keys(this.reduceData)
        .map((item) => {
          if (dbMapping.method === 'DB_SCHEMA') {
            if (this.reduceData[item].deleteAll) {
              const key = {
                value: this.reduceData[item].sourceDb && this.reduceData[item].sourceDb ? this.reduceData[item].sourceDb : this.reduceData[item].db
              };
              const
                value = {
                  parent: {
                    value: this.reduceData[item].targetDb
                  },
                  value: this.reduceData[item].targetSchema
                };

              dbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
            }
          } if (dbMapping.method === 'SCHEMA_DB') {
            if (this.reduceData[item].deleteAll) {
              const key = {
                parent: {
                  value: this.reduceData[item].sourceDb && this.reduceData[item].sourceDb ? this.reduceData[item].sourceDb : this.reduceData[item].db
                },
                value: this.reduceData[item].sourceSchema()
              };
              const
                value = {
                  value: this.reduceData[item].targetDb
                };

              dbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
            }
          } else if (this.reduceData[item].sourceDb !== this.reduceData[item].targetDb) {
            if (this.reduceData[item].deleteAll) {
              const key = {
                value: this.reduceData[item].sourceDb && this.reduceData[item].sourceDb ? this.reduceData[item].sourceDb : this.reduceData[item].db
              };
              const
                value = {
                  value: this.reduceData[item].targetDb
                };

              dbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
            }
          }
          const reduceConfigData = {
            db: item
          };

          if (this.reduceData[item].sourceSchema) {
            reduceConfigData.schemas = [];
            reduceConfigData.schemas.push({
              schema: this.reduceData[item].sourceSchema,
              schemaPattern: ''
            });
            if (this.reduceData[item].tableList) {
              reduceConfigData.schemas[0].tables = [];
              this.reduceData[item].tableList.map((table) => {
                reduceConfigData.schemas[0].tables.push({
                  table: table.sourceTable
                });
                if (table.sourceTable !== table.sinkTable) {
                  this.mappingDef.map((mapping) => {
                    if (mapping.method === 'TABLE_TABLE') {
                      const key = {
                        parent: {
                          value: table.sourceDb
                        },
                        value: table.sourceTable
                      };

                      if (mapping.serializeMapping[JSON.stringify(key)]) {
                        tableMapping.serializeMapping[JSON.stringify(key)] = mapping.serializeMapping[JSON.stringify(key)];
                        this.mappingDef.map((column) => {
                          if (column.method === 'COLUMN_COLUMN') {
                            column.serializeMapping.map((columnS) => {
                              const columnKey = {
                                parent: {
                                  parent: {
                                    value: table.sourceDb
                                  },
                                  value: table.sourceTable
                                },
                                value: columnS.sourceColumn
                              };

                              if (columnS[JSON.stringify(columnKey)]) {
                                columnMapping[JSON.stringify(columnKey)] = columnS[JSON.stringify(columnKey)];
                              }
                              return null;
                            });
                          }
                          return null;
                        });
                      }
                    }
                    return null;
                  });
                }
                return null;
              });
            }
          } else if (this.reduceData[item].tableList) {
            reduceConfigData.tables = [];
            this.reduceData[item].tableList.map((table) => {
              if (this.targetSchema) {
                this.targetSchema.map((topic) => {
                  if (topic.topic === table.targetTable) {
                    const hasTopic = targetReduceConfigList.find((theTopic) => theTopic.topic === topic.topic);

                    if (!hasTopic) {
                      targetReduceConfigList.push(topic);
                    }
                  } else if (topic.queue === table.targetTable) {
                    const hasTopic = targetReduceConfigList.find((theTopic) => theTopic.queue === topic.queue);

                    if (!hasTopic) {
                      targetReduceConfigList.push(topic);
                    }
                  }
                  return null;
                });
              }

              reduceConfigData.tables.push({
                table: table.sourceTable
              });
              if (table.sourceTable !== table.sinkTable) {
                this.mappingDef.map((mapping) => {
                  if (mapping.method === 'TABLE_TABLE') {
                    const key = {
                      parent: {
                        value: table.sourceDb
                      },
                      value: table.sourceTable
                    };

                    if (mapping.serializeMapping[JSON.stringify(key)]) {
                      tableMapping.serializeMapping[JSON.stringify(key)] = mapping.serializeMapping[JSON.stringify(key)];
                      this.mappingDef.map((column) => {
                        if (column.method === 'COLUMN_COLUMN') {
                          column.serializeMapping.map((columnS) => {
                            const columnKey = {
                              parent: {
                                parent: {
                                  value: table.sourceDb
                                },
                                value: table.sourceTable
                              },
                              value: columnS.sourceColumn
                            };

                            if (columnS[JSON.stringify(columnKey)]) {
                              columnMapping[JSON.stringify(columnKey)] = columnS[JSON.stringify(columnKey)];
                            }
                            return null;
                          });
                        }
                        return null;
                      });
                    }
                  } else if (mapping.method === 'TABLE_TOPIC') {
                    const key = {
                      parent: {
                        value: table.sourceDb.db || table.sourceDb
                      },
                      value: table.sourceTable
                    };
                    const
                      value = {
                        value: table.sinkTable ? table.sinkTable : table.targetTable
                      };
                    tableTopicMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                  }
                  return null;
                });
              }
              return null;
            });
          }
          reduceConfig.push(reduceConfigData);
          return null;
        });
      if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
        reduceMappingConfig.push(tableTopicMapping);
        reduceMappingConfig.push(columnMapping);
      } else {
        reduceMappingConfig.push(dbMapping);
        reduceMappingConfig.push(tableMapping);
        reduceMappingConfig.push(columnMapping);
      }
      console.log('reduce', reduceMappingConfig, configData);
      updateTransferObject({
        dataJobId: this.jobData.dataJobId,
        structMigration: configData.structMigration,
        addMappingConfig: configData.mappingDef ? JSON.stringify(configData.mappingDef) : null,
        sourceAddConfig: configData.finalSourceSchema,
        targetAddConfig: configData.finalTargetSchema,
        reduceConfig: reduceConfig.length > 0 ? JSON.stringify(reduceConfig) : null,
        reduceMappingConfig: reduceMappingConfig.length > 0 ? JSON.stringify(reduceMappingConfig) : null,
        targetReduceConfig: targetReduceConfigList.length > 0 ? JSON.stringify(targetReduceConfigList) : null,
        initialSync: this.initialSync
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.handleCancel();
            this.$Message.success('修改订阅成功！');
          }
        });
    },
    handleCancel() {
      this.handleCancelEdit();
      this.currentStep = 0;
      this.newData = {};
      if (this.$refs.editJob1) {
        this.$refs.editJob1.newData = {};
        this.$refs.editJob1.reduceData = {};
      }
      this.selectedColumns = {};
      this.sourceTableList = [];
      this.reduceData = {};
      this.newAddDb = [];
    },
    updateLoading(data) {
      this.loading = data;
    }
  },
  watch: {
    jobData(val) {
      console.log('val', val);
      if (val.targetDsVO) {
        this.editJobInitialSyncSwitch();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.table-filter-footer {
  padding-top: 20px;
  padding-bottom: 6px;
  text-align: center;
  position: relative;
}
</style>
