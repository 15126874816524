<template>
  <div class="cluster-container">
    <ClusterHeader :handleSearch="handleRefresh"
                   :handleAddCluster="handleAddCluster" :params="searchData"></ClusterHeader>
    <Table border :columns="resourceColumns" :data="showData" size="small"
           style="margin-top: 12px;margin-bottom: 64px">
      <template slot-scope="{ row }" slot="cluster">
        <div><a @click="handleCluster(row)">{{ row.clusterName }}</a></div>
      </template>
      <template slot-scope="{ row }" slot="action">
        <a style="margin-right: 16px" @click="handleCluster(row)">机器列表
        </a>
        <a style="margin-right: 16px" @click="handleDeleteCluster(row)">删除
        </a>
      </template>
      <template slot="clusterDesc" slot-scope={row}>
        <span style="padding-right: 30px;display: block">{{ row.clusterDesc }}</span>
        <i style="position: absolute;right: 20px;top: 0" @click="handleEditDClusterDesc(row)"
           class="iconfont iconbianji"></i>
      </template>
    </Table>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
              :page-size="size" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal
      v-model="showConfirmDelete"
      title="删除集群确认"
      footer-hide
    >
      <div>
        <p>确认要删除集群名称为{{ selectedCluster.clusterName }}的集群吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmDeleteCluster">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showAddCluster"
           :title="isEdit?'编辑集群':'新增集群'"
           width="800px"
           footer-hide
    >
      <div style="padding: 10px">
        <Form :model="addCluster" label-position="right" :label-width="120">
          <FormItem label="集群描述">
            <Input style="width: 280px" v-model="addCluster.clusterDesc"/>
          </FormItem>
          <FormItem label="云或机房名称">
            <RadioGroup v-model="addCluster.cloudOrIdcName" type="button"
                        @on-change="handleChangeCloudOrIdcName">
              <Radio v-for="(cloudOrIdcName) of cloudOrIdcNames"
                     :label="cloudOrIdcName"
                     :disabled="cloudOrIdcName!=='ALIBABA_CLOUD'&&cloudOrIdcName!=='SELF_MAINTENANCE'"
                     :key="cloudOrIdcName">{{ UtilMapping.cloudOrIdcName[cloudOrIdcName] }}
              </Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="地域">
            <RadioGroup v-model="addCluster.region" type="button">
              <Radio v-for="(region) of regions" :label="region" :key="region"
                     :disabled="supportedRegions.indexOf(region)===-1">
                {{ UtilMapping.region[region] }}
              </Radio>
            </RadioGroup>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmAddCluster">保存</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditDesc"
      title="修改集群描述"
      footer-hide
      width="400px"
    >
      <div>
        <p>
          修改集群名称为{{ selectedRow.clusterName }}的集群的描述为
        </p>
        <Input v-model="clusterDesc" style="width: 280px;margin-top: 20px"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditDesc">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import {
  createCluster,
  deleteCluster,
  listClusterByCondition,
  updateDesc
} from '@services/api/cluster';
import {
  listCloudOrIdcNames,
  listRegions,
  listSupportedRegions,
  queryDeployMode
} from '@services/api/constant';
import store from '@store';
import ClusterHeader from '@components/function/cluster/ClusterHeader';
import fecha from 'fecha';
import UtilMapping from '../util';

export default {
  components: {
    ClusterHeader
  },
  computed: {
    computedCloudOrIdcNames() {
      return this.cloudOrIdcNames.filter((cloudOrIdcName) => cloudOrIdcName === 'ALIBABA_CLOUD' || cloudOrIdcName === 'SELF_MAINTENANCE');
    }
  },
  mounted() {
    queryDeployMode()
      .then((res) => {
        if (res.data.code === '1') {
          this.deployMode = res.data.data;
        }
      });
    this.listRegions();
    this.listCloudOrIdcNames();
  },
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      showEditDesc: false,
      selectedRow: '',
      clusterDesc: '',
      deployMode: '',
      supportedRegions: [],
      UtilMapping,
      store,
      refreshLoading: false,
      isEdit: true,
      showAddCluster: false,
      showConfirmDelete: false,
      selectedCluster: {},
      regions: [],
      cloudOrIdcNames: [],
      addCluster: {
        clusterName: '',
        region: 'customer',
        cloudOrIdcName: 'SELF_MAINTENANCE'
      },
      searchData: {
        cloudOrIdcName: '',
        clusterDesc: '',
        clusterName: ''
      },
      resourceColumns: [
        {
          title: '集群名称',
          key: 'clusterName',
          slot: 'cluster',
          width: 300
        },
        {
          title: '集群描述',
          key: 'clusterDesc',
          slot: 'clusterDesc',
          minWidth: 200
        },
        {
          title: '类型',
          key: 'cloudOrIdcName',
          width: 240,
          render: (h, params) => h('div', {}, UtilMapping.cloudOrIdcName[params.row.cloudOrIdcName])
        },
        {
          title: '机器数量',
          key: 'workerCount',
          width: 120
        },
        {
          title: '可用数量',
          key: 'runningCount',
          width: 120
        },
        {
          title: '不可用数量',
          key: 'abnormalCount',
          width: 120
        },
        {
          title: '地域',
          key: 'region',
          width: 200,
          render: (h, params) => h('div', {}, UtilMapping.region[params.row.region])
        },
        {
          title: '创建人',
          key: 'ownerName',
          width: 120
        },
        {
          title: '创建时间',
          key: 'ownerName',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '操作',
          key: '',
          slot: 'action',
          width: 180,
          fixed: 'right'
        }
      ],
      resourceData: [],
      showData: [],
      pagingData: []
    };
  },
  methods: {
    handleRefresh(data) {
      this.searchData = data;
      this.getClusterList(data);
    },
    handleCluster(row) {
      localStorage.setItem(`cluster-${row.id}`, JSON.stringify(row));
      this.$router.push({ path: `/system/resource/${row.id}` });
    },
    handleAddCluster() {
      this.isEdit = false;
      this.showAddCluster = true;
    },
    handleDeleteCluster(row) {
      this.selectedCluster = row;
      this.showConfirmDelete = true;
    },
    handleConfirmDeleteCluster() {
      this.showConfirmDelete = false;
      this.selectedCluster.clusterId = this.selectedCluster.id;
      deleteCluster(this.selectedCluster)
        .then((res) => {
          if (res.data.code === '1') {
            this.getClusterList(this.searchData);
            this.$Message.success('删除成功！');
          }
        });
    },
    handleEditCluster(row) {
      this.addCluster = _.cloneDeep(row);
      this.isEdit = true;
      this.showAddCluster = true;
    },
    handleConfirmAddCluster() {
      this.showAddCluster = false;
      createCluster(this.addCluster)
        .then((res) => {
          if (res.data.code === '1') {
            this.getClusterList(this.searchData);
            this.$Message.success('添加成功！');
            this.addCluster = {
              clusterName: '',
              region: 'hangzhou',
              cloudOrIdcName: 'ALIBABA_CLOUD'
            };
          }
        });
    },
    getClusterList(data) {
      this.refreshLoading = true;
      listClusterByCondition(data)
        .then((res) => {
          this.resourceData = res.data.data;
          this.total = this.resourceData.length;
          this.pagingData = _.cloneDeep(this.resourceData);
          this.showData = this.resourceData.slice((this.page - 1) * this.size, this.page * this.size);
          this.refreshLoading = false;
        })
        .catch(() => {
          this.refreshLoading = false;
        });
    },
    listRegions() {
      listRegions()
        .then((res) => {
          if (res.data.code === '1') {
            this.regions = res.data.data;
            listSupportedRegions({ cloudOrIdcName: this.addCluster.cloudOrIdcName })
              .then((response) => {
                if (res.data.code === '1') {
                  this.supportedRegions = response.data.data;
                }
              });
          }
        });
    },
    listCloudOrIdcNames() {
      listCloudOrIdcNames()
        .then((res) => {
          if (res.data.code === '1') {
            this.cloudOrIdcNames = res.data.data;
            if (store.state.globalConfig.product_trial) {
              this.cloudOrIdcNames = ['SELF_MAINTENANCE'];
            }
          }
        });
    },
    handleCancel() {
      this.showAddCluster = false;
      this.addCluster = {
        clusterName: '',
        region: 'hangzhou',
        cloudOrIdcName: 'ALIBABA_CLOUD'
      };
      this.showConfirmDelete = false;
    },
    handleChangeCloudOrIdcName(data) {
      this.listRegions();
      if (data === 'SELF_MAINTENANCE') {
        this.addCluster.region = 'customer';
      } else {
        this.addCluster.region = 'hangzhou';
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.resourceData.slice((this.page - 1) * this.size, this.page * this.size);
      this.showData.map((item) => {
        item.showEditDesc = false;
        return null;
      });
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    handleEditDClusterDesc(row) {
      this.clusterDesc = row.clusterDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handleConfirmEditDesc() {
      this.showEditDesc = false;
      updateDesc({
        clusterId: this.selectedRow.id,
        clusterName: this.selectedRow.clusterName,
        region: this.selectedRow.region,
        cloudOrIdcName: this.selectedRow.cloudOrIdcName,
        clusterDesc: this.clusterDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.getClusterList(this.searchData);
            this.$Message.success('修改成功！');
            this.addCluster = {
              clusterName: '',
              region: 'hangzhou',
              cloudOrIdcName: 'ALIBABA_CLOUD'
            };
          }
        });
    },
    handleCancelEdit() {
      this.showEditDesc = false;
    }
  }
};
</script>
<style lang="less">
.cluster-container {
  .iconfont {
    font-size: 12px;
  }
}

.resource-manager-wrapper {
  padding: 24px;
  background: #ffffff;
  margin-top: 16px;
}

.iconbianji {
  color: #8D95A6;
  cursor: pointer;
}
</style>
