<template>
  <div class="task-create-first-step">
    <Form ref="taskInfo" :model="taskInfo" label-position="right" :label-width="90"
          :rules="ruleValidate">
      <h4>绑定集群（任务运行集群）</h4>
      <FormItem label="绑定集群" prop="clusterId">
        <Select v-model="taskInfo.clusterId" style="width:280px" @on-change="handleChangeCluster"
                filterable>
          <Option v-for="(cluster) in taskInfo.clusterList" :value="cluster.id" :key="cluster.id"
                  :label="cluster.clusterDesc?cluster.clusterDesc:cluster.clusterName">
            <p>{{ cluster.clusterName }}</p>
            <p style="color:#ccc;margin: 5px 0">{{ cluster.clusterDesc }} <span
              style="margin-left: 8px">{{ cluster.runningCount }}/{{ cluster.workerCount }}</span>
            </p>
          </Option>
        </Select>
        <span v-if="this.currentCluster.runningCount===0"><span style="color: #FF6E0C"><i
          style="margin-left: 10px;margin-right: 8px"
          class="iconfont iconTIP"></i>该集群无存活机器，</span><a
          :href="`/#/system/resource/${currentCluster.id}`">管理机器</a></span>
      </FormItem>
      <h4>源库和目标库设置</h4>
      <Row :gutter="16">
        <Col span="12">
          <div>
            <DataSourceSetting type="source" :taskInfo="taskInfo"
                               :handleDeployTypeChange="handleDeployTypeChange"
                               :handleTypeChange="handleTypeChange" :handleDbChange="handleDbChange"
                               :hasTheDb="hasTheDb"
                               :getSchemaList="getSchemaList" :store="store"
                               :handleRemoveMap="handleRemoveMap"
                               :containsDb="containsDb" :handleAddMap="handleAddMap"
                               :handleInstanceChange="handleInstanceChange"
                               :showSinkAddWhiteList="showSinkAddWhiteList"
                               :handleAddWhiteList="handleAddWhiteList"
                               :handleTestConnection="handleTestConnection"
                               :handleChangeNetType="handleChangeNetType" :dbMap="dbMap"
                               :handleTaskName="handleTaskName"
                               :test2="test2" :dsAttrs="dsAttrs"
                               :showSourceAddWhiteList="showSourceAddWhiteList"
                               :charsetList="sourceCharsetList"
                               :test1="test1"></DataSourceSetting>
          </div>
        </Col>
        <Col span="12">
          <div>
            <DataSourceSetting type="sink" :taskInfo="taskInfo"
                               :handleDeployTypeChange="handleDeployTypeChange"
                               :handleTypeChange="handleTypeChange" :handleDbChange="handleDbChange"
                               :hasTheDb="hasTheDb"
                               :getSchemaList="getSchemaList" :store="store"
                               :handleRemoveMap="handleRemoveMap"
                               :containsDb="containsDb" :handleAddMap="handleAddMap"
                               :handleInstanceChange="handleInstanceChange"
                               :showSinkAddWhiteList="showSinkAddWhiteList"
                               :handleAddWhiteList="handleAddWhiteList"
                               :handleTestConnection="handleTestConnection"
                               :handleChangeNetType="handleChangeNetType" :dbMap="dbMap"
                               :handleTaskName="handleTaskName"
                               :test2="test2" :dsAttrs="dsAttrs"
                               :showSourceAddWhiteList="showSourceAddWhiteList"
                               :charsetList="targetCharsetList"
                               :test1="test1"></DataSourceSetting>
          </div>
        </Col>
      </Row>
    </Form>
    <Form ref="dbMappingForm" :model="taskInfo" label-position="right" :rules="dsRuleValidate">
      <DbMapping
        v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)===-1&&DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType)===-1"
        :dbMap="dbMap" :handleDbChange="handleDbChange" :showEditMapping="showEditMapping"
        :hasSelectedTheDb="hasSelectedTheDb" ref="dbMapping"
        :updateShowEditMapping="updateShowEditMapping"
        :hasTheDb="hasTheDb" :taskInfo="taskInfo" :getSchemaList="getSchemaList" :store="store"
        :handleRemoveMap="handleRemoveMap" :handleAddMap="handleAddMap"
        :handleSchemaChange="handleSchemaChange"
        :containsDb="containsDb" :handleChangeSchema="handleChangeSchema"
        :currentSourceDb="currentSourceDb"
        :containsSchema="containsSchema"></DbMapping>
    </Form>
    <Modal v-model="showAddWhiteList" title="添加白名单" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentAddWhiteListStatus==='SUCCESS'?'success':(currentAddWhiteListStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentAddWhiteListStatus === 'SUCCESS' ? '添加成功' : currentAddWhiteListStatus === 'FAILED' ? '添加失败' : '添加白名单中...'
          }}</p>
      </div>
    </Modal>
    <StToken ref="stToken"></StToken>
    <AliyunAKSK ref="aliyunAKSK" :nextStep="nextStep"></AliyunAKSK>
    <Modal v-model="showNoTableDb" title="创建任务提示" footer-hide>
      <div>
        <p style="margin-bottom: 20px;font-weight: 500;">
          CloudCanal目前不支持迁移空库，以下库为空库，表的数据为0。是否需要自动剔除以下库，并继续创建任务？
        </p>
        <Card dis-hover style="max-height: 280px;overflow:auto;width: 100%">
          <span style="line-height: 24px;margin-right: 20px;display: inline-block"
                v-for="db in hasNoTableDb" :key="db">{{ db }}</span>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleRemoveNoDbTables">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  getDbList, listbycondition, testConnection, schemasTableCount, queryDsAttrs
} from '@services/api/datasource';
import {
  dsCharsetOptions,
  listDatasourceDeployType,
  supportedsourcedstype,
  supportedtargetdstype
} from '@services/api/constant';
import { addClusterWhiteList, queryWhiteListExist } from '@services/api/ailyunRds';
import { queryConsoleJob } from '@services/api/consoleJob';
import _ from 'lodash';
import { listClusterByCondition } from '@services/api/cluster';
import store from '../../../../store';
import Mapping from '../../../../views/util';
import StToken from '../../ApplyStToken';
import DbMapping from './originalConfig/DbMapping';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import DataSourceSetting from './originalConfig/DataSourceSetting';
import AliyunAKSK from '../../ApplyAKSK';

export default {
  // 源端命名为source,目标端命名为sink
  name: 'Originalconfig',
  props: {
    updateStep: Function,
    taskInfo: Object,
    deployType: String,
    updateLoading: Function
  },
  components: {
    StToken,
    DbMapping,
    DataSourceSetting,
    AliyunAKSK
  },
  created() {
    if (!this.taskInfo.originalConfigHistory) {
      this.listCluster('init');
    }

    // this.store = store;
  },
  // mounted() {
  //   this.getInitInitInfo();
  // },
  beforeDestroy() {
    clearInterval(this.querySourceAddWhiteListInterval);
    clearInterval(this.querySinkAddWhiteListInterval);
    clearInterval(this.getPercantage);
  },
  data() {
    return {
      showNoTableDb: false,
      hasNoTableDb: [],
      DataSourceGroup,
      currentCluster: '',
      currentRegion: '',
      currentPercentage: 0,
      showSourceAddWhiteList: false,
      showSinkAddWhiteList: false,
      showAddWhiteList: false,
      showEditMapping: false,
      currentAddWhiteListStatus: '',
      getPercantage: '',
      showType: 'query',
      showAkSkType: 'source',
      aliyunAk: '',
      aliyunSk: '',
      showManualAccount1: false,
      deployMode: 'CLOUD_PUBLIC',
      exeStartTime: new Date(),
      currentSourceDb: '',
      Mapping,
      store: {},
      test1: false,
      test2: false,
      showTest1: false,
      showTest2: false,
      sourceConnection: false,
      sinkConnection: false,
      hasSourceDb: true,
      showPreCheck: false,
      showAddDataSource: false,
      querySourceAddWhiteListInterval: '',
      querySinkAddWhiteListInterval: '',
      dataSourceTypes: [],
      sourceDataSourceTypes: [],
      sinkDataSourceTypes: [],
      dataSourceDeployTypes: [],
      addDataSourceType: 'source',
      sourceCharsetList: [],
      targetCharsetList: [],
      dsAttrs: {},
      goNext: true,
      dbMap: [
        {
          sourceDb: '',
          sinkDb: '',
          targetSchema: 'public',
          sourceSchema: 'public'
        }
      ],
      selectedDB: {
        source: {},
        sink: {}
      },
      ruleValidate: {
        sourceType: [
          {
            required: true,
            message: '请选择数据源类型',
            trigger: 'change'
          }
        ],
        sourceInstance: [
          {
            required: true,
            message: '请选择数据库实例',
            trigger: 'change'
          }
        ],
        sourceAccount: [
          {
            required: true,
            type: 'string',
            message: '请输入账号',
            trigger: 'blur'
          }
        ],
        sourcePasswd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          }
        ],
        sinkType: [
          {
            required: true,
            message: '请选择数据源类型',
            trigger: 'change'
          }
        ],
        sinkInstance: [
          {
            required: true,
            message: '请选择数据库实例',
            trigger: 'change'
          }
        ],
        sinkAccount: [
          {
            required: true,
            type: 'string',
            message: '请输入账号',
            trigger: 'blur'
          }
        ],
        sinkPasswd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          }
        ],
        sourceInstanceType: [
          {
            required: true,
            message: '请选择数据源类型',
            trigger: 'change'
          }
        ],
        sinkInstanceType: [
          {
            required: true,
            message: '请选择数据源类型',
            trigger: 'change'
          }
        ],
        sourceCharset: [
          {
            required: true,
            message: '请选择字符集',
            trigger: 'change'
          }
        ],
        targetCharset: [
          {
            required: true,
            message: '请选择字符集',
            trigger: 'change'
          }
        ],
        defaltValue: [
          {
            required: true,
            message: '请选择默认Topic',
            trigger: 'change'
          }
        ],
        clusterId: [
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.clusterId) {
                return callback(new Error('请选择绑定集群'));
              }
              return callback();
            },
            trigger: 'change'
          }
        ],
        sourceInstanceId: [
          {
            required: true,
            message: '请选择数据源',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.sourceConnection) {
                return callback(new Error('请对数据源进行测试连接'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        sinkInstanceId: [
          {
            required: true,
            message: '请选择数据源',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.sinkConnection) {
                return callback(new Error('请对数据源进行测试连接'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        consumerGroupId: [
          {
            required: true,
            message: '请填写Consumer Group',
            trigger: 'blur'
          }
        ],
        srcRabbitMqVhost: [
          {
            required: true,
            message: '请填写RabbitMqVhost',
            trigger: 'blur'
          }
        ],
        dstRabbitMqVhost: [
          {
            required: true,
            message: '请填写RabbitMqVhost',
            trigger: 'blur'
          }
        ],
        dstRabbitExchange: [
          {
            required: true,
            message: '请填写dstRabbitExchange',
            trigger: 'blur'
          }
        ],
        hasDb: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let hasDbs = true;

              this.taskInfo.dbMap.forEach((item) => {
                if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                  if (!item.sinkDb) {
                    hasDbs = false;
                  }
                } else if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
                  if (!item.sourceDb) {
                    hasDbs = false;
                  }
                } else if (!item.sourceDb || !item.sinkDb) {
                  hasDbs = false;
                }
              });
              if (!hasDbs) {
                return callback(new Error('请正确选择数据库信息'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ]
      },
      dsRuleValidate: {
        hasDb: [
          {
            validator: (rule, value, callback) => {
              let hasDbs = true;

              this.taskInfo.dbMap.forEach((item) => {
                if (!item.sourceDb || !item.sinkDb) {
                  hasDbs = false;
                }
              });
              if (!hasDbs) {
                return callback(new Error('请正确选择数据库信息'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ]
      },
      nextStep: null
    };
  },
  methods: {
    listCluster(type) {
      listClusterByCondition({})
        .then((res) => {
          if (res.data.success) {
            const clusterList = res.data.data;
            this.taskInfo.clusterList = clusterList;
            for (let i = 0; i < clusterList.length; i++) {
              const cluster = clusterList[i];
              if (cluster.runningCount > 0) {
                this.taskInfo.clusterId = cluster.id;
                break;
              }
            }
            this.init(type);
          }
        });
    },
    init(type) {
      if (!this.sourceConnection || !this.sinkConnection) {
        this.listDatasourceDeployType(type);
      }
      this.getInitInitInfo(type);
    },
    getInitInitInfo() {
      this.getCharsetList('source');
      this.getCharsetList('sink');
    },
    handleTaskName(name) {
      this.taskInfo.taskName = name;
    },
    listDatasourceDeployType(type) {
      listDatasourceDeployType()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.dataSourceDeployTypes = res.data.data;
            this.handleChangeCluster(type);
          }
        });
    },
    getSourceDataSourceList() {
      const deployType = this.taskInfo.sourceInstanceType;
      const type = this.taskInfo.sourceType;

      listbycondition({
        type: this.taskInfo.sourceType,
        deployType: this.taskInfo.sourceInstanceType,
        lifeCycleState: 'CREATED',
        hostType: this.taskInfo.sourceHostType
      })
        .then((res) => {
          if (res.data.code === '1' && deployType === this.taskInfo.sourceInstanceType && type === this.taskInfo.sourceType) {
            this.taskInfo.sourceInstanceList = res.data.data;
            if (store.state.jobData && this.taskInfo.sourceDataSourceId) {
              this.handleTestConnection(1);
            }
          }
        });
    },
    getSinkDataSourceList(operatorType) {
      const deployType = this.taskInfo.sinkInstanceType;
      const type = this.taskInfo.sinkType;

      console.log('operatorType', operatorType);

      listbycondition({
        type: this.taskInfo.sinkType,
        deployType: this.taskInfo.sinkInstanceType,
        lifeCycleState: 'CREATED',
        hostType: this.taskInfo.targetHostType
      })
        .then((res) => {
          if (res.data.code === '1' && deployType === this.taskInfo.sinkInstanceType && type === this.taskInfo.sinkType) {
            this.taskInfo.sinkInstanceList = res.data.data;
            if (store.state.jobData && this.taskInfo.targetDataSourceId) {
              this.handleTestConnection(2);
              if (operatorType === 'init') {
                console.log('test1');
                const jobData = store.state.jobData;
                this.taskInfo.sinkInstanceId = jobData.targetDsVO.instanceId;
                this.taskInfo.sinkPublicHost = jobData.targetDsVO.publicHost;
                this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkInstance}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                  this.taskInfo.sinkInstanceId}`;
              }
            }
          }
        });
    },
    handleDeployTypeChange(type, operateType) {
      if (type === 'source') {
        supportedsourcedstype({ deployType: this.taskInfo.sourceInstanceType })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.sourceDataSourceTypes = res.data.data;
              const supportDs = [];

              Object.keys(res.data.data)
                .forEach((item) => {
                  if (res.data.data[item]) {
                    supportDs.push(item);
                  }
                });
              // this.taskInfo.sourceType = supportDs[0];
              this.taskInfo.sourceInstanceValue = `${this.taskInfo.sourceInstance}|${this.taskInfo.sourceDataSourceId}|${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourcePublicHost}|${
                this.taskInfo.sourceInstanceId}`;
              this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkInstance}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                this.taskInfo.sinkInstanceId}`;
              if (this.currentCluster.cloudOrIdcName === 'SELF_MAINTENANCE'
                && this.taskInfo.sourceInstanceType !== 'SELF_MAINTENANCE' || this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD'
                && this.taskInfo.sourceInstanceType !== 'ALIBABA_CLOUD_HOSTED') {
                this.taskInfo.sourceHostType = 'PUBLIC';
              } else {
                this.taskInfo.sourceHostType = 'PRIVATE';
              }
              this.getSourceDataSourceList();
              this.handleDeployTypeChange('sink', operateType);
            }
          });
        this.taskInfo.sourceDbList = [];
        this.taskInfo.showSourceAddWhiteList = false;
      } else {
        if (this.taskInfo.sourceType) {
          supportedtargetdstype({
            deployType: this.taskInfo.sinkInstanceType,
            sourceType: this.taskInfo.sourceType
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.taskInfo.sinkDataSourceTypes = res.data.data;
                const supportDs = [];

                Object.keys(res.data.data)
                  .forEach((item) => {
                    if (res.data.data[item]) {
                      supportDs.push(item);
                    }
                  });
                this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkInstance}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                  this.taskInfo.sinkInstanceId}`;
                if (this.currentCluster.cloudOrIdcName === 'SELF_MAINTENANCE'
                  && this.taskInfo.sinkInstanceType !== 'SELF_MAINTENANCE' || this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD'
                  && this.taskInfo.sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED') {
                  this.taskInfo.targetHostType = 'PUBLIC';
                } else {
                  this.taskInfo.targetHostType = 'PRIVATE';
                }
                // this.getSinkDataSourceList();
                this.handleTypeChange('sink', operateType);
              }
            });
          this.taskInfo.sinkDbList = [];
          this.taskInfo.showSinkAddWhiteList = false;
        }
      }
      this.taskInfo.dbMap = [
        {
          sourceDb: '',
          sinkDb: '',
          targetSchema: 'public',
          sourceSchema: 'public'
        }
      ];
    },
    handleShowPreCheck() {
      this.updateLoading(true);

      this.$refs.taskInfo.validate((val) => {
        if (val) {
          this.$refs.dbMappingForm.validate((val1) => {
            if (val1) {
              try {
                this.goNext = true;
                let ifStandard = true;
                const sourceDbAndSchema = {};
                const targetDbAndSchema = {};
                const
                  that = this;

                this.taskInfo.dbMap.forEach((item) => {
                  try {
                    if (targetDbAndSchema[item.sinkDb] && targetDbAndSchema[item.sinkDb] !== item.targetSchema) {
                      this.$Modal.warning({
                        title: '创建任务异常提示',
                        content: '目前暂不支持多库迁移下同库不同schema的情况，请拆分成多个任务。'
                      });
                      ifStandard = false;
                    } else {
                      targetDbAndSchema[item.sinkDb] = item.targetSchema;
                    }
                    if (sourceDbAndSchema[item.sourceDb] && sourceDbAndSchema[item.sourceDb] !== item.sourceSchema) {
                      this.$Modal.warning({
                        title: '创建任务异常提示',
                        content: '目前暂不支持多库迁移下同库不同schema的情况，请拆分成多个任务。'
                      });
                      ifStandard = false;
                    } else {
                      sourceDbAndSchema[item.sourceDb] = item.sourceSchema;
                    }
                    if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
                      item.needAutoCreated = false;
                    } else {
                      item.needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, item.sinkDb);
                    }
                  } catch (e) {
                    console.log('validate schema', e);
                  }
                });
                if (!ifStandard) {
                  this.updateLoading(false);

                  return;
                }
                // this.taskInfo.dbMap = this.dbMap;
                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) === -1
                  && DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) === -1) {
                  for (const db of this.taskInfo.dbMap) {
                    if (!db.sourceDb || !db.sinkDb) {
                      this.$Modal.warning({
                        title: '请填写完整且正确的信息',
                        content: '请点击测试连接后选择完整的源数据库和目标数据库'
                      });
                      this.updateLoading(false);
                      return;
                    }
                  }
                } else if (this.taskInfo.dbMap.length < 0) {
                  this.$Modal.warning({
                    title: '请填写完整且正确的信息',
                    content: '请至少选择一个库'
                  });
                  this.updateLoading(false);
                  return;
                }
                if (this.goNext) {
                  this.hasNoTableDb = [];

                  try {
                    const selectedSchemas = [];
                    this.taskInfo.dbMap.forEach((item) => {
                      selectedSchemas.push({
                        dbName: item.sourceDb,
                        schema: DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) ? item.sourceSchema : null
                      });
                    });
                    if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                      schemasTableCount({
                        dataSourceId: this.taskInfo.sourceDataSourceId,
                        clusterId: this.taskInfo.clusterId,
                        hostType: this.taskInfo.sourceHostType,
                        selectedSchemas
                      }).then((res) => {
                        if (res.data.code === '1') {
                          const responseData = res.data.data;
                          Object.keys(responseData).forEach((db) => {
                            if (responseData[db] === 0) {
                              this.hasNoTableDb.push(JSON.parse(db).dbName);
                            }
                          });
                          if (this.hasNoTableDb.length > 0) {
                            if (this.hasNoTableDb.length === this.taskInfo.dbMap.length) {
                              this.$Modal.warning({
                                title: '任务创建提示',
                                content: '当前所选库均为空库，表的数量为0，不支持迁移，请重新选择'
                              });
                            } else {
                              this.showNoTableDb = true;
                            }
                          } else {
                            setTimeout(() => {
                              if (that.goNext) {
                                that.updateStep(1);
                              }
                            }, 500);
                          }
                        }
                        this.updateLoading(false);
                      }).catch(() => {
                        this.updateLoading(false);
                      }).finally(() => {
                        this.updateLoading(false);
                      });
                    } else {
                      setTimeout(() => {
                        if (that.goNext) {
                          that.updateStep(1);
                        }
                      }, 100);
                      this.updateLoading(false);
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                }
              } catch (e) {
                console.log('catch error after validate', e);
              }
            } else {
              this.updateLoading(false);
            }
          });
        } else {
          this.updateLoading(false);
        }
      });
    },
    querySourceWhiteList() {
      const ids = [];

      ids.push(this.taskInfo.clusterId);
      queryWhiteListExist({
        dataSourceId: this.taskInfo.sourceDataSourceId,
        dataSourceType: this.taskInfo.sourceType,
        clusterIds: ids,
        dataSourceRegion: this.currentRegion,
        whiteListAddType: this.taskInfo.sourceHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.showSourceAddWhiteList = res.data.data.length > 0;
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
            this.nextStep = this.querySourceWhiteList;
          }
        });
    },
    handleInstanceChange(type, data) {
      if (type === 'source') {
        this.taskInfo.sourceDbList = [];
        if (data) {
          this.taskInfo.sourceInstance = data.split('|')[0];
          this.taskInfo.sourceDataSourceId = data.split('|')[1];
          this.taskInfo.sourcePrivateHost = data.split('|')[2];
          this.taskInfo.sourceInstanceId = data.split('|')[4];
          if (data.split('|')[3] === 'null') {
            this.taskInfo.sourcePublicHost = '';
          } else {
            this.taskInfo.sourcePublicHost = data.split('|')[3];
          }
          this.querySourceWhiteList();
        }
        this.taskInfo.showSourceConnection = false;
      } else {
        this.taskInfo.sinkDbList = [];
        if (data) {
          this.taskInfo.sinkInstance = data.split('|')[0];
          this.taskInfo.targetDataSourceId = data.split('|')[1];
          this.taskInfo.sinkPrivateHost = data.split('|')[2];
          this.taskInfo.sinkInstanceId = data.split('|')[4];
          if (data.split('|')[3] === 'null') {
            this.taskInfo.sinkPublicHost = '';
          } else {
            this.taskInfo.sinkPublicHost = data.split('|')[3];
          }
          this.querySinkWhiteList();
        }
        this.taskInfo.showSinkConnection = false;
      }
      this.taskInfo.functionalConfigHistory = false;
    },
    querySinkWhiteList() {
      const ids = [];

      ids.push(this.taskInfo.clusterId);
      queryWhiteListExist({
        dataSourceId: this.taskInfo.targetDataSourceId,
        dataSourceType: this.taskInfo.sinkType,
        clusterIds: ids,
        dataSourceRegion: this.currentRegion,
        whiteListAddType: this.taskInfo.targetHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.showSinkAddWhiteList = res.data.data.length > 0;
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
            this.nextStep = this.querySourceWhiteList;
          }
        });
    },
    getCharsetList(type) {
      if (type === 'source') {
        dsCharsetOptions({ dataSourceType: this.taskInfo.sourceType })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.sourceCharsetList = res.data.data.dsCharsetOptions;

              res.data.data.dsCharsetOptions.forEach((charset) => {
                if (charset.defaultChecked) {
                  this.taskInfo.sourceCharset = charset.dsCharset;
                }
              });
            }
          });
      } else {
        dsCharsetOptions({ dataSourceType: this.taskInfo.sinkType })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.targetCharsetList = res.data.data.dsCharsetOptions;

              res.data.data.dsCharsetOptions.forEach((charset) => {
                if (charset.defaultChecked) {
                  this.taskInfo.targetCharset = charset.dsCharset;
                }
              });
            }
          });
      }
    },
    handleDbChange(type, index, value) {
      // if (this.hasSelectedTheDb(value)) {
      //     return;
      // }
      if (type === 'source') {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          let hasSame = false;

          if (value) {
            if (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) === -1 || DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
              this.taskInfo.sinkDbList.forEach((sinkDb) => {
                if (sinkDb.dbName.toUpperCase() === value.toUpperCase()) {
                  this.taskInfo.dbMap[index].sinkDb = sinkDb.dbName.toLowerCase();

                  hasSame = true;
                }
              });
              if (!hasSame) {
                this.taskInfo.dbMap[index].sinkDb = value.toLowerCase();
              }
            }
          }
        } else {
          this.taskInfo.dbMap[index].sinkDb = value;
        }
        if (this.taskInfo.sinkConnection) {
          this.taskInfo.dbMap[index].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, value);
          if (DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1) {
            this.taskInfo.dbMap[index].needAutoCreated = false;
          }
        } else {
          this.taskInfo.dbMap[index].needAutoCreated = false;
        }
      } else {
        this.taskInfo.dbMap[index].sinkDb = value;

        this.taskInfo.dbMap[index].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, value);
      }
    },
    handleAddMap() {
      this.taskInfo.dbMap.push({
        sourceDb: '',
        sinkDb: '',
        targetSchema: 'public',
        sourceSchema: 'public'
      });
    },
    handleRemoveMap(i) {
      this.taskInfo.dbMap.splice(i, 1);
    },
    handleTestConnection(n) {
      if (n === 1) {
        // this.test1 = true;
        if (this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost) {
          this.taskInfo.sourceConnectionLoading = true;
          this.taskInfo.showSourceConnection = false;
          testConnection({
            host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
            privateHost: this.taskInfo.sourcePrivateHost,
            publicHost: this.taskInfo.sourcePublicHost,
            hostType: this.taskInfo.sourceHostType,
            type: this.taskInfo.sourceType,
            userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
            && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
            dataSourceId: this.taskInfo.sourceDataSourceId,
            clusterId: this.taskInfo.clusterId,
            specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
          })
            .then((res) => {
              this.taskInfo.sourceConnectionLoading = false;
              this.taskInfo.showSourceConnection = true;
              if (res.data.code === '1') {
                this.taskInfo.sourceConnection = true;
                this.queryDsAttrs('source');
                getDbList({
                  host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
                  privateHost: this.taskInfo.sourcePrivateHost,
                  publicHost: this.taskInfo.sourcePublicHost,
                  hostType: this.taskInfo.sourceHostType,
                  type: this.taskInfo.sourceType,
                  userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
                  && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
                  dataSourceId: this.taskInfo.sourceDataSourceId,
                  clusterId: this.taskInfo.clusterId
                })
                  .then((response) => {
                    this.taskInfo.sourceDbList = response.data.data;
                    if (store.state.jobData) {
                      this.parseSchemaData(store.state.jobData.schemaData);
                      this.dbMap = _.cloneDeep(this.taskInfo.dbMap);
                    }
                    const that = this;

                    this.$nextTick(() => {
                      // eslint-disable-next-line no-unused-expressions
                      ({ ...that.taskInfo });
                    });
                  });
              } else {
                this.taskInfo.sourceConnection = false;
              }
            })
            .catch(() => {
              this.taskInfo.sourceConnection = false;
            });
        }
      } else if (n === 2) {
        if (this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost) {
          this.taskInfo.sinkConnectionLoading = true;
          this.taskInfo.showSinkConnection = false;
          testConnection({
            host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
            privateHost: this.taskInfo.sinkPrivateHost,
            publicHost: this.taskInfo.sinkPublicHost,
            hostType: this.taskInfo.targetHostType,
            type: this.taskInfo.sinkType,
            userName: this.taskInfo.sinkAccount,
            password: this.taskInfo.sinkPasswd,
            dataSourceId: this.taskInfo.targetDataSourceId,
            clusterId: this.taskInfo.clusterId
          })
            .then((res) => {
              this.taskInfo.sinkConnectionLoading = false;
              this.taskInfo.showSinkConnection = true;
              if (res.data.code === '1') {
                this.taskInfo.sinkConnection = true;
                this.queryDsAttrs('sink');
                if (this.taskInfo.sinkType !== 'Kafka' && this.taskInfo.sinkType !== 'RocketMQ' && this.taskInfo.sinkType !== 'ElasticSearch') {
                  getDbList({
                    host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
                    privateHost: this.taskInfo.sinkPrivateHost,
                    publicHost: this.taskInfo.sinkPublicHost,
                    hostType: this.taskInfo.targetHostType,
                    type: this.taskInfo.sinkType,
                    userName: this.taskInfo.sinkAccount,
                    dataSourceId: this.taskInfo.targetDataSourceId,
                    clusterId: this.taskInfo.clusterId
                  })
                    .then((response) => {
                      this.taskInfo.sinkDbList = response.data.data;
                      this.taskInfo.dbMap[0].sinkDb = this.taskInfo.dbMap[0].sourceDb;
                      this.taskInfo.dbMap[0].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, this.taskInfo.dbMap[0].sourceDb);
                      if (DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1) {
                        this.taskInfo.dbMap[0].needAutoCreated = false;
                      }
                      if (store.state.jobData) {
                        this.parseSchemaData(store.state.jobData.schemaData);
                        // this.taskInfo.dbMap = _.cloneDeep(this.taskInfo.dbMap);
                      }
                    });
                }
              } else {
                this.taskInfo.sinkConnection = false;
              }
            })
            .catch(() => {
              this.taskInfo.sinkConnection = false;
            });
        }
      }
    },
    queryDsAttrs(type) {
      if (type === 'source') {
        queryDsAttrs({
          host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          clusterId: this.taskInfo.clusterId,
          specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
        }).then((res) => {
          if (res.data.code === '1') {
            this.dsAttrs = res.data.data;
            this.taskInfo.srcRabbitMqVhost = 'cloudcanal';
            this.taskInfo.srcRabbitExchange = this.taskInfo.sourceInstanceId;
          }
        });
      } else {
        queryDsAttrs({
          host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
          privateHost: this.taskInfo.sinkPrivateHost,
          publicHost: this.taskInfo.sinkPublicHost,
          hostType: this.taskInfo.targetHostType,
          type: this.taskInfo.sinkType,
          userName: this.taskInfo.sinkAccount,
          dataSourceId: this.taskInfo.targetDataSourceId,
          clusterId: this.taskInfo.clusterId
        }).then((res) => {
          if (res.data.code === '1') {
            this.dsAttrs = res.data.data;
            this.taskInfo.dstRabbitMqVhost = 'cloudcanal';
            this.taskInfo.dstRabbitExchange = this.taskInfo.sinkInstanceId;
          }
        });
      }
    },
    hasSelectedTheDb(item, type = 'sink') {
      for (const db of this.taskInfo.dbMap) {
        if (type === 'source' && item === db.sourceDb) {
          return true;
        }
        if (type === 'sink' && item === db.sinkDb) {
          return true;
        }
      }

      return false;
    },
    hasTheDb(item) {
      for (let i = 0; i < this.taskInfo.dbMap.length; i++) {
        if (item && item === this.taskInfo.dbMap[i].sourceDb) {
          return true;
        }
      }
      return false;
    },
    handleTypeChange(type, operatorType) {
      this.taskInfo.mappingMode = 'advanced';

      if (this.taskInfo.dbMap.length === 0) {
        this.taskInfo.dbMap = [
          {
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'public',
            sourceSchema: 'public'
          }
        ];
      }

      if (type === 'sink') {
        this.taskInfo.sinkInstanceId = '';
        this.taskInfo.sinkInstance = '';
        this.taskInfo.sinkInstanceValue = '';
        if (operatorType !== 'init' && operatorType !== undefined && store.state.jobData) {
          console.log('type change', operatorType, 'sink');
          this.taskInfo.targetDataSourceId = '';
        }

        this.getSinkDataSourceList(operatorType);
        // 目标端的pg\gp默认大小写不敏感
        if (DataSourceGroup.pg.indexOf(this.taskInfo.sinkType) > -1) {
          this.taskInfo.targetCaseSensitive = 'false';
        } else {
          this.taskInfo.targetCaseSensitive = 'true';
        }
        this.taskInfo.sinkExtraSetting = DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1;
        // this.$refs.dbMapping.handleEmptySinkQuery();
        this.taskInfo.showSinkConnection = false;
      } else if (type === 'source') {
        console.log('type change', operatorType, 'source');
        this.taskInfo.sourceInstanceId = '';
        this.taskInfo.sourceInstance = '';
        this.taskInfo.sourceInstanceValue = '';
        if (operatorType !== 'init' && operatorType !== undefined && store.state.jobData) {
          this.taskInfo.sourceDataSourceId = '';
        }
        this.getSourceDataSourceList();
        this.handleDeployTypeChange('sink', operatorType);
        this.taskInfo.showSourceConnection = false;
      }
      this.taskInfo.sinkDbList = [];
      this.taskInfo[`${type}Connection`] = false;

      this.getCharsetList(type);
    },
    containsDb(list, db) {
      let isSame = false;

      list.forEach((item) => {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          if (db) {
            if (item.dbName.toUpperCase() === db.toUpperCase()) {
              isSame = true;
            }
          }
        } else if (item.dbName === db) {
          isSame = true;
        }
      });
      return isSame;
    },
    containsSchema(list, db, schemaName) {
      let isSame = false;

      list.forEach((item) => {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          if (db && (item.dbName.toUpperCase() === db.toUpperCase())) {
            item.schemas.forEach((schema) => {
              if (this.taskInfo.targetCaseSensitive === 'false') {
                if (db) {
                  if (schema.toUpperCase() === schemaName.toUpperCase()) {
                    isSame = true;
                  }
                }
              } else if (schema === schemaName) {
                isSame = true;
              }
            });
          }
        } else if (item.dbName === db) {
          item.schemas.forEach((schema) => {
            if (this.taskInfo.targetCaseSensitive === 'false') {
              if (db) {
                if (schema.toUpperCase() === schemaName.toUpperCase()) {
                  isSame = true;
                }
              }
            } else if (schema === schemaName) {
              isSame = true;
            }
          });
        }
      });
      return isSame;
    },
    getSchemaList(sinkDbList, db) {
      let list = [];

      sinkDbList.forEach((item) => {
        if (item.dbName === db) {
          list = item.schemas;
        }
      });
      return list;
    },
    handleAddWhiteList(type) {
      this.showType = 'add';
      if (!type) {
        // eslint-disable-next-line no-param-reassign
        type = 'source';
      }
      this.addClusterWhiteList(type);
    },
    addClusterWhiteList(type) {
      this.currentPercentage = 0;
      if (type === 'source') {
        // this.sourceDisabled = true;
        const ids = [];

        ids.push(this.taskInfo.clusterId);
        addClusterWhiteList({
          dataSourceId: this.taskInfo.sourceDataSourceId,
          dataSourceType: this.taskInfo.sourceType,
          clusterIds: ids,
          dataSourceRegion: this.currentRegion,
          whiteListAddType: this.taskInfo.sourceHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
        })
          .then((res) => {
            if (res.data.code === '1') {
              if (this.dataIsNaN(res.data.data)) {
                this.showAddWhiteList = true;
                const that = this;

                this.querySourceAddWhiteListInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentAddWhiteListStatus = response.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          clearInterval(that.querySourceAddWhiteListInterval);
                          // that.sourceDisabled = false;
                          that.taskInfo.showSourceAddWhiteList = false;
                          setTimeout(() => {
                            that.showAddWhiteList = false;
                          }, 500);
                        } else if (response.data.data.taskState === 'FAILED') {
                          clearInterval(that.querySourceAddWhiteListInterval);
                          // that.sourceDisabled = false;
                        }
                      }
                    })
                    .catch(() => {
                      // that.sourceDisabled = false;
                    });
                }, 2000);
              } else {
                this.$Modal.success({
                  title: '正在添加白名单',
                  content: '正在添加白名单，请等待片刻，直到测试连接按钮亮起。'
                });
              }
            } else if (res.data.code === '6028') {
              this.$refs.stToken.handleShowAkSk();
            } else if (res.data.code === '6047') {
              this.$refs.aliyunAKSK.handleShowAkSk();
            }
            // this.sourceDisabled = false;
            this.showAddWhiteList = false;
          })
          .catch(() => {
            // this.sourceDisabled = false;
            this.showAddWhiteList = false;
          });
      } else {
        // this.sinkDisabled = true;
        const ids = [];

        ids.push(this.taskInfo.clusterId);
        addClusterWhiteList({
          dataSourceId: this.taskInfo.targetDataSourceId,
          dataSourceType: this.taskInfo.sinkType,
          clusterIds: ids,
          dataSourceRegion: this.currentRegion,
          whiteListAddType: this.taskInfo.targetHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
        })
          .then((res) => {
            if (res.data.code === '1') {
              const that = this;

              if (this.dataIsNaN(res.data.data)) {
                this.showAddWhiteList = true;
                this.querySinkAddWhiteListInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentAddWhiteListStatus = res.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          // that.sinkDisabled = false;
                          that.taskInfo.showSinkAddWhiteList = false;
                          clearInterval(that.querySinkAddWhiteListInterval);
                          setTimeout(() => {
                            that.showAddWhiteList = false;
                          }, 500);
                        } else if (res.data.data.taskState === 'FAILED') {
                          // that.sinkDisabled = false;
                          clearInterval(that.querySinkAddWhiteListInterval);
                        }
                      }
                    })
                    .catch(() => {
                      // that.sinkDisabled = false;
                    });
                }, 2000);
              } else {
                this.$Message.success('正在添加白名单');
              }
            } else if (res.data.code === '6028') {
              this.$refs.stToken.handleShowAkSk();
            } else if (res.data.code === '6047') {
              this.$refs.aliyunAKSK.handleShowAkSk();
            }
            // this.sinkDisabled = false;
          })
          .catch(() => {
            // this.sinkDisabled = false;
          });
      }
      const that = this;

      this.getPercantage = setInterval(() => {
        if (that.currentAddWhiteListStatus === 'SUCCESS') {
          that.currentPercentage = 100;
          clearInterval(that.getPercantage);
          setTimeout(() => {
            that.showAddWhiteList = false;
          }, 500);
        } else if (that.currentAddWhiteListStatus === 'FAILED') {
          clearInterval(that.getPercantage);
        } else if (that.currentPercentage < 90) {
          that.currentPercentage += Math.floor(Math.random() * 3);
        } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
          that.currentPercentage += Math.floor(Math.random() * 1.5);
        } else if (that.currentPercentage >= 97) {
          that.currentPercentage = 99;
        }
      }, 200);
    },
    handleChangeCluster(type) {
      this.taskInfo.clusterList.forEach((cluster) => {
        if (cluster.id === this.taskInfo.clusterId) {
          this.currentRegion = cluster.region;
          this.currentCluster = cluster;
          if (this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD') {
            this.taskInfo.sourceInstanceType = 'ALIBABA_CLOUD_HOSTED';
            this.taskInfo.sinkInstanceType = 'ALIBABA_CLOUD_HOSTED';
          } else {
            this.taskInfo.sourceInstanceType = this.currentCluster.cloudOrIdcName;
            this.taskInfo.sinkInstanceType = this.currentCluster.cloudOrIdcName;
            if (store.state.jobData) {
              this.taskInfo.sourceInstanceType = store.state.jobData.sourceDsVO.deployType;
              this.taskInfo.sinkInstanceType = store.state.jobData.targetDsVO.deployType;
            }
          }
          this.handleDeployTypeChange('source');
          this.handleDeployTypeChange('sink', type);
        }
      });
    },
    dataIsNaN(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    },
    handleChangeNetType(type) {
      if (type === 'source') {
        this.taskInfo.sourceInstanceId = '';
        this.taskInfo.sourceInstanceValue = '';
        this.taskInfo.showSourceAddWhiteList = false;
        this.getSourceDataSourceList();
      } else {
        console.log('test4');
        this.taskInfo.sinkInstanceId = '';
        this.taskInfo.sinkInstanceValue = '';
        this.taskInfo.showSinkAddWhiteList = false;
        this.getSinkDataSourceList();
      }
    },
    isOracle(type) {
      return DataSourceGroup.oracle[0] === type;
    },
    isPg(type) {
      return DataSourceGroup.pg.indexOf(type) > -1;
    },
    handleChangeSchema(db, data) {
      const {
        sourceType,
        sinkType
      } = this.taskInfo;

      if (this.isOracle(sourceType) && this.isPg(sinkType) && this.taskInfo.targetCaseSensitive === 'false') {
        db.targetSchema = data.toLowerCase();
      } else {
        db.targetSchema = data;
      }

      if (DataSourceGroup.hasSchema.includes(sinkType)) {
        db.schemaAutoCreate = this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb) && this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)
          .indexOf(data) === -1;
      } else {
        db.schemaAutoCreate = false;
        db.needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, data);
      }
      if (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) === -1) {
        db.sinkDb = data;
      }
    },
    handleSchemaChange(db, index, data) {
      if (this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)) {
        this.taskInfo.dbMap[index].schemaAutoCreate = this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb) && this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)
          .indexOf(data) === -1;
      } else {
        this.taskInfo.dbMap[index].schemaAutoCreate = true;
      }
    },
    handleRemoveNoDbTables() {
      this.hasNoTableDb.forEach((db) => {
        this.taskInfo.dbMap.forEach((item, index) => {
          if (item.sourceDb === db) {
            this.taskInfo.dbMap.splice(index, 1);
          }
        });
        if (this.$refs.dbMapping) {
          this.taskInfo.treeData.forEach((item) => {
            if (item.title === db) {
              item.checked = false;
            }
          });
        }
      });
      if (this.$refs.dbMapping) {
        this.$refs.dbMapping.filteredData = _.cloneDeep(this.taskInfo.treeData);
      }

      this.showNoTableDb = false;
      setTimeout(() => {
        this.updateStep(1);
      }, 500);
    },
    handleCancel() {
      this.showNoTableDb = false;
    },
    updateShowEditMapping(data) {
      this.showEditMapping = data;
    },
    parseSchemaData(data) {
      this.taskInfo.dbMap = [];
      const sourceSchemaJson = JSON.parse(data.sourceSchema); const
        mappingJson = JSON.parse(data.mappingConfig);
      const targetSchemaJson = data.targetSchema ? JSON.parse(data.targetSchema) : [];
      let schema = [];
      console.log('sourceSchemaJson', sourceSchemaJson, mappingJson, targetSchemaJson);
      if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
        schema = targetSchemaJson;
      } else {
        schema = sourceSchemaJson;
      }
      schema.forEach((db) => {
        const dbMapItem = {
          sourceDb: db.db,
          sinkDb: this.getTargetDb(db, mappingJson).db,
          targetSchema: this.getTargetDb(db, mappingJson).schema,
          sourceSchema: db.schemas ? db.schemas[0].schema : db.tableSpaces ? db.tableSpaces[0].tableSpace : 'public',
          needAutoCreated: db.targetAutoCreate
        };
        this.taskInfo.dbMap.push(dbMapItem);
      });
      if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
        console.log('targetSchemaJson[0].globalTimeZone', targetSchemaJson[0].globalTimeZone);
        this.taskInfo.globalTimeZone = targetSchemaJson[0].globalTimeZone;
      }
    },
    getTargetDb(db, mappingJson) {
      let dbMethod = 'DB_DB';
      let schemaMethod = '';
      let targetDb = db.db; let
        targetSchema = 'public';
      if (!DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) && DataSourceGroup.hasSchema.includes(this.taskInfo.sinkType)) {
        dbMethod = 'DB_SCHEMA';
      } else if (DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) && DataSourceGroup.hasSchema.includes(this.taskInfo.sinkType)) {
        schemaMethod = 'SCHEMA_SCHEMA';
      } else if (DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) && !DataSourceGroup.hasSchema.includes(this.taskInfo.sinkType)) {
        schemaMethod = 'SCHEMA_DB';
      }
      let dbMapping = {}; let
        schemaMapping = {};
      mappingJson.forEach((mapping) => {
        if (mapping.method === dbMethod) {
          dbMapping = mapping.serializeMapping;
        } else if (mapping.method === schemaMethod) {
          schemaMapping = mapping.serializeMapping;
        }
      });
      Object.keys(dbMapping).forEach((theMapping) => {
        const theKey = {
          value: db.db
        };
        if (theMapping === JSON.stringify(theKey)) {
          const theValue = JSON.parse(dbMapping[theMapping]);
          if (theValue.parent) {
            targetDb = theValue.parent.value;
            targetSchema = theValue.value;
          } else {
            targetDb = theValue.value;
          }
        }
      });
      Object.keys(schemaMapping).forEach((theMapping) => {
        let theKey = {
          value: db.db
        };
        if (schemaMethod === 'SCHEMA_SCHEMA' || schemaMethod === 'SCHEMA_DB') {
          theKey = {
            parent: {
              value: db.db
            },
            value: db.schemas ? db.schemas[0].schema : db.tableSpaces[0].tableSpace
          };
        }
        console.log('theMapping', theMapping, JSON.stringify(theKey));
        if (theMapping === JSON.stringify(theKey)) {
          const theValue = JSON.parse(schemaMapping[theMapping]);
          console.log('theValue', theValue);
          if (schemaMethod === 'SCHEMA_DB') {
            targetDb = theValue.value;
          } else {
            targetSchema = theValue.value;
          }
        }
      });
      return {
        db: targetDb,
        schema: targetSchema
      };
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'taskInfo.sourceDbList': function () {
      this.taskInfo.treeData = [];
      this.taskInfo.sourceDbList.forEach((db) => {
        this.taskInfo.treeData.push({
          title: db.dbName,
          checked: false,
          render: (h, { data }) => {
            if (data.checked) {
              return h('span', {
                class: 'db-mapping-item'
              }, [
                h('span', {}, db.dbName),
                h('span', {
                  style: {
                    marginLeft: '16px'
                  }
                }, data.sinkDb ? `目标库：${data.sinkDb}` : ''),
                h('Icon', {
                  props: {
                    type: 'md-create'
                  },
                  style: {
                    marginLeft: '16px'
                  },
                  class: 'edit-mapping-icon',
                  on: {
                    click: () => {
                      this.showEditMapping = true;
                      this.currentSourceDb = db.dbName;
                      this.currentSinkDb = data.sinkDb || db.dbName;
                      this.batchlyEdit = false;
                    }
                  }
                })
              ]);
            }
            return h('span', [
              h('span', {}, db.dbName),
              h('span', {}, data.sinkDb ? `目标库：${data.sinkDb}` : '')
            ]);
          }
        });
      });
    },
    // eslint-disable-next-line func-names
    'taskInfo.sinkDbList': function () {
      this.taskInfo.dbMap.forEach((item) => {
        item.needAutoCreated = !(DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1);
        this.taskInfo.sinkDbList.forEach((db) => {
          if (item.sinkDb === db.dbName) {
            item.needAutoCreated = false;
          }
        });
      });
    },
    deep: true
  }
};
</script>
<style lang="less">
.task-create-first-step {
  padding: 20px;

  .datasource-icon {
    font-size: 18px;
  }
}

.arrow-between-card {
  text-align: center;
  margin-top: 200px;
  font-size: 38px;
}

.show-manual-account {
  margin-top: 20px;
  margin-bottom: 12px;
}

.show-manual-account-container {
  padding: 16px;
  border: 1px solid #EDEDED;
  width: 600px;
  background-color: #FAFAFA;
  margin-bottom: 20px;
  position: relative;

  .cancel-manual-btn {
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
    z-index: 2;
  }
}
</style>
