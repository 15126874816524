<template>
  <div>
    <Breadcrumb>
      <BreadcrumbItem to="/system/resource">集群管理</BreadcrumbItem>
      <BreadcrumbItem>机器列表</BreadcrumbItem>
    </Breadcrumb>
    <div class="worker-list-content">
      <div class="job-header">
                    <span class="job-header-db">
                        <i class="iconfont iconClass"></i>
                    </span>
        <div class="job-header-name">
          <p>
            <span class="job-header-name-main">{{ clusterInfo.clusterName }}</span>
            <span class="job-header-name-desc"
                  v-if="clusterInfo.cloudOrIdcName">{{ Mapping.cloudOrIdcName[clusterInfo.cloudOrIdcName] }}</span>
            <span class="job-header-name-desc" v-if="clusterInfo.region"><i class="iconfont icondingwei"
                                                                            style="margin-right: 6px"></i>{{ Mapping.region[clusterInfo.region] }}</span>
          </p>
          <p class="job-header-desc">
            描述：{{ clusterInfo.clusterDesc }}
          </p>
        </div>
        <div class="job-header-buttons">
          <pd-button type="primary" style="margin-right: 6px" @click="handleAddWorker" ghost>
            <Icon type="md-add"/>
            新增机器
          </pd-button>
          <pd-button type="default" @click="handleRefresh"
                     :loading="refreshLoading">
            刷新
          </pd-button>
        </div>
      </div>
      <div class="worker-body">
        <div class="worker-item-container">
          <p class="worker-item-title">机器列表</p>
          <div class="task-list-none" v-if="resourceData.length===0 && !refreshLoading">
            <svg class="icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-zanwushujuimg"></use>
            </svg>
            <p>您还没有添加机器，<a @click="handleAddWorker">新增机器</a></p>
          </div>
          <div v-for="(worker,index) in resourceData" :key="index"
               :class="`worker-item-content ${selectWorker.id===worker.id?'worker-item-selected':''}`"
               @click="handleSelectWorker(worker)">
            <div class="worker-info-content">
              <span class="status-point" :style="`background-color: ${getPointColor(worker)}`"></span>
              <p><span class="worker-ip"
                       @click="handleGoMonitor(worker)">{{ worker.privateIp ? worker.privateIp : '待确认' }}</span><span
                  :style="`color: ${getWorkerStatusColor(worker)}`"
                  class="worker-status">{{ getWorkerStatus(worker) }}</span>
                <Tooltip placement="right" class="alarm-icon" transfer content="存在异常的后台任务，请点击处理"
                         v-if="worker.consoleTaskState==='FAILED'">
                  <span style="display: inline-block" @click="handleGoConsoleJob(worker)"><i
                      class="iconfont iconyibuforce"></i></span>
                </Tooltip>
              </p>
              <p class="worker-info">描述：{{ worker.workerDesc ? worker.workerDesc : '暂无' }}
                <Poptip placement="right" class="show-more-info">
                  <i class="iconfont iconbianji1"></i>
              <div slot="content">
                <div>
                  <span>公网信息：</span>
                  <span style="font-weight: 500;width: 200px;display: inline-block">{{ worker.publicIp }}</span>
                  <span>地区：</span>
                  <span
                      style="font-weight: 500;width: 200px;display: inline-block">{{ Mapping.region[worker.region] }}</span>
                </div>
                <div style="margin-top: 10px">
                  <span>创建时间：</span>
                  <span
                      style="font-weight: 500;width: 200px;display: inline-block">{{ fecha.format(new Date(worker.gmtCreate), 'YYYY-MM-DD HH:mm:ss') }}</span>
                  <span>机器类型：</span>
                  <span
                      style="font-weight: 500;width: 200px;display: inline-block">{{ Mapping.workerType[worker.workerType] }}</span>
                </div>
                <div style="margin-top: 10px">
                  <span>机器唯一标识：</span>
                  <span style="font-weight: 500">{{ worker.workerSeqNumber }}</span>
                  <i style="color: #0BB9F8;cursor: pointer" @click="handleCopy(worker.workerSeqNumber)"
                     class="iconfont iconcopy"></i>
                </div>
                <div style="margin-top: 10px">
                  <span>最近更新日期：</span>
                  <span
                      style="font-weight: 500;width: 200px;display: inline-block">{{ fecha.format(new Date(worker.installOrUpgradeDate), 'YYYY-MM-DD HH:mm:ss') }}</span>
                  <span>最近更新版本：</span>
                  <span
                      style="font-weight: 500;width: 200px;display: inline-block">{{ worker.installOrUpgradeVersion }}</span>
                </div>
              </div>
              </Poptip>
              </p>
              <div class="worker-item-circle" @click="toMonitorPage(worker)">
                <Row>
                  <Col :span="8">
                    <span :style="`font-size:24px;line-height: 56px;color:${worker.workerLoad>worker.logicalCoreNum?'rgb(255, 24, 21)':'#333'}`">{{ worker.workerLoad }}</span>
                    <p>5m平均CPU负载</p>
                  </Col>
                  <Col :span="8">
                    <i-circle :size="50" :trail-width="8" :stroke-width="8" :percent="worker.cpuUseRatio"
                              :stroke-color="worker.cpuUseRatio<40?'#19be6b':worker.cpuUseRatio>70?'#ed4014':'#ff9900'">
                      <span class="demo-Circle-inner" style="font-size:24px">{{ worker.cpuUseRatio }}%</span>
                    </i-circle>
                    <p>当前CPU使用率</p>
                  </Col>
                  <Col :span="8">
                    <i-circle :size="50" :trail-width="8" :stroke-width="8" :percent="(worker.totalTaskMemMb/worker.physicMemMb)*100"
                              :stroke-color="(worker.totalTaskMemMb/worker.physicMemMb)<0.44?'#19be6b':(worker.totalTaskMemMb/worker.physicMemMb)>0.7?'#ed4014':'#ff9900'">
                      <span class="demo-Circle-inner" style="font-size:24px">{{ ((worker.totalTaskMemMb/worker.physicMemMb)*100).toFixed(2)}}%</span>
                    </i-circle>
                    <p>当前内存使用率</p>
                  </Col>
                </Row>
              </div>
            </div>
            <div class="worker-item-action" v-if="selectWorker.id===worker.id">
              <span class="worker-action-item" @click="handleShowConfirmOnline"
                    v-if="(worker.workerState==='OFFLINE'||worker.workerState==='WAIT_TO_OFFLINE')&&
                    (worker.deployStatus==='INSTALLED'&&
                    worker.cloudOrIdcName!=='SELF_MAINTENANCE'||
                    worker.cloudOrIdcName==='SELF_MAINTENANCE')"><i
                  class="iconfont icononline"></i>启动</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="worker.workerState!=='OFFLINE'&&
                    worker.workerState!=='WAIT_TO_OFFLINE'||
                    (worker.deployStatus!=='INSTALLED'&&
                    worker.cloudOrIdcName!=='SELF_MAINTENANCE')"><i
                  class="iconfont icononline"></i>启动</span>
              <span class="worker-action-item" @click="handleShowConfirmOffline"
                    v-if="worker.workerState==='ONLINE'||
                    worker.workerState==='WAIT_TO_ONLINE'||
                    worker.workerState==='ABNORMAL'"><i
                  class="iconfont iconoutline"></i>停止</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="!(worker.workerState==='ONLINE'||
                    worker.workerState==='WAIT_TO_ONLINE'||
                    worker.workerState==='ABNORMAL')"><i
                  class="iconfont iconoutline"></i>停止</span>
              <span class="worker-action-item" @click="handleDeployCore(worker)"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&
                    (worker.workerState==='OFFLINE'&&
                    (worker.deployStatus==='UNINSTALLED'||worker.deployStatus==='')||
                    ((worker.workerState==='WAIT_TO_OFFLINE'&&
                    worker.deployStatus==='UNINSTALLED')))"><i
                  class="iconfont iconanzhuang"></i>安装</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&
                    ((worker.workerState!=='OFFLINE'&&
                    !(worker.workerState==='WAIT_TO_OFFLINE'&&
                    worker.deployStatus==='UNINSTALLED'))||
                    (worker.deployStatus!=='UNINSTALLED'&&worker.deployStatus!==''))"><i
                  class="iconfont iconanzhuang"></i>安装</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&
                    (worker.deployStatus!=='INSTALLED'||
                    worker.consoleTaskState!=='SUCCESS')"><i
                  class="iconfont iconxiezai"></i>更新</span>
              <span class="worker-action-item"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&!(worker.deployStatus!=='INSTALLED'||worker.consoleTaskState!=='SUCCESS')"
                    @click="handleUpgradeAll(worker)"><i class="iconfont iconxiezai"></i>更新</span>
              <span class="worker-action-item"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&(worker.workerState==='WAIT_TO_OFFLINE'||worker.workerState==='OFFLINE')&&worker.deployStatus==='INSTALLED'"
                    @click="handleUnInstall(worker)"><i class="iconfont iconxiezai"></i>卸载</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="worker.cloudOrIdcName!=='SELF_MAINTENANCE'&&(!(worker.workerState==='WAIT_TO_OFFLINE'||worker.workerState==='OFFLINE')||worker.deployStatus!=='INSTALLED')"><i
                  class="iconfont iconxiezai"></i>卸载</span>
              <span class="worker-action-item" @click="handleDownloadClient(worker)"
                    v-if="worker.cloudOrIdcName==='SELF_MAINTENANCE'&&!store.state.globalConfig.product_trial"><i class="iconfont iconKHD"></i>下载客户端</span>
              <Tooltip placement="bottom">
                <div slot="content">
                  <p>
                    想要增加机器，请查看文档：<a href="https://doc.cloudcanal.clougence.com/docs/en/ha_install" target="_blank">高可用部署</a>
                  </p>
                </div>
                <span class="worker-action-item worker-action-item-disabled"
                    v-if="worker.cloudOrIdcName==='SELF_MAINTENANCE'&&store.state.globalConfig.product_trial"><i class="iconfont iconKHD"></i>下载客户端</span>
              </Tooltip>
              <span class="worker-action-item" @click="handleDownloadConfig(worker)"
                    v-if="worker.cloudOrIdcName==='SELF_MAINTENANCE'"><i class="iconfont iconPZWJ"></i>查看配置文件</span>
              <span class="worker-action-item" @click="handleDeleteWorker(worker)"
                    v-if="(worker.workerState==='OFFLINE'||
                    worker.workerState==='WAIT_TO_OFFLINE')&&
                    (worker.deployStatus==='UNINSTALLED'&&worker.cloudOrIdcName!=='SELF_MAINTENANCE'||worker.cloudOrIdcName==='SELF_MAINTENANCE')||
                    (worker.cloudOrIdcName==='SELF_MAINTENANCE'&&!worker.privateIp)||
                    worker.healthLevel !== 'Health'&&(!store.state.globalConfig.product_trial || worker.id !==1)"><i
                  class="iconfont icondel"></i>删除</span>
              <span class="worker-action-item worker-action-item-disabled"
                    v-if="(!(worker.workerState==='OFFLINE'||worker.workerState==='WAIT_TO_OFFLINE')||
                    (worker.deployStatus!=='UNINSTALLED'&&worker.cloudOrIdcName!=='SELF_MAINTENANCE'))&&
                    !(worker.cloudOrIdcName==='SELF_MAINTENANCE'&&!worker.privateIp)&&
                    worker.healthLevel === 'Health'&&(!store.state.globalConfig.product_trial || worker.id !==1)"><i
                  class="iconfont icondel"></i>删除</span>
              <Tooltip placement="bottom" content="请勿删除默认机器">
       <span class="worker-action-item worker-action-item-disabled"
             v-if="store.state.globalConfig.product_trial&&worker.id === 1"><i
         class="iconfont icondel"></i>删除</span>
              </Tooltip>
              <span class="worker-action-off">
                                <Checkbox @on-change="handleAlarm(worker,$event)"
                                          v-model="worker.alertConfigVO.dingding">存活性检测</Checkbox>
                            </span>
            </div>
          </div>
        </div>
        <div class="worker-detail-container">
          <div v-if="!selectWorker.workerName" style="width: 100%;height: 700px;background-color: #ffffff"></div>
          <div class="install-worker-process-content"
               v-if="(selectWorker&&selectWorker.currentStatus==='process'||selectWorker.consoleTaskState === 'EXECUTE')&&(selectWorker&&selectWorker.deployStatus==='INSTALLING')">
            <p class="install-worker-process"><a class="install-worker-status"
                                                 @click="handleGoConsoleJob(selectWorker)">客户端安装中。</a>这个过程可能需要几分钟。</p>
            <!--                        <div class="install-worker-process-img"></div>-->
            <Progress style="position: absolute;bottom: -8px" :percent="99.99" status="active" hide-info
                      :stroke-width="2"/>
          </div>
          <div class="install-worker-process-content"
               v-if="(selectWorker&&selectWorker.currentStatus==='process'||selectWorker.consoleTaskState === 'EXECUTE' )&&(selectWorker&&selectWorker.deployStatus==='UNINSTALLING')">
            <p class="install-worker-process"><a class="install-worker-status"
                                                 @click="handleGoConsoleJob(selectWorker)">客户端卸载中。</a>这个过程可能需要几分钟。</p>
            <!--                        <div class="install-worker-process-img"></div>-->
            <Progress style="position: absolute;bottom: -8px" :percent="99.99" status="active" hide-info
                      :stroke-width="2"/>
          </div>
          <div v-if="selectWorker&&selectWorker.currentStatus==='error'&&selectWorker.deployStatus==='INSTALLING'">
            <Alert type="error" show-icon>安装失败！<a @click="handleStopInstallAndClean" style="margin-left: 4px">取消安装</a>
            </Alert>
          </div>
          <div v-if="selectWorker&&selectWorker.currentStatus==='error'&&selectWorker.deployStatus==='UNINSTALLING'">
            <Alert type="error" show-icon>卸载失败！<a @click="handleStopInstallAndClean" style="margin-left: 4px">取消卸载</a>
            </Alert>
          </div>
          <div class="worker-detail-metric" v-if="selectWorker.workerName">
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.logicalCoreNum }}</p>
              <p>CPU逻辑核数</p>
            </div>
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.physicDiskGb }}<span
                  style="font-size: 16px;font-family: PingFangSC-Regular,serif">GB</span></p>
              <p>物理磁盘容量</p>
            </div>
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.physicMemMb }}<span
                  style="font-size: 16px;font-family: PingFangSC-Regular,serif">MB</span></p>
              <p>物理内存容量</p>
            </div>
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.totalTaskMemMb }}<span
                style="font-size: 16px;font-family: PingFangSC-Regular,serif">MB</span></p>
              <p>任务已占用内存</p>
            </div>
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.freeMemMb }}<span
                  style="font-size: 16px;font-family: PingFangSC-Regular,serif">MB</span></p>
              <p>空闲内存</p>
            </div>
            <div class="worker-detail-metric-item">
              <p class="worker-count">{{ selectWorker.freeDiskGb }}<span
                  style="font-size: 16px;font-family: PingFangSC-Regular,serif">GB</span></p>
              <p>空闲磁盘</p>
            </div>
          </div>
          <div class="worker-task-container" v-if="selectWorker.workerName">
            <p style="font-weight: 500;font-size: 16px"><span class="warn-point" style="background-color: #333"></span>运行在该机器上的任务列表
            </p>
            <Button class="show-log-btn" @click="handleShowLogContainer">查看机器日志</Button>
            <Table :max-height="maxHeight" style="margin-top: 10px" :row-class-name="rowClassName" size="small" stripe
                   :columns="taskColumn" :data="selectWorker.taskScheduleVOs?selectWorker.taskScheduleVOs:[]"></Table>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="showLog"
           title="机器日志"
           footer-hide
           width="1200px"
           class-name="show-log-container"
    >
      <div>
        <Tabs type="card" v-model="logFileName" @on-click="handleSelectLog" :animated="false">
          <TabPane :label="log.fileName" :name="log.fileName" v-for="(log,index) in logData" :key="index">
            <div class="log-content">
              <pd-button class="refresh-btn" :loading="logLoading" @click="handleShowLog">刷新</pd-button>
              <p class="log-content-desc"><span>描述：</span><span class="point-content">{{ selectedLog.desc }}</span></p>
              <p class="log-content-desc"><span>路径：</span><span class="point-content">{{ selectedLog.path }}</span></p>
              <pre>{{ selectedLog.content }}</pre>
            </div>
          </TabPane>
        </Tabs>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleCancel">关闭
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmUpdateAlarm"
        title="停止活性检测确认"
        footer-hide
    >
      <div>
        <p>确认要对物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器停止活性检测吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleUpdateAlarm">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmDelete"
        title="删除确认"
        footer-hide
    >
      <div>
        <p>确认要删除物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmDeleteCluster">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmUninstall"
        title="卸载CloudCanal客户端确认"
        footer-hide
    >
      <div>
        <p>确认要卸载物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器上的CloudCanal客户端吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUnInstall">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmUpgrade"
        title="更新CloudCanal客户端确认"
        footer-hide
    >
      <div>
        <p>确认要更新物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器上的CloudCanal客户端吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpgradeAll">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmOffline"
        title="停止CloudCanal客户端确认"
        footer-hide
    >
      <div>
        <p>确认要停止物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器上的CloudCanal客户端吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleChangeWorkerStatus(false)">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfirmOnline"
        title="启动CloudCanal客户端确认"
        footer-hide
    >
      <div>
        <p>确认要启动物理IP为{{ selectWorker ? selectWorker.privateIp : '' }}的机器上的CloudCanal客户端吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleChangeWorkerStatus(true)">确定</pd-button>
          <pd-button @click="handleCancelUpdateAlarm">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showAddWorker"
           :title="isEdit?'编辑机器信息':'新增机器'"
           width="1240px"
           footer-hide
    >
      <div>
        <AddWorker :handleCancel="handleCancel" :clusterInfo="clusterInfo" :handleShowSighin="handleShowSighin"
                   :getWorkList="getWorkList"></AddWorker>
      </div>
    </Modal>
    <Modal
        v-model="showUnInstallLocal"
        @on-ok="handleConfirmUnInstall"
        title="卸载提示">
      <div>
        <Form :model="addWorkerForm" label-position="left" :label-width="60">
          <FormItem label="账号">
            <Input v-model="addWorkerForm.remoteUser" style="width: 300px"/>
          </FormItem>
          <FormItem label="密码">
            <Input v-model="addWorkerForm.remotePassword" type="password" password style="width: 300px"/>
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal
        v-model="showStopLocal"
        @on-ok="stopInstall"
        title="停止并清除提示">
      <div>
        <Form :model="addWorkerForm" label-position="left" :label-width="60">
          <FormItem label="账号">
            <Input v-model="addWorkerForm.remoteUser" style="width: 300px"/>
          </FormItem>
          <FormItem label="密码">
            <Input v-model="addWorkerForm.remotePassword" type="password" password style="width: 300px"/>
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal
        v-model="showDispatch"
        title="重新调度"
        footer-hide
    >
      <div>
        <p style="margin-bottom: 10px">需要把{{ selectedRow.taskName }}从{{ selectedRow.workerIp }}调度到：</p>
        <Select v-model="workerToDispatch" style="width:400px">
          <Option :disabled="selectedRow.workerIp===(item.privateIp||item.publicIp)" v-for="item in getWorkerList(selectedRow)" :value="item.id" :key="item.id">{{
              item.privateIp
            }}（{{ item.workerDesc }}）
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleDispatch">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <StToken ref="stToken" :nextStep="nextStep"></StToken>
    <AliyunAKSK ref="aliyunAKSK"></AliyunAKSK>
    <verify-code-modal
        :visible="showConfigVerify"
        title="查看配置文件确认"
        :handle-close-modal="hideConfigModal"
        :handle-confirm-callback="handleConfirmDownload"
        verify-code-type='FETCH_WORKER_DEPLOY_CORE_CONFIG'
        ref="verify-code-modal"
    >
      <div slot="content">
        <p style="margin-bottom: 10px">请输入验证码以获取配置文件</p>
      </div>
    </verify-code-modal>
<!--    <Modal-->
<!--        v-model="showConfigVerify"-->
<!--        title="查看配置文件确认"-->
<!--        footer-hide-->
<!--        width="400px"-->
<!--    >-->
<!--      <div>-->
<!--        <p style="margin-bottom: 10px">请输入验证码以获取配置文件</p>-->
<!--        <Alert type="error" v-if="verifyCodeError" style="width: 290px;" show-icon>{{verifyCodeError}}</Alert>-->
<!--        <p>-->
<!--          <Input style="width: 160px;" type="text" v-model="verifyCode"-->
<!--                 placeholder="请输入短信验证码"/>-->
<!--          <pd-button type="primary" ghost style="margin-left: 10px" :disabled="!sendcodeDisabled"-->
<!--                     @click="handleVerify">-->
<!--            {{ sendcodeDisabled ? '获取短信验证码' : '再次获取(' + sendCodeAgainTime + 's)' }}-->
<!--          </pd-button>-->
<!--        </p>-->
<!--        <div slot="footer" class="modal-footer" style="margin-top: 20px">-->
<!--          <pd-button type="primary" @click="handleConfirmDownload">确定</pd-button>-->
<!--          <pd-button @click="handleCancel">关闭</pd-button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </Modal>-->
    <Modal
        v-model="showDownloadClient"
        title="下载客户端"
        width="1100px"
        footer-hide
    >
      <div>
        <Alert type="warning" show-icon>该下载链接有效时间为1小时，请尽快下载。</Alert>
        <div style="white-space: pre-wrap;word-wrap: break-word;border: 1px solid #DADADA;padding: 20px">
          {{ downloadUrl }}
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleDownloadDireactly">直接下载</pd-button>
          <pd-button type="primary" @click="handleCopy(downloadUrl)">复制链接</pd-button>
          <pd-button @click="handleCancel">关闭</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
        v-model="showConfigData"
        title="配置文件"
        width="1100px"
        footer-hide
    >
      <div>
        <Alert type="warning">请将下列配置完整复制到CloudCanal解压目录/cloudcanal/global_conf/conf.properties文件中</Alert>
        <div style="line-height: 30px;border: 1px solid #dadada;padding: 20px;margin-top: 20px">
          <p>{{ configData.userAkLabel }}={{ configData.userAkValue }}</p>
          <p>{{ configData.userSkLabel }}={{ configData.userSkValue }}</p>
          <p>{{ configData.wsnLabel }}={{ configData.wsnValue }}</p>
          <p>{{ configData.consoleDomainLabel }}={{ configData.consoleDomainValue }}</p>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCopy(getConfigData(configData))">复制</pd-button>
          <pd-button @click="handleCancel">关闭</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  listWorker,
  deleteWorker,
  downLoadClientUrl,
  clientCoreConfig,
  updateAlertConfig,
  waitToOffline,
  waitToOnline
} from '@services/api/worker';
import {
  listRegions, listCloudOrIdcNames, listWorkerTypes
} from '@services/api/constant';
import {
  install, queryProcess, uninstall, stopInstallAndClean, upgradeAll
} from '@services/api/aliyunEcs';
import { manualSchedule, tailWorkerLog } from '@services/api/job';
import { sendCodeInLoginState } from '@services/api/verify';
import { retryConsoleTask } from '@services/api/consoleJob';
import fecha from 'fecha';
import { getUserQuota } from '@services/api/resourceQuota';
import store from '@store';
import JobOnWorker from '@components/function/JobOnWorker';
import StToken from '@components/function/ApplyStToken';
import AddWorker from '@components/function/cluster/AddWorker';
import AliyunAKSK from '@components/function/ApplyAKSK';
import VerifyCodeModal from '@components/modal/VerifyCodeModal';
import Mapping from '../util';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    JobOnWorker,
    StToken,
    AddWorker,
    AliyunAKSK,
    VerifyCodeModal
  },
  created() {
    this.queryIp = this.$route.query.ip;
    this.taskId = this.$route.query.task;
    this.clusterId = this.$route.params.id;
    if (localStorage.getItem(`cluster-${this.clusterId}`)) {
      this.clusterInfo = JSON.parse(localStorage.getItem(`cluster-${this.clusterId}`));
    }
  },
  mounted() {
    this.addWorker.cloudOrIdcName = this.clusterInfo.cloudOrIdcName;
    this.addWorker.region = this.clusterInfo.region;
    const totalHeight = window.innerHeight;

    // eslint-disable-next-line no-undef
    $('.worker-body').css('height', `${totalHeight - 200}px`);
    this.maxHeight = totalHeight - 400;
    this.getWorkList();
    this.listRegions();
    this.listCloudOrIdcNames();
    this.listWorkerTypes();
  },
  beforeDestroy() {
    clearInterval(this.getProcess);
    clearInterval(this.sendCodeAgainTime);
  },
  data() {
    return {
      showLog: false,
      logFileName: '',
      logData: '',
      selectedLog: '',
      logLoading: false,
      maxHeight: 500,
      showConfirmOnline: false,
      showConfirmOffline: false,
      showConfirmUninstall: false,
      showDownloadClient: false,
      downloadUrl: '',
      configData: '',
      showConfigVerify: false,
      showConfigData: false,
      verifyCode: '',
      verifyCodeError: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      offAlarm: false,
      workers: [],
      showDispatch: false,
      selectWorker: {},
      selectedRow: {},
      workerToDispatch: '',
      fecha,
      Mapping,
      store,
      nextStep: '',
      addWorkerForm: {
        aliyunAk: '',
        aliyunSk: ''
      },
      taskColumn: [
        {
          title: 'task名称',
          key: 'taskName',
          width: 280
        },
        {
          title: '任务名称',
          key: 'jobName',
          width: 200,
          render: (h, params) => h('a', {
            on: {
              click: () => {
                this.$router.push({ path: `/data/job/${params.row.jobId}` });
              }
            }
          }, params.row.jobName)
        },
        {
          title: '任务描述',
          key: 'jobDesc',
          minWidth: 150
        },
        {
          title: '规格',
          key: 'jvmHeapMb',
          width: 80,
          render: (h, params) => h('div', {}, `${params.row.jvmHeapMb / 1024}G`)
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          width: 180,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '操作',
          width: 120,
          render: (h, params) => h('div', [
            h('a', {
              on: {
                click: () => {
                  this.showDispatch = true;
                  this.selectedRow = params.row;
                  console.log('this.selectedRow', this.selectedRow);
                }
              }
            }, '重新调度')
          ])
        }
      ],
      taskData: [],
      addDataSourceRuleAkSk: {},
      showUnInstall: false,
      showStop: false,
      showInstallLocal: false,
      showUnInstallLocal: false,
      showStopLocal: false,
      showConfirmUpgrade: false,
      pageData: {
        startId: 0,
        pageSize: 5,
        pageNumber: 1
      },
      showConfirmUpdateAlarm: false,
      showDeployProgress: false,
      taskId: 0,
      currentState: false,
      avgLoad: 0,
      avgCpu: 0,
      avgMem: 0,
      showWorkerListInterval: '',
      queryIp: '',
      refreshLoading: false,
      regions: [],
      cloudOrIdcNames: [],
      workerStates: [],
      workerTypes: [],
      isEdit: false,
      showAddWorker: false,
      showConfirmDelete: false,
      clusterId: 0,
      clusterInfo: {},
      clusterName: '',
      addWorker: {
        clusterId: this.$route.params.id,
        workerIp: '',
        cloudOrIdcName: 'aliyun',
        region: 'hangzhou',
        physicMemMb: 0,
        physicCoreNum: 0,
        physicDiskMb: 0,
        workerType: 'BARE_METAL',
        workerState: ''
      },
      resourceColumns: [
        {
          title: '详情',
          type: 'expand',
          width: 60,
          render: (h, params) => h(JobOnWorker, {
            props: {
              row: params.row,
              index: params.index,
              workerList: this.resourceData,
              getWorkList: this.getWorkList,
              taskId: this.taskId
            }
          })
        },
        {
          title: '物理IP',
          key: 'privateIp',
          render: (h, params) => h('a', {
            on: {
              click: () => {
                this.$router.push({ path: `/monitor/worker/graph?ip=${params.row.privateIp}&id=${params.row.id}` });
              }
            }
          }, params.row.privateIp)
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss')),
          width: 150
        },
        {
          title: '类型',
          key: 'workerType',
          width: 120,
          render: (h, params) => h('div', {}, Mapping.workerType[params.row.workerType])
        },
        {
          title: '机器状态',
          key: 'workerState',
          width: 140,
          render: (h, params) => h('div', {
            style: {
              color: params.row.workerState === 'ABNORMAL' ? '#ed4014' : ''
            }
          }, Mapping.workerStatus[params.row.workerState])
        },
        {
          title: '负载',
          key: 'workerLoad',
          sortable: true,
          width: 120,
          render: (h, params) => {
            let color = '#19be6b';

            if (params.row.workerLoad > 40 && params.row.workerLoad < 70) {
              color = '#ff9900';
            } else if (params.row.workerLoad > 70) {
              color = '#ed4014';
            }
            return h('div', {
              style: {
                color
              }
            }, `${params.row.workerLoad}%`);
          }
        },
        {
          title: 'CPU使用率',
          key: 'cpuUseRatio',
          width: 120,
          sortable: true,
          render: (h, params) => {
            let color = '#19be6b';

            if (params.row.cpuUseRatio > 40 && params.row.cpuUseRatio < 70) {
              color = '#ff9900';
            } else if (params.row.cpuUseRatio > 70) {
              color = '#ed4014';
            }
            return h('div', {
              style: {
                color
              }
            }, `${params.row.cpuUseRatio}%`);
          }
        },
        {
          title: '内存使用率',
          key: 'memUseRatio',
          sortable: true,
          width: 120,
          render: (h, params) => {
            let color = '#19be6b';

            if (params.row.memUseRatio > 40 && params.row.memUseRatio < 70) {
              color = '#ff9900';
            } else if (params.row.memUseRatio > 70) {
              color = '#ed4014';
            }
            return h('div', {
              style: {
                color
              }
            }, `${params.row.memUseRatio}%`);
          }
        },
        {
          title: '健康程度',
          key: 'healthLevel',
          render: (h, params) => h('div', {
            style: {
              background: params.row.healthLevel === 'Health' ? '#19be6b' : params.row.healthLevel === 'Unhealthy' ? '#ed4014' : '#ff9900',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              marginLeft: '25px'
            }
          }),
          width: 90
        },
        {
          title: '操作',
          slot: 'action',
          width: 200
        }
      ],
      resourceData: []
    };
  },
  computed: {
    getCloudOrIdcNames() {
      const list = [];

      this.cloudOrIdcNames.map((cloudOrIdcName) => {
        if (cloudOrIdcName === 'ALIBABA_CLOUD' || cloudOrIdcName === 'SELF_MAINTENANCE') {
          list.push(cloudOrIdcName);
        }
        return null;
      });
      return list;
    },
    getWorkerStatus() {
      return (worker) => {
        if (worker.workerState === 'ONLINE') {
          return '在线';
        } if (worker.workerState === 'ABNORMAL') {
          return '异常';
        } if (worker.workerState === 'WAIT_TO_ONLINE') {
          return '等待上线';
        } if (worker.workerState === 'WAIT_TO_OFFLINE') {
          return '等待下线';
        } if (worker.workerState === 'OFFLINE') {
          return '离线';
        }
      };
    },
    getPointColor() {
      return (worker) => {
        if (worker.healthLevel === 'Unhealthy') {
          return '#FF6E0D';
        } if (worker.healthLevel === 'SubHealth') {
          return '#FFA30E';
        }
        return '#52C41A';
      };
    },
    getWorkerStatusColor() {
      return (worker) => {
        if (worker.workerState === 'ABNORMAL') {
          return '#FF6E0D';
        }
        return '#333';
      };
    },
    getWorkerList() {
      return (selectedRow) => {
        const list = [];

        this.resourceData.map((item) => {
          if (item.workerName !== selectedRow.workerName && item.healthLevel === 'Health') {
            list.push(item);
          }
          return null;
        });
        return list;
      };
    }
  },
  methods: {
    hideConfigModal() {
      this.showConfigVerify = false;
    },
    handleRefresh() {
      this.getWorkList();
    },
    handleAddWorker() {
      getUserQuota().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.quotaType === 'WORKER_COUNT') {
              if (item.used >= item.quota) {
                this.$Modal.warning({
                  title: '额度不足',
                  content: '您当前的机器额度不足，请去<a href="/#/system/userCenter">个人中心</a>申请额度。'
                });
              } else {
                this.isEdit = false;
                this.showAddWorker = true;
              }
            }
            return null;
          });
        }
      });
    },
    handleDeployCore(row) {
      this.selectWorker = row;
      this.showDeployProgress = false;
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        this.handleInstall();
      } else {
        this.showInstallLocal = true;
      }
    },
    handleUnInstall(row) {
      this.showConfirmUninstall = true;
      this.selectWorker = row;
    },
    handleUpgradeAll(row) {
      this.showConfirmUpgrade = true;
      this.selectWorker = row;
    },
    handleConfirmDeleteCluster() {
      this.showConfirmDelete = false;
      this.selectWorker.workerId = this.selectWorker.id;
      deleteWorker(this.selectWorker).then((res) => {
        if (res.data.code === '1') {
          this.selectWorker = {};
          this.getWorkList();
          this.$Message.success('删除成功！');
        } else {
          this.getWorkList();
        }
        clearInterval(this.getProcess);
      });
    },
    handleDeleteWorker(row) {
      this.selectWorker = row;
      this.showConfirmDelete = true;
    },
    getWorkList() {
      this.refreshLoading = true;
      listWorker({ clusterId: this.clusterId }).then((res) => {
        this.resourceData = res.data.data;
        if (!this.selectWorker.id) {
          if (res.data.data.length > 0) {
            this.selectWorker = this.resourceData[0];
          } else {
            this.selectWorker = {};
          }
        } else {
          res.data.data.map((item) => {
            if (item.id === this.selectWorker.id) {
              this.selectWorker = item;
            }
            return null;
          });
        }

        if (this.queryIp) {
          this.resourceData.map((worker) => {
            if (worker.privateIp === this.queryIp) {
              this.selectWorker = worker;
            }
            return null;
          });
        }
        let totalCpu = 0; let totalMem = 0; let totalWorkerCount = 0; let
          totalWorkerLoad = 0;

        this.resourceData.map((worker) => {
          totalWorkerLoad += worker.workerLoad;
          totalCpu += worker.cpuUseRatio;
          totalMem += worker.memUseRatio;
          totalWorkerCount++;
          return null;
        });
        if (totalWorkerCount !== 0) {
          this.avgLoad = (totalWorkerLoad / totalWorkerCount).toFixed(2);
          this.avgCpu = (totalCpu / totalWorkerCount).toFixed(2);
          this.avgMem = (totalMem / totalWorkerCount).toFixed(2);
        }
        this.refreshLoading = false;
        if (this.selectWorker.id) {
          const workerIds = [];

          workerIds.push(this.selectWorker.id);
          if (this.selectWorker.consoleJobId) {
            this.selectWorker.currentStatus = 'process';
            if (this.selectWorker.consoleTaskState === 'SUCCESS') {
              this.selectWorker.currentStatus = 'finish';
            } else if (this.selectWorker.consoleTaskState === 'FAILED') {
              this.selectWorker.currentStatus = 'error';
            } else if (this.selectWorker.consoleTaskState === 'CANCELED') {
              this.selectWorker.currentStatus = '';
            }
          }
          if (this.selectWorker.consoleTaskState === 'EXECUTE') {
            this.queryProcess(workerIds);
          }
        }
      }).catch(() => {
        this.refreshLoading = false;
      });
    },
    listRegions() {
      listRegions().then((res) => {
        if (res.data.code === '1') {
          this.regions = res.data.data;
        }
      });
    },
    listCloudOrIdcNames() {
      listCloudOrIdcNames().then((res) => {
        if (res.data.code === '1') {
          this.cloudOrIdcNames = res.data.data;
        }
      });
    },
    listWorkerTypes() {
      listWorkerTypes().then((res) => {
        if (res.data.code === '1') {
          this.workerTypes = res.data.data;
        }
      });
    },
    handleChangeWorkerStatus(value) {
      this.showConfirmOnline = false;
      this.showConfirmOffline = false;
      this.addWorker.workerId = this.selectWorker.id;
      if (value) {
        this.addWorker.workerState = 'WAIT_TO_ONLINE';
        waitToOnline(this.addWorker).then((res) => {
          if (res.data.code === '1') {
            this.getWorkList();
          } else if (res.data.code === '6028') {
            // this.nextStep = this.handleChangeWorkerStatus(true);
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      } else {
        this.addWorker.workerState = 'WAIT_TO_OFFLINE';
        waitToOffline(this.addWorker).then((res) => {
          if (res.data.code === '1') {
            this.getWorkList();
          } else if (res.data.code === '6028') {
            // this.nextStep = this.handleChangeWorkerStatus(false);
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      }
      // this.isEdit = true;
      // this.handleConfirmAddWorker();
    },
    handleCloseDepoly() {
      this.showDeployProgress = false;
    },
    showDeployProgressFunc(workerIds) {
      // this.showDeployProgress = true;
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        queryProcess({
          clusterId: this.clusterId,
          workerIds,
          pageData: this.pageData,
          deployActionType: 'INSTALL'
        }).then((response) => {
          if (response.data.code === '1') {
            this.selectWorker.consoleTasks = response.data.data[this.selectWorker.id];
            this.selectWorker.currentStatus = 'process';
            if (this.selectWorker.consoleTasks.length > 0) {
              if (this.selectWorker.consoleTasks[this.selectWorker.consoleTasks.length - 1]
                                && this.selectWorker.consoleTasks[this.selectWorker.consoleTasks.length - 1].taskState === 'SUCCESS') {
                this.selectWorker.currentStatus = 'finish';
                this.getWorkList();
              }
              this.selectWorker.step = 0;
              this.selectWorker.consoleTasks.map((task) => {
                if (task.taskState !== 'WAIT_START') {
                  this.selectWorker.step++;
                }
                if (task.taskState === 'FAILED') {
                  this.selectWorker.currentStatus = 'error';
                  this.getWorkList();
                  this.selectWorker.step--;
                }
                return null;
              });
              if (this.selectWorker.consoleTaskState === 'CANCELED') {
                this.selectWorker.currentStatus = '';
              }
            }

            this.selectWorker = { ...this.selectWorker };

            const that = this;

            this.getProcess = setInterval(() => {
              queryProcess({
                clusterId: that.clusterId,
                workerIds,
                pageData: that.pageData,
                deployActionType: 'INSTALL'
              }).then((response1) => {
                if (response1.data.code === '1') {
                  let isFinished = true;

                  that.selectWorker.consoleTasks = response1.data.data[that.selectWorker.id];
                  if (that.selectWorker.consoleTasks.length === 0) {
                    clearInterval(that.getProcess);
                  }
                  that.selectWorker.currentStatus = 'process';
                  if (that.selectWorker.consoleTasks.length > 0) {
                    if (that.selectWorker.consoleTasks[that.selectWorker.consoleTasks.length - 1].taskState === 'SUCCESS') {
                      that.selectWorker.current = 'finish';
                      that.getWorkList();
                    }
                    that.selectWorker.step = 0;
                    that.selectWorker.consoleTasks.map((task) => {
                      if (task.taskState !== 'WAIT_START') {
                        that.selectWorker.step++;
                      }
                      if (task.taskState === 'FAILED') {
                        that.selectWorker.currentStatus = 'error';
                        that.selectWorker.step--;
                      }
                      return null;
                    });
                    if (that.selectWorker.consoleTaskState === 'CANCELED') {
                      that.selectWorker.currentStatus = '';
                    }
                  }
                  if (that.selectWorker.currentStatus === 'process') {
                    isFinished = false;
                  }
                  if (isFinished) {
                    clearInterval(that.getProcess);
                  }
                  that.selectWorker = { ...that.selectWorker };
                } else if (response1.data.code === '6028') {
                  clearInterval(that.getProcess);
                  that.nextStep = that.showDeployProgressFunc(workerIds);
                  that.$refs.stToken.handleShowAkSk();
                } else if (response1.data.code === '6047') {
                  that.$refs.aliyunAKSK.handleShowAkSk();
                }
              });
            }, 5000);
          } else if (response.data.code === '6028') {
            this.nextStep = this.showDeployProgressFunc(workerIds);
            this.$refs.stToken.handleShowAkSk();
          } else if (response.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      }
    },
    handleShowDeploy(row) {
      this.selectWorker = row;
      const workerIds = [];

      workerIds.push(this.selectWorker.id);
      this.showDeployProgressFunc(workerIds);
    },
    handleShowSighin(workers) {
      this.showAddWorker = false;
      this.workers = workers;
      this.getWorkList();
      this.addWorkerForm = {
        clusterId: this.$route.params.id,
        workerIp: '',
        cloudOrIdcName: 'aliyun',
        region: 'hangzhou',
        physicMemMb: 0,
        physicCoreNum: 0,
        physicDiskMb: 0,
        workerType: 'BARE_METAL',
        workerState: ''
      };
      this.addWorker.cloudOrIdcName = this.clusterInfo.cloudOrIdcName;
    },
    handleInstall() {
      const workerIds = [];

      workerIds.push(this.selectWorker.id);
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        install({
          clusterId: this.clusterId,
          workerIds,
          pageData: this.pageData,
          deployActionType: 'INSTALL'
        }).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('开始安装！');
            this.getWorkList();
            this.showDeployProgressFunc(workerIds);
          } else if (res.data.code === '6028') {
            this.nextStep = this.handleInstall;
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      }
    },
    handleConfirmUnInstall() {
      this.showConfirmUninstall = false;
      const workerIds = [];

      workerIds.push(this.selectWorker.id);
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        uninstall({
          clusterId: this.clusterId,
          workerIds,
          pageData: this.pageData,
          deployActionType: 'UNINSTALL'
        }).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('开始卸载！');
            this.getWorkList();
            this.showDeployProgressFunc(workerIds);
          } else if (res.data.code === '6028') {
            this.nextStep = this.handleConfirmUnInstall;
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      }
    },
    handleConfirmUpgradeAll() {
      this.showConfirmUpgrade = false;
      const workerIds = [];

      workerIds.push(this.selectWorker.id);
      upgradeAll({
        clusterId: this.clusterId,
        workerIds,
        pageData: this.pageData,
        deployActionType: 'UPGRADE_ALL'
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('开始更新！');
          this.getWorkList();
          this.showDeployProgressFunc(workerIds);
        } else if (res.data.code === '6028') {
          this.$refs.stToken.handleShowAkSk();
        } else if (res.data.code === '6047') {
          this.$refs.aliyunAKSK.handleShowAkSk();
        }
      });
    },
    handleStopInstallAndClean() {
      this.showDeployProgress = false;
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        this.stopInstall();
      } else {
        this.showStopLocal = true;
      }
    },
    stopInstall() {
      this.showStop = false;
      const workerIds = [];

      workerIds.push(this.selectWorker.id);
      if (this.selectWorker.cloudOrIdcName === 'ALIBABA_CLOUD') {
        stopInstallAndClean({
          userAk: this.addWorkerForm.aliyunAk,
          userSk: this.addWorkerForm.aliyunSk,
          clusterId: this.clusterId,
          workerIds,
          pageData: this.pageData,
          deployActionType: 'INSTALL'
        }).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('开始停止并清除！');
            clearInterval(this.getProcess);
            this.showDeployProgress = false;
            this.getWorkList();
          } else if (res.data.code === '6028') {
            clearInterval(this.getProcess);
            this.nextStep = this.stopInstall;
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });
      }
    },
    rowClassName(row) {
      if (this.taskId) {
        if (row.taskId.toString() === this.taskId.toString()) {
          return 'current-task-row';
        }
        return '';
      }
      return '';
    },
    handleSelectWorker(worker) {
      this.selectWorker = worker;
      const workerIds = [];

      workerIds.push(worker.id);
      // this.queryProcess(workerIds);
      clearInterval(this.getProcess);
    },
    toMonitorPage(worker) {
      const { privateIp, publicIp, id } = worker;
      const ip = privateIp || publicIp || '';

      if (ip && id) {
        this.$router.push({ path: `/monitor/worker/graph?ip=${ip}&id=${id}` });
      }
    },
    handleDispatch() {
      this.showDispatch = false;
      const data = {
        oldWorkerId: this.selectedRow.workerId,
        targetWorkerId: this.workerToDispatch,
        dataTaskId: this.selectedRow.taskId
      };

      manualSchedule(data).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('手动调度成功！');
          this.getWorkList();
        }
      });
    },
    handleCancel() {
      this.showAddWorker = false;
      this.showConfigData = false;
      this.showDownloadClient = false;
      this.showConfigVerify = false;
      this.addWorkerForm = {
        clusterId: this.$route.params.id,
        workerIp: '',
        cloudOrIdcName: 'aliyun',
        region: 'hangzhou',
        physicMemMb: 0,
        physicCoreNum: 0,
        physicDiskMb: 0,
        workerType: 'BARE_METAL',
        workerState: ''
      };
      this.addWorker.cloudOrIdcName = this.clusterInfo.cloudOrIdcName;
      this.showDispatch = false;
      this.showLog = false;
      this.verifyCode = '';
    },
    queryProcess(workerIds) {
      queryProcess({
        clusterId: this.clusterId,
        workerIds,
        pageData: this.pageData,
        deployActionType: 'INSTALL'
      }).then((res) => {
        if (res.data.code === '1') {
          this.selectWorker.consoleTasks = res.data.data[this.selectWorker.id];
          this.selectWorker.currentStatus = 'process';
          if (this.selectWorker.consoleTasks.length > 0) {
            if (this.selectWorker.consoleTasks[this.selectWorker.consoleTasks.length - 1].taskState === 'SUCCESS') {
              this.selectWorker.currentStatus = 'finish';
              // this.getWorkList();
            }
            this.selectWorker.step = 0;
            this.selectWorker.consoleTasks.map((task) => {
              if (task.taskState !== 'WAIT_START') {
                this.selectWorker.step++;
              }
              if (task.taskState === 'FAILED') {
                this.selectWorker.currentStatus = 'error';
                this.selectWorker.step--;
              }
              return null;
            });
          }
          this.selectWorker = { ...this.selectWorker };
        } else if (res.data.code === '6028') {
          clearInterval(this.getProcess);
          this.nextStep = this.showDeployProgressFunc(workerIds);
          this.$refs.stToken.handleShowAkSk();
        } else if (res.data.code === '6047') {
          this.$refs.aliyunAKSK.handleShowAkSk();
        }
      });
    },
    handleCopy(data) {
      const input = document.createElement('textarea');

      input.value = data;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);

      this.$Message.success('复制成功！');
    },
    handleDownloadClient(worker) {
      downLoadClientUrl({ workerId: worker.id }).then((res) => {
        if (res.data.code === '1') {
          this.downloadUrl = res.data.data;
          this.showDownloadClient = true;
        }
      });
    },
    handleDownloadConfig(worker) {
      this.showConfigVerify = true;
      this.selectWorker = worker;
      this.verifyCodeError = '';
      this.verifyCode = '';
    },
    handleConfirmDownload(verifyCode) {
      clientCoreConfig({ workerId: this.selectWorker.id, verifyCode }).then((res) => {
        if (res.data.code === '1') {
          this.showConfigVerify = false;
          this.configData = res.data.data;
          this.showConfigData = true;
          this.$refs['verify-code-modal'].handleEmptyVerifyCodeModalData();
        }
      });
    },
    handleDownloadDireactly() {
      window.open(this.downloadUrl);
    },
    getConfigData(data) {
      let text = '';

      text += `${data.userAkLabel}=${data.userAkValue}\n`;
      text += `${data.userSkLabel}=${data.userSkValue}\n`;
      text += `${data.wsnLabel}=${data.wsnValue}\n`;
      text += `${data.consoleDomainLabel}=${data.consoleDomainValue}`;
      return text;
    },
    handleVerify() {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: 'FETCH_WORKER_DEPLOY_CORE_CONFIG'
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('发送成功！');
        } else {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          this.$Modal.error({
            title: 'ERROR',
            content: `${res.data.msg}`
          });
        }
      }).catch((res) => {
        this.sendcodeDisabled = true;
        this.sendCodeAgainTime = 60;
        clearInterval(this.sendCodeAgain);
        this.$Modal.error({
          title: 'ERROR',
          content: `${res.data.msg}`
        });
      });
    },
    handleAlarm(worker, data) {
      const alertConfigVO = {
        phone: false,
        email: true,
        dingding: true,
        sms: true,
        workerId: worker.id,
        id: worker.alertConfigVO.id
      };

      if (!data) {
        this.showConfirmUpdateAlarm = true;
        this.selectWorker = worker;
      } else {
        updateAlertConfig(alertConfigVO).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('修改成功！');
            this.getWorkList();
          } else {
            worker.alertConfigVO.dingding = false;
          }
        });
      }
    },
    handleUpdateAlarm() {
      this.showConfirmUpdateAlarm = false;
      const alertConfigVO = {
        phone: false,
        email: false,
        dingding: false,
        sms: false,
        workerId: this.selectWorker.id,
        id: this.selectWorker.alertConfigVO.id
      };

      updateAlertConfig(alertConfigVO).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('修改成功！');
          this.getWorkList();
        } else {
          this.selectWorker.alertConfigVO.dingding = true;
        }
      });
    },
    handleCancelUpdateAlarm() {
      this.selectWorker.alertConfigVO.dingding = true;
      this.showConfirmUpdateAlarm = false;
      this.showConfirmDelete = false;
      this.showConfirmUninstall = false;
      this.showConfirmOffline = false;
      this.showConfirmOnline = false;
      this.showConfirmUpgrade = false;
    },
    handleGoConsoleJob(worker) {
      this.$router.push({ path: `/system/state/task/${worker.consoleJobId}` });
    },
    handleRetryTask(task) {
      retryConsoleTask({
        consoleJobId: this.consoleJobId,
        consoleTaskId: task.id
      }).then((res) => {
        if (res.data.code === '1') {
          this.getConsoleJobInfo();
        }
      });
    },
    handleShowConfirmOffline() {
      this.showConfirmOffline = true;
    },
    handleShowConfirmOnline() {
      this.showConfirmOnline = true;
    },
    handleGoMonitor(worker) {
      this.$router.push({ path: `/monitor/worker/graph?ip=${worker.privateIp}&id=${worker.id}` });
    },
    handleShowLogContainer() {
      this.showLog = true;
      this.handleShowLog();
    },
    handleSelectLog(logName) {
      this.logData.map((item) => {
        if (item.fileName === logName) {
          this.selectedLog = item;
        }
        return null;
      });
    },
    handleShowLog() {
      this.logLoading = true;
      tailWorkerLog({
        workerId: this.selectWorker.id

      }).then((res) => {
        this.logLoading = false;
        if (res.data.code === '1') {
          this.logData = res.data.data;
          if (this.selectedLog && this.selectedLog.fileName) {
            this.logData.map((item) => {
              if (item.fileName === this.selectedLog.fileName) {
                this.selectedLog = item;
              }
              return null;
            });
          } else if (this.logData.length > 0) {
            this.selectedLog = this.logData[0];
            this.logFileName = this.selectedLog.fileName;
          }
          this.showLog = true;
        }
      });
    }
  }

};
</script>
<style lang="less">
.demo-Circle-inner {
  font-size: 12px !important;
}

.job-header {
  height: 80px;
  background-color: #DEEFFF;
  border: 1px solid #DADADA;
  border-right: none;
  position: relative;
  padding: 10px 20px;

  span {
    display: inline-block;
  }

  .job-header-db {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #F4C22D;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    box-shadow: 0 3px 12px 0 rgba(244, 194, 45, 0.79);
    margin-right: 16px;
    vertical-align: middle;

    .iconfont {
      font-size: 20px;
    }
  }

  .job-header-name {
    display: inline-block;
    vertical-align: middle;

    .job-header-desc {
      color: #808CA7;
      margin-top: 4px;
    }

    .job-header-name-main {
      font-size: 16px;
      font-family: PingFangSC-Medium,serif;
      margin-right: 6px;
    }

    .job-info-status-running {
      margin-left: 8px;
    }
  }

  .task-detail-step {
    width: 670px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 100px;

    .ivu-steps .ivu-steps-title {
      background: #DEEFFF;
      margin-left: 16px;
      margin-top: 2px;
      display: inline-block;
    }

    .ivu-steps .ivu-steps-head {
      background: none;
    }

    .ivu-steps .ivu-steps-tail > i {
      height: 1px;
      margin-top: 4px;
      margin-left: 6px;
    }

    .ivu-steps-item.ivu-steps-status-process .ivu-steps-tail > i {
      background-color: #A2A9B6;
    }

    .ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner {
      background-color: #A2A9B6;
    }

    .ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner span, .ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner > .ivu-steps-icon {
      color: #ffffff;
    }

    .ivu-steps-item.ivu-steps-status-wait .ivu-steps-tail > i {
      background-color: #A2A9B6;
    }
  }

  .job-header-buttons {
    position: absolute;
    right: 16px;
    top: 24px;
  }
}

.worker-body {
  border-left: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
}

.worker-list-content {
  margin-top: 10px;

  .worker-item-container {
    width: 740px;
    float: left;

    .worker-item-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      background: #F5F5F5;
      padding-left: 16px;
      border-bottom: 1px solid #DADADA;
    }

    .worker-item-content {
      background-color: #ffffff;
      border-bottom: 1px solid #DADADA;
      position: relative;
      cursor: pointer;

      .worker-info-content {
        padding: 28px 20px 28px 28px;

        .status-point {
          position: absolute;
          left: 12px;
          top: 37px;
        }

        .alarm-icon {
          width: 20px;
          height: 20px;
          display: inline-block;
          /*border-radius: 50%;*/
          /*background-color: #FF6E0D;*/
          color: #FF6E0D;
          text-align: center;
          line-height: 20px;
          cursor: pointer;
          /*position: absolute;*/
          /*top: 4px;*/
          /*left: 4px;*/
          /*margin-left: 8px;*/

          .iconyibuforce {
            font-size: 14px;
          }
        }
      }

      .worker-ip {
        font-size: 18px;
        font-family: PingFangSC-Semibold,serif;
        vertical-align: middle;
      }

      .worker-status {
        display: inline-block;
        padding: 0 10px;
        border-radius: 14px;
        color: #ffffff;
        font-size: 14px;
        vertical-align: middle;
        margin-left: 4px;
      }

      .worker-info {
        margin-top: 15px;
        color: #555;

        .show-more-info {
          margin-left: 10px;
          color: #333;
        }
      }

      .worker-item-circle {
        position: absolute;
        right: 20px;
        top: 0;
        width: 280px;
        text-align: center;
        margin-top: 24px;
      }
    }

    .worker-item-selected {
      border-left: 4px solid #0BB9F8;
      background-color: #F3F8FF;

      .worker-item-action {
        width: 100%;
        height: 36px;
        line-height: 36px;
        background-color: #E9F4FF;
        border-top: 1px solid #DADADA;
        padding: 0 20px;

        .worker-action-off {
          float: right;
        }

        .worker-action-item {
          padding: 10px 30px 10px 10px;

          .iconfont {
            margin-right: 6px;
          }
        }

        .worker-action-item-disabled {
          color: #999999;
          cursor: not-allowed;
        }
      }
    }
  }

  .worker-detail-container {
    padding: 20px;
    border-left: 1px solid #DADADA;
    background-color: #ffffff;
    margin-left: 740px;
    height: 100%;

    .install-worker-process-content {
      position: relative;

      .install-worker-process {
        background-color: #E7F9FF;
        padding: 8px 10px;
        margin-bottom: 10px;
        border-bottom: 2px solid #CBE3EC;
      }

      .install-worker-process-img {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #0BB9F8;
        height: 2px;
        width: 25%
      }

      .ivu-progress-bg {
        background-color: #0BB9F8 !important;
      }
    }

    .worker-detail-metric {
      background-color: #F5F5F5;
      padding: 28px 0;

      .worker-detail-metric-item {
        display: inline-block;
        width: 16%;
        /*padding: 0 28px;*/
        border-right: 1px solid #DADADA;
        text-align: center;

        .worker-count {
          font-size: 21px;
          font-family: Avenir-Medium,serif;
          margin-bottom: 8px;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .worker-task-container {
      margin-top: 20px;
      position: relative;

      .show-log-btn {
        position: absolute;
        right: 0;
        top: -8px;
      }
    }
  }
}

.ivu-table .current-task-row td {
  background-color: rgba(45, 183, 245, 0.1);

}

.install-worker-status {
  font-weight: 500;
  color: #333333;
}
</style>
