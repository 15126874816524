<template>
    <div style="padding-bottom: 74px">
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem>异步任务</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchKey" inline label-position="left"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
                        <Option value="type" label="任务类型">
                            <span>任务类型</span>
                        </Option>
                        <Option value="status" label="状态">
                            <span>任务状态</span>
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='type'">
                    <Select v-model="searchKey.label" style="width: 250px">
                        <Option value="">全部</Option>
                        <Option v-for="(label) of consoleJobLabels" :value="label" :key="label">{{Mapping.consoleJob[label]}}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='status'">
                    <Select v-model="searchKey.consoleTaskState" style="width: 250px">
                        <Option value="">全部</Option>
                        <Option v-for="(state) of consoleTaskStates" :value="state" :key="state">{{Mapping.consoleJobStatus[state]}}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <pd-button type="primary" @click="getConsoleJobList">查询</pd-button>
                </FormItem>
            </Form>
            <div class="page-header-function">
                <pd-button type="default" style="margin-right: 6px" @click="getConsoleJobList"
                        :loading="refreshLoading">
                    刷新
                </pd-button>
            </div>
        </div>
        <Table stripe style="margin-top: 20px" size="small" border :columns="asyncColumn" :data="showData">
            <template slot-scope="{ row }" slot="link">
                <a @click="handleDetail(row.id)" style="color: #0BB9F8;width:100%;display: block">{{row.id}}</a>
            </template>
            <template slot-scope="{ row }" slot="resourceId">
                <a @click="getJob(row)" v-if="row.dataJobName">{{row.dataJobName}}</a>
                <span v-if="row.workerName">{{row.workerName}}</span>
                <span v-if="row.dsInstanceId">{{row.dsInstanceId}}</span>
            </template>
            <template slot-scope="{ row }" slot="resourceDesc">
                <span v-if="row.dataJobDesc">{{row.dataJobDesc}}</span>
                <span v-if="row.workerDesc">{{row.workerDesc}}</span>
                <span v-if="row.datasourceDesc">{{row.datasourceDesc}}</span>
            </template>
            <template slot-scope="{ row }" slot="action">
                <a @click="handleGoDetail(row)" style="margin-right: 16px">详情</a>
<!--                <a v-if="row.taskState!=='SUCCESS'&&row.taskState!=='CANCELED'" @click="handleCancelJob(row)">取消</a>-->
            </template>
            <template slot="taskState" slot-scope="{row}">
                <div :style="`color:${row.taskState==='FAILED'?'#FF1815':row.taskState==='SUCCESS'?'#52C41A':row.taskState==='CANCELED'?'#555555':''}`">
                  <span class="status-point" :style="`background-color:${row.taskState==='FAILED'?'#FF1815':row.taskState==='SUCCESS'?'#52C41A':row.taskState==='CANCELED'?'#555555':''}`">
                  </span>{{Mapping.consoleJobStatus[row.taskState]}}</div>
            </template>
        </Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>上一页</pd-button>
                <span style="margin: 0 10px">第{{page}}页</span>
                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                  <Icon type="ios-arrow-forward" style="font-size: 16px"/>下一页</pd-button>
            </div>
        </div>
    </div>
</template>
<script>
import { listConsoleTaskStates, listConsoleJobLabels } from '@services/api/constant';
import fecha from 'fecha';
import { getConsoleJobList, cancelConsoleJob } from '@services/api/consoleJob';
import { queryJobById } from '@services/api/noError';
import Mapping from '../util';

export default {
  mounted() {
    this.getConsoleJobList();
    this.listConsoleTaskStates();
    this.listConsoleJobLabels();
  },
  data() {
    return {
      searchType: 'type',
      Mapping,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      noMoreData: false,
      refreshLoading: false,
      searchKey: {
        consoleTaskState: '',
        label: '',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      size: 20,
      consoleTaskStates: [],
      consoleJobLabels: [],
      showData: [],
      asyncColumn: [
        {
          title: '名称',
          key: 'label',
          render: (h, params) => h('div', {}, Mapping.consoleJob[params.row.label])
        },
        {
          title: '发起者',
          key: 'launcher',
          width: 100
        },
        {
          title: '发起时间',
          key: 'launchTime',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.launchTime), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '状态',
          key: 'taskState',
          width: 120,
          slot: 'taskState'
        },
        {
          title: '类型',
          key: 'resourceType',
          width: 120,
          render: (h, params) => h('div', {}, Mapping.resourceType[params.row.resourceType])
        },
        {
          title: '资源ID',
          key: 'dataJobName',
          width: 240,
          slot: 'resourceId'
        },
        {
          title: '资源描述',
          key: 'dataJobDesc',
          slot: 'resourceDesc'
        },
        {
          title: '操作',
          slot: 'action',
          width: 160
        }

      ],
      asyncData: []
    };
  },
  methods: {
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        consoleTaskState: '',
        label: '',
        pageData: {
          startId: 0,
          pageSize: 10
        }
      };
    },
    handleRefresh() {
      this.getConsoleJobList();
    },
    handleCancelJob(row) {
      this.$Modal.confirm({
        title: '取消异步任务确认',
        content: '<p>取消之后整个任务将失败并结束。确认要取消该异步任务吗？</p>',
        onOk: () => {
          cancelConsoleJob({
            consoleJobId: row.id,
            consoleTaskId: ''
          }).then((res) => {
            if (res.data.code === '1') {
              this.getConsoleJobList();
            }
          });
        }
      });
    },
    handleGoDetail(row) {
      this.$router.push({ path: `/system/state/task/${row.id}` });
    },
    getConsoleJobList(type) {
      this.refreshLoading = true;
      const consoleTaskData = {
        consoleTaskState: this.searchKey.consoleTaskState,
        label: this.searchKey.label,
        startId: this.searchKey.pageData.startId,
        pageSize: this.searchKey.pageData.pageSize
      };

      getConsoleJobList(consoleTaskData).then((res) => {
        if (res.data.code === '1') {
          this.asyncData = res.data.data;
          this.showData = this.asyncData;
          if (type === 'next') {
            if (!this.prevFirst[this.page - 1]) {
              this.prevFirst.push(this.firstId);
            }
          }
          this.firstId = this.showData[0].id;
          this.lastId = this.showData[this.showData.length - 1].id;
          this.noMoreData = res.data.data.length < this.searchKey.pageData.pageSize;
        }
        this.refreshLoading = false;
      }).catch(() => {
        this.refreshLoading = false;
      });
    },
    listConsoleTaskStates() {
      listConsoleTaskStates().then((res) => {
        if (res.data.code === '1') {
          this.consoleTaskStates = res.data.data;
        }
      });
    },
    listConsoleJobLabels() {
      listConsoleJobLabels().then((res) => {
        if (res.data.code === '1') {
          this.consoleJobLabels = res.data.data;
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchKey.pageData.startId = startId;
      this.getConsoleJobList('prev');
    },
    handleNext() {
      this.searchKey.pageData.startId = this.lastId;
      this.getConsoleJobList('next');
      this.page++;
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    getJob(row) {
      queryJobById({ jobId: row.resourceId }).then((res) => {
        if (res.data.code === '1') {
          this.$router.push({ path: `/data/job/${row.resourceId}` });
        } else {
          this.$Modal.info({
            title: '跳转失败',
            content: '该任务已不存在'
          });
        }
      });
    }
  }
};
</script>
