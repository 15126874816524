<template>
  <div>
    <!--                <svg class="icon svg-icon" aria-hidden="true">-->
    <!--                    <use xlink:href="#icon-zanwushujuimg"></use>-->
    <!--                </svg>-->
    <!--                <p>您还没有相关任务，<a @click="handleAddTask">创建任务</a></p>-->
    <div class="no-job-guide-container">
      <div class="no-job-guide">
        <img src="@asset/guide1.svg" alt="">
        <p class="no-job-guide-title">添加机器</p>
        <div style="margin: 10px 0 12px 0">添加您的专属机器到CloudCanal集群中</div>
        <pd-button type="primary" ghost @click="handleGoWorker">添加机器</pd-button>
      </div>
      <div class="no-job-guide-arrow">
        <i class="iconfont iconjiantou2"></i>
      </div>
      <div class="no-job-guide">
        <img src="@asset/guide3.svg" alt="添加数据源"/>
        <p class="no-job-guide-title">添加数据源</p>
        <div style="margin: 10px 0 12px 0">添加需要进行数据同步、迁移的数据源</div>
        <pd-button type="primary" ghost @click="handleGoDatasource">添加数据源</pd-button>
      </div>
      <div class="no-job-guide-arrow">
        <i class="iconfont iconjiantou2"></i>
      </div>
      <div class="no-job-guide">
        <img src="@asset/guide2.svg" alt="创建任务">
        <p class="no-job-guide-title">创建任务</p>
        <div style="margin: 10px 0 12px 0">使用您的专属机器进行数据迁移、同步</div>
        <pd-button type="primary" ghost @click="handleGoDataJob">创建任务</pd-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    handleGoWorker: Function,
    handleGoDatasource: Function,
    handleGoDataJob: Function
  }
};
</script>
