<template>
  <Modal
    :value="visible"
    :title="title"
    footer-hide
    :width="width"
      :closable="false"
    @cancel="handleEmptyVerifyCodeModalData"
  >
    <div>
      <slot name="content"/>
      <Alert type="error" v-if="verifyCodeError" style="width: 290px;" show-icon>{{
          verifyCodeError
        }}
      </Alert>
      <p>
        <Input style="width: 160px;" type="text" v-model="verifyCode"
               :placeholder="!store.state.globalConfig.product_trial?'请输入短信验证码':`点击获取，输入${store.state.globalConfig.product_trial_verify_code}`"/>
        <pd-button type="primary" ghost style="margin-left: 10px" :disabled="sendCodeDisabled"
                   @click="handleSendVerifyCode">
          {{ sendCodeDisabled ? '再次获取(' + sendCodeAgainTime + 's)' : '获取短信验证码' }}
        </pd-button>
      </p>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button type="primary" @click="handleConfirm">确定</pd-button>
        <pd-button @click="handleEmptyVerifyCodeModalData">关闭</pd-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import store from '@store';
import { sendCodeInLoginState } from '../../services/api/verify';

export default {
  name: 'VerifyCodeModal',
  props: {
    visible: Boolean,
    title: String,
    handleCloseModal: Function,
    handleConfirmCallback: Function,
    footerHide: Boolean,
    verifyCodeType: String,
    width: { type: [Number, String], default: 400 }
  },
  data() {
    return {
      verifyCodeError: '',
      sendCodeDisabled: false,
      verifyCode: '',
      sendCodeAgainTime: 60,
      store
    };
  },
  methods: {
    handleEmptyData() {
      this.sendCodeDisabled = false;
      this.verifyCode = '';
      this.verifyCodeError = '';
      this.sendCodeAgainTime = 60;
    },
    handleEmptyVerifyCodeModalData() {
      this.handleEmptyData();
      this.handleCloseModal();
    },
    setErrorMsg(msg) {
      this.verifyCodeError = msg;
    },
    handleConfirm() {
      this.handleConfirmCallback(this.verifyCode, true);
      this.handleEmptyData();
    },
    handleSendVerifyCode() {
      this.sendCodeDisabled = true;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendCodeDisabled = false;
        }
      }, 1000);

      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: this.verifyCodeType
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('发送成功！');
        } else {
          this.sendCodeDisabled = false;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          this.verifyCodeError = res.data.msg;
        }
      }).catch((res) => {
        this.sendCodeDisabled = false;
        this.sendCodeAgainTime = 60;
        clearInterval(this.sendCodeAgain);
        this.$Modal.error({
          title: 'ERROR',
          content: `${res.data.msg}`
        });
      });
    }
  },
  destroyed() {
    clearInterval(this.sendCodeAgainTime);
  }
};
</script>

<style scoped>

</style>
