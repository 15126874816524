import { instance } from '../instance';

export const getConsoleJobList = (param) => instance.post('consolejob/list', param);

export const cancelConsoleJob = (param) => instance.post('consolejob/cancel', param);

export const retryConsoleTask = (param) => instance.post('consolejob/retrytask', param);
export const skipConsoleTask = (param) => instance.post('consolejob/skiptask', param);

export const queryConsoleJob = (param) => instance.post('consolejob/queryconsolejob', param);
