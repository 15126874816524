<template>
    <div class="license-center">
        <Breadcrumb>
            <BreadcrumbItem>资源中心</BreadcrumbItem>
        </Breadcrumb>
        <Tabs value="name1" @on-click="handleChangeTab">
            <TabPane label="套餐" name="name1">
                <div class="page-header-container">
                    <Form ref="formInline" :model="searchData" inline label-position="right"
                          style="padding-right: 300px">
                        <FormItem>
                            <Select v-model="setSearchType" style="width:160px" @on-change="handleChangeSearchType">
                                <Option value="setName" label="套餐名称">
                                    <span>套餐名称</span>
                                </Option>
                                <Option value="setDesc" label="备注">
                                    <span>备注</span>
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem v-if="setSearchType==='setName'">
                            <Input v-model="searchData.licenseSetMetaLike" style="width: 280px;"/>
                        </FormItem>
                        <FormItem v-if="setSearchType==='setDesc'">
                            <Input v-model="searchData.descriptionLike" style="width: 280px;"/>
                        </FormItem>
                        <FormItem>
                            <pd-button type="primary" @click="listAll">查询</pd-button>
                        </FormItem>
                    </Form>
                    <div class="page-header-function">
                        <!--            <a href="/home/document" target="_blank">查看任务创建流程</a>-->
                        <pd-button type="primary" ghost @click="handleAddPackage">
                            <i class="iconfont" style="font-size: 12px">&#xe662;</i>
                            新增套餐</pd-button>
                        <pd-button type="default" style="margin-right: 6px" @click="listAll"
                                   :loading="refreshLoading">
                            刷新
                        </pd-button>
                    </div>
                </div>
                <div style="margin-top: 10px;margin-right: 270px">
                    <Table height="680" highlight-row @on-current-change="handleSelectRow" border stripe :columns="packageColumn" :data="packageData">
                        <template slot-scope="{row}" slot="purchaseUrl">
                            <a :href="row.purchaseUrl" target="_blank">{{row.purchaseUrl}}</a>
                        </template>
                        <template slot="action" slot-scope="{row}">
                            <a @click="handleGenerateLicense(row)">生成序列号</a>
                            <a style="margin-left: 16px" @click="handleDeleteSet(row)">删除</a>
                        </template>
                    </Table>
                </div>
                <div class="license-set-detail">
                    <p class="title">套餐明细</p>
                    <div>
                        <p v-for="(item,key) in selectedRow.licenseMetas" :key="item.id" class="license-set-detail-item">
                            <span>{{getLicenseType(key)}}</span>
                            <span>{{getLicenseCount(key)}}</span>
                            <span>x {{item}}</span>
                        </p>
                    </div>
                </div>
            </TabPane>
            <TabPane label="库存" name="name2">
                <div class="page-header-container">
                    <Form ref="formInline" :model="searchData" inline label-position="right"
                          style="padding-right: 300px">
                        <FormItem>
                            <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
                                <Option value="used" label="状态">
                                    <span>状态</span>
                                </Option>
                                <Option value="userName" label="使用者">
                                    <span>使用者</span>
                                </Option>
                                <Option value="licenseSetMetaId" label="套餐">
                                    <span>套餐</span>
                                </Option>
                                <Option value="licenseSetKey" label="状态">
                                    <span>序列号</span>
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem v-if="searchType === 'used'">
                            <Select v-model="searchData.used" transfer style="width: 120px">
                                <Option value="false">未兑换</Option>
                                <Option value="true">已兑换</Option>
                            </Select>
                        </FormItem>
                        <FormItem v-if="searchType === 'userName'">
                            <Input v-model="searchData.userName" style="width: 280px"/>
                        </FormItem>
                        <FormItem v-if="searchType === 'licenseSetMetaId'">
                            <Select v-model="searchData.licenseSetMetaId" style="width:280px" @on-change="handleChangeSetMeta">
                                <Option v-for="item in packageData" :value="item.id" :key="item.id">{{ item.licenseSetMeta }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem v-if="searchType === 'licenseSetKey'">
                            <Input v-model="searchData.licenseSetKey" style="width: 280px"/>
                        </FormItem>
                        <FormItem>
                            <pd-button type="primary" @click="listLicenseSet">查询</pd-button>
                        </FormItem>
                    </Form>
                    <div class="page-header-function">
                        <!--            <a href="/home/document" target="_blank">查看任务创建流程</a>-->
                        <pd-button type="primary" ghost @click="handleGenerateLicense">
                            <i class="iconfont" style="font-size: 12px">&#xe662;</i>
                            生成序列号</pd-button>
                        <pd-button type="default" style="margin-right: 6px" @click="listLicenseSet"
                                   :loading="refreshLoading">
                            刷新
                        </pd-button>
                    </div>
                </div>
                <Table style="margin-top: 10px" border stripe :columns="inventoryColumn" :data="inventoryData">
                    <template slot="action" slot-scope="{row}">
                        <a @click="handleCopy(row.licenseSetKey)">复制</a>
                    </template>
                </Table>
            </TabPane>
        </Tabs>
        <div class="page-footer-container" v-if="currentValue==='name2'" >
            <div class="page-footer-paging">
                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>上一页</pd-button>
                <span style="margin: 0 10px">第{{page}}页</span>
                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                  <Icon type="ios-arrow-forward" style="font-size: 16px"/>下一页</pd-button>
            </div>
        </div>
        <Modal
                v-model="showAddPackage"
                title="新增套餐"
                footer-hide
                width="778px">
                <div>
                    <Form :model="addPackage" label-position="right" :label-width="80">
                        <FormItem label="套餐名称">
                            <Input style="width: 415px" v-model="addPackage.licenseSetMeta"></Input>
                        </FormItem>
                        <FormItem label="购买链接">
                            <Input style="width: 415px" v-model="addPackage.purchaseUrl"></Input>
                        </FormItem>
                        <FormItem label="备注">
                            <Input style="width: 415px" v-model="addPackage.description"></Input>
                        </FormItem>
                        <FormItem label="选择项目">
                            <Row>
                                <Col :span="4">
                                    <div class="choose-container" style="border-right: none">
                                        <div :class="`choose-item ${selectedType==='incrementDuration'?'choose-item-selected':''}`"
                                             @click="handleChhooseType('incrementDuration')">{{Mapping.licenseType.incrementDuration}}</div>
                                        <div :class="`choose-item ${selectedType==='fullTransferCount'?'choose-item-selected':''}`"
                                             @click="handleChhooseType('fullTransferCount')">{{Mapping.licenseType.fullTransferCount}}</div>
                                        <div :class="`choose-item ${selectedType==='checkCount'?'choose-item-selected':''}`"
                                             @click="handleChhooseType('checkCount')">{{Mapping.licenseType.checkCount}}</div>
                                        <div :class="`choose-item ${selectedType==='structCount'?'choose-item-selected':''}`"
                                             @click="handleChhooseType('structCount')">{{Mapping.licenseType.structCount}}</div>
                                    </div>
                                </Col>
                                <Col :span="4">
                                    <div class="choose-container" style="border-right: none">
                                        <div v-for="item in licenseMetaData[selectedType]" class="choose-item choose-count"
                                             @click="handleChooseCount(item)" :key="item.id">{{selectedType==='incrementDuration'?
                                            item.amount/24:item.amount}}{{selectedType==='incrementDuration'?'天':'次'}}</div>
                                    </div>
                                </Col>
                                <Col :span="16">
                                    <div class="choose-container">
                                        <Table height="280" stripe border :columns="addPackageColumn" :data="addPackageData">
                                            <template slot="action" slot-scope="{index,row}">
                                                <a style="color: #FF1815" @click="handleDeleteItem(index,row)">
                                                    <i class="iconfont iconDEL-R"></i>
                                                </a>
                                            </template>
                                        </Table>
                                        <div class="price-footer">
                                            <span>
                                                <span>折扣</span>
                                                <Input @on-change="handleChangeDiscount" style="width: 80px;margin: 0 6px 0 10px" v-model="addPackage.discount"></Input>
                                                <span>%</span>
                                            </span>
                                            <span style="margin-left: 40px">
                                                <span>金额</span>
                                                <Input @on-change="handleChangeTotalPrice" style="width: 126px;margin: 0 6px 0 10px" v-model="addPackage.totalPrice"></Input>
                                                <span>元</span>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                    <div slot="footer" class="modal-footer" style="margin-top: 20px">
                        <pd-button type="primary" @click="handleGenerateSet">保存</pd-button>
                        <pd-button @click="handleCancel">取消</pd-button>
                    </div>
                </div>
        </Modal>
        <Modal
                v-model="showAddNewLicense"
                title="生成序列号"
                width="400px"
                footer-hide
        >
            <div>
                <Form :model="addLicense" label-position="right" :label-width="80">
                    <FormItem label="选择套餐">
                        <Select v-model="addLicense.metaId" style="width:280px">
                            <Option v-for="item in packageList" :value="item.id" :key="item.id">{{ item.licenseSetMeta }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="数量">
                        <Input style="width: 120px" v-model="addLicense.count"></Input>
                    </FormItem>
                    <FormItem label="渠道">
                        <RadioGroup v-model="addLicense.occupyUse" type="button">
                            <Radio label="TAOBAO">淘宝</Radio>
                        </RadioGroup>
                    </FormItem>
                </Form>
                <div slot="footer" class="modal-footer" style="margin-top: 20px">
                    <pd-button type="primary" @click="handleAddLicense">保存</pd-button>
                    <pd-button @click="handleCancel">取消</pd-button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
  listLicenseSet, listAllLicenseMeta, genNewLicenseSetAndOccupy, generateSet, listAll, listAllSimple, logicDeleteSet
} from '@services/api/license';
import fecha from 'fecha';
import Mapping from '../util';

export default {
  mounted() {
    this.listAll();
  },
  data() {
    return {
      refreshLoading: false,
      setSearchType: 'setName',
      searchType: 'used',
      currentValue: 'name1',
      Mapping,
      addPackage: {
        licenseSetMeta: '',
        description: '',
        discount: 100,
        totalPrice: 0,
        purchaseUrl: ''
      },
      trueTotalPrice: 0,
      addLicense: {
        occupyUse: 'TAOBAO'
      },
      showAddPackage: false,
      showAddNewLicense: false,
      searchData: {
        startId: 0,
        pageSize: 20
      },
      totalPrice: 0,
      noMoreData: false,
      page: 1,
      prevFirst: 0,
      lastId: 0,
      firstId: 0,
      selectedRow: '',
      selectedType: 'incrementDuration',
      selectedCount: '',
      licenseMetaData: {
        fullTransferCount: [],
        incrementDuration: [],
        structCount: [],
        checkCount: []
      },
      packageList: [],
      packageColumn: [
        {
          title: '序号',
          key: 'id',
          width: 100
        },
        {
          title: '套餐名称',
          key: 'licenseSetMeta'
        },
        {
          title: '备注',
          key: 'description'
        },
        {
          title: '折扣',
          key: 'discount',
          width: 120,
          render: (h, params) => h('div', {}, `${params.row.discount}%`)
        },
        {
          title: '价格（元）',
          key: 'price',
          width: 120,
          render: (h, params) => h('div', {}, this.getLicensePrice(params.row.licenseMetas))
        },
        {
          title: '购买链接',
          key: 'purchaseUrl',
          slot: 'purchaseUrl',
          minWidth: 400
        },
        {
          title: '操作',
          slot: 'action',
          width: 180
        }
      ],
      packageData: [],
      inventoryColumn: [
        {
          title: '序号',
          key: 'id',
          width: 100
        },
        {
          title: '序列号',
          key: 'licenseSetKey',
          width: 300
        },
        {
          title: '状态',
          key: 'used',
          width: 120,
          render: (h, parmas) => h('div', {}, parmas.row.used ? '已兑换' : '未兑换')
        },
        {
          title: '使用渠道',
          key: 'occupyUse',
          width: 120
        },
        {
          title: '套餐名称',
          key: 'setMetaName'
        },
        {
          title: '使用者',
          key: 'userName',
          width: 120
        },
        {
          title: '生成者',
          key: 'setOccupiedUserName',
          width: 120
        },
        {
          title: '生成时间',
          key: 'gmtCreate',
          width: 190,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '失效日期',
          key: 'gmtExpire',
          width: 190,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtExpire), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '兑换日期',
          key: 'gmtSetUsed',
          width: 190,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtSetUsed), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '操作',
          key: '',
          slot: 'action',
          width: 100
        }
      ],
      inventoryData: [],
      addPackageColumn: [
        {
          title: '项目名称',
          key: '',
          render: (h, parmas) => h('div', {}, Mapping.licenseType[parmas.row.type])
        },
        {
          title: '规格',
          key: 'amount',
          render: (h, params) => h('div', {}, params.row.type === 'incrementDuration' ? `${params.row.amount / 24}天` : `${params.row.amount}次`)
        },
        {
          title: '价格（元）',
          key: 'price'
        },
        {
          title: '操作',
          key: '',
          slot: 'action'
        }
      ],
      addPackageData: []
    };
  },
  methods: {
    handleChangeDiscount() {
      this.addPackage.totalPrice = this.trueTotalPrice * this.addPackage.discount / 100;
    },
    handleChangeTotalPrice() {
      if (this.trueTotalPrice && this.trueTotalPrice > 0) {
        this.addPackage.discount = Math.ceil(this.addPackage.totalPrice / this.trueTotalPrice * 100);
      }
    },
    listLicenseSet(type) {
      this.refreshLoading = true;
      listLicenseSet(this.searchData).then((res) => {
        this.refreshLoading = false;
        if (res.data.code === '1') {
          this.inventoryData = res.data.data;
          if (type === 'next') {
            if (!this.prevFirst[this.page - 1]) {
              this.prevFirst.push(this.firstId);
            }
          }
          this.firstId = this.inventoryData[0].id;
          this.lastId = this.inventoryData[this.inventoryData.length - 1].id;
          this.noMoreData = res.data.data.length < this.searchData.pageSize;
        }
      });
    },
    handleAddPackage() {
      this.showAddPackage = true;
      this.listAllLicenseMeta();
    },
    handleChhooseType(type) {
      this.selectedType = type;
    },
    handleChooseCount(item) {
      item.type = this.selectedType;
      this.addPackageData.push(item);
      this.totalPrice += Number(item.price);
      this.trueTotalPrice = this.totalPrice;
      this.addPackage.totalPrice = this.trueTotalPrice * this.addPackage.discount / 100;
    },
    listAllLicenseMeta() {
      this.licenseMetaData = {
        fullTransferCount: [],
        incrementDuration: [],
        structCount: [],
        checkCount: []
      };
      listAllLicenseMeta().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.licenseType === 'FULL_TRANSFER_COUNT') {
              this.licenseMetaData.fullTransferCount.push(item);
            } else if (item.licenseType === 'INCREMENT_SYNC_DURATION') {
              this.licenseMetaData.incrementDuration.push(item);
            } else if (item.licenseType === 'FULL_CHECK_COUNT') {
              this.licenseMetaData.checkCount.push(item);
            } else if (item.licenseType === 'STRUCT_TRANSFER_COUNT') {
              this.licenseMetaData.structCount.push(item);
            }
            return null;
          });
        }
      });
    },
    handleDeleteItem(index, row) {
      this.addPackageData.splice(index, 1);
      this.totalPrice -= row.price;
      this.trueTotalPrice = this.totalPrice;
      this.addPackage.totalPrice = this.trueTotalPrice * this.addPackage.discount / 100;
    },
    handleGenerateSet() {
      this.showAddPackage = false;
      if (!this.addPackage.licenseSetMeta) {
        this.$Modal.warning({
          title: '操作错误',
          content: '必须填写套餐名称！'
        });
        return;
      }
      const licenseMetaIds = [];

      this.addPackageData.map((item) => {
        licenseMetaIds.push(item.id);
        return null;
      });
      generateSet({
        licenseSetMeta: this.addPackage.licenseSetMeta,
        description: this.addPackage.description,
        purchaseUrl: this.addPackage.purchaseUrl,
        totalPrice: this.addPackage.totalPrice,
        discount: this.addPackage.discount,
        licenseMetaIds
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('新增成功！');
          this.listAll();
        }
      });
    },
    handleCancel() {
      this.showAddPackage = false;
      this.showAddNewLicense = false;
    },
    listAll() {
      this.refreshLoading = true;
      listAll({ licenseSetMetaLike: this.searchData.licenseSetMetaLike, descriptionLike: this.searchData.descriptionLike }).then((res) => {
        this.refreshLoading = false;
        if (res.data.code === '1') {
          this.packageData = res.data.data;
        }
      });
    },
    handleChangeTab(value) {
      if (value === 'name1') {
        this.listAll();
      } else {
        this.listLicenseSet();
      }
      this.currentValue = value;
    },
    handleSelectRow(currentRow) {
      this.selectedRow = currentRow;
    },
    handleGenerateLicense(row) {
      this.showAddNewLicense = true;
      this.listAllSimple();
      if (row) {
        this.addLicense.metaId = row.id;
      }
    },
    listAllSimple() {
      listAllSimple().then((res) => {
        if (res.data.code === '1') {
          this.packageList = res.data.data;
        }
      });
    },
    handleAddLicense() {
      this.showAddNewLicense = false;
      genNewLicenseSetAndOccupy({
        metaId: this.addLicense.metaId,
        count: this.addLicense.count,
        occupyUse: this.addLicense.occupyUse
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success('新增成功！');
          this.listLicenseSet();
        }
      });
    },
    getLicenseType(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      return Mapping.licenseTypeDefault[map.licenseType];
    },
    getLicenseCount(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      if (map.licenseType === 'INCREMENT_SYNC_DURATION') {
        return `${map.amount / 24}天`;
      }
      return `${map.amount}次`;
    },
    getLicensePrice(data) {
      let totalPrice = 0;

      Object.keys(data).map((key) => {
        const value = key.substring(14, key.length - 2);
        const list = value.split(', ');
        const map = {};

        list.map((item) => {
          const kv = item.split('=');

          map[kv[0]] = kv[1];
          return null;
        });
        totalPrice += map.price * data[key];
        return null;
      });
      return totalPrice;
    },
    handleDeleteSet(row) {
      this.$Modal.confirm({
        title: '删除套餐确认',
        content: `确认要删除套餐名称为${row.description}的套餐吗？`,
        onOk: () => {
          const list = [];

          list.push(row.id);
          logicDeleteSet({ licenseMetaIds: list }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success('删除成功！');
              this.listAll();
            }
          });
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchData.startId = startId;
      this.listLicenseSet('prev');
    },
    handleNext() {
      this.searchData.startId = this.lastId;
      this.listLicenseSet('next');
      this.page++;
    },
    handleCopy(data) {
      const input = document.createElement('textarea');

      input.value = data;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);

      this.$Message.success('复制成功！');
    },
    handleChangeSearchType() {
      this.searchData = {
        startId: 0,
        pageSize: 20
      };
    },
    handleChangeSetMeta(data) {
      this.searchData.licenseSetMetaId = data;
    }
  }
};
</script>
<style lang="less" scoped>
    .choose-container{
        border:1px solid #dadada;
        height: 360px;
        position: relative;
        .price-footer{
            position: absolute;
            left: 0;
            bottom: 0;
            border-top: 1px solid #CCCCCC;
            height: 60px;
            line-height: 60px;
            width: 100%;
            padding: 0 20px;
        }
        .choose-item{
            padding: 8px 10px;
            cursor: pointer;
        }
        .choose-item-selected{
            background-color: #66C6F4;
            color: #ffffff;
        }
        .choose-count:hover{
            background-color: #ECECEC;
        }
        .choose-count-selected{
            background-color: #ECECEC;
        }
    }
    .license-set-detail{
        position: absolute;
        right: 0;
        top: 70px;
        width: 270px;
        height: 680px;
        background-color: #F4F4F4;
        border: 1px solid #dadada;
        border-left: none;
        .title{
            background-color: #ECECEC;
            height: 40px;
            line-height: 40px;
            font-family: PingFangSC-Semibold,serif;
            font-weight: 500;
            padding-left: 18px;
        }
        .license-set-detail-item{
            height: 40px;
            line-height: 40px;
            padding-left: 18px;
            border-bottom: 1px solid #DADADA;
            span{
                width: 80px;
                display: inline-block;
            }
        }
    }
    .license-center{
        .page-header-container{
            border-top: none;
        }
    }
</style>
