<template>
    <div style="position: relative">
        <Breadcrumb>
            <BreadcrumbItem>监控管理</BreadcrumbItem>
        </Breadcrumb>
        <div style="position: absolute;right: 0;top: 0">
            <pd-button type="default" style="margin-right: 6px" @click="handleSearch">
                刷新
            </pd-button>
        </div>
        <div class="container-wrapper monitor-wrapper">
            <div>
                <Row :gutter="20">
                    <Col :span="14">
                        <p style="margin-bottom: 20px;font-family: PingFangSC-Medium,serif"><span class="status-point"></span>任务监控</p>
                        <div class="monitor-overview">
                            <Row type="flex" justify="center">
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.activeDataJobCount}}</span><span class="worker-monitor-count-title">运行中的任务数</span>
                                    </div>
                                </Col>
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.fullTaskCount}}</span><span class="worker-monitor-count-title">结构迁移任务数</span>
                                    </div>
                                </Col>
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.fullTaskCount}}</span><span class="worker-monitor-count-title">全量迁移任务数</span>
                                    </div>
                                </Col>
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.incrementTaskCount}}</span><span class="worker-monitor-count-title">增量迁移任务数</span>
                                    </div>
                                </Col>
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.checkTaskCount}}</span><span class="worker-monitor-count-title">数据校验任务数</span>
                                    </div>
                                </Col>
                                <Col span="4">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{taskCount.deletedDataJobCount}}</span><span class="worker-monitor-count-title">已删除的任务数</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="margin-top: 20px;border:1px solid #dadada">
                            <Table style="border:none" size="small" :columns="jobColumn" :data="jobData" border stripe height="490">
                                <template slot="dataJobName" slot-scope="{row}">
                                    <span class="status-point" :style="`vertical-align: middle;background:${getStatusColor(row)}`"></span>
                                    <a v-if="row.dataTaskType==='FULL' || row.dataTaskType==='INCREMENT'" @click="handleGoGraph(row)">{{row.dataJobName}}</a>
                                    <span  v-if="row.dataTaskType!=='FULL' && row.dataTaskType!=='INCREMENT'">{{row.dataJobName}}</span>
                                </template>
                            </Table>
                            <div class="job-monitor-footer">
                                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>上一页</pd-button>
                                <span style="margin: 0 10px">第{{page}}页</span>
                                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px"
                                           @click="handleNext"><Icon type="ios-arrow-forward" style="font-size: 16px"/>下一页</pd-button>
                            </div>
                        </div>
                    </Col>
                    <Col :span="10">
                        <p style="margin-bottom: 20px;font-family: PingFangSC-Medium,serif"><span class="status-point"></span>机器监控</p>
                        <div class="monitor-overview">
                            <Row type="flex" justify="center">
                                <Col span="12">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{workerMonitorInfo.clusterCount}}</span><span class="worker-monitor-count-title">集群总数</span>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div class="worker-monitor-count">
                                        <span class="worker-monitor-count-number">{{workerMonitorInfo.workerCount}}</span><span class="worker-monitor-count-title">运行中的机器总数</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="margin-top: 20px;border:1px solid #dadada">
                            <Table style="border: none" size="small" :columns="workerColumn" :data="workerData" border stripe height="560"></Table>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>
<script>
import { getTaskCount, getDataJobMonitor, getWorkerCount } from '@services/api/monitor';
import { formatSeconds } from '@components/util';
import Mapping from '../util';

export default {
  components: {
  },
  mounted() {
    this.getTaskCount();
    this.getDataJobMonitor();
    this.handleWorkerCount();
  },
  data() {
    return {
      page: 1,
      noMoreData: false,
      prevFirst: [],
      startId: 0,
      pageSize: 10,
      firstId: 0,
      lastId: 0,
      workerMonitorInfo: {},
      searchInfo: {
        jobId: 'all',
        jobStatus: 'all',
        jobState: 'all',
        taskState: ''
      },
      taskCount: {
        taskCount: 0,
        fullTaskCount: 0,
        incrementTaskCount: 0,
        errorTaskCount: 0,
        errorFullTaskCount: 0,
        errorIncrementTaskCount: 0
      },
      jobList: [],
      jobColumn: [
        {
          title: '实例名称',
          key: 'dataJobName',
          minWidth: 200,
          slot: 'dataJobName'
        },
        {
          title: '任务描述',
          key: 'dataJobDesc',
          width: 160
        },
        {
          title: '任务类型',
          key: 'dataTaskType',
          width: 120,
          render: (h, param) => h('div', {}, Mapping.dataTaskType[param.row.dataTaskType])
        },
        {
          title: '任务延迟',
          key: 'taskDelay',
          width: 140,
          render: (h, params) => h('div', {}, params.row.taskDelay ? formatSeconds(params.row.taskDelay.count / 1000) : '-')
        },
        {
          title: 'cpu使用率',
          key: 'cpuUsage',
          width: 120,
          render: (h, params) => h('div', {}, params.row.cpuUsage ? Number(params.row.cpuUsage.count) > 0.001 ? Number(params.row.cpuUsage.count).toFixed(3) : 0.001 : '-')
        },
        {
          title: '堆积的消息数',
          key: 'storeRemainEvent',
          width: 130,
          render: (h, params) => {
            if (params.row.storeRemainEvent) {
              return h('div', {}, params.row.afterStoreProcessTimeMs.count);
            }
            return h('div', {}, '-');
          }
        }
      ],
      jobData: [
      ],
      workerColumn: [
        {
          title: '机器地址',
          key: 'ip',
          width: 140,
          render: (h, params) => h('a', {
            on: {
              click: () => {
                this.$router.push({ path: `/monitor/worker/graph?ip=${params.row.ip}&id=${params.row.workerId}` });
              }
            }
          }, params.row.ip)
        },
        {
          title: '集群',
          key: 'clusterName',
          minWidth: 180
        },
        {
          title: 'cpu使用率',
          key: 'cpuUsage',
          width: 130,
          sortable: true,
          render: (h, params) => h('div', {}, params.row.cpuUsage)
        },
        {
          title: '内存使用率',
          key: 'memUsage',
          width: 130,
          sortable: true,
          render: (h, params) => h('div', {}, params.row.memUsage)
        },
        {
          title: '磁盘使用率',
          key: 'diskUsage',
          width: 130,
          sortable: true,
          render: (h, params) => h('div', {}, params.row.diskUsage)
        }
      ],
      workerData: []
    };
  },
  methods: {
    handleDetail() {
      this.$router.push({ path: '/monitor/job/graph' });
    },
    getTaskCount() {
      getTaskCount({
        startId: this.startId,
        pageSize: this.pageSize,
        pageNumber: this.page
      }).then((res) => {
        if (res.data.code === '1') {
          this.taskCount = res.data.data;
        }
      });
    },
    handleWorkerCount() {
      getWorkerCount({
      }).then((res) => {
        if (res.data.code === '1') {
          this.workerMonitorInfo = res.data.data;
          this.workerData = [];
          if (this.workerMonitorInfo.workerMonitorInfoList) {
            this.workerMonitorInfo.workerMonitorInfoList.map((item) => {
              const data = {
                ip: item.ip,
                clusterName: item.clusterName,
                cpuUsage: item.cpuUsage,
                memUsage: item.memUsage,
                diskUsage: item.diskUsage,
                workerId: item.workerId
              };

              this.workerData.push(data);
              return null;
            });
          }
        }
      });
    },
    getDataJobMonitor(type) {
      getDataJobMonitor({
        startId: this.startId,
        pageSize: this.pageSize,
        pageNumber: this.page
      }).then((res) => {
        this.jobData = [];
        if (res.data.code === '1') {
          const dataJobCount = res.data.data.length;

          res.data.data.map((job) => {
            job.taskMonitorInfos.map((task, index) => {
              task.dataJobName = job.dataJobName;
              task.dataJobDesc = job.dataJobDesc;
              task.index = index;
              this.jobData.push(task);
              return null;
            });
            return null;
          });
          if (type === 'next') {
            if (!this.prevFirst[this.page - 1]) {
              this.prevFirst.push(this.firstId);
            }
          }
          this.firstId = this.jobData[0].dataJobId;
          this.lastId = this.jobData[this.jobData.length - 1].dataJobId;
          this.noMoreData = dataJobCount < this.pageSize;
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.startId = startId;
      this.getDataJobMonitor('prev');
    },
    handleNext() {
      this.startId = this.lastId;
      this.getDataJobMonitor('next');
      this.page++;
    },
    handleGoGraph(row) {
      if (row.dataTaskType === 'FULL') {
        this.$router.push({ path: `/monitor/job/full/graph/${row.dataJobId}/${row.id}` });
      } else if (row.dataTaskType === 'INCREMENT') {
        this.$router.push({ path: `/monitor/job/graph/${row.dataJobId}/${row.id}` });
      }
    },
    getStatusIcon(row) {
      const type = Mapping.taskSateToTaskType[row.dataTaskState];

      if (type) {
        if (!this.getDataTask(row.dataTasks)[type]) {
          return 'icon-chongqi';
        }
        if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
          return 'icon-zanting';
        } if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
          return 'icon-zhengchang';
        }
        return 'icon-yichang';
      } if (row.dataTaskState === 'COMPLETE') {
        return 'icon-wancheng';
      }
      return 'icon-zhengchang';
    },
    getStatusColor(row) {
      if (row.dataTaskStatus === 'COMPLETE' || row.dataTaskStatus === 'INIT') {
        return '#999999';
      }
      if (row.dataTaskStatus === 'STOP' || row.dataTaskStatus === 'WAIT_STOP') {
        return '#FFA30E';
      } if (row.dataTaskStatus === 'ABNORMAL') {
        return '#FF1815';
      }
      return '#52C41A';
    },
    handleSearch() {
      this.getTaskCount();
      this.getDataJobMonitor();
      this.handleWorkerCount();
    }
  }
};
</script>
<style lang="less">
    .worker-monitor-count{
        text-align: center;
        .worker-monitor-count-number{
            font-size: 32px;
            font-family: PingFangSC-Semibold,serif;
            font-weight: 500;
        }
        .worker-monitor-count-title{
            display: block;
        }
        .worker-monitor-count-error{
            color: #ed4014;
        }
    }
    .monitor-overview{
        background-color: #F5F5F5;
        padding: 20px 0;
    }
    .job-monitor-footer{
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
</style>
