<template>
  <div>
    <p><span class="job-header-name-desc" style="margin-right: 10px">HOST</span><span
      class="basic-info-value">{{ dataSourceDetail.host || dataSourceDetail.privateHost || dataSourceDetail.publicHost }}</span></p>
    <Row style="margin-top: 8px" :gutter="80">
      <Col :span="12">
        <p>
          <span>部署情况：</span>
          <span
            class="basic-info-value">{{
              MappingUtil.cloudOrIdcName[dataSourceDetail.deployType]
            }}</span>
        </p>
        <p>
          <span>所有者：</span>
          <span class="basic-info-value">{{ dataSourceDetail.owner }}</span>
        </p>
        <p>
          <span>实例ID：</span>
          <span class="basic-info-value">{{ dataSourceDetail.instanceId }}</span>
        </p>
        <p>
          <span>区域：</span>
          <span class="basic-info-value">{{ MappingUtil.region[dataSourceDetail.region] }}</span>
        </p>
        <p>
          <span>认证方式：</span>
          <span class="basic-info-value">{{ MappingUtil.securityType[dataSourceDetail.securityType] }}</span>
        </p>
      </Col>
      <Col :span="12">
        <p>
          <span>描述：</span>
          <span class="basic-info-value">{{ dataSourceDetail.instanceDesc }}</span>
        </p>
        <p>
          <span>版本号：</span>
          <span class="basic-info-value">{{ dataSourceDetail.version }}</span>
        </p>
        <p>
          <span>类型：</span>
          <span class="basic-info-value"><DataSourceIcon :type="dataSourceDetail.dataSourceType"
                                                         :instanceType="dataSourceDetail.deployType"></DataSourceIcon></span>
        </p>
        <p>
          <span>添加时间：</span>
          <span
            class="basic-info-value">{{
              dataSourceDetail.gmtCreate ? fecha.format(new Date(dataSourceDetail.gmtCreate), 'YYYY-MM-DD HH:mm:ss') : ''
            }}</span>
        </p>
      </Col>
    </Row>
    <div class="datasource-info-block"
         v-if="dataSourceDetail.extraVO&&dataSourceDetail.dataSourceType==='Hive'">
      <p style="margin-top: 8px">
        <span>HDFS Host：</span>
        <span
          class="basic-info-value">{{
            dataSourceDetail.extraVO.hdfsIp + ':' + dataSourceDetail.extraVO.hdfsPort
          }}</span>
      </p>
      <p>
        <span>数仓路径：</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hdfsDwDir }}</span>
      </p>
      <p>
        <span>HDFS Principal：</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hdfsPrincipal }}</span>
      </p>
      <p>
        <span>Hadoop User：</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hadoopUser }}</span>
      </p>
    </div>
    <div class="datasource-info-block">
      <!--            <Divider class="datasource-info-block-title" orientation="left">账号信息</Divider>-->
      <p style="margin-top: 8px">
        <span>账号：</span>
        <span
          class="basic-info-value">{{
            dataSourceDetail.accountName ? dataSourceDetail.accountName : '暂无'
          }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '../../../views/util';
import DataSourceIcon from '../DataSourceIcon';

export default {
  components: { DataSourceIcon },
  props: {
    dataSourceDetail: Object
  },
  data() {
    return {
      fecha,
      MappingUtil,
      desc: ''
    };
  }
};
</script>
