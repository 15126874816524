import DataSourceGroup from '../../../views/dataSourceGroup';

export default {
  data() {
    return {
      selectedColumns: {}
    };
  },
  methods: {
    getDataJobData() {
      if (this.taskInfo.virtualColumns.length > 0) {
        this.taskInfo.coreConfigWrapper = {
          processorConfigList: [],
          processorConsoleConfigs: []
        };
        this.taskInfo.coreConfigWrapper.processorConfigList.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(this.taskInfo.virtualColumns)
        });
        this.taskInfo.coreConfigWrapper.processorConsoleConfigs.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(this.taskInfo.customVirtualColumns)
        });
      }
      const taskInfo = this.taskInfo;
      const configData = this.getConfigData(taskInfo.dbMap, taskInfo.sourceType, taskInfo.sinkType);

      return {
        jobName: taskInfo.taskName,
        jobType: taskInfo.type,
        initialSync: taskInfo.type === 'SYNC' && taskInfo.mode.init,
        shortTermSync: taskInfo.type === 'MIGRATION' && taskInfo.mode.synchronize,
        shortTermNum: taskInfo.type === 'MIGRATION' && taskInfo.mode.synchronize ? taskInfo.mode.shortTermNum : 0,
        sourceHost: taskInfo.sourceInstance,
        sinkHost: taskInfo.sinkInstance,
        structMigration: configData.structMigration,
        dataJobDesc: taskInfo.desc,
        checkOnce: taskInfo.checkMode === 'checkOnce',
        checkPeriod: taskInfo.checkMode === 'checkPeriod',
        checkPeriodCronExpr: this.parseCron(taskInfo.checkPeriodDate),
        fullPeriod: taskInfo.fullPeriod,
        fullPeriodCronExpr: this.parseCron(taskInfo.fullPeriodDate),
        sourceCaseSensitive: JSON.parse(taskInfo.sourceCaseSensitive),
        targetCaseSensitive: JSON.parse(taskInfo.targetCaseSensitive),
        enableAutoIncrement: JSON.parse(taskInfo.enableAutoIncrement),
        commonRule: taskInfo.commonRule,
        // useQualifiers:JSON.parse(taskInfo.useQualifiers),
        autoStart: JSON.parse(taskInfo.autoStart),
        clusterId: taskInfo.clusterId,
        specId: taskInfo.spec ? taskInfo.spec.id : 0,
        sourceDataSourceId: taskInfo.sourceDataSourceId,
        sourceHostType: taskInfo.sourceHostType,
        targetDataSourceId: taskInfo.targetDataSourceId,
        targetHostType: taskInfo.targetHostType,
        // indexMetaMap: indexMetaMap,
        globalTimeZone: this.taskInfo.globalTimeZone,
        srcRocketMqGroupId: this.taskInfo.consumerGroupId,
        srcRabbitMqVhost: this.taskInfo.srcRabbitMqVhost,
        srcRabbitExchange: this.taskInfo.srcRabbitExchange,
        dstRabbitMqVhost: this.taskInfo.dstRabbitMqVhost,
        dstRabbitExchange: this.taskInfo.dstRabbitExchange,
        kafkaConsumerGroupId: this.taskInfo.consumerGroupId,
        srcDsCharset: this.taskInfo.sourceCharset,
        tarDsCharset: this.taskInfo.targetCharset,
        srcSchemaLessFormat: this.taskInfo.srcSchemaLessFormat,
        dstSchemaLessFormat: this.taskInfo.dstSchemaLessFormat,
        schemaWhiteListLevel: this.taskInfo.schemaWhiteListLevel || null,
        dstMqDefaultTopic: this.taskInfo.defaltValue,
        dstMqDefaultTopicPartitions: this.taskInfo.defaltPartitions,
        dstCkTableEngine: this.taskInfo.dstCkTableEngine,
        filterDDL: JSON.parse(this.taskInfo.ddl),
        kuduNumReplicas: this.taskInfo.kuduNumReplicas,
        pkgDescription: this.taskInfo.pkgDescription,
        sourceSchema: configData.finalSourceSchema,
        targetSchema: configData.finalTargetSchema,
        mappingDef: JSON.stringify(configData.mappingDef),
        processorConfigList: this.taskInfo.coreConfigWrapper ? this.taskInfo.coreConfigWrapper.processorConfigList : []
      };
    },
    getConfigData(list, sourceType, sinkType, type, data, selectedColumns) {
      const sourceSchema = [];
      const targetSchema = [];
      const mappingDef = [];
      const tableKeyPrefixMapping = {};
      const tableTopicMapping = {};
      const tableIndexMapping = {};
      const columnMapping = {};
      const topicTableMapping = {};
      const indexMetaMap = {};
      const schemaMapping = {};
      const tableMapping = {};
      const topics = [];
      const esIndex = [];
      const dbMapping = {};
      const anyDbMapping = {};
      const redisKeys = [];
      const noTargetDbTableMapping = {};

      let structMigration = false;
      let tableList = [];
      let finalSourceSchema = [];
      let finalTargetSchema = [];

      dbMapping.method = 'DB_DB';
      dbMapping.serializeMapping = {};
      dbMapping.serializeAutoGenRules = {};
      dbMapping.commonGenRule = 'MIRROR';

      anyDbMapping.method = 'ANY_DB';
      anyDbMapping.serializeMapping = {};
      anyDbMapping.serializeAutoGenRules = {};
      anyDbMapping.commonGenRule = 'MIRROR';

      if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
        if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
          schemaMapping.method = 'SCHEMA_SCHEMA';
        } else {
          schemaMapping.method = 'SCHEMA_DB';
        }
      } else if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
        schemaMapping.method = 'DB_SCHEMA';
      }
      schemaMapping.serializeMapping = {};
      schemaMapping.serializeAutoGenRules = {};
      schemaMapping.commonGenRule = 'MIRROR';
      tableTopicMapping.serializeMapping = {};
      tableTopicMapping.method = 'TABLE_TOPIC';
      tableTopicMapping.serializeAutoGenRules = {};
      tableTopicMapping.commonGenRule = 'MIRROR';
      if (this.taskInfo && this.taskInfo.schemaWhiteListLevel) {
        tableTopicMapping.commonGenRule = 'DEFAULT_TOPIC';
      }

      tableKeyPrefixMapping.serializeMapping = {};
      tableKeyPrefixMapping.method = 'TABLE_KEYPREFIX';
      tableKeyPrefixMapping.serializeAutoGenRules = {};
      tableKeyPrefixMapping.commonGenRule = 'MIRROR';
      topicTableMapping.serializeMapping = {};
      topicTableMapping.method = 'TOPIC_TABLE';
      topicTableMapping.serializeAutoGenRules = {};
      topicTableMapping.commonGenRule = 'MIRROR';

      tableIndexMapping.serializeMapping = {};
      tableIndexMapping.method = 'TABLE_INDEX';
      tableIndexMapping.serializeAutoGenRules = {};
      tableIndexMapping.commonGenRule = 'MIRROR';

      tableMapping.serializeMapping = {};
      tableMapping.method = 'TABLE_TABLE';
      tableMapping.serializeAutoGenRules = {};
      tableMapping.commonGenRule = 'MIRROR';
      if (this.taskInfo && this.taskInfo.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1) {
        tableMapping.commonGenRule = 'TO_UPPER_CASE';
      }

      noTargetDbTableMapping.serializeMapping = {};
      noTargetDbTableMapping.method = 'TABLE_TABLE';
      noTargetDbTableMapping.serializeAutoGenRules = {};
      noTargetDbTableMapping.commonGenRule = 'MIRROR';
      columnMapping.method = 'COLUMN_COLUMN';
      columnMapping.serializeMapping = {};
      columnMapping.serializeAutoGenRules = {};
      columnMapping.commonGenRule = 'MIRROR';
      if (this.taskInfo && this.taskInfo.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1) {
        columnMapping.commonGenRule = 'TO_UPPER_CASE';
      }
      list.forEach((db) => {
        const sourceDbSchema = {};
        const
          targetDbSchema = {};

        sourceDbSchema.db = db.sourceDb;
        if (DataSourceGroup.oracle.indexOf(sourceType) === -1) {
          if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
            sourceDbSchema.schemas = [];
            sourceDbSchema.schemas.push({
              schema: db.sourceSchema,
              schemaPattern: '',
              tables: [],
              targetAutoCreate: DataSourceGroup.hasSchema.indexOf(sinkType) > -1 ? db.schemaAutoCreate : db.needAutoCreated
            });
          } else {
            if (DataSourceGroup.mongo.includes(sourceType)) {
              sourceDbSchema.dbPattern = '';
              sourceDbSchema.collections = [];
            } else {
              sourceDbSchema.dbPattern = '';
              sourceDbSchema.tables = [];
            }
          }
        } else {
          sourceDbSchema.tableSpaces = [];
          sourceDbSchema.tableSpaces.push({
            tableSpace: db.sourceSchema,
            tableSpacePattern: '',
            tables: [],
            targetAutoCreate: DataSourceGroup.hasSchema.indexOf(sinkType) > -1 ? db.schemaAutoCreate : db.needAutoCreated
          });
        }
        if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
          targetDbSchema.topics = [];
        } else {
          targetDbSchema.db = db.sinkDb;
          targetDbSchema.dbPattern = '';
          if (DataSourceGroup.pg.indexOf(sinkType) > -1) {
            targetDbSchema.schemas = [];
            targetDbSchema.schemas.push({
              schema: db.targetSchema,
              schemaPattern: '',
              tables: []
            });
          } else {
            targetDbSchema.tables = [];
          }
        }
        sourceDbSchema.targetAutoCreate = false;
        targetDbSchema.targetAutoCreate = false;
        targetDbSchema.inBlackList = false;
        sourceDbSchema.inBlackList = false;
        const dbName = db.sourceDb;

        if (!dbName) {
          if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
            const key = {
              value: 'ANY_DB'
            };
            const
              value = {
                value: db.sinkDb
              };

            anyDbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
          } else if (data[db].sourceDb !== data[db].targetDb) {
            const key = {
              value: data[db].sourceDb
            };
            const
              value = {
                value: data[db].targetDb
              };

            dbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
          }
        } else if (dbName !== db.sinkDb) {
          const key = {
            value: dbName
          };
          const
            value = {
              value: db.sinkDb
            };

          dbMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
        }
        if (db.needAutoCreated) {
          sourceDbSchema.targetAutoCreate = true;
          structMigration = true;
        }
        console.log('DataSourceGroup.hasSchema.indexOf(this.sourceType)', sourceType, DataSourceGroup.hasSchema.indexOf(sourceType));
        if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
          if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
            console.log('test');
            if (dbName !== db.sinkDb || db.sourceSchema !== db.targetSchema) {
              const key = {
                parent: {
                  value: dbName
                },
                value: db.sourceSchema
              };
              const
                value = {
                  parent: {
                    value: db.sinkDb
                  },
                  value: db.targetSchema
                };
              schemaMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
            }
          } else {
            const key = {
              parent: {
                value: dbName
              },
              value: db.sourceSchema
            };
            const
              value = {
                value: db.sinkDb
              };

            schemaMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
          }
        } else if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
          console.log(23333);
          if (!dbName) {
            const key = {
              value: data[db].sourceDb
            };
            const
              value = {
                parent: {
                  value: data[db].targetDb
                },
                value: data[db].targetSchema
              };

            schemaMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
          } else {
            const key = {
              value: dbName
            };
            const
              value = {
                parent: {
                  value: db.sinkDb
                },
                value: db.targetSchema
              };

            schemaMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
          }
        }
        if (db.selectedTables) {
          tableList = db.selectedTables;
        } else if (data) {
          data[dbName].tableList.forEach((item) => {
            item.selected = true;
          });
          tableList = data[dbName].tableList;
        }

        tableList.forEach((table) => {
          const sourceSchemaTable = {};
          const
            targetSchemaTable = {};

          sourceSchemaTable.table = table.sourceTable;
          sourceSchemaTable.tablePattern = '';
          sourceSchemaTable.columns = [];
          sourceSchemaTable.actions = table.action;
          sourceSchemaTable.inBlackList = true;
          sourceSchemaTable.targetAutoCreate = false;
          sourceSchemaTable.specifiedPks = table.cols || [];
          targetSchemaTable.tablePattern = '';
          targetSchemaTable.columns = [];
          if (!this.taskInfo || !this.taskInfo.schemaWhiteListLevel) {
            if (table.selected) {
              targetSchemaTable.table = table.sinkTable ? table.sinkTable : table.targetTable;
              if (DataSourceGroup.drds.indexOf(sinkType) > -1) {
                if (table.dbFuncs && table.dbpartition.length > 0) {
                  targetSchemaTable.dbPartitionFunc = table.dbFuncs;
                  targetSchemaTable.dbPartitionCols = table.dbpartition;
                }
                if (table.tbFuncs && table.tbpartition.length > 0 && table.tbpartitions) {
                  targetSchemaTable.tbPartitionFunc = table.tbFuncs;
                  targetSchemaTable.tbPartitionCols = table.tbpartition;
                  targetSchemaTable.tbPartitions = table.tbpartitions;
                }
              }
              if (DataSourceGroup.hive.indexOf(sinkType) > -1 && table.partitionData) {
                const partitionKeyList = [];
                partitionKeyList.push({
                  keyName: table.partitionData.partition[0].key,
                  partitionFunction: table.partitionData.partition[0].func
                });
                targetSchemaTable.partitionKeys = partitionKeyList;
                targetSchemaTable.fileFormat = table.partitionData.fileFormat;
                targetSchemaTable.compressType = table.partitionData.compressType;
              }
              targetSchemaTable.inBlackList = false;
              sourceSchemaTable.inBlackList = false;
              if (table.sourceTable !== (table.sinkTable ? table.sinkTable : table.targetTable)) {
                if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
                  if (DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                    if (table.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                      if (!((table.sinkTable ? table.sinkTable : table.targetTable) === table.sourceTable)) {
                        const key = DataSourceGroup.hasSchema.indexOf(sourceType) > -1
                          ? {
                            value: table.sourceTable,
                            parent: {
                              value: table.sourceSchema,
                              parent: {
                                value: table.sourceDb || table.db
                              }
                            }
                          } : {
                            parent: {
                              value: table.sourceDb || table.db
                            },
                            value: table.sourceTable
                          }; const
                          value = {
                            value: table.sinkTable ? table.sinkTable : table.targetTable,
                            parent: {
                              value: table.targetSchema,
                              parent: {
                                value: table.sinkDb ? table.sinkDb : table.targetDb
                              }
                            }
                          };

                        tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                      }
                    }
                  } else {
                    if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                      const key = {
                        parent: {
                          value: table.sourceSchema,
                          parent: {
                            value: table.sourceDb || table.db
                          }
                        },
                        value: table.sourceTable
                      };
                      const
                        value = {
                          value: table.sinkTable ? table.sinkTable : table.targetTable,
                          parent: {
                            value: table.targetSchema,
                            parent: {
                              value: table.sinkDb ? table.sinkDb : table.targetDb
                            }
                          }
                        };

                      tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    } else {
                      const key = {
                        parent: {
                          value: table.sourceDb || table.db
                        },
                        value: table.sourceTable
                      };
                      const
                        value = {
                          value: table.sinkTable ? table.sinkTable : table.targetTable,
                          parent: {
                            value: table.targetSchema,
                            parent: {
                              value: table.sinkDb ? table.sinkDb : table.targetDb
                            }
                          }
                        };

                      tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    }
                  }
                } else {
                  if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                    if (DataSourceGroup.oracle.includes(sourceType) && DataSourceGroup.kudu.includes(sinkType)) {
                      const key = {
                        parent: {
                          parent: {
                            value: table.sourceDb || table.db
                          },
                          value: table.sourceSchema
                        },
                        value: table.sourceTable
                      };
                      const value = {
                        value: table.sinkTable ? table.sinkTable : table.targetTable
                      };

                      noTargetDbTableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    } else {
                      const key = {
                        parent: {
                          value: table.sourceSchema,
                          parent: {
                            value: table.sourceDb || table.db
                          }
                        },
                        value: table.sourceTable
                      };
                      const
                        value = {
                          value: table.sinkTable ? table.sinkTable : table.targetTable,
                          parent: {
                            value: table.sinkDb ? table.sinkDb : table.targetDb
                          }
                        };

                      tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    }
                  } else {
                    const key = {
                      parent: {
                        value: table.sourceDb || table.db
                      },
                      value: table.sourceTable
                    };
                    const
                      value = {
                        value: table.sinkTable ? table.sinkTable : table.targetTable,
                        parent: {
                          value: table.sinkDb ? table.sinkDb : table.targetDb
                        }
                      };

                    tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                  }
                }
              }
              if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const
                  value = {
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                tableTopicMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.redis.includes(sinkType)) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const value = {
                  value: table.sinkTable ? table.sinkTable : table.targetTable
                };

                tableKeyPrefixMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const
                  value = {
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                tableIndexMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
                const key = {
                  value: table.sourceTable
                };
                const
                  value = {
                    parent: {
                      value: table.sinkDb ? table.sinkDb : table.targetDb
                    },
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                topicTableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable] = {};
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].indexName = table.sinkTable ? table.sinkTable : table.targetTable;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].numberOfShards = table.shards;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].numberOfReplicas = table.replication;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].fieldMetaList = [];
              }
              if (table.needAutoCreated && table.selected) {
                sourceSchemaTable.targetAutoCreate = true;
                structMigration = true;
              }
              if (table.whereCondition) {
                sourceSchemaTable.dataFilter = {
                  type: 'SQL_WHERE',
                  expression: table.whereCondition
                };
              }
              let selectedColumnList = [];

              if (db.selectedColumns) {
                if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
                  selectedColumnList = db.selectedColumns[table.sinkTable ? table.sinkTable : table.targetTable];
                } else {
                  selectedColumnList = db.selectedColumns[table.sourceTable];
                }
                if (this.selectedColumns[db] && this.selectedColumns[db][table.sourceTable]) {
                  selectedColumnList = this.selectedColumns[db][table.sourceTable];
                }
              } else if (selectedColumns) {
                selectedColumnList = selectedColumns[dbName][table.sourceTable];
              }
              if (selectedColumnList) {
                selectedColumnList.forEach((column) => {
                  const sourceColumns = {};
                  const
                    targetColumns = {};

                  sourceColumns.column = column.sourceColumn;
                  sourceColumns.targetAutoCreate = false;
                  sourceColumns.inBlackList = true;
                  targetColumns.column = column.sinkColumn;
                  targetColumns.targetAutoCreate = false;
                  targetColumns.inBlackList = true;

                  if (!column.selected) {
                    targetColumns.inBlackList = true;
                    sourceColumns.inBlackList = true;
                  } else {
                    targetColumns.inBlackList = false;
                    sourceColumns.inBlackList = false;
                    if (column.sourceColumn !== column.sinkColumn) {
                      if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
                        if (DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                          if (!(column.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1)) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.sinkDb
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        } else {
                          if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: db.sourceSchema,
                                  parent: {
                                    value: dbName
                                  }
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          } else {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        }
                      } else {
                        if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: db.sourceSchema,
                                parent: {
                                  value: dbName
                                }

                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else if (DataSourceGroup.es.includes(sinkType)) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        }
                      }
                    }
                  }
                  if (column.needAutoCreated && column.selected) {
                    if (!(!table.needAutoCreated && DataSourceGroup.mq.indexOf(sinkType) > -1)) {
                      sourceColumns.targetAutoCreate = true;
                      structMigration = true;
                    }
                  }
                  sourceSchemaTable.columns.push(sourceColumns);
                  targetSchemaTable.columns.push(targetColumns);
                  if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                    indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].fieldMetaList.push({
                      fieldName: column.sinkColumn,
                      needIndex: column.isIndex,
                      fieldTypeName: column.sinkType,
                      esAnalyzerType: column.analyzer,
                      needAutoCreated: column.needAutoCreated,
                      timeFormat: column.timeFormat,
                      jsonValue: column.jsonValue,
                      isPk: column.isPk,
                      isId: column.isId
                    });
                  }
                });
              }
              if (DataSourceGroup.pg.indexOf(sourceType) > -1) {
                sourceDbSchema.schemas[0].tables.push(sourceSchemaTable);
              } else if (DataSourceGroup.oracle.indexOf(sourceType) > -1) {
                sourceDbSchema.tableSpaces[0].tables.push(sourceSchemaTable);
              } else if (DataSourceGroup.mongo.indexOf(sourceType) > -1) {
                sourceDbSchema.collections.push({
                  collection: sourceSchemaTable.table,
                  actions: sourceSchemaTable.actions,
                  targetAutoCreate: sourceSchemaTable.targetAutoCreate
                });
              } else {
                sourceDbSchema.tables.push(sourceSchemaTable);
              }
              if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
                if (DataSourceGroup.rabbitMq.indexOf(sourceType) > -1) {
                  topics.push({
                    queue: table.sourceTable,
                    queuePattern: ''
                  });
                } else {
                  topics.push({
                    topic: table.sourceTable,
                    topicPattern: '',
                    partitions: table.partitions,
                    partitionKeys: table.partitionKeys
                  });
                }
              }
              if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
                if (DataSourceGroup.rabbitMq.indexOf(sinkType) > -1) {
                  topics.push({
                    queue: table.sinkTable ? table.sinkTable : table.targetTable,
                    queuePattern: ''
                  });
                } else {
                  topics.push({
                    topic: table.sinkTable ? table.sinkTable : table.targetTable,
                    topicPattern: '',
                    partitions: table.partitions,
                    partitionKeys: table.partitionKeys
                  });
                }
              } else if (DataSourceGroup.redis.includes(sinkType)) {
                redisKeys.push({
                  prefix: table.sinkTable,
                  suffixFields: table.suffixFields || []
                });
              } else if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                // console.log('table', table, indexMetaMap);
                let fileds = [];
                if (indexMetaMap[table.sinkTable || table.targetTable]) {
                  fileds = indexMetaMap[table.sinkTable || table.targetTable].fieldMetaList;
                }
                const idFields = [];
                const
                  pkFields = [];

                fileds.forEach((field) => {
                  if (field.isPk) {
                    pkFields.push(field.fieldName);
                  }
                  if (field.isId) {
                    idFields.push(field.fieldName);
                  }
                });
                console.log(idFields);

                esIndex.push({
                  indexName: table.sinkTable ? table.sinkTable : table.targetTable,
                  fields: fileds,
                  numberOfShards: table.shards,
                  numberOfReplicas: table.replication,
                  globalTimeZone: this.taskInfo.globalTimeZone,
                  idFieldNames: idFields.length ? idFields : pkFields
                });
              } else if (DataSourceGroup.pg.indexOf(sinkType) > -1) {
                targetDbSchema.schemas[0].tables.push(targetSchemaTable);
              } else {
                targetDbSchema.tables.push(targetSchemaTable);
              }
            }
          } else {
            if (table.selected) {
              targetSchemaTable.inBlackList = false;
              sourceSchemaTable.inBlackList = false;
              targetSchemaTable.table = table.sinkTable ? table.sinkTable : table.targetTable;
              if (this.taskInfo && DataSourceGroup.drds.indexOf(this.taskInfo.sinkType) > -1) {
                if (table.dbFuncs && table.dbpartition.length > 0) {
                  targetSchemaTable.dbPartitionFunc = table.dbFuncs;
                  targetSchemaTable.dbPartitionCols = table.dbpartition;
                }
                if (table.tbFuncs && table.tbpartition.length > 0 && table.tbpartitions) {
                  targetSchemaTable.tbPartitionFunc = table.tbFuncs;
                  targetSchemaTable.tbPartitionCols = table.tbpartition;
                  targetSchemaTable.tbPartitions = table.tbpartitions;
                }
              }
              if (DataSourceGroup.hive.indexOf(sinkType) > -1 && table.partitionData) {
                const partitionKeyList = [];
                partitionKeyList.push({
                  keyName: table.partitionData.partition[0].key,
                  partitionFunction: table.partitionData.partition[0].func
                });
                targetSchemaTable.partitionKeys = partitionKeyList;
                targetSchemaTable.fileFormat = table.partitionData.fileFormat;
                targetSchemaTable.compressType = table.partitionData.compressType;
              }
              if (table.sourceTable !== (table.sinkTable ? table.sinkTable : table.targetTable)) {
                if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
                  if (DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                    if (table.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                      if (!((table.sinkTable ? table.sinkTable : table.targetTable) === table.sourceTable)) {
                        const key = {
                          parent: {
                            value: table.sourceDb || table.db
                          },
                          value: table.sourceTable
                        };
                        const
                          value = {
                            value: table.sinkTable ? table.sinkTable : table.targetTable,
                            parent: {
                              value: table.targetSchema,
                              parent: {
                                value: table.sinkDb ? table.sinkDb : table.targetDb
                              }
                            }
                          };

                        tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                      }
                    }
                  } else {
                    if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                      const key = {
                        parent: {
                          value: table.sourceSchema,
                          parent: {
                            value: table.sourceDb || table.db
                          }
                        },
                        value: table.sourceTable
                      };
                      const
                        value = {
                          value: table.sinkTable ? table.sinkTable : table.targetTable,
                          parent: {
                            value: table.targetSchema,
                            parent: {
                              value: table.sinkDb ? table.sinkDb : table.targetDb
                            }
                          }
                        };

                      tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    } else {
                      const key = {
                        parent: {
                          value: table.sourceDb || table.db
                        },
                        value: table.sourceTable
                      };
                      const
                        value = {
                          value: table.sinkTable ? table.sinkTable : table.targetTable,
                          parent: {
                            value: table.targetSchema,
                            parent: {
                              value: table.sinkDb ? table.sinkDb : table.targetDb
                            }
                          }
                        };

                      tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                    }
                  }
                } else {
                  if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                    const key = {
                      parent: {
                        value: table.sourceSchema,
                        parent: {
                          value: table.sourceDb || table.db
                        }
                      },
                      value: table.sourceTable
                    };
                    const
                      value = {
                        value: table.sinkTable ? table.sinkTable : table.targetTable,
                        parent: {
                          value: table.sinkDb ? table.sinkDb : table.targetDb
                        }
                      };

                    tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                  } else {
                    const key = {
                      parent: {
                        value: table.sourceDb || table.db
                      },
                      value: table.sourceTable
                    };
                    const
                      value = {
                        value: table.sinkTable ? table.sinkTable : table.targetTable,
                        parent: {
                          value: table.sinkDb ? table.sinkDb : table.targetDb
                        }
                      };

                    tableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                  }
                }
              }
              if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const
                  value = {
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                tableTopicMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.redis.indexOf(sinkType) > -1) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const value = {
                  value: table.sinkTable ? table.sinkTable : table.targetTable
                };

                tableKeyPrefixMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                const key = {
                  parent: {
                    value: table.sourceDb || table.db
                  },
                  value: table.sourceTable
                };
                const
                  value = {
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                tableIndexMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
                const key = {
                  value: table.sourceTable
                };
                const
                  value = {
                    parent: {
                      value: table.sinkDb ? table.sinkDb : table.targetDb
                    },
                    value: table.sinkTable ? table.sinkTable : table.targetTable
                  };

                topicTableMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
              }
              if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable] = {};
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].indexName = table.sinkTable ? table.sinkTable : table.targetTable;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].numberOfShards = table.shards;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].numberOfReplicas = table.replication;
                indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].fieldMetaList = [];
              }
            } else {
              targetSchemaTable.inBlackList = true;
              sourceSchemaTable.inBlackList = true;
            }
            if (table.needAutoCreated && table.selected) {
              sourceSchemaTable.targetAutoCreate = true;
              structMigration = true;
            }
            if (table.whereCondition) {
              sourceSchemaTable.dataFilter = {
                type: 'SQL_WHERE',
                expression: table.whereCondition
              };
            }
            let selectedColumnList = [];

            if (db.selectedColumns) {
              if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
                selectedColumnList = db.selectedColumns[table.sinkTable ? table.sinkTable : table.targetTable];
              } else {
                selectedColumnList = db.selectedColumns[table.sourceTable];
              }
              if (this.selectedColumns[db] && this.selectedColumns[db][table.sourceTable]) {
                selectedColumnList = this.selectedColumns[db][table.sourceTable];
              }
            } else if (selectedColumns) {
              selectedColumnList = selectedColumns[dbName][table.sourceTable];
            }
            if (selectedColumnList) {
              selectedColumnList.forEach((column) => {
                const sourceColumns = {};
                const
                  targetColumns = {};

                sourceColumns.column = column.sourceColumn;
                sourceColumns.targetAutoCreate = false;
                sourceColumns.inBlackList = true;
                targetColumns.column = column.sinkColumn;
                targetColumns.targetAutoCreate = false;
                targetColumns.inBlackList = true;
                if (!this.taskInfo || !this.taskInfo.schemaWhiteListLevel) {
                  if (column.selected) {
                    targetColumns.inBlackList = false;
                    sourceColumns.inBlackList = false;
                    if (column.sourceColumn !== column.sinkColumn) {
                      if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
                        if (DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                          if (!(column.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1)) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.sinkDb
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        } else {
                          if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: db.sourceSchema,
                                  parent: {
                                    value: dbName
                                  }
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          } else {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        }
                      } else {
                        if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: db.sourceSchema,
                                parent: {
                                  value: dbName
                                }

                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else if (DataSourceGroup.es.includes(sinkType)) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        }
                      }
                    }
                    if (column.needAutoCreated && column.selected) {
                      if (!(!table.needAutoCreated && DataSourceGroup.mq.indexOf(sinkType) > -1)) {
                        sourceColumns.targetAutoCreate = true;
                        structMigration = true;
                      }
                    }
                    sourceSchemaTable.columns.push(sourceColumns);
                    targetSchemaTable.columns.push(targetColumns);
                    if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                      indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].fieldMetaList.push({
                        fieldName: column.sinkColumn,
                        needIndex: column.isIndex,
                        fieldTypeName: column.sinkType,
                        esAnalyzerType: column.analyzer,
                        needAutoCreated: column.needAutoCreated,
                        timeFormat: column.timeFormat,
                        jsonValue: column.jsonValue,
                        isPk: column.isPk,
                        isId: column.isId
                      });
                    }
                  }
                } else {
                  if (column.selected) {
                    targetColumns.inBlackList = false;
                    sourceColumns.inBlackList = false;
                    if (column.sourceColumn !== column.sinkColumn) {
                      if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
                        if (DataSourceGroup.oracle.indexOf(sinkType) > -1) {
                          if (!(column.commonRule === 'UpperCase' || !this.taskInfo && DataSourceGroup.oracle.indexOf(sinkType) > -1)) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.sinkDb
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        } else {
                          if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: db.sourceSchema,
                                  parent: {
                                    value: dbName
                                  }
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          } else {
                            const key = {
                              parent: {
                                value: table.sourceTable,
                                parent: {
                                  value: dbName
                                }
                              },
                              value: column.sourceColumn
                            };
                            const
                              value = {
                                value: column.sinkColumn,
                                parent: {
                                  value: table.sinkTable ? table.sinkTable : table.targetTable,
                                  parent: {
                                    value: db.targetSchema,
                                    parent: {
                                      value: db.sinkDb
                                    }
                                  }
                                }
                              };

                            columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                          }
                        }
                      } else {
                        if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: db.sourceSchema,
                                parent: {
                                  value: dbName
                                }

                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else if (DataSourceGroup.es.includes(sinkType)) {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        } else {
                          const key = {
                            parent: {
                              value: table.sourceTable,
                              parent: {
                                value: dbName
                              }
                            },
                            value: column.sourceColumn
                          };
                          const
                            value = {
                              value: column.sinkColumn,
                              parent: {
                                value: table.sinkTable ? table.sinkTable : table.targetTable,
                                parent: {
                                  value: db.sinkDb
                                }
                              }
                            };

                          columnMapping.serializeMapping[JSON.stringify(key)] = JSON.stringify(value);
                        }
                      }
                    }
                  } else {
                    targetColumns.inBlackList = true;
                    sourceColumns.inBlackList = true;
                  }
                  if (column.needAutoCreated && column.selected) {
                    if (!(!table.needAutoCreated && DataSourceGroup.mq.indexOf(sinkType) > -1)) {
                      sourceColumns.targetAutoCreate = true;
                      structMigration = true;
                    }
                  }
                  sourceSchemaTable.columns.push(sourceColumns);
                  targetSchemaTable.columns.push(targetColumns);
                  if (DataSourceGroup.es.indexOf(sinkType) > -1) {
                    indexMetaMap[table.sinkTable ? table.sinkTable : table.targetTable].fieldMetaList.push({
                      fieldName: column.sinkColumn,
                      needIndex: column.isIndex,
                      fieldTypeName: column.sinkType,
                      esAnalyzerType: column.analyzer,
                      needAutoCreated: column.needAutoCreated,
                      timeFormat: column.timeFormat,
                      jsonValue: column.jsonValue,
                      isPk: column.isPk,
                      isId: column.isId
                    });
                  }
                }
              });
            }
            if (DataSourceGroup.pg.indexOf(sourceType) > -1) {
              sourceDbSchema.schemas[0].tables.push(sourceSchemaTable);
            } else if (DataSourceGroup.oracle.indexOf(sourceType) > -1) {
              sourceDbSchema.tableSpaces[0].tables.push(sourceSchemaTable);
            } else {
              sourceDbSchema.tables.push(sourceSchemaTable);
            }
            if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
              if (DataSourceGroup.rabbitMq.indexOf(sourceType) > -1) {
                topics.push({
                  queue: table.sourceTable,
                  queuePattern: ''
                });
              } else {
                topics.push({
                  topic: table.sourceTable,
                  topicPattern: '',
                  partitions: table.partitions,
                  partitionKeys: table.partitionKeys
                });
              }
            }
            if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
              if (DataSourceGroup.rabbitMq.indexOf(sinkType) > -1) {
                topics.push({
                  queue: table.sinkTable ? table.sinkTable : table.targetTable,
                  queuePattern: ''
                });
              } else {
                topics.push({
                  topic: table.sinkTable ? table.sinkTable : table.targetTable,
                  topicPattern: '',
                  partitions: table.partitions,
                  partitionKeys: table.partitionKeys
                });
              }
            } else if (DataSourceGroup.es.indexOf(sinkType) > -1) {
              console.log('table', table, indexMetaMap);
              const fileds = indexMetaMap[table.sinkTable || table.targetTable].fieldMetaList;
              const idFields = [];
              const
                pkFields = [];

              fileds.forEach((field) => {
                if (field.isPk) {
                  pkFields.push(field.fieldName);
                }
                if (field.isId) {
                  idFields.push(field.fieldName);
                }
              });
              console.log(idFields);
              esIndex.push({
                indexName: table.sinkTable ? table.sinkTable : table.targetTable,
                fields: fileds,
                numberOfShards: table.shards,
                numberOfReplicas: table.replication,
                globalTimeZone: this.taskInfo.globalTimeZone,
                idFieldNames: idFields.length ? idFields : pkFields
              });
            } else if (DataSourceGroup.pg.indexOf(sinkType) > -1) {
              targetDbSchema.schemas[0].tables.push(targetSchemaTable);
            } else if (DataSourceGroup.redis.indexOf(sinkType) > -1) {
              redisKeys.push({
                prefix: table.sinkTable,
                suffixFields: table.suffixFields || []
              });
            } else {
              targetDbSchema.tables.push(targetSchemaTable);
            }
          }
        });
        sourceSchema.push(sourceDbSchema);
        targetSchema.push(targetDbSchema);
      });
      if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
        mappingDef.push(anyDbMapping);
        mappingDef.push(topicTableMapping);
      } else if (DataSourceGroup.hasSchema.indexOf(sourceType) > -1) {
        if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
          mappingDef.push(dbMapping);
          mappingDef.push(schemaMapping);
          mappingDef.push(tableMapping);
          mappingDef.push(columnMapping);
        } else if (DataSourceGroup.oracle.includes(sourceType) && DataSourceGroup.kudu.includes(sinkType)) {
          mappingDef.push(noTargetDbTableMapping);
          mappingDef.push(columnMapping);
        } else {
          mappingDef.push(schemaMapping);
          mappingDef.push(tableMapping);
          mappingDef.push(columnMapping);
        }
      } else if (DataSourceGroup.hasSchema.indexOf(sinkType) > -1) {
        mappingDef.push(schemaMapping);
        mappingDef.push(tableMapping);
        mappingDef.push(columnMapping);
      } else if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
        mappingDef.push(tableTopicMapping);
        mappingDef.push(columnMapping);
      } else if (DataSourceGroup.redis.includes(sinkType)) {
        mappingDef.push(tableKeyPrefixMapping);
        mappingDef.push(columnMapping);
      } else if (DataSourceGroup.es.indexOf(sinkType) > -1) {
        mappingDef.push(tableIndexMapping);
        mappingDef.push(columnMapping);
      } else {
        mappingDef.push(dbMapping);
        mappingDef.push(tableMapping);
        mappingDef.push(columnMapping);
      }
      if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
        finalSourceSchema = JSON.stringify(topics);
      } else {
        finalSourceSchema = sourceSchema.length > 0 ? JSON.stringify(sourceSchema) : null;
      }

      if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
        finalTargetSchema = JSON.stringify(topics);
      } else if (DataSourceGroup.redis.includes(sinkType)) {
        finalTargetSchema = JSON.stringify(redisKeys);
      } else if (DataSourceGroup.es.indexOf(sinkType) > -1) {
        finalTargetSchema = JSON.stringify(esIndex);
      } else if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
        finalTargetSchema = JSON.stringify(targetSchema);
      } else if (DataSourceGroup.drds.indexOf(sinkType) > -1) {
        finalTargetSchema = JSON.stringify(targetSchema);
      } else if (DataSourceGroup.hive.indexOf(sinkType) > -1) {
        finalTargetSchema = JSON.stringify(targetSchema);
      } else {
        finalTargetSchema = null;
        // finalTargetSchema = JSON.stringify(targetSchema);
      }

      if (this.taskInfo && this.taskInfo.customVirtualColumns.length > 0) {
        structMigration = true;
      }

      if (DataSourceGroup.redis.includes(sinkType)) {
        structMigration = false;
      }
      return {
        structMigration,
        finalSourceSchema,
        finalTargetSchema,
        mappingDef
      };
    },
    parseCron(date) {
      let cron = '';

      if (date.dayType === 'DAY') {
        cron += `0 ${parseInt(date.time.split(':')[1], 10)} ${parseInt(date.time.split(':')[0], 10)} * * ? *`;
        return cron;
      }
      if (date.dayType === 'MONTH') {
        cron += `0 ${parseInt(date.time.split(':')[1], 10)} ${parseInt(date.time.split(':')[0], 10)} ${date.date} * ? *`;
        return cron;
      }
      if (date.dayType === 'YEAR') {
        cron += `0 ${parseInt(date.time.split(':')[1], 10)} ${parseInt(date.time.split(':')[0], 10)} ${date.date} ${date.month} ? *`;
        return cron;
      }
      if (date.dayType === 'HOUR') {
        cron += `0 ${date.hour} * * * ? *`;
        return cron;
      }
    }
  }
};
