var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"row-class-name":_vm.rowClassName,"stripe":"","size":"small","border":"","columns":_vm.virtualColumns,"data":_vm.virtualList,"height":"120"},scopedSlots:_vm._u([{key:"targetVirtualName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isCustom ? row.customName : row.targetVirtualName)+" ")]}},{key:"targetVirtualLength",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.columnLength ? row.columnLength : row.targetVirtualLength && row.targetVirtualLength.match(/\((.+)\)/g) && row.targetVirtualLength.match(/\((.+)\)/g)[0] && row.targetVirtualLength.match(/\((.+)\)/g)[0].substr(1, row.targetVirtualLength.match(/\((.+)\)/g)[0].length - 2))+" ")]}},{key:"targetVirtualType",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Select',{attrs:{"transfer":""},on:{"on-change":function($event){return _vm.handleChangeRow(row,index,'targetVirtualType',$event)}},model:{value:(row.targetVirtualType),callback:function ($$v) {_vm.$set(row, "targetVirtualType", $$v)},expression:"row.targetVirtualType"}},[_c('Option',{attrs:{"value":"varchar"}},[_vm._v("varchar")])],1)]}},{key:"columnValue",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{on:{"on-change":function($event){return _vm.handleChangeInputRow(row,index,'columnValue',$event)}},model:{value:(row.columnValue),callback:function ($$v) {_vm.$set(row, "columnValue", $$v)},expression:"row.columnValue"}})]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('i',{staticClass:"iconfont iconDEL-R",staticStyle:{"color":"#FF1815","cursor":"pointer"},on:{"click":function($event){return _vm.handleDeleteVirtual(index,_vm.selectedTable)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }