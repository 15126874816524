import User from '../../views/system/User';
import Resource from '../../views/worker/Cluster';
import Server from '../../views/worker/Worker';
import StateMachine from '../../views/system/Fsm';
import StateTask from '../../views/system/AsyncManager';
import StateDetail from '../../views/system/FsmDetail';
import OperationLog from '../../views/system/OperationLog';
// import SystemConfig from '../../views/system/SystemConfig';
import UserCenter from '../../views/system/UserCenter';
import License from '../../views/system/License';
import ConsoleJobDetail from '../../views/system/ConsoleJobDetail';
import ExChangeHistory from '../../views/system/ExchangeHistory';
import AlertSetting from '../../views/system/AlertSetting';
import StTokenSetting from '../../views/system/StTokenSetting';

export default [
  {
    path: '/system/user',
    name: 'user',
    component: User
  },
  {
    path: '/system/resource',
    name: 'resource',
    component: Resource
  },
  {
    path: '/system/resource/:id',
    name: 'resource/id',
    component: Server
  },
  {
    path: '/system/operationLog',
    name: 'operationLog',
    component: OperationLog
  },
  {
    path: '/system/fsm',
    name: '/system/fsm',
    component: StateMachine
  },
  {
    path: '/system/fsm/:id/:jobId',
    name: '/system/fsm/id/jobId',
    component: StateDetail
  },
  {
    path: '/system/state/task',
    name: '/system/state/task',
    component: StateTask
  },
  {
    path: '/system/state/task/:id',
    name: '/system/state/task/id',
    component: ConsoleJobDetail
  },
  // {
  //   path: '/system/config',
  //   name: '/system/config',
  //   component: SystemConfig
  // },
  {
    path: '/system/userCenter',
    name: '/system/userCenter',
    component: UserCenter
  },
  {
    path: '/system/license',
    name: '/system/license',
    component: License
  },
  {
    path: '/system/exchangeHistory',
    name: '/system/exchangeHistory',
    component: ExChangeHistory
  },
  {
    path: '/system/alertSetting',
    name: '/system/alertSetting',
    component: AlertSetting
  },
  {
    path: '/system/stTokenSetting',
    name: '/system/stTokenSetting',
    component: StTokenSetting
  }
];
