var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"stripe":"","border":"","size":"small","height":_vm.tableHeight,"row-class-name":_vm.rowClassName,"loading":_vm.loading,"columns":_vm.getTableColumns,"data":_vm.getTableData(_vm.table),"span-method":_vm.handleSpan},on:{"on-select":function($event){return _vm.handleOnSelect(_vm.table,_vm.index,$event)},"on-select-cancel":function($event){return _vm.handleOnSelectCancel(_vm.table,_vm.index,$event)},"on-select-all":function($event){return _vm.handleOnSelectAll(_vm.table,_vm.index,$event)},"on-select-all-cancel":function($event){return _vm.handleOnSelectAllCancel(_vm.table,_vm.index,$event)}},scopedSlots:_vm._u([{key:"sourceColumn",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sourceColumn)+" "),(row.isPk)?_c('Icon',{staticStyle:{"color":"#ff9900","margin-left":"5px"},attrs:{"type":"ios-key"}}):_vm._e(),(row.isId && _vm.DataSourceGroup.es.indexOf(_vm.sinkType) > -1)?_c('Tooltip',{attrs:{"transfer":""}},[_c('div',{staticClass:"_id"},[_vm._v(" _id ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',[_vm._v(" ElasticSearch中_id用于唯一识别一个文档。CloudCanal允许用户指定源端的某个列作为_id取值的来源，如果用户不指定，则默认使用源端主键列的取值作为写入对端_id的取值。在使用CloudCanal自定义代码构建宽表进行多表join的场景下，将_id设置为源端多表的关联列对多表join非常有用。 ")]),_c('a',{attrs:{"href":"https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-id-field.html","target":"_blank"}},[_vm._v("参考官方资料：_id field说明 ")])])]):_vm._e()]}},{key:"sink",fn:function(ref){
var index = ref.index;
var row = ref.row;
return [(_vm.selectedIndex===index&&_vm.table&&_vm.selectedColumns[_vm.table.sourceDb]&&_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable])?_c('Select',{class:_vm.dbToCreateClass(_vm.table,row),staticStyle:{"width":"100%"},attrs:{"filterable":"","transfer":true},on:{"on-change":function($event){return _vm.handleSinkColumnChange(_vm.table,row,$event)}},model:{value:(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn),callback:function ($$v) {_vm.$set(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index], "sinkColumn", $$v)},expression:"selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn"}},[_vm._v("}} "),(_vm.taskInfo.customPkgFile || _vm.havePackage)?_c('Option',{key:"-1",attrs:{"value":"clougence_cloudcanal_filter_after_mapping_column","label":"仅订阅不写入对端"}},[_c('Tooltip',{attrs:{"transfer":"","content":"订阅的源端列的值会在自定义代码中传递给用户，但是该列的值不映射目标端的任何列，也不会写入目标端。","placement":"right"}},[_c('p',[_vm._v("仅订阅不写入对端")])])],1):_vm._e(),_vm._l((_vm.taskInfo.sinkColumns[_vm.table.sinkDb?_vm.table.sinkDb:_vm.table.targetDb][_vm.table.sinkTable?_vm.table.sinkTable:_vm.table.targetTable]),function(item,i){return _c('Option',{key:i,attrs:{"value":item.columnName}},[_vm._v(_vm._s(item.columnName)+" ")])}),((_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable]&&
            _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn)&&
            !_vm.hasTheSameColumn(_vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn) &&
            _vm.taskInfo.sinkColumns[_vm.table.sinkDb?_vm.table.sinkDb:_vm.table.targetDb][_vm.table.sinkTable?_vm.table.sinkTable:_vm.table.targetTable]) &&
            _vm.table.needAutoCreated)?_c('Option',{attrs:{"value":_vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn)}},[_vm._v(" "+_vm._s(_vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn))+"（待创建） ")]):_vm._e()],2):_c('div',{on:{"mouseover":function($event){return _vm.displaySelect(index)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',[((_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable]&&
            _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn) &&
            !_vm.hasTheSameColumn(_vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn),
            _vm.taskInfo.sinkColumns[_vm.table.sinkDb?_vm.table.sinkDb:_vm.table.targetDb][_vm.table.sinkTable?_vm.table.sinkTable:_vm.table.targetTable])
            && _vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn) === _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn &&
            _vm.table.needAutoCreated )?_c('div',{staticStyle:{"color":"#FF6E0D"}},[_vm._v(" "+_vm._s(_vm.getTargetValue(_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sourceColumn))+"（待创建） ")]):_c('div',[_vm._v(" "+_vm._s(_vm.DataSourceGroup.columnMapping[_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn] || _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkColumn)+" ")])]),_c('Icon',{attrs:{"type":"ios-arrow-down","size":"14","color":"#808695"}})],1)])]}},{key:"sinkType",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.table && _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable] ? _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkType : '')+" "),(_vm.table &&
      _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable]&&
      !_vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkType)?_c('Tooltip',{attrs:{"content":"当前目标数据库不支持该类型","placement":"bottom","transfer":""}},[_c('p',{staticStyle:{"color":"#bbbbbb"}},[_c('i',{staticClass:"iconfont iconTIP",staticStyle:{"margin-right":"4px","vertical-align":"middle"}}),_vm._v("不支持的类型")])]):_vm._e()]}},{key:"sinkIsNullable",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.table && _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable] ? _vm.selectedColumns[_vm.table.sourceDb][_vm.table.sourceTable][row.index].sinkIsNullable : '')+" ")]}},{key:"indexType",fn:function(ref){
      var row = ref.row;
      var index = ref.index;
return [_c('div',[((row.sinkType!=='TEXT'&&row.sinkType!=='KEYWORD'&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT')||!row.needAutoCreated)?_c('span',{staticStyle:{"margin-left":"7px"}},[_vm._v(_vm._s(row.sinkType))]):_vm._e(),(row.needAutoCreated&&row.sinkType&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT'&&(row.sinkType==='TEXT'||row.sinkType==='KEYWORD'))?_c('Select',{staticStyle:{"width":"120px"},attrs:{"transfer":true},on:{"on-change":function($event){return _vm.handleChangeIndexType(row,index,_vm.table,$event)}},model:{value:(row.sinkType),callback:function ($$v) {_vm.$set(row, "sinkType", $$v)},expression:"row.sinkType"}},[_c('Option',{attrs:{"value":"TEXT"}},[_vm._v("TEXT")]),_c('Option',{attrs:{"value":"KEYWORD"}},[_vm._v("KEYWORD")])],1):_vm._e(),(row.needAutoCreated&&(row.sourceType.toUpperCase()==='JSON'||row.sourceType.toUpperCase()==='LONGTEXT'))?_c('Select',{staticStyle:{"width":"120px"},attrs:{"transfer":true},on:{"on-change":function($event){return _vm.handleChangeIndexType(row,index,_vm.table,$event)}},model:{value:(row.sinkType),callback:function ($$v) {_vm.$set(row, "sinkType", $$v)},expression:"row.sinkType"}},[_c('Option',{attrs:{"value":"OBJECT"}},[_vm._v("OBJECT")]),_c('Option',{attrs:{"value":"TEXT"}},[_vm._v("TEXT")]),_c('Option',{attrs:{"value":"NESTED"}},[_vm._v("NESTED")])],1):_vm._e()],1)]}},{key:"ifKey",fn:function(ref){
      var row = ref.row;
      var index = ref.index;
return [_c('div',[_c('Checkbox',{attrs:{"disabled":!row.needAutoCreated||_vm.esDisableIndexType.indexOf(row.sinkType)>-1},on:{"on-change":function($event){return _vm.handleChangeIndex(row,index)}},model:{value:(row.isIndex),callback:function ($$v) {_vm.$set(row, "isIndex", $$v)},expression:"row.isIndex"}})],1)]}},{key:"columnValue",fn:function(ref){
      var row = ref.row;
return [_c('div',[_c('Input',{model:{value:(row.columnValue),callback:function ($$v) {_vm.$set(row, "columnValue", $$v)},expression:"row.columnValue"}})],1)]}},{key:"action",fn:function(ref){
      var row = ref.row;
      var index = ref.index;
return [_c('div',[(row.isVirtual)?_c('i',{staticClass:"iconfont iconDEL-R",staticStyle:{"color":"#FF1815","cursor":"pointer"},on:{"click":function($event){return _vm.handleDeleteColumn(row,index)}}}):_vm._e()])]}},{key:"analyzer",fn:function(ref){
      var row = ref.row;
return [(row.sinkType==='TEXT'&&row.needAutoCreated)?_c('Select',{staticStyle:{"width":"120px"},attrs:{"transfer":true,"filterable":""},on:{"on-change":function($event){return _vm.handleChangeAnalyzer(row,_vm.index,_vm.table,$event)}},model:{value:(row.analyzer),callback:function ($$v) {_vm.$set(row, "analyzer", $$v)},expression:"row.analyzer"}},_vm._l((_vm.esTypeList),function(esType){return _c('Option',{key:esType,attrs:{"value":esType}},[_vm._v(_vm._s(esType))])}),1):_vm._e(),(row.sinkType==='TEXT'&&!row.needAutoCreated)?_c('span',[_vm._v(_vm._s(row.analyzer))]):_vm._e()]}}])},[_c('template',{slot:"mqSink"},[_c('div',[_c('pre',{key:_vm.mqSchemaKey},[_vm._v(_vm._s(_vm.mqSchema))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }