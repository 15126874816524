<template>
  <div class="job-detail-basic">
    <div class="basic-info-content">
      <p><span>任务类型：</span><span
        class="basic-info-value">{{ MappingUtil.jobType[jobData.dataJobType] }}</span></p>
      <p><span>创建人：</span><span class="basic-info-value">{{ jobData.username }}</span></p>
      <p><span>创建时间：</span><span
        class="basic-info-value">{{
          fecha.format(new Date(jobData.gmtCreated), 'YYYY-MM-DD HH:mm:ss')
        }}</span>
      </p>
      <p v-if="jobData.shotTermSync"><span>增量同步：</span>
        <span
          class="basic-info-value">至{{
            fecha.format(new Date(new Date(jobData.gmtCreated).getTime() + jobData.shotTermNum * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm:ss')
          }}结束</span>
      </p>
      <!--                            <p><span>DDL过滤规则：</span><span class="basic-info-value">{{jobData.businessConfig.enableFilterDDLStatement?'过滤':'全部同步'}}</span></p>-->
      <p><span>规格：</span><span
        class="basic-info-value">{{
          taskList['FULL'] ? '全量：' + taskList['FULL'].fullJvmHeapMb / 1024 + 'G' : ''
        }}
              {{
          taskList['INCREMENT'] ? '增量：' + taskList['INCREMENT'].incrementJvmHeapMb / 1024 + 'G' : ''
        }}
              {{
          taskList['CHECK'] ? '校验：' + taskList['CHECK'].checkJvmHeapMb / 1024 + 'G' : ''
        }}
              {{taskList['REVISE']?'订正：'+taskList['REVISE'].fullJvmHeapMb/1024+'G':''}}
            </span>
      </p>
      <p><span>库表映射：</span><span><a
        style="margin-left: 5px;color: #0BB9F8"
        @click="handleShowMapping">查看</a></span></p>
    </div>
    <div class="basic-info-content" v-if="jobData.havePackage">
      <p class="basic-info-title">代码处理包</p>
      <p><span>包名：</span><span
        class="basic-info-value">{{ activePackage.fileName }}</span></p>
      <p><span>描述：</span><span class="basic-info-value">{{ activePackage.description }}</span></p>
      <p><span>创建时间：</span><span v-if="activePackage.gmtCreate"
        class="basic-info-value">{{
          fecha.format(new Date(activePackage.gmtCreate), 'YYYY-MM-DD HH:mm:ss')
        }}</span>
      </p>
      <p>
        <span>代码包管理：</span>
        <a @click="handleShowPackageList">查看</a>
      </p>
    </div>
    <div class="basic-info-content">
      <p class="basic-info-title">源端</p>
      <p><span>实例备注：</span><span
        class="basic-info-value">{{ jobData.sourceDsVO.instanceDesc }}</span></p>
      <p><span>地域：</span><span class="basic-info-value">{{ jobData.sourceDsVO.region }}</span></p>
      <p><span>类型：</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${jobData.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[jobData.sourceDsVO.dataSourceType]:jobData.sourceDsVO.dataSourceType}`">
                                    <DataSourceIcon :type="jobData.sourceDsVO.dataSourceType"
                                                    :instanceType="jobData.sourceDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
      <div><span>实例ID：</span><span
        class="basic-info-value">{{ jobData.sourceDsVO.instanceId }}</span>
        <Poptip placement="right" width="680">
          <i @click="getDataSourceDetail" class="iconfont iconinfo"></i>
          <div slot="content" style="line-height: 24px">
            <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
          </div>
        </Poptip>
      </div>
      <p>
        <span>连接串：</span><span class="basic-info-value">{{ jobData.sourceDsVO.host }}</span>
        <Icon class="copy-icon" type="ios-photos-outline"
              @click="handleCopy(jobData.sourceDsVO.host)"/>
      </p>
      <p><span>部署类型：</span><span
        class="basic-info-value">{{
          jobData.sourceDsVO.deployType === 'SELF_MAINTENANCE' ? '自建数据库' : '阿里云'
        }}</span>
      </p>
    </div>
    <div class="basic-info-content">
      <p class="basic-info-title">目标端</p>
      <p><span>实例备注：</span><span
        class="basic-info-value">{{ jobData.targetDsVO.instanceDesc }}</span></p>
      <p><span>地域：</span><span class="basic-info-value">{{ jobData.targetDsVO.region }}</span></p>
      <p><span>类型：</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${jobData.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[jobData.targetDsVO.dataSourceType]:jobData.targetDsVO.dataSourceType}`">
                                      <DataSourceIcon :type="jobData.targetDsVO.dataSourceType"
                                                      :instanceType="jobData.targetDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
      <p><span>实例ID：</span><span class="basic-info-value">{{ jobData.targetDsVO.instanceId }}</span>
        <Poptip placement="right" width="680">
          <i @click="getDataSinkDetail" class="iconfont iconinfo"></i>
      <div slot="content" style="line-height: 24px">
        <DataSourceInDetail :dataSourceDetail="sinkDetail"></DataSourceInDetail>
      </div>
      </Poptip>
      </p>
      <p>
        <span>连接串：</span><span class="basic-info-value">{{ jobData.targetDsVO.host }}</span>
        <Icon class="copy-icon" type="ios-photos-outline"
              @click="handleCopy(jobData.targetDsVO.host)"/>
      </p>
      <p><span>部署类型：</span><span
        class="basic-info-value">{{
          jobData.targetDsVO.deployType === 'SELF_MAINTENANCE' ? '自建数据库' : '阿里云'
        }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '../../../../views/util';
import DataSourceIcon from '../../DataSourceIcon';
import DataSourceInDetail from '../../addDataSource/DataSourceInDetail';

export default {
  components: {
    DataSourceIcon,
    DataSourceInDetail
  },
  props: {
    jobData: Object,
    taskList: Object,
    handleShowMapping: Function,
    getDataSourceDetail: Function,
    sourceDetail: Object,
    sinkDetail: Object,
    getDataSinkDetail: Function,
    activePackage: Object,
    handleShowPackageList: Function
  },
  data() {
    return {
      MappingUtil,
      fecha
    };
  },
  methods: {
    handleCopy(value) {
      const aux = document.createElement('input');

      aux.setAttribute('value', value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success('复制成功！');
    }
  }
};
</script>
