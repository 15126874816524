<template>
    <div class="task-list" v-if="showPage">
        <div v-if="!showGroup">
            <PageHeader :instanceList="instanceList" :hasInterval="hasInterval" :handleInterval="handleInterval"
                        :loading="refreshLoading" :updateBatchInTable="updateBatchInTable" :batchEditInTable="batchEdit"
                        :handleChangeMode="handleChangeMode" :handleSearch="handleJobList" :mode="mode" :handleClassify="handleClassify"></PageHeader>
            <div :class="`job-info-container ${batchEdit?'margin-more':''}`" v-if="showData.length>0&&mode==='card'&&!classifyType">
                <Row :gutter="10">
                    <Col :span="getFitSpan" v-for="row of showData" :key="row.id">
                        <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :handleShowGroup="handleShowGroup"
                                     :getProgressStatus="getProgressStatus" :handleConfirmEdit="handleConfirmEdit" :handleReplay="handleReplay"
                                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob" :handleStopSchedule="handleStopSchedule"
                                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart" :handleDelete="handleDelete"
                                     :handleRestart="handleRestart" :handleStartFsm="handleStartFsm" :handleStop="handleStop" :dataJobInfo="row"
                                     v-if="row.isParent" :handleBatchEdit="handleBatchEdit"></JobInfoCard>
                    </Col>
                </Row>
            </div>
            <div class="task-list-none" v-if="showData.length===0&&mode==='card'&&show&&!classifyType">
                <NoDataJob :handleGoWorker="handleGoWorker" :handleGoDatasource="handleGoDatasource" :handleGoDataJob="handleGoDataJob"></NoDataJob>
            </div>
            <ClassifyDashboard :handleShowHistory="handleShowHistory" v-if="classifyType" :loadingData="loadingData" :classifyData="classifyData"
                               :classifyType="classifyType" :handleShowGroup="handleShowGroup" :getProgressStatus="getProgressStatus"
                               :handleConfirmEdit="handleConfirmEdit" :handleStartTimeScheduleJob="handleStartTimeScheduleJob" :handleReplay="handleReplay"
                               :handleStopSchedule="handleStopSchedule" :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                               :handleDelete="handleDelete" :handleRestart="handleRestart" :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                               :handleBatchEdit="handleBatchEdit"></ClassifyDashboard>
            <JobList :updateAllBatchList="updateAllBatchList" :handleCancelAllBatch="handleCancelAllBatch" :handleSelectBatch="handleSelectBatch" :handleCancelBatch="handleCancelBatch"
                     :handleShowHistory="handleShowHistory" :handleTableSort="handleTableSort" :handleReplay="handleReplay"
                     :getFilters="getFilters" :batchEditInTable="batchEdit" :getProgressStatus="getProgressStatus"
                     :handleConfirmEdit="handleConfirmEdit" :showData="showData" :getJobList="getJobList"
                     :handleStartTimeScheduleJob="handleStartTimeScheduleJob" :handleStopSchedule="handleStopSchedule"
                     :handleResumSchedule="handleResumSchedule" :handleStart="handleStart" :handleDelete="handleDelete"
                     :handleRestart="handleRestart" :handleStartFsm="handleStartFsm" :handleStop="handleStop" v-if="mode==='table'&&!classifyType"></JobList>
        </div>
        <PageFooter v-if="showData.length>0" class="data-job-footer" :batchEditBtns="batchEditBtns" :handleBatchEdit="handleBatchEditConfirm"
                    :total="total" :handlePageChange="handlePageChange" :batchEdit="batchEdit" :handlePageSizeChange="handlePageSizeChange"
                    :size="size"></PageFooter>
        <DataJobListModal :getJobList="getJobList" :showConfirm="showConfirm" :showScheduleHistory="showScheduleHistory"
                          :showBatchConfirm="showBatchConfirm" :scheduleHistoryColumn="scheduleHistoryColumn" :handleCloseHistory="handleCloseHistory"
                          :scheduleHistoryData="scheduleHistoryData" :batchEditList="batchEditList" :handleCancelDelete="handleCancelDelete"
                          :selectedJobRow="selectedJobRow" :batchType="batchType" :checkAllGroup="checkAllGroup"
                          :handleBeginBatchEdit="handleBeginBatchEdit" :jobId="jobId"></DataJobListModal>
    </div>
</template>
<script>
import {
  getJobList
} from '@services/api/job';
import { listbycondition } from '@services/api/datasource';
import _ from 'lodash';
import { listUserLicense } from '@services/api/userLicense';
import { getUserQuota } from '@services/api/resourceQuota';
import PageHeader from '@components/function/dataJob/dataJobList/PageHeader';
import PageFooter from '@components/function/dataJob/dataJobList/PageFooter';
import JobInfoCard from '@components/function/dataJob/dataJobList/JobInfoCard';
import JobList from '@components/function/dataJob/dataJobList/JobList';
import ClassifyDashboard from '@components/function/dataJob/dataJobList/ClassifyDashboard';
import NoDataJob from '@components/function/dataJob/dataJobList/NoDataJob';
import DataJobListModal from '@components/function/dataJob/dataJobList/modal/DataJobListModal';
import MyMixin from '@components/function/mixin/dataJobListMixin';
import DataJobDetaibMix from '@components/function/mixin/dataJobDetailMixin';
import Mapping from '../util';
import store from '../../store/index';

export default {
  mixins: [MyMixin, DataJobDetaibMix],
  components: {
    PageHeader,
    PageFooter,
    JobInfoCard,
    JobList,
    ClassifyDashboard,
    NoDataJob,
    DataJobListModal
  },
  data() {
    return {
      classifyData: {},
      classifyType: '',
      showGroup: false,
      groupData: '',
      show: false,
      batchType: '',
      showBatchConfirm: false,
      batchEditInTable: false,
      batchEdit: false,
      batchEditList: [],
      batchEditBtns: [],
      checkAllGroup: [],
      mode: 'table',
      store,
      searchType: 'dataJobDesc',
      instanceList: [],
      startInterval: '',
      restartInterval: '',
      stopInterval: '',
      showPage: true,
      dataJobDesc: '',
      Mapping,
      sortDelay: 'normal',
      sortTime: 'normal',
      statusTime: 10000,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      filterValue: '',
      hasInterval: false,
      myInterval: '',
      refreshLoading: false,
      showData: [],
      pagingData: [],
      page: 1,
      size: 20,
      total: 0,
      showDataJobListInterval: {},
      searchData: {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      },
      showAllSearch: false,
      jobData: []
    };
  },
  created() {
    // this.getJobList();
    const theMode = localStorage.getItem('cc-data-job-mode');

    if (theMode) {
      this.mode = theMode;
    }
  },
  mounted() {
    this.getDataSourceList();
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
    clearInterval(this.startInterval);
    clearInterval(this.restartInterval);
    clearInterval(this.stopInterval);
  },
  computed: {
  },
  methods: {
    handleChangeMode(mode) {
      this.mode = mode;
      this.classifyType = '';
      this.batchEditList = [];
      this.batchEditInTable = false;
      this.batchEdit = false;
      this.batchEditBtns = [];
      this.getFilters('');
      localStorage.setItem('cc-data-job-mode', this.mode);
    },
    getDataSourceList() {
      listbycondition({}).then((res) => {
        if (res.data.code === '1') {
          this.instanceList = res.data.data;
        }
      });
    },
    handleRefresh(data) {
      this.getJobList(data);
    },
    getJobList(data) {
      if (data) {
        this.searchData = data;
      }
      this.refreshLoading = true;
      const searchData = _.cloneDeep(this.searchData);

      if (searchData.dataJobType === 'all') {
        searchData.dataJobType = '';
      }
      this.loading = true;
      getJobList(searchData).then((res) => {
        if (res.data.code === '1') {
          this.jobData = res.data.data;
          this.total = this.jobData.length;
          const errorList = []; const
            errorCount = [];

          this.jobData.forEach((item, index) => {
            try {
              if (item.dataTaskState !== 'COMPLETE') {
                item.dataTasks.map((task) => {
                  if (task.dataTaskType === item.dataTaskState) {
                    item.currentStatus = task.dataTaskStatus;
                  } else if (task.dataTaskType === 'INCREMENT' && item.dataTaskState === 'CATCH_UP') {
                    if (task.dataTaskStatus === 'RUNNING') {
                      if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                        task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                      } else {
                        task.trueDelay = task.dataDelayMs;
                      }
                      item.trueDelay = task.trueDelay;
                    }
                    item.currentStatus = task.dataTaskStatus;
                  } else if (task.dataTaskType === 'INCREMENT' && item.dataTaskState === 'INCRE') {
                    if (task.dataTaskStatus === 'RUNNING') {
                      if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                        task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                      } else {
                        task.trueDelay = task.dataDelayMs;
                      }
                      item.trueDelay = task.trueDelay;
                    }
                    item.currentStatus = task.dataTaskStatus;
                  }
                  return null;
                });
              }
              const type = Mapping.taskSateToTaskType[item.dataTaskState];

              if (this.getDataTask(item.dataTasks)[type] && this.getDataTask(item.dataTasks)[type].dataTaskStatus !== 'STOP'
                && this.getDataTask(item.dataTasks)[type].healthLevel !== 'Health') {
                item.trueSatus = 'error';
                errorList.push(item);
                errorCount.push(index);
              }
              item._showChildren = false;
              // 判断是否是子任务
              item.children = _.cloneDeep(item.childJobs);
              item.isParent = true;
              return null;
            } catch (e) {
              console.log('e', e);
            }
          });

          this.pagingData = _.cloneDeep(this.jobData);
          if (this.sortDelay !== 'normal') {
            let sortList = [];

            const deletedList = [];

            this.pagingData.map((item, index) => {
              item.dataTasks.map((task) => {
                if (task.trueDelay) {
                  sortList.push(item);
                  // this.pagingData.splice(index,1);
                  deletedList.push(index);
                }
                return null;
              });
              return null;
            });
            deletedList.map((item, index) => {
              this.pagingData.splice(item - index, 1);
              this.jobData.splice(item - index, 1);
              return null;
            });
            if (this.sortDelay === 'asc') {
              sortList = _.cloneDeep(this.jsQuickSortAsc(sortList));
              this.pagingData = sortList.concat(this.pagingData);
              this.jobData = sortList.concat(this.jobData);
            } else {
              sortList = _.cloneDeep(this.jsQuickSortDesc(sortList));
              this.pagingData = sortList.concat(this.pagingData);
              this.jobData = sortList.concat(this.jobData);
            }
          }
          if (this.sortTime !== 'normal') {
            if (this.sortTime === 'asc') {
              this.pagingData.sort(this.sortDateAsc);
              this.jobData.sort(this.sortDateAsc);
            } else {
              this.pagingData.sort(this.sortDateDesc);
              this.jobData.sort(this.sortDateDesc);
            }
          }

          this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
          if (this.showData.length < 1 && this.page > 1) {
            this.page--;
            this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
          }
          this.showPage = true;
        }
        this.refreshLoading = false;
        if (this.filterValue) {
          this.getFilters(this.filterValue);
        }
        this.show = true;
        this.loading = false;
      }).catch(() => {
        this.refreshLoading = false;
        this.loading = false;
      });
    },
    jsQuickSortAsc(array) {
      if (array.length <= 1) {
        return array;
      }
      const pivotIndex = Math.floor(array.length / 2);
      const pivot = array.splice(pivotIndex, 1)[0];
      const left = []; const
        right = [];

      array.forEach((item) => {
        if (item.trueDelay < pivot.trueDelay) {
          left.push(item);
        } else {
          right.push(item);
        }
      });
      return this.jsQuickSortAsc(left).concat(pivot, this.jsQuickSortAsc(right));
    },
    jsQuickSortDesc(array) {
      if (array.length <= 1) {
        return array;
      }
      const pivotIndex = Math.floor(array.length / 2);
      const pivot = array.splice(pivotIndex, 1)[0];
      const left = []; const
        right = [];

      array.forEach((item) => {
        if (item.trueDelay > pivot.trueDelay) {
          left.push(item);
        } else {
          right.push(item);
        }
      });
      return this.jsQuickSortDesc(left).concat(pivot, this.jsQuickSortDesc(right));
    },
    sortDateDesc(a, b) {
      return new Date(a.gmtCreated).getTime() - new Date(b.gmtCreated).getTime();
    },
    sortDateAsc(a, b) {
      return new Date(b.gmtCreated).getTime() - new Date(a.gmtCreated).getTime();
    },
    handleJobList(data) {
      this.getJobList(data);
    },
    getCurrentState(row) {
      return Mapping.taskState[row.dataTaskState];
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handleEditJobDesc(row) {
      this.dataJobDesc = row.dataJobDesc;
    },
    getCheckExpression(expression) {
      const weekMap = {
        1: '日',
        2: '一',
        3: '二',
        4: '三',
        5: '四',
        6: '五',
        7: '六'
      };
      const expressionArr = expression.split(' ');

      if (expressionArr[5] === '?') {
        if (expressionArr[2] === '*') {
          // return `每小时 ${expressionArr[1] < 10 ? '0' + expressionArr[1] : expressionArr[1]} 分执行一次`;
          return '每小时执行一次';
        }
        return `每天 ${expressionArr[2]}:${expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1]} 执行一次`;
      }
      return `每周${weekMap[expressionArr[5]]} ${expressionArr[2]}:${expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1]} 执行一次`;
    },
    handleInterval(data) {
      const that = this;

      if (!data) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.getJobList();
        }, 20000);
      }
    },
    handleCancelEdit(row) {
      row.showEditDesc = false;
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      };
    },
    getFilters(value) {
      this.filterValue = value;
      const dataTasks = {}; const
        list = [];

      this.showData = [];
      this.total = 0;
      this.jobData.map((row) => {
        row.dataTasks.map((item) => {
          dataTasks[item.dataTaskType] = item;
          return null;
        });
        const type = Mapping.taskSateToTaskType[row.dataTaskState];

        let currentValue = '';

        if (type) {
          if (!this.getDataTask(row.dataTasks)[type]) {
            currentValue = 'init';
          } else if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
            currentValue = 'stop';
          } else if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
            currentValue = 'normal';
          } else {
            currentValue = 'error';
          }
        } else if (row.dataTaskState === 'COMPLETE') {
          currentValue = 'finish';
        } else {
          currentValue = 'normal';
        }
        if (currentValue.indexOf(value) > -1) {
          list.push(row);
          this.total = list.length;
          this.page = 1;
          this.showData = list.slice((this.page - 1) * this.size, this.page * this.size);
        }
        return null;
      });
      this.pagingData = _.cloneDeep(list);
    },
    handleTableSort(data) {
      let userId = '';
      let sort = {};

      if (localStorage.getItem('userInfo')) {
        userId = JSON.parse(localStorage.getItem('userInfo')).id;
      }

      if (data.key === 'gmtCreated') {
        this.sortTime = data.order;
        if (localStorage.getItem(`job_list_sort_${userId}`)) {
          sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
        }

        sort.sortTime = data.order;
        localStorage.setItem(`job_list_sort_${userId}`, JSON.stringify(sort));
        this.getJobList();
      } else if (data.key === 'dataTasks') {
        this.sortDelay = data.order;
        if (localStorage.getItem(`job_list_sort_${userId}`)) {
          sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
        }

        sort.sortDelay = data.order;
        localStorage.setItem(`job_list_sort_${userId}`, JSON.stringify(sort));
        this.getJobList();
      }
      this.page = 1;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handleClassify(classifyType) {
      this.classifyType = classifyType;
      if (classifyType === 'status') {
        const normalList = []; const abnormalList = []; const stopList = []; const
          finishList = [];

        this.pagingData.map((item) => {
          const type = Mapping.taskSateToTaskType[item.dataTaskState];

          if (type) {
            if (!this.getDataTask(item.dataTasks)[type]) {
              normalList.push(item);
            } else if (this.getDataTask(item.dataTasks)[type].dataTaskStatus === 'STOP') {
              stopList.push(item);
            } else if (this.getDataTask(item.dataTasks)[type].healthLevel === 'Health') {
              normalList.push(item);
            } else {
              abnormalList.push(item);
            }
          } else if (item.dataTaskState === 'COMPLETE') {
            finishList.push(item);
          } else {
            normalList.push(item);
          }
          return null;
        });
        this.classifyData.normalList = normalList;
        this.classifyData.abnormalList = abnormalList;
        this.classifyData.stopList = stopList;
        this.classifyData.finishList = finishList;
      } else if (classifyType === 'state') {
        const buildStruct = []; const fullList = []; const incrementList = []; const checkList = []; const
          finishList = [];

        this.pagingData.map((item) => {
          if (item.dataTaskState === 'BUILD_STRUCT') {
            buildStruct.push(item);
          } else if (item.dataTaskState === 'FULL') {
            fullList.push(item);
          } else if (item.dataTaskState === 'INCRE' || item.dataTaskState === 'CATCH_UP') {
            incrementList.push(item);
          } else if (item.dataTaskState === 'CHECK') {
            checkList.push(item);
          } else if (item.dataTaskState === 'COMPLETE') {
            finishList.push(item);
          }
          return null;
        });
        this.classifyData.buildStruct = buildStruct;
        this.classifyData.fullList = fullList;
        this.classifyData.incrementList = incrementList;
        this.classifyData.checkList = checkList;
        this.classifyData.finishList = finishList;
      } else if (classifyType === 'type') {
        const migrationList = []; const syncList = []; const structList = []; const
          checkList = [];

        this.pagingData.map((item) => {
          if (item.dataJobType === 'MIGRATION') {
            migrationList.push(item);
          } else if (item.dataJobType === 'SYNC') {
            syncList.push(item);
          } else if (item.dataJobType === 'CHECK') {
            checkList.push(item);
          } else if (item.dataJobType === 'STRUCT_MIGRATION') {
            structList.push(item);
          }
          return null;
        });
        this.classifyData.migrationList = migrationList;
        this.classifyData.syncList = syncList;
        this.classifyData.structList = structList;
        this.classifyData.checkList = checkList;
      }
    },
    handleGoWorker() {
      getUserQuota().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.quotaType === 'WORKER_COUNT') {
              if (item.used >= item.quota) {
                this.$Modal.warning({
                  title: '额度不足',
                  content: '您当前的机器额度不足，请去<a href="/#/system/userCenter">个人中心</a>申请额度。'
                });
              } else {
                this.$router.push({ path: '/system/resource' });
              }
            }
            return null;
          });
        }
      });
    },
    handleGoDatasource() {
      getUserQuota().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.quotaType === 'DATA_SOURCE_COUNT') {
              if (item.used >= item.quota) {
                this.$Modal.warning({
                  title: '额度不足',
                  content: '您当前的数据源额度不足，请去<a href="/#/system/userCenter">个人中心</a>申请额度。'
                });
              } else {
                this.$router.push({ path: '/data/dataSource' });
              }
            }
            return null;
          });
        }
      });
    },
    handleGoDataJob() {
      getUserQuota().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.quotaType === 'DATA_JOB_COUNT') {
              if (item.used >= item.quota) {
                this.$Modal.warning({
                  title: '额度不足',
                  content: '您当前的任务数额度不足，请去<a href="/#/system/userCenter">个人中心</a>申请额度。'
                });
              } else {
                listUserLicense().then((res1) => {
                  if (res1.data.code === '1') {
                    let hasResource = false;

                    res1.data.data.map((item1) => {
                      if (item1.amount > 0 || item1.amount === -10000) {
                        hasResource = true;
                      }
                      return null;
                    });
                    if (hasResource) {
                      this.$router.push({ path: '/data/job/create/process' });
                    } else {
                      this.$Modal.warning({
                        title: '资源不足',
                        content: '您当前的资源不足，请去<a href="/#/system/userCenter">个人中心</a>充值后再创建任务。'
                      });
                    }
                  }
                });
              }
            }
            return null;
          });
        }
      });
    },
    handleUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleCloseHistory() {
      this.showScheduleHistory = false;
    }
  }
};
</script>
<style lang="less">
    .task-list-body {
        padding: 24px;
        background: #ffffff;
        margin-top: 16px;

        .demo-table-info-row {
            .ivu-table-cell-slot {
                display: inline-block;
                vertical-align: middle;
            }

            .ivu-table-cell-tree {
                display: none;
            }

            .ivu-table-cell-tree-level {
                display: none;
            }
        }

        .ivu-progress-inner-text {
            color: #17233d;
        }
    }

    .job-edit-desc-a {
        opacity: 0;
    }

    .ivu-table-row :hover .job-edit-desc-a {
        opacity: 1;
    }

    .task-step-status {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
    }
    .job-info-container{
        margin-top: 16px;
        margin-bottom: 56px;
    }
    .margin-more{
        margin-bottom: 116px;
    }
    .data-job-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index:999;
    }
</style>
