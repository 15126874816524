import DATASOURCE_TYPE from '@consts/datasourceType';

export const DATASOURCE_DEPLOY_TYPE = {
  SELF_MAINTENANCE: 'SELF_MAINTENANCE',
  ALIBABA_CLOUD_HOSTED: 'ALIBABA_CLOUD_HOSTED'
};

export const HOST_TYPE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};

export const DATASOURCE_LIFECYCLE_STATE = {
  CREATED: 'CREATED'
};

export {
  DATASOURCE_TYPE
};
