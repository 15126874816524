<template>
  <div>
    <div class="clean-data-search-container">
      <Select v-if="!DataSourceGroup.mq.includes(sourceType)" prefix="ios-search"
              @on-change="handleTableFilter('option',$event)"
              style="width:160px;margin-right: 10px" v-model="filterTable.tableFilterType">
        <Option value="hasWhere">已添加where条件</Option>
        <Option value="noWhere">未添加where条件</Option>
      </Select>
      <Input v-model="filterTable.filterTableName" placeholder="筛选表" style="width: 300px"
             @on-change="handleTableFilter('filter',$event)"/>
      <Dropdown style="float: right;margin-right: 20px;">
        <Button>
          批量操作
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem v-if="!DataSourceGroup.mq.includes(sourceType)"><span @click="handleShowFilterAll">批量添加where条件</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.mysql.includes(sourceType)||DataSourceGroup.polar.includes(sourceType)"><span @click="handleSetTargetKey">批量设置目标主键</span></DropdownItem>
          <DropdownItem v-if="theType!=='edit'"><span @click="handleShowOperateColumns">批量裁剪列</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.redis.indexOf(sinkType)>-1"><span @click="handleBatchSetRedisKey">批量配置 Redis KEY</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.hive.indexOf(sinkType)>-1"><span @click="handleShowPartitionAll">批量配置分区键</span></DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <pd-button style="float: right;margin-right: 20px" type="success" ghost
                 v-if="DataSourceGroup.hive.indexOf(sinkType)>-1&&taskInfo.partitionData.partition[0].func"
                 @click="handleShowPartitionAll">配置分区键(已配置)
      </pd-button>
      <Button v-if="taskInfo&&(taskInfo.type==='SYNC'||taskInfo.type==='MIGRATION')&&!taskInfo.customPkgFile&&supportPkgDs.includes(taskInfo.sinkType)"
              style="float: right;margin-right: 16px;" @click="handleShowUploadPkg">上传自定义代码</Button>
      <Button type="success" ghost v-if="taskInfo&&taskInfo.customPkgFile&&supportPkgDs.includes(taskInfo.sinkType)"
              style="float: right;margin-right: 16px;" @click="handleShowUploadPkg">自定义代码插件（已配置）</Button>
      <div style="margin-left: 8px;display: inline-block" v-if="filterList.length>0">
        <span>检索项：</span>
        <span class="table-filter-filter-span" v-for="(filter,i) in filterList"
              :key="i">{{ Mapping.filterType[filter] }}<Icon type="md-close-circle"
                                                             @click="handleDeleteFilter(i)"/></span>
        <span class="table-filter-filter-clear" @click="handleClearFilter">清除</span>
      </div>
    </div>
    <Modal
      v-model="showUploadPkg"
      title="自定义代码处理插件"
      footer-hide
    >
      <div>
        <Alert style="margin-bottom: 20px;font-size: 12px">自定义代码实时加工允许用户使用Java语言编写自定义的数据行处理逻辑，上传CloudCanal平台后，
          数据同步任务执行全量、增量时会自动应用用户的自定义处理逻辑，然后再下入对端数据源。<br><br>
          示例：<a target="_blank" href="https://e.gitee.com/clougence/repos/clougence/cloudcanal-sdk-demos">https://e.gitee.com/clougence/repos/clougence/cloudcanal-sdk-demos</a>
        </Alert>
        <Form label-position="left" :label-width="60">
          <FormItem label="上传插件">
        <span class="upload-btn" v-if="!customPkgFile">
                  <input style="margin-left: 10px" @change="handleFileChange" type = "file" name = "uploadfile" id = "uploadfile" />
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-LocalUpload"></use>
                  </svg>
                  点击这里上传文件
          </span>
            <span v-if="customPkgFile" class="file-name-span">
            {{customPkgFile.name}}
            ({{(customPkgFile.size/(1024)).toFixed(1)}}KB)
            <Icon class="pkg-close" type="md-close" @click="handleRemovePkg"/>
<!--              <Icon class="pkg-upload-success" style="color: #00bc4a" type="md-checkmark" />-->
          </span>
          </FormItem>
          <FormItem label="描述" v-if="taskInfo">
            <Input style="width: 280px" v-model="taskInfo.pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpload">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import Mapping from '../../../../../views/util';

export default {
  props: {
    handleTableFilter: Function,
    filterTable: Object,
    handleShowFilterAll: Function,
    handleShowOperateColumns: Function,
    filterList: Array,
    handleDeleteFilter: Function,
    handleClearFilter: Function,
    handleGetColumnByTable: Function,
    selectedIndex: Number,
    getSelectedInfo: Function,
    getWhereCondition: Function,
    handleFilter: Function,
    handleSetPartition: Function,
    handleShowPartitionAll: Function,
    handleSetTargetKey: Function,
    sourceType: String,
    sinkType: String,
    theType: String,
    handleBatchSetRedisKey: Function,
    taskInfo: Object
  },
  data() {
    return {
      DataSourceGroup,
      Mapping,
      showUploadPkg: false,
      customPkgFile: null,
      supportPkgDs: ['MySQL', 'ElasticSearch', 'ClickHouse']
    };
  },
  methods: {
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: '文件过大',
            content: '文件大小应不超过500M'
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleShowUploadPkg() {
      this.customPkgFile = this.taskInfo.customPkgFile;
      this.showUploadPkg = true;
    },
    handleConfirmUpload() {
      this.taskInfo.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
    },
    handleCancel() {
      this.showUploadPkg = false;
    },
    handleRemovePkg() {
      this.customPkgFile = null;
    }
  }

};
</script>
<style lang="less">
.clean-data-search-container {
  padding: 14px 20px;
}
.upload-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  vertical-align: middle;
  padding: 0 16px;
}
.upload-btn input {
  display: inline-block;
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.file-name-span {
  height: 34px;
  line-height: 34px;
  background: #fafafa;
  padding: 8px 16px;
  .ivu-icon-md-close {
    cursor: pointer;
  }
}
</style>
