<template>
  <div>
    <DataSourceHeader :handleSearch="getDataSourceList"
                      :handleShowAddDataSource="handleShowAddDataSource"
                      :refreshLoading="refreshLoading"></DataSourceHeader>
    <div class="data-source-container">
      <div style="margin-top: 16px">
        <Table size="small" border :columns="dataSourceColumn" :data="showData">
          <template slot-scope="{ row }" slot="instanceId">
            <div style="position:relative;">
              <span>{{ row.instanceId }}</span>
              <Tooltip placement="right" class="alarm-icon" transfer content="存在异常的后台任务，请点击处理"
                       v-if="row.consoleTaskState==='FAILED'">
                <span style="display: inline-block" @click="handleGoConsoleJob(row)"><i
                  class="iconfont iconyibuforce"></i></span>
              </Tooltip>
              <Poptip placement="right" width="680" class="show-datasource-info-icon" transfer>
                <i @click="getDataSourceDetail(row)" class="iconfont iconinfo"></i>
                <div slot="content" style="line-height: 24px">
                  <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
                </div>
              </Poptip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="action">
            {{ '' }}
            <span v-if="row.lifeCycleState!=='CREATED'"
                  style="cursor: not-allowed;color: #BABDC5;margin-right: 16px">添加白名单</span>
            <a v-if="row.deployType!=='SELF_MAINTENANCE'&&row.lifeCycleState==='CREATED'"
               style="margin-right: 16px"
               @click="handleShowAddWhiteList(row)">添加白名单</a>
            <Dropdown transfer trigger="click">
              <a>
                更多
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <DropdownMenu slot="list">
                <DropdownItem>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">测试连接</span>
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleShowTestConnectionModal(row)">测试连接</a>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">修改外网地址</span>
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdatePublicHost(row)">修改外网地址</a>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdatePrivateHost(row)">修改内网地址</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">修改内网地址</span>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleEditAccount(row)">修改账号</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">修改账号</span>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdateAKSK(row)">修改阿里云AK/SK</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">修改阿里云AK/SK</span>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleDeleteAccount(row)">删除账号</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">删除账号</span>
                </DropdownItem>
                <DropdownItem>
                  <a @click="handleDeleteConfirm(row)"
                     style="width: 100%;display: inline-block">删除</a>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
          <template slot-scope="{ row }" slot="host">
            <div class="host-type">
              <p v-if="row.privateHost"><span class="host-type-label">内</span>{{ row.privateHost }}
              </p>
              <p v-if="row.publicHost" style="margin-top: 3px"><span
                class="host-type-label">外</span>{{ row.publicHost }}</p>
            </div>
          </template>
          <template slot-scope="{ row }" slot="dataSourceType">
            <div>
              <Tooltip style="margin-left: 10px;font-size: 24px;cursor: pointer" placement="right"
                       class="dataSource-icon" transfer
                       :content="`${row.deployType==='ALIBABA_CLOUD_HOSTED'?Mapping.aliyunType[row.dataSourceType]:row.dataSourceType}`">
                <DataSourceIcon :type="row.dataSourceType"
                                :instanceType="row.deployType"></DataSourceIcon>
              </Tooltip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="deployType">
            <div>
              <span>{{ row.deployType === 'SELF_MAINTENANCE' ? '自建数据库' : '阿里云' }}</span>
            </div>
          </template>
          <template slot-scope="{ row }" slot="instanceDesc">
            <div style="position: relative">
              {{ row.instanceDesc }}
              <i style="position: absolute;right: 5px;top: 0" @click="handleEditDataSourceDesc(row)"
                 class="iconfont iconbianji"></i>
            </div>
          </template>
        </Table>
      </div>
    </div>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
              :page-size="size"
              @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <StToken ref="stToken"></StToken>
    <AliyunAKSK ref="aliyunAKSK"></AliyunAKSK>
    <Modal
      v-model="showDeleteDataSourceConfirm"
      title="删除源数据源确认"
      @on-ok="deleteDataSource"
      footer-hide
    >
      <div>
        <p>确认要删除名为{{ selectedRow.instanceDesc }}({{ selectedRow.instanceId }})的数据源吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="deleteDataSource">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditDesc"
      title="修改数据源描述"
      footer-hide
      width="400px"
    >
      <div>
        <p>
          修改ID为{{ selectedRow.instanceId }}的数据源的描述为
        </p>
        <Input v-model="instanceDesc" style="width: 280px;margin-top: 20px"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditDesc">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditAccount"
      title="修改账号"
      footer-hide
      width="430px"
    >
      <div>
        <Form label-position="left" :label-width="60" style="margin-top: 10px" :model="accountInfo"
              :rules="accountInfoValidate"
              ref="account-info-form">
          <FormItem label="认证方式" key="securityType">
            <Select v-model="accountInfo.securityType" style="width: 280px">
              <Option v-for="security in securitySetting" :value="security.securityType"
                      :key="security.securityType">{{ Mapping.securityType[security.securityType] }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="账号" prop="account" key="account"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needUserName">
            <Input v-model="accountInfo.account" style="width: 280px"></Input>
          </FormItem>
          <FormItem label="密码" prop="password" key="password"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needPassword">
            <Input type="password" placeholder="默认不展示当前密码。请输入新密码" password
                   autocomplete="new-password"
                   v-model="accountInfo.password" style="width: 280px"></Input>
          </FormItem>
          <FormItem label="SSL 配置文件" prop="securityFile" key="securityFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needTlsFile">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile1"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
          </FormItem>
          <FormItem label="Kerberos 配置文件" prop="securityFile" key="securityFile2"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKrb5File">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile"/>
          </FormItem>
          <FormItem label="Keytab 文件" prop="secretFile" key="secretFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKeyTabFile">
            <input @change="handleKeyTabFileChange" type="file" name="uploadKeytabFile"
                   id="uploadKeytabFile"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmEditAccount">保存</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdateAKSK"
      title="修改阿里云AK/SK"
      footer-hide
      width="450px"
    >
      <div>
        <p style="color: #888888;margin-left: 8px;">该阿里云AS/SK仅用于数据同步时拉取备份日志文件，为必填项。</p>
        <Form label-position="right" :label-width="120" style="margin-top: 10px"
              ref="account-aksk-form" :model="accountAkSk" :rules="accountAkSkValidate">
          <FormItem label="AccessKey ID" prop="accessKey">
            <Input v-model="accountAkSk.accessKey" style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
          <FormItem label="AccessKey Secret" prop="secretKey">
            <Input type="password" password v-model="accountAkSk.secretKey"
                   style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmUpdateAKSK">保存</pd-button>
          <!--                    <pd-button type="error" ghost @click="handleDeleteAccount">删除</pd-button>-->
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showAddWhiteList"
      title="添加白名单"
      footer-hide
      width="430px"
    >
      <div>
        <Alert style="margin-bottom: 20px" type="warning" show-icon>设定访问机器，添加机器到数据源白名单</Alert>
        <Form label-position="left" :label-width="70" style="margin-top: 10px">
          <FormItem label="选择机器">
            <div class="add-white-list-container">
              <SelectCluster :row="selectedRow" ref="selectCluster"
                             :handleChangeType="handleChangeType"></SelectCluster>
            </div>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmAddWhiteList">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showAddWhiteListProgress" title="添加白名单" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentAddWhiteListStatus==='SUCCESS'?'success':(currentAddWhiteListStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentAddWhiteListStatus === 'SUCCESS' ? '添加成功' : currentAddWhiteListStatus === 'FAILED' ? '添加失败' : '添加白名单中...'
          }}</p>
      </div>
    </Modal>
    <Modal
      v-model="showEditPublicHost"
      title="修改数据源外网地址"
      footer-hide
      width="620px"
    >
      <div>
        <p>
          修改ID为{{ selectedRow.instanceId }}的数据源的外网地址为
        </p>
        <div style="margin-top: 20px">
          <Input v-model="publicIp" placeholder="ip或域名:端口" style="width: 460px"/>
          <!--          <span v-if="selectedRow.dataSourceType==='Oracle'">:</span>-->
          <!--          <Input v-if="selectedRow.dataSourceType==='Oracle'" placeholder="表空间名称" v-model="publicSid"-->
          <!--                 style="width: 100px"/>-->
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPublicHost">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditPrivateHost"
      title="修改数据源内网地址"
      footer-hide
      width="620px"
    >
      <div>
        <p>
          修改ID为{{ selectedRow.instanceId }}的数据源的内网地址为
        </p>
        <div style="margin-top: 20px">
          <Input v-model="privateIp" style="width: 460px" placeholder="ip或域名:端口"/>
          <!--          <span v-if="selectedRow.dataSourceType==='Oracle'">:</span>-->
          <!--          <Input v-if="selectedRow.dataSourceType==='Oracle'" v-model="sid" style="width: 100px" placeholder="sid"/>-->
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPrivateHost">确定</pd-button>
          <pd-button @click="handleCancelEdit">取消</pd-button>
        </div>
      </div>
    </Modal>
    <test-connection-modal :visible="showTestConnectionModal" :datasource="selectedRow" :handle-close-modal="handleCloseTestConnectionModal" type="dataSourceList"/>
  </div>
</template>
<script>
import {
  deleteAccount,
  deleteDataSource,
  listbycondition,
  queryById,
  updateAliyunRdsAkSk,
  updateDataSourceDesc,
  updateDataSourceHost,
  updatePublicHost
} from '@services/api/datasource';
import { dsSecurityOption, listFilterDsTypes } from '@services/api/constant';
import _ from 'lodash';
import { addClusterWhiteList } from '@services/api/ailyunRds';
import { queryConsoleJob } from '@services/api/consoleJob';
import { getUserQuota } from '@services/api/resourceQuota';
import DataSourceIcon from '@components/function/DataSourceIcon';
import DataSourceHeader from '@components/function/addDataSource/DataSourceHeader';
import DataSourceInDetail from '@components/function/addDataSource/DataSourceInDetail';
import SelectCluster from '@components/function/addDataSource/SelectCluster';
import StToken from '@components/function/ApplyStToken';
import AliyunAKSK from '@components/function/ApplyAKSK';
import store from '../../store/index';
import Mapping from '../util';
import { updateAccountAndPassword } from '../../services/api/upload';
import TestConnectionModal from '../../components/function/addDataSource/TestConnectionModal';

export default {
  components: {
    TestConnectionModal,
    DataSourceIcon,
    DataSourceHeader,
    DataSourceInDetail,
    SelectCluster,
    StToken,
    AliyunAKSK
  },
  mounted() {
    this.listDataSourceTypes();
    // this.getDataSourceList();
  },
  data() {
    return {
      showTestConnectionModal: false,
      securitySetting: [],
      securitySettingObj: {},
      store,
      publicHost: '',
      privateHost: '',
      publicIp: '',
      publicPort: '3306',
      privateIp: '',
      privatePort: '3306',
      sid: '',
      publicSid: '',
      showEditPublicHost: false,
      showEditPrivateHost: false,
      currentAddWhiteListStatus: '',
      currentPercentage: 0,
      showAddWhiteListProgress: false,
      showAddWhiteList: false,
      showEditAccount: false,
      showUpdateAKSK: false,
      accountInfo: {
        account: '',
        password: '',
        securityType: '',
        securityFile: '',
        secretFile: ''
      },
      accountAkSk: {
        accessKey: '',
        secretKey: ''
      },
      accountAkSkValidate: {
        accessKey: [
          {
            required: true,
            message: 'accessKey不能为空'
          }
        ],
        secretKey: [
          {
            required: true,
            message: 'secretKey不能为空'
          }
        ]
      },
      accountInfoValidate: {
        account: [
          {
            required: true,
            message: '账号不能为空'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空'
          }
        ],
        securityFile: [
          {
            required: true,
            message: 'SSL配置文件不能为空'
          }
        ],
        secretFile: [
          {
            required: true,
            message: 'Keytab 文件不能为空'
          }
        ]
      },
      sourceDetail: {},
      Mapping,
      regionFilters: [],
      showEditDesc: false,
      instanceDesc: '',
      selectedRow: {},
      refreshLoading: false,
      showAddDataSource: false,
      showDeleteDataSourceConfirm: false,
      dataSourceTypes: [],
      page: 1,
      size: 20,
      total: 0,
      addDataSourceForm: {
        host: '',
        type: 'MySQL',
        region: 'hangzhou',
        role: 'MASTER',
        instanceType: 'SELF_MAINTENANCE',
        sid: ''
      },
      searchKey: {
        host: '',
        region: '',
        dbType: 'all'
      },
      dataSourceColumn: [
        {
          title: '数据源ID',
          key: 'instanceId',
          slot: 'instanceId',
          width: 250
        },
        {
          title: '数据源描述',
          key: 'instanceDesc',
          minWidth: 300,
          slot: 'instanceDesc'
        },
        {
          title: 'host',
          key: 'host',
          minWidth: 520,
          slot: 'host'
        },
        {
          title: '类型',
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        {
          title: '创建状态',
          key: 'lifeCycleState',
          width: 110,
          render: (h, params) => h('div', { style: { color: params.row.lifeCycleState === 'CREATED' ? '#52C41A' : '#FF1815' } }, Mapping.createStatus[params.row.lifeCycleState])
        },
        {
          title: '部署类型',
          key: 'deployType',
          width: 150,
          slot: 'deployType',
          renderHeader: (h) => h('span', [
            h('span', {}, '部署类型'),
            h('Tooltip', {
              props: {
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('a',
                {
                  style: {
                    color: '#333'
                  }
                }, [
                  h('icon', {
                    props: {
                      type: 'md-help-circle'
                    },
                    style: {
                      marginLeft: '5px'
                    }
                  })
                ]),
              h('div', {
                slot: 'content'
              }, [
                h('p', {}, '【自建数据库】是用户自己部署的数据库实例'),
                h('p', {}, '【阿里云】是用户在阿里云上购买的数据库实例')
              ])
            ])
          ])
        },
        {
          title: '版本号',
          key: 'version',
          width: 180
        },
        {
          title: '区域',
          key: 'region',
          width: 100,
          render: (h, params) => h('div', {}, Mapping.region[params.row.region]),
          filters: this.regionFilters
        },
        {
          title: '所有者',
          key: 'owner',
          width: 120
        },
        {
          title: '操作',
          key: '',
          slot: 'action',
          width: 220,
          fixed: 'right'
        }
      ],
      dataSourceData: [],
      showData: [],
      pagingData: [],
      addDataSourceRule: {
        host: [
          {
            required: true,
            message: 'The host cannot be empty',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: 'The type cannot be empty',
            trigger: 'change'
          }
        ],
        role: [
          {
            required: true,
            type: 'string',
            message: 'The role cannot be empty',
            trigger: 'change'
          }
        ],
        region: [
          {
            required: true,
            type: 'string',
            message: 'The region cannot be empty',
            trigger: 'change'
          }
        ],
        instanceType: [
          {
            required: true,
            message: 'Please select type',
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    handleKeyTabFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.secretFile = file;
        this.$refs['account-info-form'].validateField('secretFile');
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.securityFile = file;
        this.$refs['account-info-form'].validateField('securityFile');
      }
    },
    handleRefresh() {
      this.getDataSourceList();
    },
    getDataSourceList(searchKey) {
      this.searchKey = searchKey;
      this.refreshLoading = true;
      let type = '';

      if (searchKey && searchKey.dbType !== 'all') {
        type = searchKey.dbType;
      }
      if (type) {
        listbycondition({ type })
          .then((res) => {
            if (res.data.code === '1') {
              this.dataSourceData = res.data.data;
              this.pagingData = _.cloneDeep(this.dataSourceData);
              this.total = this.dataSourceData.length;
              this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
              this.showData.map((item) => {
                item.showEditDesc = false;
                return null;
              });
            }
            this.refreshLoading = false;
          })
          .catch(() => {
            this.refreshLoading = false;
          });
      } else {
        listbycondition({})
          .then((res) => {
            if (res.data.code === '1') {
              this.dataSourceData = res.data.data;
              this.total = this.dataSourceData.length;
              this.pagingData = _.cloneDeep(this.dataSourceData);
              this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
              this.showData.map((item) => {
                item.showEditDesc = false;
                return null;
              });
            }
            this.refreshLoading = false;
          })
          .catch(() => {
            this.refreshLoading = false;
          });
      }
    },
    handleShowAddDataSource() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.map((item) => {
              if (item.quotaType === 'DATA_SOURCE_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: '额度不足',
                    content: '您当前的数据源额度不足，请去<a href="/system/userCenter">个人中心</a>申请额度。'
                  });
                } else {
                  this.$router.push({ path: '/data/dataSource/add' });
                }
              }
              return null;
            });
          }
        });
    },
    guid() {
      let num = '';

      for (let i = 0; i < 16; i++) {
        num += Math.floor(Math.random() * 10);
      }
      return num;
    },
    deleteDataSource() {
      this.showDeleteDataSourceConfirm = false;
      deleteDataSource({ dataSourceId: this.selectedRow.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.getDataSourceList();
            this.$Message.success('删除成功！');
          }
        });
    },
    handleDeleteConfirm(row) {
      this.selectedRow = row;
      this.showDeleteDataSourceConfirm = true;
    },
    listDataSourceTypes() {
      listFilterDsTypes({})
        .then((res) => {
          if (res.data.code === '1') {
            this.dataSourceTypes = res.data.data;
          }
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
      this.showData.map((item) => {
        item.showEditDesc = false;
        return null;
      });
    },
    handleConfirmEditDesc() {
      this.showEditDesc = false;
      updateDataSourceDesc({
        dataSourceId: this.selectedRow.id,
        instanceDesc: this.instanceDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.getDataSourceList();
            this.$Message.success('修改成功！');
          }
        });
    },
    handleCancelEdit() {
      this.showEditDesc = false;
      this.showEditAccount = false;
      this.showAddWhiteList = false;
      this.showEditPrivateHost = false;
      this.showEditPublicHost = false;
      this.showDeleteDataSourceConfirm = false;
      store.state.selectedCluster.selectedCluster = {};
      this.showUpdateAKSK = false;
    },
    handleEditDataSourceDesc(row) {
      this.instanceDesc = row.instanceDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    getDataSourceDetail(row, security = false) {
      queryById({ dataSourceId: row.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
            this.accountInfo.account = this.sourceDetail.accountName;
            this.accountInfo.securityType = this.sourceDetail.securityType;

            if (security) {
              console.log(res.data.data);
              const {
                deployType,
                dataSourceType
              } = res.data.data;
              dsSecurityOption({
                deployEnvType: deployType,
                dataSourceType
              })
                .then((res2) => {
                  if (res2.data.code === '1') {
                    this.securitySetting = res2.data.data.securityOptions;
                    const obj = {};
                    res2.data.data.securityOptions.forEach((s) => {
                      obj[s.securityType] = s;
                    });
                    this.securitySettingObj = obj;
                  }
                });
            }
          }
        });
    },
    confirmEditAccount() {
      this.$refs['account-info-form'].validate((valid) => {
        console.log(valid);
        if (valid) {
          const formData = new FormData();
          const datasourceUpdateData = {
            dataSourceId: this.sourceDetail.id,
            userName: this.accountInfo.account,
            password: this.accountInfo.password,
            securityType: this.accountInfo.securityType
          };
          formData.append('DataSourceUpdateData', JSON.stringify(datasourceUpdateData));
          formData.append('securityFile', this.accountInfo.securityFile);
          formData.append('secretFile', this.accountInfo.secretFile);
          console.log(formData.get('DataSourceUpdateData'));
          updateAccountAndPassword(formData)
            .then((res) => {
              if (res.data.code === '1') {
                this.showEditAccount = false;
                this.getDataSourceList();
                this.accountInfo = {
                  account: '',
                  password: '',
                  securityType: ''
                };
                this.$Message.success('修改成功！');
              }
            });
        }
      });
    },
    confirmUpdateAKSK() {
      this.$refs['account-aksk-form'].validate((valid) => {
        if (valid) {
          updateAliyunRdsAkSk({
            dataSourceId: this.sourceDetail.id,
            accessKey: this.accountAkSk.accessKey,
            secretKey: this.accountAkSk.secretKey
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.showUpdateAKSK = false;
                this.getDataSourceList();
                this.accountAkSk = {
                  secretKey: '',
                  accessKey: ''
                };
                this.$Message.success('修改成功！');
              }
            });
        }
      });
    },
    handleEditAccount(row) {
      this.showEditAccount = true;
      this.sourceDetail = row;
      this.getDataSourceDetail(row, true);
    },
    handleUpdateAKSK(row) {
      this.showUpdateAKSK = true;
      this.sourceDetail = row;
    },
    handleDeleteAccount(row) {
      this.accountInfo.account = '';
      this.accountInfo.password = '';
      this.accountInfo.securityType = '';
      this.sourceDetail = row;
      this.$Modal.confirm({
        title: '删除数据源账号确认',
        content: `<p>确认要删除${row.instanceId}的账号吗？</p>`,
        onOk: () => {
          deleteAccount({ dataSourceId: row.id })
            .then((res) => {
              if (res.data.code === '1') {
                this.$Message.success('删除账号成功！');
                this.getDataSourceList();
              }
            });
        },
        onCancel: () => {
        }
      });
    },
    handleShowAddWhiteList(row) {
      this.showAddWhiteList = true;
      this.selectedRow = row;
      if (this.selectedRow.publicHost) {
        this.selectedRow.whiteListAddType = 'PUBLIC_IP_ONLY';
      } else {
        this.selectedRow.whiteListAddType = 'PRIVATE_IP_ONLY';
      }
    },
    handleChangeType(type) {
      this.selectedRow.whiteListAddType = type;
    },
    confirmAddWhiteList() {
      this.showAddWhiteList = false;
      this.currentPercentage = 0;
      this.currentAddWhiteListStatus = '';
      const ids = [];
      const
        that = this;

      store.state.selectedCluster[this.selectedRow.instanceId].map((item) => {
        ids.push(item.id);
        return null;
      });
      addClusterWhiteList({
        dataSourceId: this.selectedRow.id,
        dataSourceType: this.selectedRow.dataSourceType,
        clusterIds: ids,
        whiteListAddType: this.selectedRow.whiteListAddType,
        dataSourceRegion: this.selectedRow.region
      })
        .then((res) => {
          if (res.data.code === '1' && this.dataIsNaN(res.data.data)) {
            this.showAddWhiteListProgress = true;

            this.querySourceAddWhiteListInterval = setInterval(() => {
              queryConsoleJob({ consoleJobId: res.data.data })
                .then((response) => {
                  if (res.data.code === '1') {
                    that.currentAddWhiteListStatus = response.data.data.taskState;
                    if (response.data.data.taskState === 'SUCCESS') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                      setTimeout(() => {
                        that.showAddWhiteListProgress = false;
                      }, 500);
                    } else if (response.data.data.taskState === 'FAILED') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                    }
                  }
                });
            }, 3000);
            store.state.selectedCluster.selectedCluster = {};
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });

      this.getPercantage = setInterval(() => {
        if (that.currentAddWhiteListStatus === 'SUCCESS') {
          that.currentPercentage = 100;
          clearInterval(that.getPercantage);
          setTimeout(() => {
            that.showAddWhiteList = false;
          }, 500);
        } else if (that.currentAddWhiteListStatus === 'FAILED') {
          clearInterval(that.getPercantage);
        } else if (that.currentPercentage < 90) {
          that.currentPercentage += Math.floor(Math.random() * 3);
        } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
          that.currentPercentage += Math.floor(Math.random() * 1.5);
        } else if (that.currentPercentage >= 97) {
          that.currentPercentage = 99;
        }
      }, 200);
    },
    handleCloseTestConnectionModal() {
      this.showTestConnectionModal = false;
    },
    handleShowTestConnectionModal(row) {
      this.showTestConnectionModal = true;
      this.selectedRow = row;
    },
    handleUpdatePublicHost(row) {
      this.showEditPublicHost = true;
      this.selectedRow = row;
      this.publicHost = row.publicHost;
      const list = this.publicHost.split(':');

      if (list.length > 1) {
        this.publicIp = this.publicHost;
        // oracle
        // this.publicSid = list[2];
      } else if (row.deployType === 'SELF_MAINTENANCE') {
        if (row.dataSourceType === 'MySQL') {
          this.publicPort = '3306';
        } else if (row.dataSourceType === 'PostgreSQL') {
          this.publicPort = '5432';
        } else if (row.dataSourceType === 'Greenplum') {
          this.publicPort = '5432';
        } else if (row.dataSourceType === 'Hive') {
          this.publicPort = '10000';
        }
      } else if (row.dataSourceType === 'MySQL') {
        this.publicPort = '3306';
      } else if (row.dataSourceType === 'PostgreSQL') {
        this.publicPort = '1433';
      } else if (row.dataSourceType === 'Greenplum') {
        this.publicPort = '3432';
      }
    },
    handleUpdatePrivateHost(row) {
      this.showEditPrivateHost = true;
      this.selectedRow = row;
      this.privateHost = row.privateHost;
      const list = this.privateHost.split(':');

      if (list.length > 1) {
        this.privateIp = this.privateHost;
        // oracle
        // this.sid = list[2];
      } else if (row.deployType === 'SELF_MAINTENANCE') {
        if (row.dataSourceType === 'MySQL') {
          this.privatePort = '3306';
        } else if (row.dataSourceType === 'PostgreSQL') {
          this.privatePort = '5432';
        } else if (row.dataSourceType === 'Greenplum') {
          this.privatePort = '5432';
        } else if (row.dataSourceType === 'Hive') {
          this.privatePort = '10000';
        }
      } else if (row.dataSourceType === 'MySQL') {
        this.privatePort = '3306';
      } else if (row.dataSourceType === 'PostgreSQL') {
        this.privatePort = '1433';
      } else if (row.dataSourceType === 'Greenplum') {
        this.privatePort = '3432';
      }
    },
    handleConfirmEditPublicHost() {
      if (!this.publicIp) {
        this.$Modal.warning({
          title: '数据源添加提示',
          content: '请填写完整的数据源信息'
        });
      } else if (this.selectedRow.dataSourceType === 'Oracle' && !this.publicSid) {
        this.$Modal.warning({
          title: '数据源添加提示',
          content: '请填写完整的数据源信息'
        });
      } else {
        updatePublicHost({
          dataSourceId: this.selectedRow.id,
          publicHost: this.selectedRow.dataSourceType === 'Oracle' ? `${this.publicIp}:${this.publicSid}` : this.publicIp
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.showEditPublicHost = false;
              this.getDataSourceList();
            }
          });
      }
    },
    handleConfirmEditPrivateHost() {
      updateDataSourceHost({
        dataSourceId: this.selectedRow.id,
        privateHost: this.selectedRow.dataSourceType === 'Oracle' ? this.privateIp : this.privateIp
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.showEditPrivateHost = false;
            this.getDataSourceList();
          }
        });
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/system/state/task/${row.consoleJobId}` });
    },
    dataIsNaN(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    }
  }
};
</script>
<style lang="less">
.data-source-container {
  position: relative;
  margin-top: 16px;
  margin-bottom: 74px;

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
    font-size: 12px;
  }

  .show-datasource-info-icon {
    color: #0BB9F8;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: -6px;
    cursor: pointer;
  }
}

.add-white-list-container {
  width: 280px;
  border: 1px solid #DADADA;
  padding: 0 12px;
  border-radius: 4px;
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-left: 4px;

  .iconyibuforce {
    font-size: 14px;
    color: #FF6E0D;
  }
}
</style>
