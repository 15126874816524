<template>
  <div class="finish-reset-body">
    <div style="text-align: center">
      <p class="success-add-title"><i class="iconfont iconwancheng"></i>找回密码</p>
      <p class="success-add-content">您已经成功设置密码，请试用新密码登录。</p>
    </div>
    <pd-button type="primary" style="width: 330px" @click="handleLogin">马上登录</pd-button>
  </div>
</template>
<script>
export default {
  methods: {
    handleLogin() {
      this.$router.push({ path: '/login' });
      window.location.reload();
    }
  }
};
</script>
<style lang="less" scoped>
.finish-reset-body {
  width: 500px;
  margin: 0 auto;
  padding: 30px 0 220px 0;
  position: relative;
  text-align: center;
}

.success-add-title {
  font-size: 20px;
  margin-bottom: 16px;

  .iconfont {
    font-size: 28px;
    margin-right: 10px;
    color: #52C41A;

  }
}

.success-add-content {
  margin-bottom: 40px;
  font-size: 14px;
}
</style>
